import React from "react";
import styled from "styled-components";
import { Card, Input, Form } from "antd";
import PropTypes from "prop-types";

import { Editor } from "../../commons";

export const CardInputTopicStyle = styled(Card)`
  border: none;
  padding: 1.5em;
  margin-bottom: 20px;
  &.text-right {
    text-align: right;
  }
  &.disable {
    background: #f5f5f5;
  }
  .ant-card-head {
    font-size: 18px;
    border-bottom: none !important;
    .ant-card-head-title {
      padding: 16px 0 0 0 !important;
    }
  }
  .ant-card-body {
    padding: 0 !important;
    .ant-form-item {
      margin-bottom: 5px !important;
    }
  }
`;

const CardInputTopic = ({
  isCheckInEvent,
  isDisable,
  valueTitle,
  nameTitle,
  onChangeTitle,
  valueDetail,
  onChangeDetail,
  valueAddress,
  onChangeAddress,
  valuePoints,
  onChangePoints,
  labelTitle,
}) => (
    <CardInputTopicStyle>
      <Form.Item
        name={[`${nameTitle}`]}
        label={labelTitle}
        initialValue={valueTitle}
        rules={[{ required: true, message: `กรุณากรอกข้อมูล ${labelTitle}!` }]}
      >
        <Input onChange={onChangeTitle} />
      </Form.Item>
      <Form.Item name={["detail"]} label="Detail">
        <Editor data={valueDetail} onChange={onChangeDetail} isRules />
      </Form.Item>
      {isCheckInEvent !== false ? (
        <Form.Item
          name={["place"]}
          label="สถานที่จัดงาน"
          initialValue={valueAddress}
          rules={[
            { required: true, message: "กรุณากรอกข้อมูล สถานที่จัดงาน!" },
          ]}
        >
          <Input onChange={onChangeAddress} />
        </Form.Item>
      ) : (
        ""
      )}
      {isCheckInEvent !== false ? (
        <Form.Item
          label="คะแนนที่ได้เพิ่ม"
          name={["points"]}
          initialValue={valuePoints}
          rules={[
            {
              required: true,
              message: "กรุณากรอกข้อมูล คะแนนที่ได้เพิ่ม!",
            },
          ]}
        >
          <Input
            type="number"
            suffix="Points"
            onChange={onChangePoints}
            disabled={isDisable === true ? true : false}
            onKeyDown={(evt) =>
              evt.key === "e" || evt.key === "E" || evt.key === "."
                ? evt.preventDefault()
                : {}
            }
            min={1}
          />
        </Form.Item>
      ) : (
        ""
      )}
    </CardInputTopicStyle>
  );
CardInputTopic.propTypes = {
  isCheckInEvent: PropTypes.bool.isRequired,
  isDisable: PropTypes.bool.isRequired,
  nameTitle: PropTypes.string,
  labelTitle: PropTypes.string,
  valueTitle: PropTypes.string,
  onChangeTitle: PropTypes.func,
  valueDetail: PropTypes.string,
  onChangeDetail: PropTypes.func,
  valueAddress: PropTypes.string,
  onChangeAddress: PropTypes.func,
  valuePoints: PropTypes.string,
  onChangePoints: PropTypes.func,
};
CardInputTopic.defaultProps = {
  className: "",
};
export default CardInputTopic;
