import React, { Component } from "react";
import { Dropdown, Menu, Avatar, Spin, Button, Modal } from "antd";
import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { TagStatus } from "../../commons";
import { eventsApi } from "../../../apis";
import { AdvancedTableSearch } from "../../views";
import { userAction } from "../../../store/action";
import { Layout } from "../../containers";

const { confirm } = Modal;

class Voucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      data: [],
      start: 0,
      length: 20,
      search: "",
      total: 0,
      modalVoucherId: 0,
      modalVoucherName: "",
      modalVoucherImg: "",
      dataPrint: [],
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await this.setState({ loading: true });
    const { start, length, search } = this.state;
    await eventsApi.eventsVoucherTable(start, length, search).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        data: resp.data,
        total: resp.recordsTotal,
        loading: false,
      }));
    });
    await eventsApi.eventsVoucherTableReport().then((resp) => {
      const data = [];
      resp.data.forEach((element) => {
        data.push({
          amount: element.events_details.amount,
          amount_used:
            element.events_details.amount_used &&
            element.events_details.amount_used,
          detail:
            element.events_details.detail && element.events_details.detail,
          end_date:
            element.events_details.end_date && element.events_details.end_date,
          start_date:
            element.events_details.start_date &&
            element.events_details.start_date,
          id: element.id,
          name: element.name,
          points: element.points,
          status: element.status,
          type: element.type,
          created_at: element.created_at,
          element,
        });
        this.setState((prevState) => ({
          ...prevState,
          dataPrint: data,
          loading: false,
        }));
      });
    });
    this.onLoadUser();
  };

  onLoadUser = async () => {
    this.props.userAction.userProfile();
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async (id) => {
    this.setState({ loading: true });
    await this.setState({
      visible: false,
    });
    await eventsApi.deleteEventsVoucher(id).then((resp) => {
      Swal.fire({
        icon: "success",
        title: `${resp.message}`,
        timer: 1500,
      }).then(() => {
        this.setState({
          loading: false,
        });
        this.componentDidMount();
      });
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleSearch = (value) => {
    this.setState({ loading: true });
    const { length } = this.state;
    eventsApi.eventsVoucherTable(0, length, value).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        data: resp.data,
        loading: false,
      }));
    });
  };

  handlePagination = (start, length, search) => {
    this.setState({ loading: true });
    this.setState((prevState) => ({
      ...prevState,
      start,
      length,
      search,
    }));
    eventsApi.eventsVoucherTable(start, length, search).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        data: resp.data,
        total: resp.recordsTotal,
        loading: false,
      }));
    });
  };

  onChangeSwitch = (record) => {
    const { start, length, search } = this.state;
    this.setState({ loading: true });
    const status = record.status === 1 ? 0 : 1;
    const { id } = record;

    eventsApi
      .eventsUpdateVoucherStatus(id, status)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: `${response.message}`,
          timer: 1500,
        }).then(() => {
          this.setState((prevState) => ({
            ...prevState,
            start,
            length,
            search,
          }));
          eventsApi.eventsVoucherTable(start, length, search).then((resp) => {
            this.setState((prevState) => ({
              ...prevState,
              data: resp.data,
              total: resp.recordsTotal,
              loading: false,
            }));
          });
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "success",
          title: `${error.response.data.message}`,
          timer: 1500,
        }).then(() => {
          this.setState({ loading: false });
        });
      });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[16].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, data, total, dataPrint, check_token } = this.state;
    const menu = (record) => (
      <Menu>
        <Menu.Item>
          <Link to={`/voucher/${record.id}`}>View</Link>
        </Menu.Item>
        {isEdit[0] === 1 && (
          <Menu.Item>
            <Link to={`/voucher/${record.id}/edit`}>Edit</Link>
          </Menu.Item>
        )}
        {isDelete[0] === 1 && (
          <Menu.Item>
            <Link
              to="/voucher"
              onClick={() => {
                confirm({
                  title: "ต้องการลบข้อมูล voucher ใช่หรือไม่?",
                  centered: true,
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => {
                    this.handleOk(record.id);
                  },
                });
              }}
            >
              Delete
            </Link>
          </Menu.Item>
        )}
      </Menu>
    );

    const columns = [
      {
        title: "Titile",
        dataIndex: "name",
        key: "title",
        type: "input",
        value: "",
        selectData: [],
        onChange: () => console.log("ok"),
        render: (text, record) => (
          <Link to={`/voucher/${record.id}`}>
            <Avatar
              shape="square"
              className="rounded"
              size={64}
              src={record.image_s}
            />
            <span className="ml-3">{record.name}</span>
          </Link>
        ),
      },
      {
        title: "Display date",
        dataIndex: "events_date",
        key: "Display date",
        type: "date",
        value: "",
        selectData: [],
        onChange: () => console.log("ok"),
        render: (text, record) => (
          <span>
            {`${record.events_details.start_date} - ${record.events_details.end_date}`}
          </span>
        ),
      },
      {
        title: "Points",
        dataIndex: "type_points",
        key: "point",
        type: "input",
        align: "right",
        value: "",
        selectData: [],
        onChange: () => console.log("ok"),
        render: (text, record) => <span>{record.points}</span>,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        type: "select",
        value: "",
        selectData: [],
        onChange: () => console.log("ok"),
        render: (text, record) => (
          <TagStatus
            onClick={() => this.onChangeSwitch(record)}
            StatusValue={text}
          />
        ),
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        width: "10%",
        render: (text, record) => (
          <Dropdown
            trigger={["click"]}
            overlay={menu(record)}
            placement="bottomRight"
          >
            <Button type="link">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];

    const headers = [
      { label: "Name", key: "name" },
      { label: "Detail", key: "detail" },
      { label: "Created At", key: "created_at" },
      { label: "Points", key: "points" },
      { label: "Amount", key: "amount" },
      { label: "Amount Used", key: "amount_used" },
      { label: "Start Date", key: "start_date" },
      { label: "End Date", key: "end_date" },
      { label: "Type", key: "type" },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="Voucher"
            extraBtn={
              isCreate[0] === 1
                ? [
                    <Button
                      type="primary"
                      onClick={() => this.props.history.push("/voucher/create")}
                    >
                      + Add Voucher
                    </Button>,
                  ]
                : []
            }
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "voucher",
                breadcrumbName: "Voucher",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <AdvancedTableSearch
                dataExport={dataPrint}
                fileName="artists"
                headers={headers}
                columns={columns}
                data={data}
                handleSearch={(value) => this.handleSearch(value)}
                onChangeSearch={(e) => this.handleSearch(e.target.value)}
                nonExport
                noDropdownSearch
                noFillter
                loading={loading}
                searchCondition={["image", "action", "point", "Display date"]}
                columnsFilter={columns}
                filterCondition={["action", "image"]}
                rowKey={(record) => record.id}
                total={total}
                onTableChange={this.handlePagination}
              />
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Voucher);
