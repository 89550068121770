import { httpClient } from "../components/utils/axios";

export const getProvinces = () =>
  httpClient
    .get(`/customers/provinces`)
    .then((res) => {
      if (res !== undefined) {
        return res.data.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getDistricts = (id) =>
  httpClient
    .post(`/customers/amphures`, { province_id: id })
    .then((res) => {
      if (res !== undefined) {
        return res.data.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getSubDistricts = (id) =>
  httpClient
    .post(`/customers/districts`, { amphure_id: id })
    .then((res) => {
      if (res !== undefined) {
        return res.data.data;
      }
    })
    .catch((error) => {
      throw error;
    });
