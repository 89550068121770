import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import { LayoutLogin } from "../../containers";
import firebase from "../../utils/firebase";

const PasswordForgetPage = () => {
  const [email, setEmail] = useState("");
  const history = useHistory();

  const onFinish = (values) => {
    firebase
      .auth()
      .sendPasswordResetEmail(values.email)
      .then(
        () => {
          Swal.fire({
            icon: "success",
            html: "Password reset email has been sent.",
            title: `ส่งข้อมูลไปที่อีเมล ${email} เรียบร้อย`,
            timer: 1000,
            showConfirmButton: false,
          }).then(() => {
            history.push("/");
          });
        },
        (error) => {
          const errorMessage = error.message;
          Swal.fire({
            icon: "error",
            html: `${errorMessage}`,
            timer: 1500,
            showConfirmButton: false,
          });
        },
      );
  };

  const inputOnChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <LayoutLogin
      title={
        <div className="form-title">
          <h1>Forgot password</h1>
        </div>
      }
    >
      <Form
        layout="vertical"
        className="form-login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your email!",
            },
          ]}
        >
          <Input
            type="email"
            size="large"
            value={email}
            onChange={inputOnChange}
          />
        </Form.Item>
        <Form.Item className="d-flex btn-wrap">
          <Button
            className="mr-auto"
            type="primary"
            size="large"
            htmlType="submit"
          >
            Send email
          </Button>
          <Link to="/" className="d-block">
            Back to login
          </Link>
        </Form.Item>
      </Form>
    </LayoutLogin>
  );
};

export default PasswordForgetPage;
