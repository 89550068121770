import { httpClient } from "../components/utils/axios";

export const createUser = (data) =>
  httpClient
    .post(`users`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const getUser = (id) =>
  httpClient
    .get(`users?id=${id}`)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const getMyProfile = () =>
  httpClient
    .post("/auth/login")
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const RoleList = () =>
  httpClient
    .get("/roles/list")
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const PermissionsList = (id) =>
  httpClient
    .get(`permissions?role_id=${id}`)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const PermissionsUpdate = (id, data) =>
  httpClient
    .put(`permissions?role_id=${id}`, { data })
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const createRole = (data) =>
  httpClient
    .post(`roles`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const RoleDelete = (id) =>
  httpClient
    .delete(`roles?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch(
      (error) => error.response,
      // throw error;
    );

export const userUpdate = (id, data) =>
  httpClient
    .put(`users?id=${id}`, data)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const UserTable = (start, length, search, type, type_filter) =>
  httpClient
    .get(
      `users/datatable?start=${start || 0}&length=${length || 20}&search=${
        search || ""
      }&type=${type || 0}&type_filter=${type_filter}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const deleteUserById = (id) =>
  httpClient
    .delete(`users?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch(
      (error) => error.response,
      // throw error;
    );

export const userLogTable = (start, length, search) =>
  httpClient
    .post(`users/logs/datatable`, {
      start,
      length,
      search,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const userLogTableReport = () =>
  httpClient
    .post(`users/logs/all`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const userTableReport = () =>
  httpClient
    .post(`users/all`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const modulesGet = () =>
  httpClient
    .get("/modules/datatable?start=0&length=50")
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const modulesUpdate = (id, data) =>
  httpClient
    .put(`modules?id=${id}`, data)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });
