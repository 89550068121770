import React, { Component } from "react";
import {
  Card,
  Col,
  Form,
  Row,
  Spin,
  Button,
  Input,
  DatePicker,
  Upload,
  Select,
  Radio,
} from "antd";
import * as Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import styled from "styled-components";
import ImgCrop from "antd-img-crop";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import QuillStyle from "../../commons/EditorQuill/QuillStyle";
import "react-quill/dist/quill.snow.css";

import { cmsApi } from "../../../apis";
import { httpClient } from "../../utils/axios";
import { Layout } from "../../containers";

const { Option } = Select;

const UploadStyle = styled(Upload)``;

async function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `You can only upload JPG/PNG file!`,
      timer: 1500,
    });
  }
  const isLt5M = file.size / 1024 / 1024 < 1;
  if (!isLt5M) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `Image must smaller than 1MB!`,
      timer: 1500,
    });
  }
  return isJpgOrPng && isLt5M;
}

// eslint-disable-next-line no-unused-vars
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", (e) => {
    const img = new Image();
    img.src = e.target.result;
    img.onload = () => {
      callback(reader.result);
    };
  });
  Swal.close();
  reader.readAsDataURL(img);
}

class NewsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: null,
      loading: true,
      onSave: "new",
      dataTag: [],
      addKey: "",
      editorData: "",
      tags: [],
      dataUser: [],
      status: 1,
      check_token: false,
    };
    this.reactQuillRef = null;
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = () => {
    this.setState({
      check_token: true,
    });
    cmsApi
      .getNews(this.props.match.params.newsId)
      .then((res) => {
        this.setState({
          title: res.data.title,
          auther: res.data.auther,
          editorData: res.data.content,
          imageUrl: res.data.image,
          status: res.data.status,
          tags: res.data.tags,
          startDate: res.data.start_date,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    cmsApi
      .NewsTagList()
      .then((resp) => {
        const dataTag = [];
        for (let i = 0; i < resp.data.length; i += 1) {
          dataTag.push({ id: resp.data[i].name, name: resp.data[i].name });
        }
        this.setState((prevState) => ({
          ...prevState,
          dataTag,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleCancel = () => {
    this.props.history.push("/campaign");
  };

  // การเปลื่ยนแปลงของ Photo
  handleChangePhoto = async (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      await getBase64(info.file.originFileObj, (imageUrl) => {
        this.setState({
          imageUrl,
          loading: false,
          imageFile: info.file.originFileObj,
        });
      });
    }
  };

  handleChangeInput = (changeObject) => {
    this.setState(changeObject);
  };

  onFinish = (values) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("content", values.content);
    formData.append(
      "start_date",
      moment(this.state.startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
    );
    formData.append("status", this.state.status);
    formData.append("image", this.state.imageFile);
    const tags = [];
    for (let i = 0; i < this.state.dataTag.length; i += 1) {
      for (let j = 0; j < values.tag.length; j += 1) {
        if (values.tag[j] === this.state.dataTag[i].id) {
          tags.push(this.state.dataTag[i].name);
        }
      }
    }
    for (let i = 0; i < tags.length; i += 1) {
      formData.append("tags[]", tags[i]);
    }

    Swal.fire({
      title: "ต้องการแก้ไขข้อมูลข่าวสารใช่หรือไม่",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "ระบบกำลังทำงาน",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        cmsApi
          .updateNews(formData, this.props.match.params.newsId)
          .then((resp) => {
            Swal.fire({
              icon: "success",
              title: `${resp.message}`,
              timer: 1500,
            }).then(() => this.props.history.push("/news"));
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
          });
      }
    });
  };

  disabledDate = (current) =>
    current && current < moment().add(-1, "days").endOf("day");

  saveOnchange = () => {
    this.setState({
      onSave: "loading",
    });
    setTimeout(() => {
      this.setState({
        onSave: "finish",
      });
    }, 1000);
  };

  onChangeText = (text) => {
    this.setState({ editorData: text });
  };

  addTage = async () => {
    const { tags } = this.state;
    tags.push(this.state.addKey);
    await this.setState({
      loading: true,
    });
    await this.setState({
      dataTag: this.state.dataTag.concat([
        { id: this.state.addKey, name: this.state.addKey },
      ]),
      tags,
    });
    await this.setState({
      loading: false,
      addKey: "",
    });
  };

  onChangeDate = (date, string) => {
    this.setState({
      startDate: string,
    });
  };

  imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      this.uploadFiles(file, this.reactQuillRef);
    };
  };

  uploadFiles = (uploadFileObj) => {
    const formData = new FormData();
    const that = this;
    formData.append("image", uploadFileObj);
    httpClient
      .post("/news/content/image", formData)
      .then((response) => {
        if (response.data) {
          const range = that.reactQuillRef.getEditorSelection();
          setTimeout(() => {
            that.reactQuillRef
              .getEditor()
              .insertEmbed(range.index, "image", response.data.url);
          }, 2000);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  render() {
    moment.locale("th");
    const { loading, imageUrl, dataTag, tags, check_token } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        {check_token === true && (
          <Layout
            title="Edit news"
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "news",
                breadcrumbName: "CMS",
              },
              {
                path: "news",
                breadcrumbName: "News",
              },
              {
                path: "",
                breadcrumbName: "Edit news",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              {loading === false && (
                <Form
                  layout="vertical"
                  onFinish={this.onFinish}
                  initialValues={{
                    detail: "",
                    points: 1,
                    amount: 1,
                    time_used: 1,
                  }}
                >
                  <Row gutter={[16, 16]}>
                    <Col md={16}>
                      <Card>
                        <Form.Item
                          label="Title"
                          name="title"
                          initialValue={this.state.title}
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล title!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) => {
                              this.handleChangeInput({
                                topicInputActivity: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                        <QuillStyle>
                          <Form.Item
                            initialValue={this.state.editorData}
                            name="content"
                            label="Content"
                          >
                            <ReactQuill
                              ref={(el) => {
                                this.reactQuillRef = el;
                              }}
                              theme="snow"
                              onChange={this.onChangeText}
                              modules={{
                                toolbar: {
                                  container: [
                                    [{ header: [3, 4, false] }],
                                    ["bold", "italic", "underline"],
                                    [
                                      {
                                        color: [
                                          "#000000",
                                          "#e60000",
                                          "#ff9900",
                                          "#ffff00",
                                          "#008a00",
                                          "#0066cc",
                                          "#9933ff",
                                          "#ffffff",
                                          "#facccc",
                                          "#ffebcc",
                                          "#ffffcc",
                                          "#cce8cc",
                                          "#cce0f5",
                                          "#ebd6ff",
                                          "#bbbbbb",
                                          "#f06666",
                                          "#ffc266",
                                          "#ffff66",
                                          "#66b966",
                                          "#66a3e0",
                                          "#c285ff",
                                          "#888888",
                                          "#a10000",
                                          "#b26b00",
                                          "#b2b200",
                                          "#006100",
                                          "#0047b2",
                                          "#6b24b2",
                                          "#444444",
                                          "#5c0000",
                                          "#663d00",
                                          "#666600",
                                          "#003700",
                                          "#002966",
                                          "#3d1466",
                                        ],
                                      },
                                    ],
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    ["link", "image", "video"],
                                    ["clean"],
                                  ],
                                  handlers: {
                                    image: this.imageHandler,
                                  },
                                },
                                clipboard: {
                                  matchVisual: false,
                                },
                              }}
                            />
                          </Form.Item>
                        </QuillStyle>
                      </Card>
                    </Col>
                    <Col md={8}>
                      <Card>
                        <Form.Item name="status" className="my-3">
                          <div className="d-flex">
                            <Radio.Group
                              defaultValue={this.state.status}
                              onChange={(e) =>
                                this.setState({ status: e.target.value })
                              }
                            >
                              <Radio value={1}>Active</Radio>
                              <Radio value={0}>Inactive</Radio>
                            </Radio.Group>
                            <Button
                              type="primary"
                              className="d-block ml-auto"
                              htmlType="submit"
                            >
                              Publish
                            </Button>
                          </div>
                        </Form.Item>
                        <Form.Item
                          label="Publish date"
                          name="public"
                          initialValue={moment(
                            this.state.startDate,
                            "DD/MM?YYYY",
                          )}
                          rules={[
                            {
                              required: true,
                              message: "กรุณาเลือก วันในการเริ่มและสิ้นสุด!",
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            onChange={this.onChangeDate}
                            format="DD/MM/YYYY"
                            disabledDate={this.disabledDate}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Feature image"
                          name="image"
                          extra={
                            <ul>
                              <li>Max file size 1MB.</li>
                              <li>Image with .jpg, .jpeg and .png</li>
                              <li>ขนาดรูปแนะนำ 1280*960px</li>
                            </ul>
                          }
                        >
                          <ImgCrop aspect={16 / 9}>
                            <UploadStyle
                              accept=".jpg, .jpeg, .png"
                              name="avartar"
                              customRequest={dummyRequest}
                              listType="picture-card"
                              multiple={false}
                              showUploadList={false}
                              beforeUpload={beforeUpload}
                              onChange={this.handleChangePhoto}
                            >
                              {imageUrl ? (
                                <img width="100%" src={imageUrl} alt="avatar" />
                              ) : (
                                uploadButton
                              )}
                            </UploadStyle>
                          </ImgCrop>
                        </Form.Item>
                        <Form.Item initialValue={tags} label="Tags" name="tag">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            onChange={(e) => this.setState({ tagSelect: e })}
                            onInputKeyDown={(e) =>
                              e.key === "Enter"
                                ? this.addTage()
                                : this.setState({
                                    addKey: e.target.value + e.key,
                                  })
                            }
                          >
                            {dataTag.map((doc) => (
                              <Option key={doc.id} value={doc.id}>
                                {doc.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          initialValue={this.state.auther}
                          label="Auther"
                          name="auther"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              )}
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

export default NewsEdit;
