import React, { Component } from "react";
import {
  Col,
  Form,
  Row,
  Spin,
  Button,
  Input,
  Card,
  Switch,
  Table,
  Menu,
  Typography,
} from "antd";
import MenuRoleStyle from "./MenuRoleStyle";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { RiDeleteBin7Fill } from "react-icons/ri";
// import { Link } from "react-router-dom";
import { Layout } from "../../containers";
import { userApi } from "../../../apis";
import { userAction } from "../../../store/action";

const { Text, Title } = Typography;
class RolePesmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      roleUser: [],
      roleName: "",
      moduleData: [],
      check_token: false,
    };
  }

  componentDidMount = async (sec) => {
    await this.setState({
      loading: true,
      roleName: "",
    });
    this.checkToken(sec);
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async (sec) => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData(sec);
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData(sec);
    }
  };

  fetchData = async (sec) => {
    await this.setState({
      check_token: true,
    });
    await this.setState({
      loading: true,
      roleName: "",
    });
    await userApi
      .RoleList()
      .then((resp) => {
        !sec &&
          this.setState((prevState) => ({
            ...prevState,
            roleUser: resp.data,
            roleCurrentName: resp.data[0].name,
            roleCurrentId: resp.data[0].id,
          }));
        userApi
          .PermissionsList(this.state.roleCurrentId)
          .then((resp) => {
            this.setState((prevState) => ({
              ...prevState,
              moduleData: resp.data,
              loading: false,
              roleCurrentName: this.state.roleCurrentName,
              roleCurrentId: this.state.roleCurrentId,
            }));
          })
          .catch((error) => {
            this.setState({ loading: false });
            throw error;
          });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  onChange = async (id, name) => {
    await this.setState({
      roleName: "",
      loading: true,
    });
    await userApi
      .PermissionsList(id)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          moduleData: resp.data,
          loading: false,
          roleCurrentName: name,
          roleCurrentId: id,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  onDelete = (id) => {
    Swal.fire({
      title: "ต้องการลบใช่หรือไม่",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "ระบบกำลังทำงาน",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        userApi.RoleDelete(id).then((resp) => {
          Swal.fire({
            icon: "success",
            title: `${resp.message}`,
            timer: 1500,
          });
          this.componentDidMount();
        });
      }
    });
  };

  onCheck = async (id, action, check) => {
    Swal.fire({
      title: "ระบบกำลังทำงาน",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    const data = this.state.moduleData;
    await data.forEach((doc, index) => {
      if (id === doc.module_id) {
        if (action === 0 && check === 0) {
          doc.actions[0].selected = check;
          doc.actions[1].selected = check;
          doc.actions[2].selected = check;
          doc.actions[3].selected = check;
        } else {
          doc.actions[action].selected = check;
        }
        const filtered = doc.actions.filter((value) => value.status === 1);
        data[index].actions = filtered;
      } else {
        const filtered = doc.actions.filter((value) => value.status === 1);
        data[index].actions = filtered;
      }
      delete doc.name;
    });
    await userApi
      .PermissionsUpdate(this.state.roleCurrentId, data)
      .then((resp) => {
        Swal.fire({
          icon: "success",
          title: `${resp.message}`,
          timer: 1500,
        }).then(() => {
          this.fetchData(true);
        });
        this.componentDidMount(true);
      });
    Swal.close();
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[24].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, roleUser, roleCurrentName, moduleData, check_token } =
      this.state;
    const columns =
      loading === true
        ? []
        : [
            {
              title: "Modules",
              dataIndex: "module",
              id: "module",
              render: (text, record) => <span>{record.name}</span>,
            },
            {
              title: "Read",
              dataIndex: "read",
              key: "read",
              align: "center",
              render: (text, record) =>
                record.actions[0].status === 1 ? (
                  <Switch
                    disabled={
                      isEdit[0] !== 1 || record.name === "dashboard"
                        ? true
                        : false
                    }
                    onChange={() =>
                      this.onCheck(
                        record.module_id,
                        0,
                        record.actions[0].selected === 1 ? 0 : 1,
                      )
                    }
                    defaultChecked={
                      record.actions[0].selected === 1 ? true : false
                    }
                  />
                ) : (
                  <span />
                ),
            },
            {
              title: "Create",
              dataIndex: "create",
              key: "create",
              align: "center",
              render: (text, record) =>
                record.actions[1].status === 1 ? (
                  <Switch
                    disabled={isEdit[0] === 1 ? false : true}
                    onChange={() =>
                      this.onCheck(
                        record.module_id,
                        1,
                        record.actions[1].selected === 1 ? 0 : 1,
                      )
                    }
                    defaultChecked={
                      record.actions[1].selected === 1 ? true : false
                    }
                  />
                ) : (
                  <span />
                ),
            },
            {
              title: "Update",
              dataIndex: "update",
              key: "update",
              align: "center",
              render: (text, record) =>
                record.actions[2].status === 1 ? (
                  <Switch
                    disabled={isEdit[0] === 1 ? false : true}
                    onChange={() =>
                      this.onCheck(
                        record.module_id,
                        2,
                        record.actions[2].selected === 1 ? 0 : 1,
                      )
                    }
                    defaultChecked={
                      record.actions[2].selected === 1 ? true : false
                    }
                  />
                ) : (
                  <span />
                ),
            },
            {
              title: "Delete",
              dataIndex: "delete",
              key: "delete",
              align: "center",
              render: (text, record) =>
                record.actions[3].status === 1 ? (
                  <Switch
                    disabled={isEdit[0] === 1 ? false : true}
                    onChange={() =>
                      this.onCheck(
                        record.module_id,
                        3,
                        record.actions[3].selected === 1 ? 0 : 1,
                      )
                    }
                    defaultChecked={
                      record.actions[3].selected === 1 ? true : false
                    }
                  />
                ) : (
                  <span />
                ),
            },
          ];
    const onFinish = () => {
      const data = {
        name: this.state.roleName,
      };
      Swal.fire({
        title: "ระบบกำลังทำงาน",
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        },
      });
      userApi.createRole(data).then(async (resp) => {
        Swal.fire({
          icon: "success",
          title: `${resp.message}`,
          timer: 1500,
        });
        this.componentDidMount();
      });
    };
    return (
      <>
        {check_token === true && (
          <Layout
            title="Roles and permissions"
            breadcrumb={[
              {
                id: "0",
                to: "/dashboard",
                title: "Dashboard",
              },
              {
                id: "1",
                to: "/role",
                title: "Roles and permissions",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  {isCreate[0] === 1 && (
                    <Card>
                      <Row
                        gutter={[8, 8]}
                        type="flex"
                        align="middle"
                        justify="center"
                      >
                        <Col span={20}>
                          <Form layout="vertical">
                            <Form.Item
                              name={["role"]}
                              rules={[
                                {
                                  required: true,
                                  message: "role name is required!",
                                },
                              ]}
                              label="Role"
                            >
                              {loading === false && (
                                <Input
                                  defaultValue=""
                                  ref={(el) => (this.inputRole = el)}
                                  onChange={(e) =>
                                    this.setState({ roleName: e.target.value })
                                  }
                                />
                              )}
                            </Form.Item>
                          </Form>
                        </Col>
                        <Col span={4} className="mt-1">
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={onFinish}
                          >
                            Add
                          </Button>
                        </Col>
                      </Row>
                      <MenuRoleStyle>
                        <Menu selectedKeys={[`${this.state.roleCurrentId}`]}>
                          {roleUser.map((doc) => (
                            <Menu.Item
                              onClick={() => this.onChange(doc.id, doc.name)}
                              key={doc.id}
                            >
                              <span className="menu-role_title text-capitalize">
                                {doc.name}
                              </span>
                              <Text type="danger" className="menu-role_icon">
                                <RiDeleteBin7Fill
                                  onClick={() => this.onDelete(doc.id)}
                                />
                              </Text>
                            </Menu.Item>
                          ))}
                        </Menu>
                      </MenuRoleStyle>
                    </Card>
                  )}
                </Col>
                <Col span={16}>
                  {loading === false && (
                    <Card>
                      <Title level={3}>
                        Permission of{" "}
                        <span className="text-capitalize">
                          {roleCurrentName}
                        </span>
                      </Title>
                      <Table
                        rowKey={(record) => record.module_id}
                        columns={columns}
                        dataSource={moduleData}
                        pagination={false}
                      />
                    </Card>
                  )}
                </Col>
              </Row>
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RolePesmission);
