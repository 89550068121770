import React from "react";
import PropTypes from "prop-types";
import { Menu, Divider } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { MenuStyle } from "./style";

const { SubMenu } = Menu;

const getPaths = (pathname) => {
  const paths = ["/"];
  if (pathname === "/") return paths;
  pathname.split("/").reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });
  return paths;
};

const LeftBar = ({ navi, userId, profileId, roleIds, menus }) => {
  const currPath = useLocation().pathname;
  const paths = getPaths(currPath);
  const navigation = (naviItem) =>
    naviItem.map((item) => {
      if (item.tag === "NavDropdown") {
        const havePath = [];
        item.id.forEach((element) => {
          menus.forEach((doc) => {
            if (`${element}` === `${doc.module_id}`) {
              havePath.push(1);
            }
          });
        });
        return (
          havePath.length > 0 && (
            <SubMenu
              key={item.name}
              icon={item.icon ? item.icon : ""}
              title={`${item.name}`}
            >
              {navigation(item.children)}
            </SubMenu>
          )
        );
      }
      if (item.tag === "NavDivider") {
        return (
          <li className="px-3" key="divider">
            <Divider />
          </li>
        );
      }
      if (item.tag === "NavTitle") {
        return (
          <li className="sidebar-nav-title" key="sidebar-nav-title">
            <span>{item.name}</span>
          </li>
        );
      }
      return (
        <Menu.Item
          style={{ display: !menus[+item.id] ? "none" : "" }}
          key={item.to}
        >
          {item.name === "Members" ? (
            <NavLink
              className=""
              to={{
                pathname: item.to,
                state: {
                  memberId: `${userId}`,
                  profileId: `${profileId}`,
                  roleId: roleIds,
                },
              }}
            >
              {item.icon ? (
                <>
                  {item.icon}
                  <span>{item.name}</span>
                </>
              ) : (
                `${item.name}`
              )}
            </NavLink>
          ) : (
            menus[+item.id] && (
              <NavLink className="" to={item.to}>
                {item.icon ? (
                  <>
                    {item.icon}
                    <span>{item.name}</span>
                  </>
                ) : (
                  `${item.name}`
                )}
              </NavLink>
            )
          )}
          {item.badge ? (
            <span
              className={`badge badge-pill badge-${
                item.badge && item.badge.color
              } ml-auto`}
            >
              {item.badge && item.badge.text}
            </span>
          ) : (
            ""
          )}
        </Menu.Item>
      );
    });

  return (
    <MenuStyle
      theme="light"
      mode="inline"
      defaultSelectedKeys={[`${paths}`]}
      selectedKeys={[`${currPath}`]}
    >
      {navigation(navi)}
    </MenuStyle>
  );
};

LeftBar.propTypes = {
  navi: PropTypes.array.isRequired,
};

export default LeftBar;
