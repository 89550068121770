import React from "react";
import { Dropdown } from "antd";
import { Link } from "react-router-dom";
import { HeaderStyle } from "./style";
import Avatar from "react-avatar";

const Header = (props) => {
  const { menu, name } = props;
  return (
    <HeaderStyle>
      <Link to="/dashboard">
        <img src="/assets/images/Logo@2x.png" alt="SPICYDISC" width={55} />
        <span>Spicydisc Backoffice</span>
      </Link>
      <div className="profile-menu">
        <Avatar
          size="48"
          round
          name={name || localStorage.getItem("userData") || ""}
        />
        <Dropdown trigger={["click"]} placement="bottomRight" overlay={menu}>
          <span className="profile-menu_name">
            {name || localStorage.getItem("userData") || ""}
          </span>
        </Dropdown>
      </div>
    </HeaderStyle>
  );
};

export default Header;
