import React, { Component } from "react";
import { Avatar, Dropdown, Menu, Spin, Button, Modal, Switch } from "antd";
import { TagStatus } from "../../commons";
import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { Layout } from "../../containers";
import { AdvancedTableSearch } from "../../views";
import { ArtistsApi } from "../../../apis";
import { userAction } from "../../../store/action";

const { confirm } = Modal;

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class ArtistsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      check_token: false,
      visible: false,
      data: [],
      dataPrint: [],
      start: 0,
      length: 20,
      search: "",
      total: 0,
      typeFilter: 0,
      typeSearch: 1,
      name: "",
      follow: "",
      status: "",
      song: "",
      album: "",
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await this.setState({ loading: true });
    const { start, length, search } = this.state;
    await ArtistsApi.ArtistTable(start, length, search).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        data: resp.data,
        total: resp.recordsTotal,
        loading: false,
      }));
    });
    await ArtistsApi.ArtistTableReport().then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        dataPrint: resp.data,
        loading: false,
      }));
    });
  };

  handleSearch = (typeFilter) => {
    this.setState({ loading: true });
    const { length, search, typeSearch, name, song, album, follow, status } =
      this.state;
    const roleId = [];
    if (typeSearch === 2) {
      if (search === "active" || search === "Active") {
        roleId.push(1);
      } else {
        roleId.push(0);
      }
    }
    const text = roleId.length > 0 ? roleId[0] : search;
    const type =
      typeFilter === 0
        ? typeFilter
        : `1&name=${name}&song=${song}&album=${album}&status=${status}&follow=${follow}`;
    ArtistsApi.ArtistTable(0, length, text, typeSearch, type)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          loading: false,
          typeFilter,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handlePaggination = (start, length) => {
    this.setState({ loading: true });
    const {
      search,
      typeSearch,
      name,
      song,
      album,
      follow,
      status,
      typeFilter,
    } = this.state;
    const roleId = [];
    if (typeSearch === 2) {
      if (search === "active" || search === "Active") {
        roleId.push(1);
      } else {
        roleId.push(0);
      }
    }
    const text = roleId.length > 0 ? roleId[0] : search;
    const type =
      typeFilter === 0
        ? typeFilter
        : `1&name=${name}&song=${song}&album=${album}&status=${status}&follow=${follow}`;
    ArtistsApi.ArtistTable(start, length, text, typeSearch, type)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
          start,
          length,
          search,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  onChangeSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async (id) => {
    await this.setState({ loading: true });
    await this.setState({
      visible: false,
    });
    await ArtistsApi.DeleteArtist(id).then((resp) => {
      Swal.fire({
        icon: "success",
        title: `${resp.message}`,
        timer: 1500,
      }).then(() => {
        this.setState({
          loading: false,
        });
        this.componentDidMount();
      });
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onSticky = (e, id) => {
    ArtistsApi.ArtistSticky(id).then(() => {
      Swal.fire({
        icon: "success",
        title: `Success`,
        timer: 1500,
      }).then(() => {
        this.setState({
          loading: false,
        });
        this.componentDidMount();
      });
    });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[9].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, data, dataPrint, total, check_token } = this.state;

    const menu = (record) => (
      <Menu className="datable-actions-menu">
        <Menu.Item>
          <Link
            to={{
              pathname: `/artists/${record.id}`,
            }}
          >
            View
          </Link>
        </Menu.Item>
        {isEdit[0] === 1 && (
          <Menu.Item>
            <Link
              to={{
                pathname: `/artists/${record.id}/edit`,
              }}
            >
              Edit
            </Link>
          </Menu.Item>
        )}
        {isDelete[0] === 1 && (
          <Menu.Item>
            <Link
              to="/artists"
              onClick={() => {
                confirm({
                  title: "ต้องการลบข้อมูลศิลปินใช่หรือไม่?",
                  centered: true,
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => {
                    this.handleOk(record.id);
                  },
                });
              }}
            >
              Delete
            </Link>
          </Menu.Item>
        )}
      </Menu>
    );

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        id: "name",
        type: "input",
        value: "",
        selectData: [],
        onChange: (e) => this.setState({ name: e.target.value }),
        render: (text, record) => (
          <Link to={`/artists/${record.id}`}>
            <Avatar className="rounded" size={64} src={record.image} />
            <span className="ml-3">{text}</span>
          </Link>
        ),
      },
      {
        title: "Followers",
        dataIndex: "total_follow",
        key: "total_follow",
        align: "right",
        type: "number",
        value: "",
        selectData: [],
        onChange: (e) => this.setState({ follow: e.target.value }),
        render: (text, record) => (
          <span>{formatNumber(+record.customers_follows)}</span>
        ),
      },
      {
        title: "Songs",
        dataIndex: "songs",
        key: "song",
        align: "right",
        type: "number",
        value: "",
        selectData: [],
        onChange: (e) => this.setState({ song: e.target.value }),
        render: (text) => <span>{formatNumber(+text)}</span>,
      },
      {
        title: "Albums",
        dataIndex: "albums",
        key: "albums",
        align: "right",
        type: "number",
        value: "",
        selectData: [],
        onChange: (e) => this.setState({ album: e.target.value }),
        render: (text) => <span>{formatNumber(+text)}</span>,
      },
      {
        title: "Sticky",
        dataIndex: "sticky",
        key: "sticky",
        type: "select",
        value: "all",
        selectData: [],
        render: (text, record) =>
          record.sticky === 1 ? (
            <Switch
              disabled={isEdit[0] === 1 ? false : true}
              onChange={(e) => this.onSticky(e, record.id)}
              defaultChecked
            />
          ) : (
            <Switch
              disabled={isEdit[0] === 1 ? false : true}
              onChange={(e) => this.onSticky(e, record.id)}
            />
          ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        type: "select",
        value: "all",
        selectData: [
          {
            id: 1,
            name: "Active",
          },
          { id: 0, name: "Suspend" },
        ],
        onChange: (status) => this.setState({ status }),
        render: (text) => <TagStatus StatusValue={text} />,
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Dropdown
            trigger={["click"]}
            overlay={menu(record)}
            placement="bottomRight"
          >
            <Button type="link">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];

    const headers = [
      { label: "Name", key: "name" },
      { label: "Total Follow", key: "customers_follows" },
      { label: "Songs", key: "songs" },
      { label: "Albums", key: "albums" },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="Artists"
            extraBtn={[
              <Button
                style={{ display: isCreate[0] === 1 ? "" : "none" }}
                type="primary"
                onClick={() => this.props.history.push("/artists/create")}
              >
                + Add Artist
              </Button>,
              <Button
                onClick={() => this.props.history.push("/artists/sticky")}
              >
                Sticky
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "artists",
                breadcrumbName: "Artists",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <AdvancedTableSearch
                nonExport
                dataExport={dataPrint}
                fileName="artists"
                headers={headers}
                columns={columns}
                data={data}
                handleSearch={this.handleSearch}
                onChangeSearch={this.onChangeSearch}
                loading={loading}
                defaultValueSearch="name"
                onChangeTypeSearch={(value) => {
                  this.setState({
                    typeSearch: value === "name" ? 1 : 2,
                  });
                }}
                searchCondition={[
                  "total_follow",
                  "song",
                  "albums",
                  "sticky",
                  "action",
                ]}
                clearFilter={() =>
                  this.setState({
                    name: "",
                    song: "",
                    follow: "",
                    status: "",
                    album: "",
                  })
                }
                columnsFilter={columns}
                filterCondition={["sticky", "action"]}
                rowKey={(record) => record.id}
                total={total}
                onTableChange={this.handlePaggination}
              />
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistsPage);
