/* eslint-disable no-plusplus */
import React, { Component } from "react";
import styled from "styled-components";
import {
  Col,
  Form,
  Row,
  Spin,
  Button,
  Card,
  Input,
  Upload,
  //   DatePicker,
  Select,
} from "antd";
import ImgCrop from "antd-img-crop";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import * as Swal from "sweetalert2";
import { Layout } from "../../containers";
import { songApi, ArtistsApi } from "../../../apis";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";

const { TextArea } = Input;
const { Option } = Select;

const UploadStyle = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
  }
`;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    console.log(file);
    onSuccess("ok");
  }, 0);
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", (e) => {
    const img = new Image();
    img.src = e.target.result;
    img.onload = () => {
      callback(reader.result);
    };
  });
  Swal.close();
  reader.readAsDataURL(img);
}
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  const isLt512k = file.size / 1024 / 1024 < 0.512;
  if (!isLt512k) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `Image must smaller than 512KB!`,
      timer: 1500,
    });
  }
  return isJpgOrPng && isLt512k;
};

class SongsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ListGenre: [],
      mediaMusicData: [],
      mediaVideoData: [],
      artistData: [],
      imageUrl: "",
      nameSong: "",
      nameSongMin: "",
      genres: [],
      artists: [],
      musicFile: "",
      musicUrl: "",
      videoFile: "",
      videoUrl: "",
      check_token: false,
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await this.setState({ loading: true });
    const view = songApi
      .GetSongById(this.props.match.params.songId)
      .then((resp) => {
        const genresArray = resp.data.genres.array;
        const artistArray = resp.data.artists.array;
        const artists = [];
        const genres = [];
        for (let i = 0; i < genresArray.length; i += 1) {
          genres.push(+genresArray[i].genre_id);
        }
        for (let i = 0; i < artistArray.length; i += 1) {
          artists.push(+artistArray[i].artist_id);
        }
        this.setState({
          nameSong: resp.data.name,
          description: resp.data.description,
          duration_minutes: resp.data.duration_minutes,
          duration_seconds: resp.data.duration_seconds,
          imageUrl: resp.data.image,
          genres,
          artists,
          release_years: resp.data.release_years,
          music: resp.data.media_music && resp.data.media_music.media_music_id,
          video: resp.data.media_video && resp.data.media_video.media_video_id,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    const mediaMusic = songApi
      .ListMedia(1)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          mediaMusicData: resp.data,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    const mediaVideo = songApi
      .ListMedia(2)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          mediaVideoData: resp.data,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    const artist = ArtistsApi.ArtistTableReport()
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          artistData: resp.data,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    const genre = songApi
      .ListGenre()
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          ListGenre: resp.data,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    await Promise.all([view, artist, genre, mediaMusic, mediaVideo]);
    await this.setState({ loading: false });
  };

  handleCancel = () => {
    this.props.history.push("/songs");
  };

  handleChangePhoto = async (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      await getBase64(info.file.originFileObj, (imageUrl) => {
        this.setState({
          imageUrl,
          imgFile: info.file.originFileObj,
          loading: false,
        });
      });
    }
  };

  handleChangeInput = (changeObject) => {
    this.setState(changeObject);
  };

  onFinish = (values) => {
    const { genres, artis } = values;
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("release_years", moment(values.year).format("YYYY"));
    formData.append("duration_minutes", this.state.duration_minutes);
    formData.append("duration_seconds", this.state.duration_seconds);
    formData.append("media_music_id", values.mFile);
    formData.append("media_video_id", values.vFile);
    formData.append("description", values.description);
    for (let i = 0; i < genres.length; i++) {
      formData.append("genres[]", +values.genres[i]);
    }
    for (let i = 0; i < artis.length; i++) {
      formData.append("artists[]", +values.artis[i]);
    }
    formData.append("image", this.state.imgFile);
    // console.log({
    //   name: values.name,
    //   release_years: moment(values.year).format("YYYY"),
    //   duration_minutes: this.state.duration_minutes,
    //   duration_seconds: this.state.duration_seconds,
    //   media_music_id: values.mFile,
    //   media_video_id: values.vFile,
    //   genres,
    //   artis,
    //   image: this.state.imgFile,
    // });
    Swal.fire({
      title: "ระบบกำลังทำงาน",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    songApi
      .EditSong(this.props.match.params.songId, formData)
      .then((resp) => {
        Swal.fire({
          icon: "success",
          title: `${resp.message}`,
          timer: 1500,
        }).then(() => {
          this.props.history.push("/songs");
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  render() {
    const {
      loading,
      imageUrl,
      artistData,
      mediaMusicData,
      mediaVideoData,
      ListGenre,
      check_token,
    } = this.state;

    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        {check_token === true && (
          <Layout
            title="Edit song"
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "songs",
                breadcrumbName: "Songs",
              },
              {
                path: "edit",
                breadcrumbName: "Edit song",
              },
            ]}
          >
            <Card className="mb-4">
              <Spin spinning={loading} tip="Loading...">
                {loading === false && (
                  <Form
                    layout="vertical"
                    size="medium"
                    onFinish={this.onFinish}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <p>Cover</p>
                        <Form.Item
                          extra={
                            <ul>
                              <li>Max file size 512Kb.</li>
                              <li>Image with .jpg, .jpeg and .png</li>
                              <li>ขนาดรูปแนะนำ 500*500px</li>
                            </ul>
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please upload image!",
                            },
                          ]}
                        >
                          <ImgCrop aspect={500 / 500}>
                            <UploadStyle
                              accept=".jpg, .jpeg, .png"
                              name="avartar"
                              customRequest={dummyRequest}
                              listType="picture-card"
                              multiple={false}
                              showUploadList={false}
                              beforeUpload={beforeUpload}
                              onChange={this.handleChangePhoto}
                              onPreview={this.onPreview}
                            >
                              {imageUrl ? (
                                <img width="100%" src={imageUrl} alt="avatar" />
                              ) : (
                                uploadButton
                              )}
                            </UploadStyle>
                          </ImgCrop>
                        </Form.Item>
                      </Col>
                      <Col span={16}>
                        <Form.Item
                          initialValue={this.state.nameSong}
                          label="Name"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please input name!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          initialValue={this.state.description}
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: "Please input description!",
                            },
                          ]}
                        >
                          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                        </Form.Item>
                        <Form.Item
                          initialValue={moment(
                            `${this.state.release_years}`,
                            "YYYY",
                          ).format("YYYY")}
                          label="Release year"
                          name="year"
                          rules={[
                            {
                              required: true,
                              message: "Please input release year!",
                            },
                          ]}
                        >
                          <Input
                            required
                            type="number"
                            onKeyDown={(evt) =>
                              evt.key === "e" ||
                              evt.key === "E" ||
                              evt.key === "."
                                ? evt.preventDefault()
                                : {}
                            }
                            onChange={(e) =>
                              this.setState({ sec: e.target.value })
                            }
                            min={1990}
                          />
                          {/* <DatePicker
                        placeholder=""
                        format="YYYY"
                        // picker="year"
                        style={{ width: "100%" }}
                      /> */}
                        </Form.Item>
                        <Form.Item label="Duration" name="duration">
                          <Row>
                            <Col span={11}>
                              <Input
                                value={this.state.duration_minutes}
                                required
                                type="number"
                                onKeyDown={(evt) =>
                                  evt.key === "e" ||
                                  evt.key === "E" ||
                                  evt.key === "."
                                    ? evt.preventDefault()
                                    : {}
                                }
                                suffix="นาที"
                                onChange={(e) =>
                                  this.setState({
                                    duration_minutes: e.target.value,
                                  })
                                }
                                min={0}
                              />
                            </Col>
                            <Col className="text-center" span={2}>
                              <span>:</span>
                            </Col>
                            <Col span={11}>
                              <Input
                                value={this.state.duration_seconds}
                                required
                                type="number"
                                onKeyDown={(evt) =>
                                  evt.key === "e" ||
                                  evt.key === "E" ||
                                  evt.key === "."
                                    ? evt.preventDefault()
                                    : {}
                                }
                                suffix="วินาที"
                                onChange={(e) =>
                                  this.setState({
                                    duration_seconds: e.target.value,
                                  })
                                }
                                min={0}
                                max={60}
                              />
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item
                          initialValue={this.state.artists}
                          rules={[
                            {
                              required: true,
                              message: "Please selete!",
                            },
                          ]}
                          label="Artists"
                          name="artis"
                        >
                          <Select
                            mode="multiple"
                            showSearch
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            onChange={this.saveOnchange}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {artistData.map((doc) => (
                              <Option value={doc.id} key={doc.id}>
                                {doc.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          initialValue={this.state.genres}
                          rules={[
                            {
                              required: true,
                              message: "Please selete!",
                            },
                          ]}
                          label="Genres"
                          name="genres"
                        >
                          <Select
                            mode="multiple"
                            showSearch
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            onChange={this.saveOnchange}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {ListGenre.map((doc) => (
                              <Option value={doc.id} key={doc.id}>
                                {doc.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          initialValue={this.state.music}
                          rules={[
                            {
                              required: true,
                              message: "Please selete!",
                            },
                          ]}
                          label="Music file"
                          name="mFile"
                        >
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {mediaMusicData.map((doc) => (
                              <Option key={doc.id} value={doc.id}>
                                {doc.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          initialValue={this.state.video}
                          rules={[
                            {
                              required: true,
                              message: "Please selete!",
                            },
                          ]}
                          label="Video file"
                          name="vFile"
                        >
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {mediaVideoData.map((doc) => (
                              <Option key={doc.id} value={doc.id}>
                                {doc.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item className="my-3">
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                      <Button
                        className="mx-4"
                        onClick={() => this.handleCancel()}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </Spin>
            </Card>
          </Layout>
        )}
      </>
    );
  }
}

export default SongsEdit;
