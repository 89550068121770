import React, { Component } from "react";
import {
  Card,
  Col,
  Form,
  Row,
  Spin,
  Button,
  Input,
  DatePicker,
  Upload,
  Radio,
  Modal,
} from "antd";
import * as Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import QuillStyle from "../../commons/EditorQuill/QuillStyle";
import { httpClient } from "../../utils/axios";

import { Layout } from "../../containers";
import { eventsApi } from "../../../apis";

// const { Search } = Input;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", (e) => {
    const img = new Image();
    img.src = e.target.result;
    img.onload = () => {
      callback(reader.result);
    };
  });
  Swal.close();
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  Swal.fire({
    title: "ระบบกำลังทำงาน",
    allowEscapeKey: false,
    allowOutsideClick: false,
    onOpen: () => {
      Swal.showLoading();
    },
  });
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `You can only upload JPG/PNG file!`,
      timer: 1500,
    });
  }
  const isLt10M = file.size / 1024 / 1024 < 1;
  if (!isLt10M) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `Image must smaller than 1MB!`,
      timer: 1500,
    });
  }
  return isJpgOrPng && isLt10M;
}

const { RangePicker } = DatePicker;

class VoucherEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      imageUrl: null,
      nameCode: "",
      radioStatusValueEdit: 0,
      typeCode: 1,
      exampleCode: [],
      visible: false,
      check_token: false,
    };
    this.reactQuillRef = null;
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  handleChangeInput(changeObject) {
    this.setState(changeObject);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const id = this.props.match.params.voucherId;
    this.setState({ loading: true });

    const setData = (doc) => {
      const start = doc.events_details && doc.events_details.start_date;
      const end = doc.events_details && doc.events_details.end_date;
      const startDate = moment(start, "DD/MM/YYYY").format("YYYY-MM-DD");
      const endDate = moment(end, "DD/MM/YYYY").format("YYYY-MM-DD");

      this.setState({
        topicInputEdit: doc.name,
        textAreaInputEdit: doc.events_details && doc.events_details.detail,
        typeCode: doc.events_details && doc.events_details.type_code,
        // nameCodeEdit: doc.events_details && doc.events_details.code,
        quantityExchangeEdit: `${
          doc.events_details && doc.events_details.amount_used
        }`, // จำนวนครั้งในการแลก
        valueEditVoucher: `${doc.events_details && doc.events_details.amount}`,
        quantityPointEdit: doc.points,
        timeCategoryEdit: `${
          doc.events_details && doc.events_details.time_used
        }`,
        // status_update: doc.status_update,
        // radioTypeValueEdit:
        //   doc.events_details && doc.events_details.type_used === 1 ? 0 : 1,
        imageUrl: doc.image_m,
        imageFile: null,
        startDate,
        endDate,
        radioStatusValueEdit: doc.status,
        // amountFirst: `${doc.events_details && doc.events_details.amount}`,
      });
    };

    eventsApi
      .eventsVoucherViewId(id)
      .then(async (response) => {
        await setData(response.data);
        await this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
          timer: 1500,
        }).then(() => {
          this.setState({
            loading: false,
          });
          this.props.history.goBack();
        });
      });
  };

  handleCancel = () => {
    this.props.history.push("/voucher");
  };

  // การเปลื่ยนแปลงของ Photo
  handleChangePhoto = async (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      await getBase64(info.file.originFileObj, (imageUrl) => {
        console.log(imageUrl);
        this.setState({
          imageUrl,
          imageFile: info.file.originFileObj,
          loading: false,
        });
      });
    }
  };

  onChangeDate = (value) => {
    this.setState({
      startDate: value[0],
      endDate: value[1],
    });
  };

  onChangeText = (text) => {
    this.setState({ textAreaInputEdit: text });
  };

  onFinish = (values) => {
    const id = this.props.match.params.voucherId;
    const that = this.props;
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("detail", this.state.textAreaInputEdit);
    formData.append("amount", +this.state.valueEditVoucher);
    formData.append("amount_used", +values.amount_used);
    formData.append("type_code", +values.type_code);
    formData.append("time_used", +values.time_used);
    formData.append("points", +values.points);
    formData.append(
      "status",
      values.status !== undefined
        ? values.status
        : this.state.radioStatusValueEdit,
    );
    formData.append("end_date", moment(this.state.endDate).unix());
    formData.append("image", this.state.imageFile);

    if (this.state.status_update === 1) {
      Swal.fire({
        icon: "warning",
        title: `ระบบกำลังอัพค่า`,
        timer: 1500,
      }).then(() => {
        that.history.push("/voucher");
      });
    } else {
      Swal.fire({
        title: "ต้องการแก้ไขข้อมูลคูปองใช่หรือไม่",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#0c7973",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: "ระบบกำลังทำงาน",
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              Swal.showLoading();
            },
          });
          eventsApi.eventsUpdateVoucher(id, formData).then((resp) => {
            Swal.close();
            if (resp.status === 400) {
              Swal.fire({
                icon: "error",
                title: `${resp.data.message}`,
                timer: 1500,
              }).then(() => {
                Swal.close();
                this.setState({
                  loading: false,
                });
              });
            } else {
              Swal.fire({
                icon: "success",
                title: `${resp.message}`,
                timer: 1500,
              }).then(() => {
                that.history.push("/voucher");
                Swal.close();
              });
            }
          });
        }
      });
    }
  };

  // handleChangePoints = (e) => {
  //   if (e.target.value === "0") {
  //     this.setState({
  //       quantityPoint: e.target.value,
  //     });
  //   } else {
  //     this.setState({
  //       quantityPoint: e.target.value,
  //     });
  //   }
  // };

  onChange = (e) => {
    const { value } = e.target;
    const valueToUpperCase = value.toUpperCase();
    if (valueToUpperCase.match(/^[A-Z0-9]{0,40}$/)) {
      if (valueToUpperCase !== "") {
        this.setState({
          nameCode: valueToUpperCase,
        });
      } else {
        this.setState({
          nameCode: "",
        });
      }
    }
  };

  onChangeType = (e) => {
    this.setState({
      typeCode: e.target.value,
    });
  };

  testCode = (type, code) => {
    eventsApi.eventsVoucherTestCode(type, code).then((res) => {
      this.setState({
        visible: true,
        exampleCode: res.data,
      });
    });
  };

  randomCode = () => {
    this.setState({
      nameCode: "",
    });
    Swal.fire({
      title: "กำลังสร้างโค้ด",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    eventsApi.eventsVoucherRandomCode().then((res) => {
      this.setState({
        nameCode: res.data,
      });
      Swal.close();
    });
  };

  disabledDate = (current) =>
    current && current < moment().add(-1, "days").endOf("day");

  uploadFiles = (uploadFileObj) => {
    const formData = new FormData();
    const that = this;
    formData.append("image", uploadFileObj);
    httpClient
      .post("/news/content/image", formData)
      .then((response) => {
        if (response.data) {
          const range = that.reactQuillRef.getEditorSelection();
          setTimeout(() => {
            that.reactQuillRef
              .getEditor()
              .insertEmbed(range.index, "image", response.data.url);
          }, 2000);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      this.uploadFiles(file, this.reactQuillRef);
    };
  };

  render() {
    const { loading, radioStatusValueEdit, imageUrl, check_token } = this.state;

    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        {check_token === true && (
          <Layout
            title="Edit voucher"
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "voucher",
                breadcrumbName: "Voucher",
              },
              {
                path: "edit",
                breadcrumbName: "Edit voucher",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              {loading === false && (
                <Form layout="vertical" onFinish={this.onFinish}>
                  <Row gutter={[16, 16]}>
                    <Col md={16}>
                      <Card>
                        <Form.Item
                          label="Title"
                          name="name"
                          initialValue={this.state.topicInputEdit}
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล title!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) =>
                              this.handleChangeInput({
                                topicInputActivity: e.target.value,
                              })
                            }
                          />
                        </Form.Item>
                        <QuillStyle>
                          <Form.Item
                            initialValue={this.state.textAreaInputEdit}
                            name="detail"
                            label="Detail"
                          >
                            <ReactQuill
                              ref={(el) => {
                                this.reactQuillRef = el;
                              }}
                              theme="snow"
                              onChange={this.onChangeText}
                              modules={{
                                toolbar: {
                                  container: [
                                    [{ header: [3, 4, false] }],
                                    ["bold", "italic", "underline"],
                                    [
                                      {
                                        color: [
                                          "#000000",
                                          "#e60000",
                                          "#ff9900",
                                          "#ffff00",
                                          "#008a00",
                                          "#0066cc",
                                          "#9933ff",
                                          "#ffffff",
                                          "#facccc",
                                          "#ffebcc",
                                          "#ffffcc",
                                          "#cce8cc",
                                          "#cce0f5",
                                          "#ebd6ff",
                                          "#bbbbbb",
                                          "#f06666",
                                          "#ffc266",
                                          "#ffff66",
                                          "#66b966",
                                          "#66a3e0",
                                          "#c285ff",
                                          "#888888",
                                          "#a10000",
                                          "#b26b00",
                                          "#b2b200",
                                          "#006100",
                                          "#0047b2",
                                          "#6b24b2",
                                          "#444444",
                                          "#5c0000",
                                          "#663d00",
                                          "#666600",
                                          "#003700",
                                          "#002966",
                                          "#3d1466",
                                        ],
                                      },
                                    ],
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    ["link", "image", "video"],
                                    ["clean"],
                                  ],
                                  handlers: {
                                    image: this.imageHandler,
                                  },
                                },
                                clipboard: {
                                  matchVisual: false,
                                },
                              }}
                            />
                          </Form.Item>
                        </QuillStyle>
                      </Card>
                      <Card className="mt-4">
                        {/* <Form.Item
                      name={["type_code"]}
                      label="รูปแบบโค้ด"
                      initialValue={`${this.state.typeCode}`}
                      onChange={this.onChangeType}
                    >
                      <Radio.Group>
                        <Radio value="1">โค้ดแบบมีคำนำหน้า</Radio>
                        <Radio value="2">โค้ดชุดเดียว</Radio>
                        <Radio value="3">โค้ดแบบสุ่ม</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {+this.state.typeCode === 1 ||
                    +this.state.typeCode === 2 ? (
                      <>
                        <Form.Item
                          initialValue={this.state.nameCodeEdit}
                          className="mb-0"
                          name={["code"]}
                          label="Code"
                        >
                          <Search
                            allowClear
                            enterButton="สุ่มตัวอักษร"
                            value={this.state.nameCode}
                            onChange={this.onChange}
                            onSearch={this.randomCode}
                          />
                          <p className="mt-2">
                            กรอกได้เฉพาะตัวใหญ่ A-Z และตัวเลข 0-9 ไม่เกิน 40 ตัว
                          </p>
                        </Form.Item>
                      </>
                    ) : null} */}
                        <Form.Item label="สิทธิ์ในการแลก" name="amount">
                          <Input
                            type="number"
                            value={this.state.valueEditVoucher}
                            onChange={(e) =>
                              this.handleChangeInput({
                                valueEditVoucher: e.target.value,
                              })
                            }
                            onKeyDown={(evt) =>
                              evt.key === "e" ||
                              evt.key === "E" ||
                              evt.key === "."
                                ? evt.preventDefault()
                                : {}
                            }
                            min={1}
                          />
                          <p className="mt-2">ขั้นต่ำคือ 1</p>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() =>
                              this.testCode(
                                +this.state.typeCode,
                                this.state.nameCode,
                              )
                            }
                          >
                            กดดูตัวอย่างการสร้างโค้ด
                          </Button>
                        </Form.Item>
                      </Card>
                    </Col>
                    <Col md={8}>
                      <Card>
                        <Form.Item className="my-3">
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                          <Button
                            className="mx-4"
                            onClick={() => this.props.history.goBack()}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                        <Form.Item
                          label="Display day"
                          name="public"
                          rules={[
                            {
                              required: true,
                              message: "กรุณาเลือก วันในการเริ่มและสิ้นสุด!",
                            },
                          ]}
                          initialValue={[
                            moment(this.state.startDate, "YYYY-MM-DD"),
                            moment(this.state.endDate, "YYYY-MM-DD"),
                          ]}
                        >
                          <RangePicker
                            style={{ width: "100%" }}
                            onChange={this.onChangeDate}
                            format="DD/MM/YYYY"
                            disabledDate={this.disabledDate}
                          />
                        </Form.Item>
                        <Form.Item
                          label="สิทธิ์ในการแลก"
                          name="amount_used"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล สิทธิ์ในการแลกต่อคน",
                            },
                          ]}
                          initialValue={this.state.quantityExchangeEdit}
                        >
                          <Input
                            type="number"
                            suffix="ครั้ง/คน/โค้ด"
                            onChange={(e) =>
                              this.handleChangeInput({
                                quantityExchangeEdit: e.target.value,
                              })
                            }
                            onKeyDown={(evt) =>
                              evt.key === "e" ||
                              evt.key === "E" ||
                              evt.key === "."
                                ? evt.preventDefault()
                                : {}
                            }
                            min={1}
                          />
                        </Form.Item>
                        <Form.Item
                          label="ระยะเวลาในการแสดงผล"
                          name="time_used"
                          initialValue={this.state.timeCategoryEdit}
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกระยะเวลาในการแสดงผลตอนใช้งาน!",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            suffix="นาที"
                            // onChange={(e) =>
                            //   this.setState({ timeCategory: e.target.value })
                            // }
                            onKeyDown={(evt) =>
                              evt.key === "e" ||
                              evt.key === "E" ||
                              evt.key === "."
                                ? evt.preventDefault()
                                : {}
                            }
                            min={1}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Point ที่ได้"
                          name="points"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล คะแนนที่ได้เพิ่ม!",
                            },
                          ]}
                          initialValue={this.state.quantityPointEdit}
                        >
                          <Input
                            type="number"
                            suffix="Points"
                            disabled
                            onChange={(e) =>
                              this.handleChangeInput({
                                quantityPointEdit: e.target.value,
                              })
                            }
                            onKeyDown={(evt) =>
                              evt.key === "e" ||
                              evt.key === "E" ||
                              evt.key === "."
                                ? evt.preventDefault()
                                : {}
                            }
                            min={1}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Feature image"
                          name="image"
                          extra={
                            <ul>
                              <li>Max file size 1MB.</li>
                              <li>Image with .jpg, .jpeg and .png</li>
                              <li>Image size 1920*1080</li>
                            </ul>
                          }
                        >
                          <Upload
                            accept=".jpg, .jpeg, .png"
                            name="upload-img"
                            listType="picture-card"
                            className="form__action avatar-uploader w-100"
                            showUploadList={false}
                            multiple={false}
                            action={`${process.env.REACT_APP_FRONT_API}test`}
                            beforeUpload={beforeUpload}
                            onChange={this.handleChangePhoto}
                          >
                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                className="img-fluid"
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </Form.Item>
                        <Form.Item
                          name="status"
                          label="status"
                          initialValue={radioStatusValueEdit === 1 ? 1 : 0}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              this.setState({
                                radioStatusValueEdit: e.target.value,
                              })
                            }
                            value={radioStatusValueEdit}
                          >
                            <Radio value={0}>Suspend</Radio>
                            <Radio value={1}>Active</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              )}
              <Modal
                title="ตัวอย่างโค้ดที่สร้าง"
                centered
                visible={this.state.visible}
                onOk={() => this.setState({ visible: false })}
                onCancel={() => this.setState({ visible: false })}
                footer={null}
              >
                {this.state.exampleCode.map((item) => (
                  <p key={item.id}>{item}</p>
                ))}
              </Modal>
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

export default VoucherEdit;
