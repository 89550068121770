import { httpClient } from "../components/utils/axios";

export const getContactUs = (offset, limit) =>
  httpClient
    .get(`/contact_us?offset=${offset || 1}&limit=${limit || 10}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const updateContactUs = (updateData) =>
  httpClient
    .put(`/contact_us/${updateData.id}`, {
      title: updateData.title,
      detail: updateData.detail,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
