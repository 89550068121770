import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import * as serviceWorker from "./serviceWorker";

import "antd/dist/antd.less";
import "react-dual-listbox/lib/react-dual-listbox.css";
import "sweetalert2/src/sweetalert2.scss";
import "react-dynamic-charts/dist/index.css";
import "./stylesheet/scss/global.scss";
import rootReducer from "./store/reducers";
// import { userAction } from '../src/store/action';

// let store = createStore(rootReducer, {}, applyMiddleware(thunk));
const store = createStore(rootReducer, {}, compose(applyMiddleware(thunk)));

// store.dispatch(userAction.userProfile());

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root"),
);

serviceWorker.register();
