import React, { Component } from "react";
import { Card, Form, Input, Spin, Button } from "antd";
import styled from "styled-components";
import Swal from "sweetalert2";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userAction } from "../../../store/action";
import { Layout } from "../../containers";
import firebase from "../../utils/firebase";
import { httpClient } from "../../utils/axios";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";

const LayoutChangePassword = styled(Card)`
  width: 600px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  @media (max-width: 768px) {
    width: calc(100% - 30px);
  }
  .ant-card-bordered {
    border: 0;
  }
  .ant-card-head {
    border-bottom: 0 !important;
    .ant-card-head-title {
      padding: 40px 0 16px 0;
      font-size: 20px;
      font-weight: bold;
    }
  }
`;

class ChangePassword extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      email: "",
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    this.setState({ loading: true });
    httpClient
      .get("/users/me")
      .then((response) => {
        if (response !== undefined) {
          this.setState({
            email: response.data.data.email,
            loading: false,
          });
          localStorage.setItem("userData", response.data.data.name);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  onFinish = (values) => {
    this.setState({ loading: true });
    // Update successful.
    Swal.fire({
      title: "ยืนยันการเปลื่ยนรหัสผ่าน",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        firebase
          .auth()
          .sendPasswordResetEmail(values.email)
          .then(
            () => {
              Swal.fire({
                icon: "success",
                html: "Password reset email has been sent.",
                title: `ส่งข้อมูลไปที่อีเมล ${this.state.email} เรียบร้อย`,
                timer: 1000,
                showConfirmButton: false,
              }).then(() => {
                this.props.history.push("/dashboard");
              });
            },
            (error) => {
              const errorMessage = error.message;
              Swal.fire({
                icon: "error",
                html: `${errorMessage}`,
                timer: 1500,
                showConfirmButton: false,
              });
            },
          );
      }
    });
  };

  render() {
    const { loading, email, check_token } = this.state;
    return (
      <>
        {check_token === true && (
          <Layout title="แก้ไขรหัสผ่าน">
            <LayoutChangePassword>
              {loading === true ? (
                <Spin spinning={loading}>
                  <Card style={{ height: 300 }} />
                </Spin>
              ) : (
                <Form
                  className="my-4 mx-3"
                  initialValues={{ remember: true }}
                  onFinish={this.onFinish}
                >
                  <Input
                    value={email}
                    placeholder="Email Address"
                    size="large"
                    className="mb-4"
                    disabled
                  />
                  <Form.Item className="btn-wrap text-right mt-4">
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      block={false}
                    >
                      Send email
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </LayoutChangePassword>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

// export default ChangePassword;
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
