import React, { Component } from "react";
import { Spin, Tag, Button } from "antd";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { Layout } from "../../containers";
import { AdvancedTableSearch } from "../../views";
import { DateTimeLong } from "../../commons";
import { userAction } from "../../../store/action";
import { eventsApi } from "../../../apis";

class ManualAddPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataManualPoints: [],
      start: 0,
      length: 20,
      search: "",
      total: 0,
      dataReport: [],
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const { start, length, search } = this.state;

    this.setState({ loading: true });
    await eventsApi
      .eventsManualPointsTable(start, length, search)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          dataManualPoints: resp.data,
          total: resp.recordsTotal,
        }));
      });
    await eventsApi.eventsManualPointsTableReport().then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        dataReport: resp.data,
      }));
    });
    await this.setState({
      loading: false,
    });
  };

  handlePaggination = (start, length, search) => {
    this.setState({ loading: true });
    this.setState((prevState) => ({
      ...prevState,
      start,
      length,
      search,
    }));
    eventsApi.eventsManualPointsTable(start, length, search).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        dataManualPoints: resp.data,
        total: resp.recordsTotal,
        loading: false,
      }));
    });
  };

  handleSearch = (value) => {
    this.setState({ loading: true });
    const { length } = this.state;
    eventsApi.eventsManualPointsTable(0, length, value).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        dataManualPoints: resp.data,
        loading: false,
      }));
    });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[19].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const columns = [
      {
        title: "Points",
        dataIndex: "points",
        key: "points",
        align: "right",
        render: (text, record) => (
          <span>
            <Tag
              color={
                record.type_points === 0
                  ? "red"
                  : record.type_points === 1
                  ? "green"
                  : ""
              }
              key={record.points}
            >
              {record.points}
            </Tag>
          </span>
        ),
      },
      {
        title: "Target Group",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Link to={`/manual-add/${record.id}`}>{record.name}</Link>
        ),
      },
      {
        title: "Note",
        dataIndex: "events_details",
        key: "events_details",
        render: (text, record) => (
          <span>
            {record.events_details !== null ? record.events_details.detail : ""}
          </span>
        ),
      },
      {
        title: "Entry date",
        dataIndex: "created_at",
        key: "created_at",
        render: (text, record) => (
          <DateTimeLong dateTime={moment(record.created_at, "DD/MM/YYYY")} />
        ),
      },
      {
        title: "Action",
        key: "action",
        render: (record) => <Link to={`/manual-add/${record.id}`}>view</Link>,
      },
    ];

    const { loading, dataManualPoints, total, check_token } = this.state;

    const headers = [
      { label: "Name", key: "name" },
      { label: "Date", key: "created_at" },
      { label: "Points", key: "points" },
      { label: "Total Send ", key: "total_send" },
      { label: "Reason", key: "events_details.detail" },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="Adjust Points"
            extraBtn={
              isCreate[0] === 1
                ? [
                    <Button
                      disabled={loading}
                      type="primary"
                      onClick={() =>
                        this.props.history.push("/manual-add/create")
                      }
                    >
                      + Add point
                    </Button>,
                  ]
                : []
            }
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
                separator: ">",
              },
              {
                path: "manual-add",
                breadcrumbName: "Adjust Points",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <AdvancedTableSearch
                fileName="adjust-points"
                headers={headers}
                columns={columns}
                data={dataManualPoints}
                handleSearch={(value) => this.handleSearch(value)}
                onChangeSearch={(e) => this.handleSearch(e.target.value)}
                nonExport
                noDropdownSearch
                noFillter
                loading={loading}
                searchCondition={["action", "points", "created_at"]}
                rowKey={(record) => record.id}
                total={total}
                onTableChange={this.handlePaggination}
              />
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManualAddPage);
