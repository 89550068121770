import React, { Component } from "react";
import { Card, Col, Form, Row, Select, Spin, DatePicker, Button } from "antd";
import Swal from "sweetalert2";
import { CardFilter, CardFilterAge, CardFilterCity } from "../../views";
import { eventsApi } from "../../../apis";
import { LayoutDashboard, ListBox } from "../../commons";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
// import moment from 'moment'

const { Option } = Select;

class SendCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selected: [],
      dataProvinces: [],
      dataAmphures: [],
      dataArtist: [],
      dataOptions: [],
      gender: "",
      day: "",
      month: "",
      province_id: "",
      amphures_id: null,
      artist_id: "",
      age_rage: [0, 100],
      age_from: 0,
      age_to: 100,
      register_date: "",
      keywords: "",
      PageName: "",
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    this.setState({ loading: true });
    const id = this.props.match.params.voucherId;
    const setData = (doc) => {
      this.setState({
        PageName: doc.name,
      });
    };
    await eventsApi.eventsVoucherViewId(id).then((response) => {
      setData(response.data);
    });
    await eventsApi.eventsManualPointsProvinces().then((resp) => {
      this.setState({
        dataProvinces: resp.data,
      });
    });
    await eventsApi.eventsManualPointsArtists().then((resp) => {
      this.setState({
        dataArtist: resp.data,
      });
    });
    await this.setState({
      loading: false,
    });
  };

  onChange = (selected) => {
    this.setState({ selected });
  };

  handleChangeGender = (e) => {
    this.setState({
      gender: e,
    });
  };

  handleChangeDay = (e) => {
    this.setState({
      day: e,
    });
  };

  handleRegisterDate = (e) => {
    let date = "";
    if (!e) {
      date = "";
    } else {
      date = e.format("DD-MM-YYYY");
    }
    this.setState({
      register_date: date,
    });
  };

  handleChangeMonth = (e) => {
    this.setState({
      month: e,
    });
  };

  handleChangeProvince = (e) => {
    this.setState({
      province_id: e,
      amphures_id: null,
    });
    const provinceId = e === "" ? 0 : e;
    eventsApi.eventsManualPointsAmphures(provinceId).then((resp) => {
      this.setState({
        dataAmphures: resp.data,
      });
    });
  };

  handleChangeCounty = (e) => {
    this.setState({
      amphures_id: e,
    });
  };

  handleChangeArtists = (e) => {
    this.setState({
      artist_id: e,
    });
  };

  onFinish = (values) => {
    this.setState({ loading: true });
    const id = this.props.match.params.voucherId;
    Swal.fire({
      title: "ยืนยันการส่งคูปอง",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ส่งคูปอง",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        eventsApi
          .eventsVoucherSend(id, values.select)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: `${res.message}`,
              timer: 1000,
            });
            this.setState({ loading: false });
            this.props.history.goBack();
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
              timer: 1000,
            });
            this.setState({ loading: false });
          });
      }
    });
  };

  onConfirmSearch = () => {
    this.setState({ loading: true });

    const {
      gender,
      day,
      month,
      province_id,
      amphures_id,
      artist_id,
      keywords,
      age_to,
      age_from,
      register_date,
    } = this.state;
    eventsApi
      .eventsManualPointsFilter(
        keywords,
        gender,
        day,
        month,
        province_id,
        amphures_id,
        artist_id,
        age_from,
        age_to,
        register_date,
      )
      .then((resp) => {
        const options = [];
        resp.data.forEach((s) => {
          options.push({ value: s.id, label: s.fullname });
        });
        this.setState({
          dataOptions: options,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  handleChangeAge = (value) => {
    this.setState({ age_from: value[0], age_to: value[1] });
  };

  render() {
    const {
      PageName,
      loading,
      dataProvinces,
      selected,
      dataOptions,
      dataAmphures,
      dataArtist,
      check_token,
    } = this.state;

    const listBirthSex = [
      { key: "", label: "ทุกเพศ" },
      { key: "1", label: "ชาย" },
      { key: "2", label: "หญิง" },
      { key: "3", label: "ไม่ระบุ" },
    ];

    const listBirthDay = [
      { key: "", label: "ทุกวันเกิด" },
      { key: "0", label: "วันจันทร์" },
      { key: "1", label: "วันอังคาร" },
      { key: "2", label: "วันพุธ" },
      { key: "3", label: "วันพฤหัสบดี" },
      { key: "4", label: "วันศุกร์" },
      { key: "5", label: "วันเสาร์" },
      { key: "6", label: "วันอาทิตย์" },
    ];

    const listBirthMonth = [
      { key: "", label: "ทุกเดือนเกิด" },
      { key: "1", label: "มกราคม" },
      { key: "2", label: "กุมภาพันธ์" },
      { key: "3", label: "มีนาคม" },
      { key: "4", label: "เมษายน" },
      { key: "5", label: "พฤษภาคม" },
      { key: "6", label: "มิถุนายน" },
      { key: "7", label: "กรกฎาคม" },
      { key: "8", label: "สิงหาคม" },
      { key: "9", label: "กันยายน" },
      { key: "10", label: "ตุลาคม" },
      { key: "11", label: "พฤศจิกายน" },
      { key: "12", label: "ธันวาคม" },
    ];

    return (
      <>
        {check_token === true && (
          <LayoutDashboard
            hasButton={false}
            className="comtain-fluid"
            title="ส่งคูปอง"
            caption="เลือกส่งคูปองให้ผู้ใช้ทุกคน หรือผู้ใช้พิเศษ"
            editName={
              PageName && PageName.length > 20
                ? `${PageName.substring(0, 20)}...`
                : PageName
            }
          >
            <Form layout="vertical" onFinish={this.onFinish}>
              <Spin spinning={loading} tip="Loading...">
                <Card>
                  <Row gutter={[16, 16]} align="bottom">
                    <CardFilter
                      nameTitle="เพศ"
                      onChange={this.handleChangeGender}
                      placeholder="เลือกเพศ"
                      value={this.state.gender}
                    >
                      {listBirthSex.map((item) => (
                        <Option key={item.key} value={item.key}>
                          {item.label}
                        </Option>
                      ))}
                    </CardFilter>

                    <CardFilter
                      nameTitle="วันเกิด"
                      onChange={this.handleChangeDay}
                      placeholder="เลือกวันเกิด"
                      value={this.state.day}
                    >
                      {listBirthDay.map((item) => (
                        <Option key={item.key} value={item.key}>
                          {item.label}
                        </Option>
                      ))}
                    </CardFilter>

                    <CardFilter
                      nameTitle="เดือนเกิด"
                      onChange={this.handleChangeMonth}
                      placeholder="เลือกเดือนเกิด"
                      value={this.state.month}
                    >
                      {listBirthMonth.map((item) => (
                        <Option key={item.key} value={item.key}>
                          {item.label}
                        </Option>
                      ))}
                    </CardFilter>

                    <CardFilterAge
                      nameTitle="ช่วงอายุ"
                      age_rage={this.state.age_rage}
                      onChange={this.handleChangeAge}
                    />

                    <CardFilterCity
                      nameTitle="จังหวัด"
                      placeholder="เลือกจังหวัด"
                      value={this.state.province_id}
                      onChange={this.handleChangeProvince}
                      id={this.state.province_id}
                    >
                      <Option key="" value="">
                        ทุกจังหวัด
                      </Option>
                      {dataProvinces.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name_th}
                        </Option>
                      ))}
                    </CardFilterCity>

                    <CardFilterCity
                      nameTitle="เขต"
                      placeholder="เลือกเขต"
                      onChange={this.handleChangeCounty}
                      id={this.state.amphures_id}
                    >
                      <Option key="" value="">
                        ทุกเขต
                      </Option>
                      {dataAmphures.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name_th}
                        </Option>
                      ))}
                    </CardFilterCity>

                    <CardFilter
                      nameTitle="คิลปิน"
                      onChange={this.handleChangeArtists}
                      showSearch
                      placeholder="เลือกคิลปิน"
                      value={this.state.artist_id}
                    >
                      <Option key="" value="">
                        ทุกคิลปิน
                      </Option>
                      {dataArtist.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </CardFilter>

                    <Col className="px-2 col-5">
                      <p className="text-left mb-1">วันที่สมัคร</p>
                      <DatePicker
                        style={{ width: "100%" }}
                        onChange={this.handleRegisterDate}
                        size="large"
                        format="DD/MM/YYYY"
                        allowClear
                      />
                    </Col>
                    <Col span={24}>
                      <Button
                        type="primary"
                        size="large"
                        className="width-220"
                        block={false}
                        style={{ float: "right" }}
                        onClick={this.onConfirmSearch}
                      >
                        ตกลง
                      </Button>
                    </Col>
                  </Row>
                  <Form.Item
                    name={["select"]}
                    rules={[
                      {
                        required: true,
                        message: "กรุณาเลือกผู้ใช้งานที่ต้องการให้คะแนน",
                      },
                    ]}
                  >
                    <ListBox
                      options={dataOptions}
                      selected={selected}
                      onChange={this.onChange}
                    />
                  </Form.Item>
                </Card>
                <Row justify="space-between" className="mt-5 mb-2">
                  <Col className="width-220">
                    <Button
                      size="large"
                      block
                      onClick={() => this.props.history.goBack()}
                    >
                      ย้อนกลับ
                    </Button>
                  </Col>
                  <Col className="width-220">
                    <Button type="primary" size="large" block htmlType="submit">
                      ยืนยัน
                    </Button>
                  </Col>
                </Row>
              </Spin>
            </Form>
          </LayoutDashboard>
        )}
      </>
    );
  }
}

export default SendCoupon;
