import React, { Component } from "react";
import { Dropdown, Menu, Spin, Button, Switch } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { Layout } from "../../containers";
import { AdvancedTableSearch } from "../../views";
import { userAction } from "../../../store/action";
import { cmsApi } from "../../../apis";
import { TagStatus } from "../../commons";

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class NewsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      autherData: [],
      title: "",
      auther: "",
      startDate: "",
      endDate: "",
      status: "",
      dataPrint: [],
      typeSearch: "1",
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await this.setState({ loading: true });
    const { start, length, search } = this.state;
    cmsApi.NewsTable(start, length, search).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        data: resp.data,
        total: resp.recordsTotal,
        loading: false,
      }));
    });
    cmsApi.NewsExportTable().then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        dataPrint: resp.data,
        loading: false,
      }));
    });
    cmsApi.NewsUserList().then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        autherData: resp.data,
        loading: false,
      }));
    });
  };

  handleSearch = (typeFilter) => {
    this.setState({ loading: true });
    const {
      length,
      search,
      typeSearch,
      title,
      auther,
      startDate,
      endDate,
      status,
      autherData,
    } = this.state;
    const roleId = [];
    if (typeSearch === 3) {
      if (search === "active" || search === "Active") {
        roleId.push(1);
      } else {
        roleId.push(0);
      }
    } else if (typeSearch === 2) {
      for (let i = 0; i < autherData.length; i += 1) {
        if (autherData[i].name === search) {
          roleId.push(autherData[i].id);
        }
      }
    }
    const text = roleId.length > 0 ? roleId[0] : search;
    const type =
      typeFilter === 0
        ? typeFilter
        : `1&title=${title}&user_id=${auther}&status=${status}&start_date=${startDate}&end_date=${endDate}`;
    cmsApi
      .NewsTable(0, length, text, typeSearch, type)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          loading: false,
          typeFilter,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handlePaggination = (start, length) => {
    this.setState({ loading: true });
    const {
      search,
      typeSearch,
      title,
      auther,
      startDate,
      endDate,
      status,
      typeFilter,
      autherData,
    } = this.state;
    const roleId = [];
    if (typeSearch === 3) {
      if (search === "active" || search === "Active") {
        roleId.push(1);
      } else {
        roleId.push(0);
      }
    } else if (typeSearch === 2) {
      for (let i = 0; i < autherData.length; i += 1) {
        if (autherData[i].name === search) {
          roleId.push(autherData[i].id);
        }
      }
    }
    const text = roleId.length > 0 ? roleId[0] : search;
    const type =
      typeFilter === 0
        ? typeFilter
        : `1&title=${title}&user_id=${auther}&status=${status}&start_date=${startDate}&end_date=${endDate}`;
    cmsApi.NewsTable(start, length, text, typeSearch, type).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        data: resp.data,
        total: resp.recordsTotal,
        start,
        length,
        search,
        loading: false,
      })).catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    });
  };

  onChangeSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async (id) => {
    await this.setState({ loading: true });
    await this.setState({
      visible: false,
    });
    await cmsApi.deleteNews(id).then((resp) => {
      Swal.fire({
        icon: "success",
        title: `${resp.message}`,
        timer: 1500,
      }).then(() => {
        this.setState({
          loading: false,
        });
        this.componentDidMount();
      });
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onSticky = (e, id) => {
    cmsApi.updateStickyNews(id).then(() => {
      Swal.fire({
        icon: "success",
        title: `Success`,
        timer: 1500,
      }).then(() => {
        this.setState({
          loading: false,
        });
        this.componentDidMount();
      });
    });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[5].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, data, total, check_token } = this.state;

    const menu = (record) => (
      <Menu className="datable-actions-menu">
        {isEdit[0] === 1 && (
          <Menu.Item>
            <Link
              to={{
                pathname: `/news/${record.id}/edit`,
              }}
            >
              Edit
            </Link>
          </Menu.Item>
        )}
      </Menu>
    );

    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        id: "title",
        type: "input",
        value: "",
        selectData: [],
        onChange: (e) => this.setState({ title: e.target.value }),
      },
      {
        title: "Auther",
        dataIndex: "auther",
        key: "auther",
        type: "select",
        value: "all",
        selectData: this.state.autherData,
        onChange: (e) => this.setState({ auther: e }),
      },
      {
        title: "Pubish date",
        dataIndex: "date",
        key: "date",
        type: "date",
        value: "",
        selectData: [],
        onChange: (e, string) =>
          this.setState({
            startDate: moment(string[0], "DD/MM/YYYY").format("YYYY-MM-DD"),
            endDate: moment(string[1], "DD/MM/YYYY").format("YYYY-MM-DD"),
          }),
        render: (text, record) => <span>{record.start_date_conver}</span>,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        type: "select",
        value: "all",
        selectData: [
          {
            id: 1,
            name: "Active",
          },
          { id: 0, name: "Suspend" },
        ],
        onChange: (status) => this.setState({ status }),
        render: (text) => <TagStatus StatusValue={+text} />,
      },
      {
        title: "Sticky",
        dataIndex: "sticky",
        key: "sticky",
        type: "select",
        value: "all",
        selectData: [],
        render: (text, record) =>
          record.sticky === 1 ? (
            <Switch
              disabled={isEdit[0] === 1 ? false : true}
              onChange={(e) => this.onSticky(e, record.id)}
              defaultChecked
            />
          ) : (
            <Switch
              disabled={isEdit[0] === 1 ? false : true}
              onChange={(e) => this.onSticky(e, record.id)}
            />
          ),
      },
      {
        title: "View",
        dataIndex: "views",
        key: "view",
        type: "input",
        align: "right",
        value: "",
        selectData: [],
        onChange: () => console.log("ok"),
        render: (view) => <span>{formatNumber(+view)}</span>,
      },
      {
        title: "Action",
        key: "action",
        width: "10%",
        align: "center",
        render: (text, record) => (
          <Dropdown
            trigger={["click"]}
            overlay={menu(record)}
            placement="bottomRight"
          >
            <Button type="link">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="News"
            extraBtn={[
              <Button
                style={{ display: isCreate[0] === 1 ? "" : "none" }}
                type="primary"
                onClick={() => this.props.history.push("/news/create")}
              >
                + Add news
              </Button>,
              <Button onClick={() => this.props.history.push("/news/sticky")}>
                Sticky
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "news",
                breadcrumbName: "News",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <AdvancedTableSearch
                fileName="news"
                columns={columns}
                data={data}
                handleSearch={this.handleSearch}
                onChangeSearch={this.onChangeSearch}
                loading={loading}
                headers={columns}
                nonExport
                // dataExport={this.state.dataPrint}
                searchCondition={["date", "sticky", "view", "action"]}
                columnsFilter={columns}
                filterCondition={["action", "sticky", "view"]}
                rowKey={(record) => record.id}
                defaultValueSearch="title"
                onChangeTypeSearch={(value) => {
                  console.log(value);
                  this.setState({
                    typeSearch:
                      value === "title" ? 1 : value === "auther" ? 2 : 3,
                  });
                }}
                clearFilter={() =>
                  this.setState({
                    name: "",
                    startDate: "",
                    endDate: "",
                    auther: "",
                    status: "",
                  })
                }
                total={total}
                onTableChange={this.handlePaggination}
              />
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsPage);
