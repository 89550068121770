import React, { Component } from "react";
import {
  Modal,
  Spin,
  Button,
  Form,
  Row,
  Col,
  Input,
  Menu,
  Dropdown,
} from "antd";
import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import * as Swal from "sweetalert2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { userAction } from "../../../store/action";
import { AdvancedTableSearch } from "../../views";
import { Layout } from "../../containers";
import { cmsApi } from "../../../apis";
import ReactQuill from "react-quill";
import QuillStyle from "../../commons/EditorQuill/QuillStyle";
import { httpClient } from "../../utils/axios";

const { confirm } = Modal;
class Journey extends Component {
  constructor(props) {
    super(props);
    this.myFormRef = React.createRef();
    this.state = {
      text: "",
      loading: true,
      visible: false,
      data: [],
      editVisble: false,
      check_token: false,
    };
    this.reactQuillRef = null;
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    cmsApi
      .JourneyTable()
      .then((resp) => {
        this.setState({
          data: resp.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleSearch = () => {
    cmsApi
      .JourneyTable(0, 50, this.state.text)
      .then((resp) => {
        this.setState({
          data: resp.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      editVisble: false,
    });
  };

  onFinish = (values) => {
    this.myFormRef.current.resetFields();
    Swal.fire({
      title: "ต้องการสร้างข้อมูล Journey ใช่หรือไม่",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "ระบบกำลังทำงาน",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        cmsApi
          .createJourney({
            years: values.years,
            description: values.description,
          })
          .then((resp) => {
            Swal.fire({
              icon: "success",
              title: `${resp.message}`,
              timer: 1500,
            }).then(() => {
              this.setState({
                visible: false,
              });
              this.componentDidMount();
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
          });
      }
    });
  };

  onEditFinish = (values) => {
    Swal.fire({
      title: "ต้องการแก้ไขข้อมูล Journey ใช่หรือไม่",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "ระบบกำลังทำงาน",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        cmsApi
          .updateJourney(
            { years: values.years, description: values.description },
            this.state.id,
          )
          .then((resp) => {
            Swal.fire({
              icon: "success",
              title: `${resp.message}`,
              timer: 1500,
            }).then(() => {
              this.setState({
                editVisble: false,
              });
              this.componentDidMount();
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
          });
      }
    });
  };

  onEdit = (id) => {
    cmsApi
      .getJourney(id)
      .then(async (resp) => {
        await this.setState({
          id: resp.data.id,
          years: resp.data.years,
          description: resp.data.description,
        });
        await this.setState({
          editVisble: true,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  uploadFiles = (uploadFileObj) => {
    const formData = new FormData();
    const that = this;
    formData.append("image", uploadFileObj);
    httpClient
      .post("/news/content/image", formData)
      .then((response) => {
        if (response.data) {
          const range = that.reactQuillRef.getEditorSelection();
          setTimeout(() => {
            that.reactQuillRef
              .getEditor()
              .insertEmbed(range.index, "image", response.data.url);
          }, 2000);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      this.uploadFiles(file, this.reactQuillRef);
    };
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[6].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, data, total, check_token } = this.state;
    const menu = (record) => (
      <Menu className="datable-actions-menu">
        {isEdit[0] === 1 && (
          <Menu.Item>
            <Link to="/journey" onClick={() => this.onEdit(record.id)}>
              Edit
            </Link>
          </Menu.Item>
        )}
        {isDelete[0] === 1 && (
          <Menu.Item>
            <Link
              to="/journey"
              onClick={() => {
                confirm({
                  title: "ต้องการลบข้อมูลใช่หรือไม่?",
                  centered: true,
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => {
                    Swal.fire({
                      title: "ระบบกำลังทำงาน",
                      allowEscapeKey: false,
                      allowOutsideClick: false,
                      onOpen: () => {
                        Swal.showLoading();
                      },
                    });
                    cmsApi
                      .deleteJourney(record.id)
                      .then((resp) => {
                        Swal.fire({
                          icon: "success",
                          title: `${resp.message}`,
                          timer: 1500,
                        }).then(() => {
                          this.componentDidMount();
                        });
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: `${error.response.data.message}`,
                        });
                      });
                  },
                });
              }}
            >
              Delete
            </Link>
          </Menu.Item>
        )}
      </Menu>
    );

    const columns = [
      {
        title: "Year",
        dataIndex: "years",
        id: "years",
        type: "input",
        value: "",
        selectData: [],
        onChange: () => console.log("ok"),
        render: (text) => <span>{text}</span>,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        type: "input",
        value: "",
        selectData: [],
        onChange: () => console.log("ok"),
        render: (text) => <span>{text.replace(/<(?:.|\n)*?>/gm, " ")}</span>,
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Dropdown
            trigger={["click"]}
            overlay={menu(record)}
            placement="bottomRight"
          >
            <Button type="link">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];
    return (
      <>
        {check_token === true && (
          <Layout
            title="Journey"
            extraBtn={
              isCreate[0] === 1
                ? [
                    <Button
                      disabled={loading}
                      type="primary"
                      onClick={async () => {
                        await this.setState({ visible: true });
                        this.myFormRef.current.resetFields();
                      }}
                    >
                      + Add journey
                    </Button>,
                  ]
                : []
            }
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "journey",
                breadcrumbName: "Journey",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              {loading === false && (
                <AdvancedTableSearch
                  columns={columns}
                  data={data}
                  handleSearch={(value) => this.handleSearch(value)}
                  onChangeSearch={(e) =>
                    this.setState({ text: e.target.value })
                  }
                  loading={loading}
                  rowKey={(record) => record.id}
                  total={total}
                  onTableChange={this.handlePaggination}
                  noDropdownSearch
                  noFillter
                />
              )}
            </Spin>
            <Modal
              title="Add journey"
              visible={this.state.visible}
              onOk={this.handleCancel}
              onCancel={this.handleCancel}
              footer={null}
              centered
              width={800}
            >
              <Form
                ref={this.myFormRef}
                layout="vertical"
                onFinish={this.onFinish}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Year"
                      name="years"
                      rules={[
                        { required: true, message: "Please input year!" },
                      ]}
                      type="number"
                    >
                      <Input />
                    </Form.Item>
                    <QuillStyle>
                      <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please input description!",
                          },
                        ]}
                      >
                        <ReactQuill
                          ref={(el) => {
                            this.reactQuillRef = el;
                          }}
                          theme="snow"
                          modules={{
                            toolbar: {
                              container: [
                                ["bold", "italic", "underline"],
                                [{ list: "ordered" }, { list: "bullet" }],
                                ["link", "image", "video"],
                                ["clean"],
                              ],
                              handlers: {
                                image: this.imageHandler,
                              },
                            },
                            clipboard: {
                              matchVisual: false,
                            },
                          }}
                        />
                      </Form.Item>
                    </QuillStyle>
                    <Form.Item className="my-3">
                      <Row>
                        <Col span={24}>
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                          <Button
                            className="mx-4"
                            onClick={() => this.setState({ visible: false })}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>
            <Modal
              title="Edit journey"
              visible={this.state.editVisble}
              onOk={this.handleCancel}
              onCancel={this.handleCancel}
              footer={null}
              centered
              width={800}
            >
              <Form layout="vertical" onFinish={this.onEditFinish}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      initialValue={this.state.years}
                      label="Year"
                      name="years"
                      rules={[
                        { required: true, message: "Please input year!" },
                      ]}
                      type="number"
                    >
                      <Input />
                    </Form.Item>
                    <QuillStyle>
                      <Form.Item
                        initialValue={this.state.description}
                        label="Description"
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please input description!",
                          },
                        ]}
                        type="text"
                      >
                        <ReactQuill
                          ref={(el) => {
                            this.reactQuillRef = el;
                          }}
                          theme="snow"
                          modules={{
                            toolbar: {
                              container: [
                                [{ header: [3, 4, false] }],
                                ["bold", "italic", "underline"],
                                [
                                  {
                                    color: [
                                      "#000000",
                                      "#e60000",
                                      "#ff9900",
                                      "#ffff00",
                                      "#008a00",
                                      "#0066cc",
                                      "#9933ff",
                                      "#ffffff",
                                      "#facccc",
                                      "#ffebcc",
                                      "#ffffcc",
                                      "#cce8cc",
                                      "#cce0f5",
                                      "#ebd6ff",
                                      "#bbbbbb",
                                      "#f06666",
                                      "#ffc266",
                                      "#ffff66",
                                      "#66b966",
                                      "#66a3e0",
                                      "#c285ff",
                                      "#888888",
                                      "#a10000",
                                      "#b26b00",
                                      "#b2b200",
                                      "#006100",
                                      "#0047b2",
                                      "#6b24b2",
                                      "#444444",
                                      "#5c0000",
                                      "#663d00",
                                      "#666600",
                                      "#003700",
                                      "#002966",
                                      "#3d1466",
                                    ],
                                  },
                                ],
                                [{ list: "ordered" }, { list: "bullet" }],
                                ["link", "image", "video"],
                                ["clean"],
                              ],
                              handlers: {
                                image: this.imageHandler,
                              },
                            },
                            clipboard: {
                              matchVisual: false,
                            },
                          }}
                        />
                      </Form.Item>
                    </QuillStyle>
                    <Form.Item className="my-3">
                      <Row>
                        <Col span={24}>
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                          <Button
                            className="mx-4"
                            onClick={() => this.setState({ editVisble: false })}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Journey);
