import React from "react";
import { Col, Select } from "antd";

const CardFilter = (props) => (
    <Col className="px-2 col-5">
      <p className="text-left my-1">{props.nameTitle}</p>
      {props.showSearch === true ? (
        <Select
          onChange={props.onChange}
          size="large"
          placeholder={props.placeholder}
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={props.value}
        >
          {props.children}
        </Select>
      ) : (
        <Select
          onChange={props.onChange}
          size="large"
          placeholder={props.placeholder}
          className="ant-select"
          value={props.value}
        >
          {props.children}
        </Select>
      )}
    </Col>
  );
export default CardFilter;
