import { httpClient } from "../components/utils/axios";

export const UserLogTable = (start, length, search, type, type_filter) =>
  httpClient
    .get(
      `user_logs/datatable?start=${start || 0}&length=${length || 20}&search=${
        search || ""
      }&type=${type || 0}&type_filter=${type_filter}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
