import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Slider,
} from "antd";
import { ExportOutlined } from "@ant-design/icons";

import PageTable from "../../commons/PageTable";
import { AdvancedTableSearchStyle } from "./style";
import TableSearchTop from "../TableSearchTop";

const { Option } = Select;
const { RangePicker } = DatePicker;

const AdvancedTableSearch = (props) => {
  const {
    className,
    title,
    columns,
    data,
    handleSearch,
    onChangeSearch,
    onChangeTypeSearch,
    searchCondition,
    rowKey,
    total,
    onTableChange,
    columnsFilter,
    filterCondition,
    noFillter,
    headers,
    dataExport,
    fileName,
    nonExport,
    noDropdownSearch,
    clearFilter,
    defaultValueSearch,
  } = props;
  const [form] = Form.useForm();
  const [isAdvancedSearchForm, setIsAdvancedSearchForm] = useState(false);

  const getFields = (columns) =>
    columns.map((item) => (
      <Fragment key={item.key}>
        {filterCondition
          ? !filterCondition.includes(item.key) &&
            item.type && (
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
                key={item.key}
              >
                <Form.Item name={`field-${item.key}`} label={item.title}>
                  {item.type === "date" ? (
                    <RangePicker
                      style={{ width: "100%" }}
                      onChange={item.onChange}
                      format="DD/MM/YYYY"
                      allowClear
                    />
                  ) : item.type === "address" ? (
                    <Select
                      style={{ minWidth: 135 }}
                      showSearch
                      optionFilterProp="children"
                      onChange={item.onChange}
                      defaultValue="all"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="all">ทุกจังหวัด</Option>
                      {item.selectData.map((item) => (
                        <Option key={item.id} value={item.name_th}>
                          {item.name_th}
                        </Option>
                      ))}
                    </Select>
                  ) : item.type === "select" ? (
                    <Select
                      className="w-100"
                      showSearch
                      value={item.value}
                      onChange={item.onChange}
                      defaultValue="all"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="all">all</Option>
                      {item.selectData.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  ) : item.type === "range" ? (
                    <Slider
                      range
                      defaultValue={[20, 50]}
                      onChange={item.onChange}
                    />
                  ) : item.type === "number" ? (
                    <Input
                      type="number"
                      onKeyDown={(evt) =>
                        evt.key === "e" ||
                        evt.key === "E" ||
                        evt.key === "+" ||
                        evt.key === "-" ||
                        evt.key === "."
                          ? evt.preventDefault()
                          : {}
                      }
                      onChange={item.onChange}
                    />
                  ) : (
                    <Input onChange={item.onChange} />
                  )}
                </Form.Item>
              </Col>
            )
          : !searchCondition.includes(item.key) && (
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
                key={item.key}
              >
                <Form.Item name={`field-${item.key}`} label={item.title}>
                  {item.type === "date" ? (
                    <DatePicker
                      style={{ width: "100%" }}
                      onChange={item.onChange}
                      format="DD/MM/YYYY"
                      allowClear
                    />
                  ) : item.type === "address" ? (
                    <Select
                      style={{ minWidth: 135 }}
                      showSearch
                      optionFilterProp="children"
                      onChange={item.onChange}
                      defaultValue="all"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="all">ทุกจังหวัด</Option>
                      {item.selectData.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name_th}
                        </Option>
                      ))}
                    </Select>
                  ) : item.type === "select" ? (
                    <Select
                      className="w-100"
                      showSearch
                      value={item.value}
                      onChange={item.onChange}
                      defaultValue="all"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="all">all</Option>
                      {item.selectData.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  ) : item.type === "range" ? (
                    <Slider
                      range
                      defaultValue={[0, 50]}
                      onChange={item.onChange}
                    />
                  ) : item.type === "number" ? (
                    <Input
                      type="number"
                      onKeyDown={(evt) =>
                        evt.key === "e" ||
                        evt.key === "E" ||
                        evt.key === "+" ||
                        evt.key === "-" ||
                        evt.key === "."
                          ? evt.preventDefault()
                          : {}
                      }
                      onChange={item.onChange}
                    />
                  ) : (
                    <Input onChange={item.onChange} />
                  )}
                </Form.Item>
              </Col>
            )}
      </Fragment>
    ));

  return (
    <AdvancedTableSearchStyle className="mb-4">
      <div className={className}>
        <TableSearchTop
          title={title}
          noFillter={noFillter || false}
          headers={headers}
          dataExport={dataExport}
          fileName={fileName}
          defaultValueSearch={defaultValueSearch}
          onChangeTypeSearch={onChangeTypeSearch}
          onSearch={handleSearch}
          columns={columns}
          noDropdownSearch={noDropdownSearch}
          searchCondition={searchCondition}
          onClickSearch={handleSearch}
          onChange={onChangeSearch}
          onToggleFilter={() => setIsAdvancedSearchForm(!isAdvancedSearchForm)}
        />

        {isAdvancedSearchForm === true && (
          <Form
            form={form}
            layout="vertical"
            name="advanced_search"
            className="advanced-table-search-form mb-4 p-4"
            onFinish={() => handleSearch(1)}
          >
            <Row gutter={24}>
              {getFields(columnsFilter ? columnsFilter : columns)}
            </Row>
            <Row>
              <Col span={8}>
                {nonExport !== true && (
                  <Button
                    className="d-flex align-items-center"
                    icon={<ExportOutlined />}
                    type="primary"
                    onClick={() => form.resetFields()}
                  >
                    Export table to csv
                  </Button>
                )}
              </Col>
              <Col span={16} className="text-right">
                <Button
                  className="mx-4"
                  onClick={() => {
                    form.resetFields();
                    clearFilter();
                  }}
                >
                  Clear
                </Button>
                <Button type="primary" htmlType="submit">
                  Filter
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
      <PageTable
        rowKey={rowKey ? rowKey : (record) => record.id}
        columns={columns}
        data={data}
        total={total}
        onChange={onTableChange}
      />
    </AdvancedTableSearchStyle>
  );
};

AdvancedTableSearch.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  handleSearch: PropTypes.func,
  onChangeSearch: PropTypes.func,
  columnsFilter: PropTypes.array,
  noDropdownSearch: PropTypes.bool,
};
AdvancedTableSearch.defaultProps = {
  className: "",
  title: "",
  noDropdownSearch: false,
};

export default AdvancedTableSearch;
