import React from "react";
import { Card, Col, Row, Avatar } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";

const CardAudienceStyle = styled(Card)`
  margin-bottom: 1rem;
  .ant-card-head {
    border: 0px;
    .ant-card-head-title,
    .ant-card-extra {
      padding-top: 25px;
      font-weight: bold;
    }
  }
  .ant-card-body {
    padding: 15px 30px 35px;
  }
  .empty {
    img {
      height: auto !important;
    }
  }
`;

const CardAudience = ({ title, dataAudience }) => (
  <CardAudienceStyle title={title}>
    {dataAudience.length > 0 ? (
      dataAudience.map((item) => (
        <li key={item.id} className="mb-3">
          <Row justify="space-between" align="bottom">
            <Col span={18}>
              <span className="text-secondary">{item.province}</span>
            </Col>
            <Col span={6} className="text-right">
              <span className="text-secondary">
                <b>{item.content}</b>
              </span>
            </Col>
          </Row>
        </li>
      ))
    ) : (
      <div className="text-center empty">
        <Avatar
          shape="square"
          src="/assets/images/dafault/empty.png"
          size={150}
        />
        <p className="mb-0">ยังไม่มีข้อมูล</p>
      </div>
    )}
  </CardAudienceStyle>
);

CardAudience.propTypes = {
  title: PropTypes.string.isRequired,
  dataAudience: PropTypes.array.isRequired,
};
CardAudience.defaultProps = {
  className: "",
};
export default CardAudience;
