import React, { Component } from "react";
import { Spin, Button, Menu, Dropdown, Modal } from "antd";
import { MoreOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";

import { Layout } from "../../containers";
import { AdvancedTableSearch } from "../../views";
import { userApi } from "../../../apis";
import { userAction } from "../../../store/action";
import { DateTimeMid, TagStatus } from "../../commons";
import Avatar from "react-avatar";

const { confirm } = Modal;

class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      data: [],
      dataReport: [],
      start: 0,
      length: 20,
      search: "",
      total: 0,
      uId: 0,
      isToggle: false,
      typeSearch: 1,
      roleUser: [],
      name: "",
      email: "",
      role: "",
      status: "",
      typeFilter: 0,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const { start, length, search, typeSearch } = this.state;

    await this.setState({ loading: true });
    await userApi
      .UserTable(start, length, search, typeSearch)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    userApi
      .RoleList()
      .then((resp) => {
        this.setState({
          roleUser: resp.data,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    await this.setState({ loading: false });
    this.onLoadUser();
  };

  onLoadUser = async () => {
    this.props.userAction.userProfile();
  };

  onChangeSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleSearch = (typeFilter) => {
    this.setState({ loading: true });
    const { length, search, typeSearch, roleUser, name, email, role, status } =
      this.state;
    const roleId = [];
    if (typeSearch === 3) {
      roleUser.forEach((doc) => {
        if (search === doc.name) {
          roleId.push(doc.id);
        }
      });
    } else if (typeSearch === 4) {
      if (search === "active") {
        roleId.push(1);
      } else {
        roleId.push(0);
      }
    }
    const text = roleId.length > 0 ? roleId[0] : search;
    const type =
      typeFilter === 0
        ? typeFilter
        : `1&name=${name}&email=${email}&role_id=${role}&status=${status}`;
    userApi
      .UserTable(0, length, text, typeSearch, type)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          loading: false,
          typeFilter,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handlePaggination = (start, length) => {
    this.setState({ loading: true });
    const {
      search,
      typeSearch,
      roleUser,
      name,
      email,
      role,
      status,
      typeFilter,
    } = this.state;
    const roleId = [];
    if (typeSearch === 3) {
      roleUser.forEach((doc) => {
        if (search === doc.name) {
          roleId.push(doc.id);
        }
      });
    } else if (typeSearch === 4) {
      if (search === "active") {
        roleId.push(1);
      } else {
        roleId.push(0);
      }
    }
    const text = roleId.length > 0 ? roleId[0] : search;
    const type =
      typeFilter === 0
        ? typeFilter
        : `1&name=${name}&email=${email}&role_id=${role}&status=${status}`;
    userApi
      .UserTable(start, length, text, typeSearch, type)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
          start,
          length,
          search,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async (id) => {
    await this.setState({ loading: true });
    await this.setState({
      visible: false,
    });
    await userApi
      .deleteUserById(id)
      .then((resp) => {
        Swal.fire({
          icon: "success",
          title: `${resp.message}`,
          timer: 1500,
          showCancelButton: false,
          showConfirmButton: false,
        }).then(() => {
          this.setState({
            loading: false,
          });
          this.componentDidMount();
          Swal.close();
        });
      })
      .catch((e) => {
        throw e;
      });
    await this.setState({ loading: false });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[22].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, data, total, roleUser, check_token } = this.state;
    const menu = (record) => (
      <Menu className="datable-actions-menu">
        {isEdit[0] === 1 && (
          <Menu.Item>
            <Link
              to={{
                pathname: `/user/${record.id}`,
              }}
            >
              Edit
            </Link>
          </Menu.Item>
        )}
        {isDelete[0] === 1 && (
          <Menu.Item>
            <Link
              to="/user"
              onClick={() => {
                confirm({
                  title: "ต้องการลบผู้ใช้งานออกจากระบบใช่หรือไม่?",
                  centered: true,
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => this.handleOk(record.id),
                });
              }}
            >
              Delete
            </Link>
          </Menu.Item>
        )}
      </Menu>
    );
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        type: "input",
        value: "",
        onChange: (e) => this.setState({ name: e.target.value }),
        render: (text, record) => (
          <div>
            <Avatar size="64" round name={record.name} />
            <span className="text-capitalize ml-3">{record.name}</span>
          </div>
        ),
      },
      {
        title: "E-mail",
        dataIndex: "email",
        key: "email",
        type: "input",
        value: "",
        onChange: (e) => this.setState({ email: e.target.value }),
        render: (text, record) => <span>{record.email}</span>,
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        type: "select",
        value: "",
        selectData: roleUser,
        onChange: (role) => this.setState({ role }),
        render: (text, record) => (
          <span className="text-capitalize">{record.role_name}</span>
        ),
      },
      {
        title: "Last login",
        dataIndex: "create_at",
        key: "create_at",
        type: "input",
        selectData: [],
        value: 1,
        render: (text, record) => (
          <DateTimeMid dateTime={record.updated_at_timestamp * 1000} />
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        type: "select",
        value: "",
        selectData: [
          {
            id: 1,
            name: "Active",
          },
          { id: 0, name: "Suspend" },
        ],
        onChange: (status) => this.setState({ status }),
        render: (text) => <TagStatus StatusValue={text} />,
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Dropdown
            trigger={["click"]}
            overlay={menu(record)}
            placement="bottomRight"
          >
            <Button type="link">
              <MoreOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];

    const headers = [
      { label: "Name", key: "name" },
      { label: "Telephone", key: "telephone" },
      { label: "Email", key: "email" },
      { label: "Role", key: "role" },
      { label: "Date", key: "created_at" },
    ];
    moment.locale("en");
    return (
      <>
        {check_token === true && (
          <Layout
            title="User"
            extraBtn={
              isCreate[0] === 1
                ? [
                    <Button
                      type="primary"
                      onClick={() => this.props.history.push("/user/create")}
                    >
                      Create User
                    </Button>,
                  ]
                : []
            }
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "user",
                breadcrumbName: "User",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <AdvancedTableSearch
                nonExport
                columns={columns}
                data={data}
                fileName="user-report"
                exportName="Export Report"
                headers={headers}
                handleSearch={this.handleSearch}
                onChangeSearch={this.onChangeSearch}
                defaultValueSearch="name"
                onChangeTypeSearch={(value) =>
                  this.setState({
                    typeSearch:
                      value === "name"
                        ? 1
                        : value === "email"
                        ? 2
                        : value === "role"
                        ? 3
                        : 4,
                  })
                }
                clearFilter={() =>
                  this.setState({
                    name: "",
                    email: "",
                    role: "",
                    status: "",
                  })
                }
                loading={loading}
                searchCondition={["action", "create_at"]}
                rowKey={(record) => record.id}
                total={total}
                onTableChange={this.handlePaggination}
              />
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
