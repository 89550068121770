import { Col, Slider } from "antd";
import React from "react";

const CardFilterAge = (props) => (
    <Col className="px-2 col-5">
      <p className="text-left my-1">{props.nameTitle}</p>
      <Slider
        range
        step={1}
        defaultValue={props.age_rage}
        onChange={props.onChangeAge}
        onAfterChange={props.onAfterChangeAge}
      />
    </Col>
  );
export default CardFilterAge;
