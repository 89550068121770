import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { Layout } from "../../containers";
import { AdvancedTableSearch } from "../../views";
import { userLogApi } from "../../../apis";
import { userAction } from "../../../store/action";

class UserLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      visible: false,
      data: [],
      dataReport: [],
      start: 0,
      length: 20,
      search: "",
      total: 0,
      uId: 0,
      isToggle: false,
      typeSearch: 1,
      roleUser: [],
      name: "",
      ip: "",
      typeFilter: 0,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const { start, length, search, typeSearch } = this.state;

    await this.setState({ loading: true });
    await userLogApi
      .UserLogTable(start, length, search, typeSearch)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    await this.setState({ loading: false });
  };

  onChangeSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleSearch = (typeFilter) => {
    this.setState({ loading: true });
    const { length, search, typeSearch, name, ip, start_date, end_date } =
      this.state;
    const type =
      typeFilter === 0
        ? typeFilter
        : `1&name=${name}&ip=${ip}&start_date=${start_date}&end_date=${end_date}`;
    userLogApi
      .UserLogTable(0, length, search, typeSearch, type)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
          loading: false,
          typeFilter,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handlePaggination = (start, length) => {
    this.setState({ loading: true });
    const { search, typeSearch, name, ip, typeFilter, start_date, end_date } =
      this.state;
    const type =
      typeFilter === 0
        ? typeFilter
        : `1&name=${name}&ip=${ip}&start_date=${start_date}&end_date=${end_date}`;
    userLogApi
      .UserLogTable(start, length, search, typeSearch, type)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
          start,
          length,
          search,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[23].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, data, total, check_token } = this.state;
    const columns = [
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        type: "input",
        value: "",
        selectData: [],
        sorter: (a, b) => a.action.length - b.action.length,
        render: (text, record) => <span>{record.action}</span>,
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        type: "date",
        value: "",
        selectData: [],
        onChange: (d, s) =>
          this.setState({
            start_date: moment(s[0], "DD/MM/YYYY").unix(),
            end_date: moment(s[1], "DD/MM/YYYY").unix(),
          }),
        render: (text, record) => (
          <span>
            {moment(record.date, "DD/MMMM/YYYY HH:mm:ss").format(
              "DD/MM/YYYY - HH:mm",
            )}
          </span>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        type: "input",
        value: "",
        onChange: (e) => this.setState({ name: e.target.value }),
        sorter: (a, b) => a.name.length - b.name.length,
      },
      {
        title: "IP Address",
        dataIndex: "ipaddress",
        key: "ipaddress",
        align: "center",
        type: "input",
        value: "",
        onChange: (e) => this.setState({ ip: e.target.value }),
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        type: "input",
        value: "",
        width: "10%",
        selectData: [],
        sorter: (a, b) => a.id - b.id,
      },
    ];
    return (
      <>
        {check_token === true && (
          <Layout title="User log">
            <Spin spinning={loading} tip="Loading...">
              <AdvancedTableSearch
                nonExport
                columns={columns}
                data={data}
                fileName="user-report"
                exportName="Export Report"
                handleSearch={this.handleSearch}
                onChangeSearch={this.onChangeSearch}
                defaultValueSearch="name"
                onChangeTypeSearch={(value) =>
                  this.setState({
                    typeSearch: value === "name" ? 1 : 2,
                  })
                }
                clearFilter={() =>
                  this.setState({
                    name: "",
                    ip: "",
                  })
                }
                loading={loading}
                searchCondition={["id", "date", "action"]}
                rowKey={(record) => record.id}
                filterCondition={["id"]}
                total={total}
                onTableChange={this.handlePaggination}
              />
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserLog);
