import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Avatar, Spin, Tag, Modal } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { Layout } from "../../containers";
import PageTable from "../../commons/PageTable";
import { DropdownDelete } from "../../views";
import { userAction } from "../../../store/action";
import { CustomerApi } from "../../../apis";

const { confirm } = Modal;

class CustomerTrash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleRestore: false,
      selectedRowKeys: [],
      loading: false,
      data: [],
      start: 0,
      length: 20,
      search: "",
      total: 0,
      modalCustomerId: 0,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    this.setState({ loading: true });
    const { start, length, search } = this.state;
    await CustomerApi.DeleteAccountTable(start, length, search)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleSearch = (value) => {
    this.setState({ loading: true });
    const { length } = this.state;

    CustomerApi.DeleteAccountTable(0, length, value)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handlePaggination = (start, length, search) => {
    this.setState({ loading: true });
    this.setState((prevState) => ({
      ...prevState,
      start,
      length,
      search,
    }));
    CustomerApi.DeleteAccountTable(start, length)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
    if (selectedRowKeys.length > 0) {
      this.setState((prevState) => ({
        ...prevState,
        // isDelete: true,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        // isDelete: false,
      }));
    }
  };

  showModalDelete = () => {
    this.setState({
      visible: true,
    });
  };

  showModalRestore = () => {
    this.setState({
      visibleRestore: true,
    });
  };

  handleOk = async () => {
    this.setState({ loading: true });
    await this.setState({
      visible: false,
    });
    const data = { id: this.state.selectedRowKeys };
    await CustomerApi.DeleteAccount(data, 0);
    Swal.fire({
      icon: "success",
      title: "success",
      timer: 1500,
    }).then(() => {
      this.setState({
        loading: false,
      });
      this.componentDidMount();
    });
  };

  handleOkRestore = async () => {
    this.setState({ loading: true });
    await this.setState({
      visibleRestore: false,
    });
    const data = { id: this.state.selectedRowKeys };
    await CustomerApi.DeleteAccount(data, 1);
    Swal.fire({
      icon: "success",
      title: "success",
      timer: 1500,
    }).then(() => {
      this.setState({
        loading: false,
      });
      this.componentDidMount();
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      visibleRestore: false,
    });
  };

  handleChangeSelect = (value) => {
    this.setState({
      type: value,
    });
  };

  onFinish = (values) => {
    if (values.trash === "restore") {
      confirm({
        title: "ต้องการกู้คืนลูกค้าเข้าสู่ระบบหรือไม่?",
        centered: true,
        icon: <ExclamationCircleOutlined />,
        onOk: () => this.handleOkRestore(),
      });
    } else {
      confirm({
        title: "ต้องการลบลูกค้าออกจากระบบใช่หรือไม่?",
        centered: true,
        icon: <ExclamationCircleOutlined />,
        onOk: () => this.handleOk(),
      });
    }
  };

  render() {
    moment.locale("en");
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[21].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const columns = [
      {
        title: "Name",
        dataIndex: "fullname",
        key: "fullname",
        render: (text, record) => (
          <Link to={`/customer/${record.id}`}>
            <Avatar size={64} shape="circle" src={record.image} />
            <span className="ml-3">{record.fullname}</span>
          </Link>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        type: "select",
        value: "",
        selectData: [
          {
            id: 1,
            name: "Active",
          },
          { id: 0, name: "Suspend" },
        ],
        onChange: (status) => this.setState({ status }),
        render: () => <Tag color="red">DELETED</Tag>,
      },
      {
        title: "Phone",
        dataIndex: "telephone",
        key: "telephone",
        align: "right",
        render: (text, record) => <span>{record.telephone}</span>,
      },
      {
        title: "Sex",
        dataIndex: "gender",
        key: "gender",
        value: "",
        type: "select",
        selectData: [],
        onChange: () => console.log("ok"),
        render: (text, record) => (
          <span>
            {record.gender === 1
              ? "Male"
              : record.gender === 2
              ? "Female"
              : "Other"}
          </span>
        ),
      },
      {
        title: "Points",
        dataIndex: "points",
        key: "points",
        align: "right",
        render: (text, record) => (
          <span>
            {record.customers_point ? record.customers_point.points : 0}
          </span>
        ),
      },
      {
        title: "Register date",
        dataIndex: "created_at",
        key: "created_at",
        type: "date",
        selectData: [],
        defaultSortOrder: "descend",
        sorter: (a, b) => a.created_at - b.created_at,
        onChange: () => console.log("ok"),
        render: (text, record) => (
          <span>{moment(record.created_at).format("DD MMMM YYYY")}</span>
        ),
      },
    ];

    const { loading, data, total, selectedRowKeys, check_token } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <>
        {check_token === true && (
          <Layout
            title="Trash"
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "customer",
                breadcrumbName: "Customers",
              },
              {
                path: `delete-account-customer`,
                breadcrumbName: "Trash",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              {isEdit[0] === 1 && (
                <DropdownDelete
                  onFinish={this.onFinish}
                  onChangeSelect={this.handleChangeSelect}
                />
              )}
              <PageTable
                rowKey={(record) => record.id}
                rowSelection={rowSelection}
                columns={columns}
                data={data}
                total={total}
                onChange={this.handlePaggination}
              />
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTrash);
