import React from "react";
import { Col, Row, Card, Avatar } from "antd";
import styled from "styled-components";
import {
  AreaChart,
  Area,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import PropTypes from "prop-types";

const Col5 = styled(Col)`
  /* flex: 0 0 100%;
  max-width: 100%;
  overflow: hidden;
  @media (min-width: 768px) {
    margin-bottom: 10px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 992px) {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  @media (min-width: 1280px) {
    flex: 0 0 20%;
    max-width: 20%;
  } */

  .ant-card-body {
    padding: 0;
  }

  .text-color-yellow {
    color: #ffcb52;
  }

  .text-color-blue {
    color: #40a9ff;
  }

  .text-color-purple {
    color: #844bc5;
  }

  .text-color-green {
    color: #33cc1a;
  }

  .text-color-gray {
    color: #8c8c8c;
  }
  .text-color-pink {
    color: #f759ab;
  }
  .ant-card {
    overflow: hidden;
  }
`;

const P = styled.p`
  font-size: 40px;
  font-weight: bold;
  margin: 0;
`;

const Card5Col = ({
  totalRegister,
  dataGraphTotalRegister,
  totalMale,
  dataGraphTotalMale,
  totalFemale,
  dataGraphTotalFemale,
  totalOther,
  dataGraphTotalOther,
  provinceGraph,
}) => (
    <>
      <Row className="mb-3">
        <Col span={12}>
          <Row align="top" className="w-100">
            <Col5
              className="px-2 mb-3"
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Card>
                <div className="text-center mt-3 mb-1">
                  <P className="text-color-yellow">
                    {totalRegister ? totalRegister : 0}
                  </P>
                  <p className="m-0">จำนวนคนสมัคร</p>
                </div>
                <AreaChart
                  data={dataGraphTotalRegister}
                  width={378}
                  height={65}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                  <Area
                    type="monotone"
                    dataKey="uv"
                    stroke="#FFF84A"
                    fill="#FFB309"
                  />
                </AreaChart>
              </Card>
            </Col5>
            <Col5
              className="px-2 mb-3"
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Card>
                <div className="text-center mt-3 mb-1">
                  <P className="text-color-blue">{totalMale ? totalMale : 0}</P>
                  <p className="m-0">เพศชาย</p>
                </div>
                <AreaChart
                  data={dataGraphTotalMale}
                  width={378}
                  height={65}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                  <Area
                    type="monotone"
                    dataKey="amt"
                    stroke="#91d5ff"
                    fill="#40a9ff"
                  />
                </AreaChart>
              </Card>
            </Col5>
            <Col5
              className="px-2 mb-3"
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Card>
                <div className="text-center mt-3 mb-1">
                  <P className="text-color-pink">
                    {totalFemale ? totalFemale : 0}
                  </P>
                  <p className="m-0">เพศหญิง</p>
                </div>
                <AreaChart
                  data={dataGraphTotalFemale}
                  width={378}
                  height={65}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                  <Area
                    type="monotone"
                    dataKey="amt"
                    stroke="#c41d7f"
                    fill="#f759ab"
                  />
                </AreaChart>
              </Card>
            </Col5>
            <Col5
              className="px-2 mb-3"
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Card>
                <div className="text-center mt-3 mb-1">
                  <P className="text-color-gray">
                    {totalOther ? totalOther : 0}
                  </P>
                  <p className="m-0">ไม่ระบุเพศ</p>
                </div>
                <AreaChart
                  data={dataGraphTotalOther}
                  width={378}
                  height={65}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                  <Area
                    type="monotone"
                    dataKey="amt"
                    stroke="#434343"
                    fill="#8c8c8c"
                  />
                </AreaChart>
              </Card>
            </Col5>
          </Row>
        </Col>
        <Col span={12}>
          <Card>
            <h5 className="mb-4">5 จังหวัดที่สมัครเยอะที่สุด</h5>
            {provinceGraph.length !== 0 ? (
              <ResponsiveContainer width="100%" height={260}>
                <AreaChart
                  data={provinceGraph}
                  margin={{ top: 5, right: 50, bottom: 0, left: 0 }}
                >
                  <Area
                    type="monotone"
                    dataKey="total"
                    stroke="#73d13d"
                    fill="#95de64"
                  />
                  <XAxis dataKey="title_th" />
                  <YAxis />
                  <Tooltip />
                </AreaChart>
              </ResponsiveContainer>
            ) : (
              <div className="text-center empty mt-5 mb-5">
                <Avatar
                  shape="square"
                  src="/assets/images/dafault/empty.png"
                  size={150}
                />
                <p className="mt-3 mb-0">ยังไม่มีข้อมูล</p>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );

Card5Col.propTypes = {
  totalRegister: PropTypes.number,
  dataGraphTotalRegister: PropTypes.array.isRequired,
  // dataActiveUser: PropTypes.number.isRequired,
  // dataGraphActiveUser: PropTypes.array.isRequired,
  totalMale: PropTypes.number,
  dataGraphTotalMale: PropTypes.array.isRequired,
  totalFemale: PropTypes.number,
  dataGraphTotalFemale: PropTypes.array.isRequired,
  totalOther: PropTypes.number,
  dataGraphTotalOther: PropTypes.array.isRequired,
};
Card5Col.defaultProps = {
  className: "",
};

export default Card5Col;
