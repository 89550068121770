import React, { Component } from "react";
import { Avatar, Col, Row, Button, Table, Modal } from "antd";
import { ArtistsApi } from "../../../apis";
import TableSearchTop from "../TableSearchTop";

class ArtistAlbums extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleView: false,
      albumsList: [
        {
          id: "single",
          name: "Single",
          release_years: "",
          description: "",
          image: `${process.env.PUBLIC_URL}/assets/spicy_logo.jpeg`,
        },
      ],
      albumView: {
        id: 1,
        name: "",
        release_years: 2021,
        description: "",
        image: "",
        table: [],
      },
      total: 0,
      start: 4,
    };
  }

  componentDidMount = () => {
    this.setState({
      albumsList: this.state.albumsList.concat(this.props.albumData),
      total: this.props.total,
    });
  };

  showModalView = async (item) => {
    if (item.id !== "single") {
      await ArtistsApi.getAlbumListSong(this.props.artistsId, item.id)
        .then((resp) => {
          this.setState({
            albumView: {
              id: item.id,
              name: item.name,
              release_years: item.release_years,
              description: item.description,
              image: item.image,
              table: resp.data,
            },
          });
        })
        .catch((error) => {
          throw error;
        });
      this.setState({
        visibleView: true,
      });
    } else {
      await ArtistsApi.getSingleList(this.props.artistsId)
        .then((resp) => {
          this.setState({
            albumView: {
              id: item.id,
              name: item.name,
              release_years: item.release_years,
              description: item.description,
              image: item.image,
              table: resp.data,
            },
          });
        })
        .catch((error) => {
          throw error;
        });
      this.setState({
        visibleView: true,
      });
    }
  };

  handleCancelModalView = () => {
    this.setState({
      visibleView: !this.state.visibleView,
    });
  };

  onClickSearch = async () => {
    const { search } = this.state;
    if (search !== "") {
      ArtistsApi.getAlbumList(this.props.artistsId, 0, 4, search)
        .then(async (resp) => {
          await this.setState({
            albumsList: [
              {
                id: "single",
                name: "Single",
                release_years: "",
                description: "",
                image: `${process.env.PUBLIC_URL}/assets/spicy_logo.jpeg`,
              },
            ],
          });
          await this.setState({
            albumsList: this.state.albumsList.concat(resp.data.rows),
            total: resp.data.count,
            start: 4,
          });
        })
        .catch((error) => {
          throw error;
        });
    } else {
      await this.setState({
        albumsList: [
          {
            id: "single",
            name: "Single",
            release_years: "",
            description: "",
            image: `${process.env.PUBLIC_URL}/assets/spicy_logo.jpeg`,
          },
        ],
      });
      this.componentDidMount();
    }
  };

  onloadMore = () => {
    ArtistsApi.getAlbumList(
      this.props.artistsId,
      this.state.start,
      4,
      this.state.search,
    )
      .then((resp) => {
        this.setState({
          albumsList: this.state.albumsList.concat(resp.data.rows),
          total: resp.data.count,
          start: this.state.start + 4,
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  render() {
    const columnsView = [
      {
        title: "ชื่อเพลง",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "ศิลปิน",
        dataIndex: "artist",
        key: "artist",
        render: (text, record) => (
          <span>{record.artists && record.artists.string}</span>
        ),
      },
      {
        title: "ความยาว/นาที",
        dataIndex: "length",
        key: "length",
        render: (text, record) => (
          <span>
            {+record.duration_minutes > 9
              ? record.duration_minutes
              : `0${record.duration_minutes}`}{" "}
            :{" "}
            {+record.duration_seconds > 9
              ? record.duration_seconds
              : `0${record.duration_seconds}`}
          </span>
        ),
      },
    ];
    const { albumsList, albumView, visibleView, total } = this.state;
    return (
      <div>
        <TableSearchTop
          noFillter
          defaultValueSearch="all"
          noDropdownSearch={false}
          searchCondition={[]}
          columns={columnsView}
          onChange={(e) => this.setState({ search: e.target.value })}
          onClickSearch={this.onClickSearch}
        />
        <Row className="mt-4 mb-4" gutter={[16, 16]}>
          {albumsList.map((item) => (
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              key={item.id}
            >
              <Row onClick={() => this.showModalView(item)}>
                <Col md={{ span: 24 }} xxl={{ span: 6 }}>
                  <Avatar
                    className="mb-3"
                    size={150}
                    src={item.image}
                    shape="square"
                  />
                </Col>
                <Col md={{ span: 24 }} xxl={{ span: 18 }}>
                  <h4>{item.name}</h4>
                  <p className="text-muted">
                    {`${item.release_years ? "at" : ""}${item.release_years}`}
                  </p>
                  <span>{item.description.slice(0, 150)}</span>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
        <Col className="text-center">
          {albumsList.length - 1 !== total && (
            <Button
              type="primary"
              onClick={this.onloadMore}
              size="large"
              className="text-center"
            >
              Loadmore
            </Button>
          )}
        </Col>
        <Modal
          visible={visibleView}
          width={800}
          title="View Albums"
          onOk={this.handleOk}
          onCancel={this.handleCancelModalView}
          footer={[]}
        >
          <Row>
            <Col xs={{ span: 6 }} md={{ span: 6 }}>
              <Avatar
                className="mb-3"
                size={150}
                src={albumView.image}
                shape="square"
              />
            </Col>
            <Col span={18}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <h4>{albumView.name}</h4>
                </Col>
              </Row>
              <p className="text-muted">
                {`${albumView.release_years ? "at" : ""}${
                  albumView.release_years
                }`}
              </p>
              <span>{albumView.description.slice(0, 150)}</span>
            </Col>
          </Row>
          <Table
            className="mt-4"
            dataSource={albumView.table}
            columns={columnsView}
          />
        </Modal>
      </div>
    );
  }
}

export default ArtistAlbums;
