import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const DateTimeShort = (props) => {
  const { dateTime, className } = props;
  moment.locale("en");
  return (
    <span className={className}>{moment(dateTime).format("DD MMM YYYY")}</span>
  );
};

DateTimeShort.propTypes = {
  className: PropTypes.string,
  dateTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
DateTimeShort.defaultProps = {
  className: "",
};

export default DateTimeShort;
