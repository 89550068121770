import styled from "styled-components";

const QuillStyle = styled.div`
  .ql-editor {
    p,
    ul,
    ol {
      margin-bottom: 16px;
      font-size: 16px;
    }
    ,
    .ql-video {
      height: 50vh;
      width: 100%;
    }
  }
`;

export default QuillStyle;
