import React, { Component } from "react";
import {
  Col,
  Row,
  Card,
  Spin,
  Button,
  Tabs,
  Avatar,
  Tag,
  Statistic,
  Modal,
} from "antd";
import {
  FaBirthdayCake,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaCalendar,
  FaEnvelope,
} from "react-icons/fa";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PageTable from "../../commons/PageTable";
import { Layout } from "../../containers";
import { TagStatus, DateTimeLong } from "../../commons";
import { AdvancedTableSearch } from "../../views";
import AvatarProfile from "../../commons/AvatarProfile";
import { CustomerApi } from "../../../apis";
import { userAction } from "../../../store/action";

const { TabPane } = Tabs;
const { confirm } = Modal;

const columnsPoint = [
  {
    title: "Points",
    dataIndex: "points",
    key: "points",
    align: "right",
    render: (text, record) => (
      <Tag
        color={
          record.type_points === 0
            ? "red"
            : record.type_points === 1
            ? "green"
            : ""
        }
        key={record.points}
      >
        {record.type_points === 0 ? "-" : "+"} {record.points}
      </Tag>
    ),
  },
  {
    title: "Entry Date",
    dataIndex: "created_at",
    key: "created_at",
    render: () => <span>12 April 2021 - 14:30</span>,
  },
  {
    title: "Cover",
    dataIndex: "image",
    key: "image",
    render: (text, record) => (
      <Avatar shape="square" className="rounded" size={64} src={record.image} />
    ),
  },
  {
    title: "Title",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Type",
    dataIndex: "t",
    key: "points",
    render: () => <span>Voucher</span>,
  },
];

const columnsMiles = [
  {
    title: "Point",
    dataIndex: "points",
    key: "points",
    align: "right",
    render: (text, record) => (
      <Tag
        color={
          record.type_points === 0
            ? "red"
            : record.type_points === 1
            ? "green"
            : ""
        }
        key={record.points}
      >
        {record.type_points === 0 ? "-" : "+"} {record.points}
      </Tag>
    ),
  },
  {
    title: "Entry Date",
    dataIndex: "created_at",
    key: "created_at",
    render: (text, record) => (
      <span>
        <DateTimeLong dateTime={record.created_at} />
      </span>
    ),
  },
  {
    title: "Title",
    dataIndex: "name",
    key: "name",
  },
];

class CustomerView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      image: "",
      loading: false,
      name: "",
      start: 0,
      length: 15,
      artists: [],
      loadmore: false,
      loadingArtist: false,
      startPoint: 0,
      lengthPoint: 20,
      searchPoint: "",
      totalPoint: 0,
      dataPoint: [],
      dataPrintPoints: [],
      dataPrintMiles: [],
      dataMiles: [],
      startMiles: 0,
      lengthMiles: 20,
      searchMiles: "",
      totalMiles: 0,
      tabActive: "1",
      register_date: "",
      birthdate: "",
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const id = this.props.match.params.customerId;
    await this.setState({ loading: true, loadingArtist: true });
    const setData = (doc) => {
      this.setState({
        address: doc.address,
        birthdate: doc.birthdate,
        register_date: doc.created_at,
        amphure: doc.amphure !== null ? doc.amphure.name_th : "",
        district: doc.district !== null ? doc.district.name_th : "",
        customers_point:
          doc.customers_points !== null ? doc.customers_points.points : 0,
        points_miles:
          doc.customers_points !== null ? doc.customers_points.points_miles : 0,
        email: doc.email,
        name: doc.fullname,
        gender: doc.gender,
        image: doc.image,
        image_default: doc.image_default,
        telephone: doc.telephone,
        status: doc.status,
        province: doc.province !== null ? doc.province.name_th : "",
      });
    };
    await CustomerApi.getCustomerById(id)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    await this.setState({ loading: false });
    await CustomerApi.customerIdFollow(id)
      .then((item) => {
        if (item.recordsTotal > this.state.length) {
          this.setState({
            loadmore: true,
            artists: item.data,
            length: this.state.length + item.data.length,
          });
        } else {
          this.setState({
            loadmore: false,
            artists: item.data,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    this.getPoint();
    this.getMiles();
    await this.setState({ loadingArtist: false });
  };

  loadMore = async () => {
    this.setState({ loading: true });
    const id = this.props.match.params.customerId;
    await CustomerApi.customerIdFollow(id)
      .then((item) => {
        if (item.recordsTotal > this.state.length) {
          this.setState({
            loading: false,
            loadmore: true,
            artists: item.data,
            start: this.state.length + item.data.length,
          });
        } else {
          this.setState({
            loading: false,
            loadmore: false,
            artists: item.data,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    // }
  };

  handleSearch = async () => {
    this.setState({ loading: true });
    const { startPoint, lengthPoint } = this.state;
    const id = this.props.match.params.customerId;
    await CustomerApi.customerIdHistory(
      startPoint,
      lengthPoint,
      id,
      this.state.search,
    )
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          dataPoint: resp.data,
          totalPoint: resp.recordsTotal,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  getPoint = async () => {
    this.setState({ loading: true });
    const { startPoint, lengthPoint, name } = this.state;
    const id = this.props.match.params.customerId;
    await CustomerApi.getCustomerById(id).then(() => {
      this.setState({ customerName: name });
    });
    await CustomerApi.customerIdHistory(startPoint, lengthPoint, id)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          dataPoint: resp.data,
          totalPoint: resp.recordsTotal,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    await CustomerApi.customerIdPointReport(id)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          dataPrintPoints: resp.data,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  getMiles = async () => {
    const id = this.props.match.params.customerId;
    const { startMiles, lengthMiles, name } = this.state;

    await this.setState({ loading: true });

    await CustomerApi.getCustomerById(id).then(() => {
      this.setState({ customerName: name });
    });

    await CustomerApi.customerIdMiles(startMiles, lengthMiles, id).then(
      (resp) => {
        this.setState((prevState) => ({
          ...prevState,
          dataMiles: resp.data,
          totalMiles: resp.recordsTotal,
          loading: false,
        }));
      },
    );

    await CustomerApi.customerIdMilesReport(id)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          dataPrintMiles: resp.data,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  callback = (key) => {
    this.setState({
      tabActive: !key,
    });
  };

  handlePaggination = (start, length, search, page, size) => {
    const id = this.props.match.params.customerId;
    this.setState({ loadingReport: true });
    this.setState((prevState) => ({
      ...prevState,
      start,
      length,
      page: page ? page : 1,
      size: size ? size : 20,
    }));
    CustomerApi.customerIdHistory(start, length, +id)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          dataPoint: resp.data,
          totalPoint: resp.recordsTotal,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handlePagginationMiles = (start, length, search, page, size) => {
    const id = this.props.match.params.customerId;
    this.setState({ loadingReport: true });
    this.setState((prevState) => ({
      ...prevState,
      start,
      length,
      page: page ? page : 1,
      size: size ? size : 20,
    }));
    CustomerApi.customerIdMiles(start, length, id)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          dataMiles: resp.data,
          totalMiles: resp.recordsTotal,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleOk = async (id) => {
    this.setState({ loading: true });
    CustomerApi.DeleteCustomer(id).then((resp) => {
      Swal.fire({
        icon: "success",
        title: `${resp.message}`,
        timer: 1500,
      }).then(() => {
        this.setState({
          loading: false,
        });
        this.props.history.push("/customer");
      });
    });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[21].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const {
      address,
      province,
      district,
      amphure,
      birthdate,
      register_date,
      customers_point,
      name,
      email,
      image,
      loading,
      artists,
      telephone,
      dataPoint,
      totalPoint,
      dataPrintPoints,
      dataPrintMiles,
      page,
      size,
      loadmore,
      loadingArtist,
      points_miles,
      dataMiles,
      totalMiles,
      check_token,
    } = this.state;
    const d = new Date();
    const yearNow = d.getFullYear();
    const headersPoint = [
      { label: "Customer Name", key: "customer_name" },
      { label: "Rewards Name", key: "rewards_detail" },
      { label: "Points", key: "points" },
      { label: "Created At", key: "created_at" },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="Customer Profile"
            extraBtn={[
              <Button
                style={{ display: isDelete[0] === 1 ? "" : "none" }}
                type="primary"
                onClick={() =>
                  confirm({
                    title: "ต้องการลบลูกค้าใช่หรือไม่?",
                    centered: true,
                    icon: <ExclamationCircleOutlined />,
                    onOk: () =>
                      this.handleOk(this.props.match.params.customerId),
                  })
                }
                danger
              >
                Move to trash
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "customer",
                breadcrumbName: "Customers",
              },
              {
                path: `${name}`,
                breadcrumbName: "Customer Profile",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <Row gutter={[16, 16]}>
                <Col md={4} className="text-center">
                  <AvatarProfile
                    className="mb-4"
                    imgSize={200}
                    imgSrc={image}
                  />
                  <TagStatus StatusValue={1} />
                </Col>
                <Col md={20}>
                  <h2>
                    {`${name} (อายุ ${
                      yearNow - +moment(birthdate, "DD/MM/YYYY").format("YYYY")
                    } ปี)`}
                  </h2>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex mb-2">
                      <FaMapMarkerAlt className="mr-2" />
                      <span>{`${address} ${district} ${amphure} ${province}`}</span>
                    </li>
                    <li className="d-flex mb-2">
                      <FaBirthdayCake className="mr-2" />
                      <span>{birthdate}</span>
                    </li>
                  </ul>
                  <div className="customer-info">
                    <ul className="nav nav-pills flex-column flex-md-row mt-4">
                      <li className="nav-item mr-5">
                        <Statistic
                          title="Favorite Artists"
                          value={artists.length}
                        />
                      </li>
                      <li className="nav-item mr-5">
                        <Statistic title="Points" value={customers_point} />
                      </li>
                      <li className="nav-item mr-5">
                        <Statistic title="Miles" value={points_miles} />
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <Tabs
                className="customer-tabs"
                defaultActiveKey="1"
                onChange={this.callback}
              >
                <TabPane tab="Basic information" key="info">
                  <Card>
                    <ul className="list-unstyled fs-sm mb-0">
                      <li className="d-flex mb-2">
                        <FaBirthdayCake className="mr-2" />
                        <strong>Birth date:</strong> <span>{birthdate}</span>
                      </li>
                      <li className="d-flex mb-2">
                        <FaPhoneAlt className="mr-2" />
                        <strong>Phone number:</strong>{" "}
                        <span className="ml-2">{telephone}</span>
                      </li>
                      <li className="d-flex mb-2">
                        <FaEnvelope className="mr-2" />
                        <strong>Email:</strong>{" "}
                        <span className="ml-2">{email}</span>
                      </li>
                      <li className="d-flex">
                        <FaCalendar className="mr-2" />
                        <strong>Register date:</strong>{" "}
                        <span>{register_date}</span>
                      </li>
                    </ul>
                  </Card>
                </TabPane>
                <TabPane tab="Favorite Artists" key="artists">
                  <Spin spinning={loadingArtist}>
                    <Card>
                      <Row align="top">
                        {artists.length > 0 ? (
                          <Row align="top" className="w-100">
                            {artists.map((item) => (
                              <Col md={4} key={item.id}>
                                <AvatarProfile
                                  className="text-center mb-3"
                                  imgSize={90}
                                  imgSrc={item.image}
                                  artistName={item.name}
                                />
                              </Col>
                            ))}
                          </Row>
                        ) : (
                          "คุณยังไม่ได้ follow ใคร"
                        )}
                      </Row>
                      {artists.length > 0 && loadmore !== false && (
                        <div className="text-center">
                          <Button
                            type="primary"
                            size="large"
                            onClick={this.loadMore}
                          >
                            Load more
                          </Button>
                        </div>
                      )}
                    </Card>
                  </Spin>
                </TabPane>
                <TabPane tab="Points" key="points">
                  <Card>
                    <AdvancedTableSearch
                      noFillter
                      noDropdownSearch
                      dataExport={dataPrintPoints}
                      fileName="customer-points"
                      headers={headersPoint}
                      columns={columnsPoint}
                      data={dataPoint}
                      handleSearch={this.handleSearch}
                      onChangeSearch={(e) =>
                        this.setState({ search: e.target.value })
                      }
                      loading={loading}
                      searchCondition={["points", "created_at", "image"]}
                      filterCondition={["action", "sticky"]}
                      rowKey={(record) => record.created_at}
                      total={totalPoint}
                      onTableChange={this.handlePaggination}
                      page={page}
                      size={size}
                    />
                  </Card>
                </TabPane>
                <TabPane tab="Miles" key="miles">
                  <Card>
                    <Row className="mb-3" justify="end">
                      <Col>
                        <CSVLink
                          data={dataPrintMiles}
                          filename={`${"Miles"}-event(points)-report-${Date.now()}.xls`}
                          headers={columnsMiles}
                          target="_blank"
                        >
                          <Button type="primary" icon={<UploadOutlined />}>
                            Export to Excel
                          </Button>
                        </CSVLink>
                      </Col>
                    </Row>
                    <PageTable
                      rowKey={(record) => record.id}
                      columns={columnsMiles}
                      data={dataMiles}
                      total={totalMiles}
                      onChange={this.handlePagginationMiles}
                    />
                  </Card>
                </TabPane>
              </Tabs>
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerView);
