import React, { Component } from "react";
import {
  Col,
  Form,
  Row,
  Spin,
  Button,
  Card,
  Input,
  Radio,
  Upload,
  Modal,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import AlertMessages from "../../commons/AlertMessages";
import ImgCrop from "antd-img-crop";
import * as Swal from "sweetalert2";
import { ArtistsApi } from "../../../apis";
import { Layout } from "../../containers";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import ReactQuill from "react-quill";
import QuillStyle from "../../commons/EditorQuill/QuillStyle";
import { httpClient } from "../../utils/axios";

const UploadStyle = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
  }
`;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    console.log(file);
    onSuccess("ok");
  }, 0);
};

function getBase64Cover(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", (e) => {
    const img = new Image();
    img.src = e.target.result;
    img.onload = () => {
      callback(reader.result);
    };
  });
  Swal.close();
  reader.readAsDataURL(img);
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

async function beforeUpload(file) {
  const reader = new FileReader();
  await reader.readAsDataURL(file);
  await reader.addEventListener("load", (event) => {
    // eslint-disable-next-line no-underscore-dangle
    const _loadedImageUrl = event.target.result;
    const image = document.createElement("img");
    image.src = _loadedImageUrl;
    image.addEventListener("load", () => {
      const { width, height } = image;
      if (width !== height) {
        Swal.fire({
          icon: "error",
          text: `The media condition maximum width 500px sets the size of the image`,
          timer: 1500,
        });

        return false;
      }
    });
  });
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `You can only upload JPG/PNG file!`,
      timer: 1500,
    });
  }
  const isLt5M = file.size / 1024 / 1024 < 0.512;
  if (!isLt5M) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `Image must smaller than 512KB!`,
      timer: 1500,
    });
  }
  return isJpgOrPng && isLt5M;
}

class EditArtists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check_token: false,
      loading: true,
      previewVisible: false,
      previewImage: "",
      fileList: [],
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.reactQuillRef = null;
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = () => {
    this.setState({
      check_token: true,
    });
    ArtistsApi.getArtist(this.props.match.params.artistsId)
      .then((resp) => {
        const image = [];
        resp.data.artists_images.forEach((doc) => {
          image.push({
            uid: doc.id,
            name: doc.id,
            status: "done",
            url: doc.image,
          });
        });
        this.setState({
          name: resp.data.name,
          facebook: resp.data.facebook,
          twitter: resp.data.twitter,
          instagram: resp.data.instagram,
          youtube: resp.data.youtube,
          status: resp.data.status,
          detail: resp.data.detail,
          imageUrl: resp.data.cover,
          fileList: image,
          oldImage: image,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleCancel = () => {
    this.props.history.push("/artists");
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt512k = file.size / 1024 / 1024 < 0.512;
    if (!isLt512k) {
      Swal.close();
      Swal.fire({
        icon: "error",
        text: `Image must smaller than 512KB!`,
        timer: 1500,
      });
    }
    return isJpgOrPng && isLt512k;
  };

  handleChange = ({ file, fileList }) => {
    const { oldImage } = this.state;
    if (file.status) {
      if (file.status === "removed") {
        for (let i = 0; i < oldImage.length; i += 1) {
          if (oldImage[i].uid === file.uid) {
            ArtistsApi.DeleteArtistImage(
              oldImage[i].uid,
              this.props.match.params.artistsId,
            ).then(() => {
              this.setState({ fileList });
            });
          }
        }
        this.setState({ fileList });
      } else {
        this.setState({ fileList });
        if (file.status === "done") {
          const formData = new FormData();
          formData.append("artist_id", +this.props.match.params.artistsId);
          formData.append("image", file.originFileObj);
          ArtistsApi.CreateArtistImage(formData).then(async (res) => {
            const imageData = this.state.fileList;
            // eslint-disable-next-line no-restricted-syntax
            for (const [i, img] of imageData.entries()) {
              // eslint-disable-next-line eqeqeq
              if (img.uid == file.originFileObj.uid) {
                imageData.splice(i, 1);
              }
            }
            await this.setState({
              fileList: imageData.concat([
                {
                  uid: res.id,
                  name: res.id,
                  status: "done",
                  url: await getBase64(file.originFileObj),
                },
              ]),
              oldImage: imageData.concat([
                {
                  uid: res.id,
                  name: res.id,
                  status: "done",
                  url: res.image,
                },
              ]),
            });
          });
        }
      }
    }
  };

  handleChangeInput = (changeObject) => {
    this.setState(changeObject);
  };

  handleChangePhotoCover = async (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      await getBase64Cover(info.file.originFileObj, (imageUrl) => {
        this.setState({
          imageUrl,
          imageFile: info.file.originFileObj,
          loading: false,
        });
      });
    }
  };

  uploadFiles = (uploadFileObj) => {
    const formData = new FormData();
    const that = this;
    formData.append("image", uploadFileObj);
    httpClient
      .post("/news/content/image", formData)
      .then((response) => {
        if (response.data) {
          const range = that.reactQuillRef.getEditorSelection();
          setTimeout(() => {
            that.reactQuillRef
              .getEditor()
              .insertEmbed(range.index, "image", response.data.url);
          }, 2000);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      this.uploadFiles(file, this.reactQuillRef);
    };
  };

  render() {
    const onFinish = (values) => {
      Swal.fire({
        title: "ต้องการแก้ไขข้อมูลศิลปินใช่หรือไม่",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#0c7973",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: "ระบบกำลังทำงาน",
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              Swal.showLoading();
            },
          });
          const formData = new FormData();
          formData.append("name", values.name);
          formData.append("detail", values.detail);
          formData.append("facebook", values.facebook);
          formData.append("twitter", values.twitter);
          formData.append("instagram", values.instagram);
          formData.append("youtube", values.youtube);
          formData.append("status", values.status);
          formData.append("cover", this.state.imageFile);
          ArtistsApi.ArtistUpdate(+this.props.match.params.artistsId, formData)
            .then((resp) => {
              Swal.fire({
                icon: "success",
                title: `${resp.message}`,
                timer: 1500,
              }).then(() => this.props.history.push("/artists"));
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: `${error.response.data.message}`,
              });
            });
        }
      });
    };

    const {
      loading,
      fileList,
      previewVisible,
      previewImage,
      imageUrl,
      check_token,
    } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        {check_token === true && (
          <Layout
            title="Edit Artists"
            // extraBtn={[{<Button>ถ้ามี</Button>},{<Button>ถ้ามี</Button>}]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "artists",
                breadcrumbName: "Artists",
              },
              {
                path: "create",
                breadcrumbName: "Edit Artists",
              },
            ]}
          >
            <Card className="mb-4">
              <Spin spinning={loading} tip="Loading...">
                {loading === false && (
                  <Form layout="vertical" size="medium" onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <p>Cover image</p>
                        <Form.Item style={{ marginTop: +10 }} name={["image"]}>
                          <Row
                            gutter={[16, 16]}
                            type="flex"
                            align="middle"
                            className="form__upload"
                          >
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 14, offset: 0 }}
                            >
                              <ImgCrop aspect={16 / 9}>
                                <UploadStyle
                                  accept=".jpg, .jpeg, .png"
                                  name="avartar"
                                  customRequest={dummyRequest}
                                  listType="picture-card"
                                  multiple={false}
                                  showUploadList={false}
                                  beforeUpload={this.beforeUpload}
                                  onChange={this.handleChangePhotoCover}
                                >
                                  {imageUrl ? (
                                    <img
                                      width="100%"
                                      src={imageUrl}
                                      alt="avatar"
                                    />
                                  ) : (
                                    uploadButton
                                  )}
                                </UploadStyle>
                              </ImgCrop>
                            </Col>
                          </Row>
                        </Form.Item>
                        <AlertMessages type="warning" icon={false}>
                          <ul>
                            <li>Max file size 512kb.</li>
                            <li>Image with .jpg, .jpeg and .png</li>
                            <li>ขนาดรูปแนะนำ 1920*1080px</li>
                          </ul>
                        </AlertMessages>
                        <p style={{ marginTop: "16px" }}>Feature image</p>

                        <Form.Item style={{ marginTop: +10 }} name={["image"]}>
                          <Row
                            gutter={[16, 16]}
                            type="flex"
                            align="middle"
                            className="form__upload"
                          >
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 14, offset: 0 }}
                            >
                              <ImgCrop aspect={1 / 1}>
                                <Upload
                                  action={`${process.env.REACT_APP_FRONT_API}test`}
                                  listType="picture-card"
                                  fileList={fileList}
                                  beforeUpload={beforeUpload}
                                  onPreview={this.handlePreview}
                                  onChange={this.handleChange}
                                >
                                  {fileList.length >= 8 ? null : uploadButton}
                                </Upload>
                              </ImgCrop>
                            </Col>
                          </Row>
                        </Form.Item>
                        <AlertMessages type="warning" icon={false}>
                          <ul>
                            <li>Max file size 512kb.</li>
                            <li>Image with .jpg, .jpeg and .png</li>
                            <li>ขนาดรูปแนะนำ 500*500px</li>
                            <li>อัพโหลดได้ 1-5 รูป</li>
                          </ul>
                        </AlertMessages>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Name"
                          name="name"
                          initialValue={this.state.name}
                          rules={[
                            {
                              required: true,
                              message: "Please input name!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <QuillStyle>
                          <Form.Item
                            label="Detail"
                            name="detail"
                            initialValue={this.state.detail}
                            rules={[
                              {
                                required: true,
                                message: "Please input detail!",
                              },
                            ]}
                          >
                            <ReactQuill
                              ref={(el) => {
                                this.reactQuillRef = el;
                              }}
                              theme="snow"
                              modules={{
                                toolbar: {
                                  container: [
                                    ["bold", "italic", "underline"],
                                    [
                                      {
                                        color: [
                                          "#000000",
                                          "#e60000",
                                          "#ff9900",
                                          "#ffff00",
                                          "#008a00",
                                          "#0066cc",
                                          "#9933ff",
                                          "#ffffff",
                                          "#facccc",
                                          "#ffebcc",
                                          "#ffffcc",
                                          "#cce8cc",
                                          "#cce0f5",
                                          "#ebd6ff",
                                          "#bbbbbb",
                                          "#f06666",
                                          "#ffc266",
                                          "#ffff66",
                                          "#66b966",
                                          "#66a3e0",
                                          "#c285ff",
                                          "#888888",
                                          "#a10000",
                                          "#b26b00",
                                          "#b2b200",
                                          "#006100",
                                          "#0047b2",
                                          "#6b24b2",
                                          "#444444",
                                          "#5c0000",
                                          "#663d00",
                                          "#666600",
                                          "#003700",
                                          "#002966",
                                          "#3d1466",
                                        ],
                                      },
                                    ],
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    ["link", "image"],
                                    ["clean"],
                                  ],
                                  handlers: {
                                    image: this.imageHandler,
                                  },
                                },
                                clipboard: {
                                  matchVisual: false,
                                },
                              }}
                            />
                          </Form.Item>
                        </QuillStyle>
                        <Form.Item
                          initialValue={this.state.facebook}
                          label="Facebook (Optional)"
                          name="facebook"
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          initialValue={this.state.instagram}
                          label="Instagram (Optional)"
                          name="instagram"
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          initialValue={this.state.youtube}
                          label="Youtube (Optional)"
                          name="youtube"
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          initialValue={this.state.twitter}
                          label="Twitter (Optional)"
                          name="twitter"
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          initialValue={this.state.status === 1 ? "1" : "0"}
                          name="status"
                          label="Status"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Status!",
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value="1">Active</Radio>
                            <Radio value="0">Suspend</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item className="my-3">
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                      <Button
                        className="mx-4"
                        onClick={() => this.handleCancel()}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </Form>
                )}
                <Modal
                  visible={previewVisible}
                  footer={null}
                  onCancel={this.handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </Spin>
            </Card>
          </Layout>
        )}
      </>
    );
  }
}

export default EditArtists;
