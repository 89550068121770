import { httpClient } from "../components/utils/axios";

export const EventTable = (start, length, search, type, type_filter) =>
  httpClient
    .get(
      `events_renews/datatable?start=${start || 0}&length=${
        length || 20
      }&search=${search || ""}&type=${type || 0}&type_filter=${type_filter}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const EventGetArtist = () =>
  httpClient
    .get(`events_renews/artist/list?search=`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const EventStickyTable = (start, length) =>
  httpClient
    .get(
      `events_renews/promote/datatable?start=${start || 0}&length=${
        length || 20
      }`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const EventCalendar = (month, year) =>
  httpClient
    .get(`events_renews/calendar?month=${month || ""}&year=${year || ""}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const EventTableReport = (search, type, type_filter) =>
  httpClient
    .get(
      `events_renews/export?search=${search || ""}&type=${
        type || 0
      }&type_filter=${type_filter}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const EventUpdate = (id, formData) =>
  httpClient
    .put(`events_renews?id=${id}`, formData)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const EventSticky = (id) =>
  httpClient
    .put(`events_renews/promote?id=${id}`)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const CreateEvent = (formData) =>
  httpClient
    .post(`events_renews`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const DeleteEvent = (id) =>
  httpClient
    .delete(`events_renews?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const getEvent = (id) =>
  httpClient
    .get(`events_renews?id=${id}`)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const EditIndexSticky = (data) =>
  httpClient
    .put(`events/sticky/index`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const CreateEventImage = (formData) =>
  httpClient
    .post(`events_renews/images`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const DeleteEventImage = (id, eventId) =>
  httpClient
    .delete(`events_renews/images?id=${id}&event_custom_id=${eventId}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
