import React, { Component } from "react";
import { Dropdown, Avatar, Spin, Button, Menu, Modal } from "antd";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { MoreOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { DateTimeMid, TagStatus } from "../../commons";
import { CustomerApi, eventsApi } from "../../../apis";
import { userAction } from "../../../store/action";
import { AdvancedTableSearch } from "../../views";
import { Layout } from "../../containers";

const { confirm } = Modal;

class CustomerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      data: [],
      start: 0,
      length: 20,
      search: "",
      total: 0,
      modalCustomerId: 0,
      modalCustomerName: "",
      modalCustomerImg: "",
      dataPrint: [],
      select: "",
      name: "",
      age: [20, 50],
      email: "",
      province: "",
      phone: "",
      register_date: "",
      keywords: "",
      dataProvinces: [],
      typeFilter: 1,
      status: "",
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    this.setState({
      loading: true,
      search: "",
      select: "",
      start: 0,
      length: 20,
      total: 0,
    });

    const {
      start,
      length,
      search,
      select,
      name,
      age,
      email,
      province,
      phone,
      register_date,
    } = this.state;

    await CustomerApi.CustomerTable(
      start,
      length,
      search,
      select,
      name,
      age,
      email,
      province,
      phone,
      register_date,
    )
      .then((resp) => {
        resp.data.forEach((doc) => {
          doc.customers_points = doc.customers_points
            ? doc.customers_points.points
            : 0;
          doc.province = doc.province ? doc.province.name_th : "-";
          doc.amphure = doc.amphure ? doc.amphure.name_th : "-";
          doc.district = doc.district
            ? doc.district
            : { name_th: "", zip_code: "-" };
        });
        this.setState({
          data: resp.data,
          total: resp.recordsTotal,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });

    await eventsApi
      .eventsManualPointsProvinces()
      .then((resp) => {
        this.setState({
          dataProvinces: resp.data,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });

    await CustomerApi.CustomerTableReport("", "", "")
      .then((resp) => {
        resp.data.forEach((doc) => {
          doc.customers_points = doc.customers_points
            ? doc.customers_points.points
            : 0;
          doc.province = doc.province ? doc.province.name_th : "-";
          doc.amphure = doc.amphure ? doc.amphure.name_th : "-";
          doc.district = doc.district ? doc.district.name_th : "-";
          doc.address = doc.address === "" ? "-" : doc.address;
        });
        for (let i = 0; i < resp.data.length; i += 1) {
          resp.data[i].artists = resp.data[i].customers_follows.map(
            (doc) => doc.artist.name,
          );
          resp.data[i].customers_follows =
            resp.data[i].customers_follows.length;
        }
        this.setState({
          dataPrint: resp.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async (id) => {
    this.setState({ loading: true });
    CustomerApi.DeleteCustomer(id).then((resp) => {
      Swal.fire({
        icon: "success",
        title: `${resp.message}`,
        timer: 1500,
      }).then(() => {
        this.setState({
          loading: false,
        });
        this.componentDidMount();
      });
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onChangeSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleSearch = async (typeFilter) => {
    this.setState({ loading: true });
    const { length, typeSearch, search, name, age, status, province } =
      this.state;
    const roleId = [];
    if (typeSearch === 4) {
      [
        {
          id: 1,
          name: "male",
        },
        {
          id: 2,
          name: "female",
        },
        {
          id: 3,
          name: "other",
        },
      ].forEach((doc) => {
        if (search === doc.name) {
          roleId.push(doc.id);
        }
      });
    } else if (typeSearch === 2) {
      if (search === "active") {
        roleId.push(1);
      } else {
        roleId.push(0);
      }
    }
    const text = roleId.length > 0 ? roleId[0] : search;
    const type =
      typeFilter === 0
        ? typeFilter
        : `1&name=${name}&start_age=${age[0]}&end_age=${
            age[1]
          }&status=${status}&province_id=${province}&start_date=${
            this.state.start_date || ""
          }&end_date=${this.state.end_date || ""}`;
    await CustomerApi.CustomerTable(0, length, text, typeSearch, type)
      .then((resp) => {
        resp.data.forEach((doc) => {
          doc.customers_points = doc.customers_points
            ? doc.customers_points.points
            : 0;
          doc.province = doc.province ? doc.province.name_th : "-";
          doc.amphure = doc.amphure ? doc.amphure.name_th : "-";
          doc.district = doc.district
            ? doc.district
            : { name_th: "", zip_code: "-" };
        });
        this.setState({
          data: resp.data,
          total: resp.recordsTotal,
          loading: false,
          typeFilter,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });

    await CustomerApi.CustomerTableReport(text, typeSearch, type)
      .then((resp) => {
        resp.data.forEach((doc) => {
          doc.customers_points = doc.customers_points
            ? doc.customers_points.points
            : 0;
          doc.province = doc.province ? doc.province.name_th : "-";
          doc.amphure = doc.amphure ? doc.amphure.name_th : "-";
          doc.district = doc.district ? doc.district.name_th : "-";
          doc.address = doc.address === "" ? "-" : doc.address;
        });
        for (let i = 0; i < resp.data.length; i += 1) {
          resp.data[i].artists = resp.data[i].customers_follows.map(
            (doc) => doc.artist.name,
          );
          resp.data[i].customers_follows =
            resp.data[i].customers_follows.length;
        }
        this.setState({
          dataPrint: resp.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handlePagination = (start, length, search, page) => {
    this.setState({ loading: true });
    this.setState((prevState) => ({
      ...prevState,
      start,
      length,
      search,
      page,
    }));
    const { typeSearch, name, age, status, province, typeFilter } = this.state;
    const roleId = [];
    if (typeSearch === 4) {
      [
        {
          id: 1,
          name: "male",
        },
        {
          id: 2,
          name: "female",
        },
        {
          id: 3,
          name: "other",
        },
      ].forEach((doc) => {
        if (search === doc.name) {
          roleId.push(doc.id);
        }
      });
    } else if (typeSearch === 2) {
      if (search === "active") {
        roleId.push(1);
      } else {
        roleId.push(0);
      }
    }
    const text = roleId.length > 0 ? roleId[0] : search;
    const type =
      typeFilter === 0
        ? typeFilter
        : `1&name=${name}&start_age=${age[0]}&end_age=${
            age[1]
          }&status=${status}&province_id=${province}&start_date=${
            this.state.start_date || ""
          }&end_date=${this.state.end_date || ""}`;
    CustomerApi.CustomerTable(start, length, text, typeSearch, type)
      .then((resp) => {
        resp.data.forEach((doc) => {
          doc.customers_points = doc.customers_points
            ? doc.customers_points.points
            : 0;
          doc.province = doc.province ? doc.province.name_th : "-";
          doc.amphure = doc.amphure ? doc.amphure.name_th : "-";
          doc.district = doc.district
            ? doc.district
            : { name_th: "", zip_code: "-" };
        });
        this.setState({
          data: resp.data,
          total: resp.recordsTotal,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleRegisterDate = (e) => {
    let date = "";
    if (!e) {
      date = "";
    } else {
      date = e.format("DD-MM-YYYY");
    }
    this.setState({
      register_date: date,
    });
  };

  handleChangeProvince = (e) => {
    this.setState({
      province: e,
    });
  };

  onChange = async (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      await this.setState({
        name: value,
      });
    }
    if (name === "age") {
      // if (!Number(value)) {
      //   return;
      // }
      await this.setState({
        age: value,
      });
    }
    if (name === "email") {
      await this.setState({
        email: value,
      });
    }
    if (name === "province") {
      await this.setState({
        province: value,
      });
    }
    if (name === "phone") {
      // if (!Number(value)) {
      //   return;
      // }
      await this.setState({
        phone: value,
      });
    }
  };

  render() {
    moment.locale("en");
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[21].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, data, total, dataProvinces, check_token } = this.state;

    const menu = (record) => (
      <Menu className="datable-actions-menu">
        <Menu.Item>
          <Link to={`/customer/${record.id}`} className="">
            View
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={`/customer/${record.id}/edit`} className="">
            Edit
          </Link>
        </Menu.Item>
        {isDelete[0] === 1 && (
          <Menu.Item>
            <Link
              to="/customer"
              onClick={() => {
                confirm({
                  title: "ต้องการลบลูกค้าใช่หรือไม่?",
                  centered: true,
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => this.handleOk(record.id),
                });
              }}
            >
              Trash
            </Link>
          </Menu.Item>
        )}
      </Menu>
    );

    const columns = [
      {
        title: "Name",
        dataIndex: "fullname",
        key: "fullname",
        type: "input",
        selectData: [],
        onChange: () => console.log("ok"),
        value: 1,
        render: (text, record) => (
          <Link to={`/customer/${record.id}`}>
            <Avatar size={64} shape="circle" src={record.image} />
            <span className="ml-3">{record.fullname}</span>
          </Link>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        type: "select",
        value: "",
        render: (text) => <TagStatus StatusValue={text} />,
      },
      {
        title: "Phone number",
        dataIndex: "telephone",
        key: "telephone",
        type: "input",
        align: "right",
        selectData: [],
        onChange: () => console.log("ok"),
        render: (text, record) => (
          <span>{record.telephone ? record.telephone : "-"}</span>
        ),
      },
      {
        title: "Sex",
        dataIndex: "gender",
        key: "gender",
        value: "",
        type: "select",
        selectData: [],
        onChange: () => console.log("ok"),
        render: (text, record) => (
          <span>
            {record.gender === 1
              ? "Male"
              : record.gender === 2
              ? "Female"
              : "Other"}
          </span>
        ),
      },
      {
        title: "Points",
        dataIndex: "points",
        key: "points",
        align: "right",
        type: "input",
        selectData: [],
        onChange: () => console.log("ok"),
        render: (text, record) => <span>{record.customers_points}</span>,
      },
      {
        title: "Register date",
        dataIndex: "created_at",
        key: "created_at",
        type: "date",
        selectData: [],
        onChange: () => console.log("ok"),
        render: (text, record) => <DateTimeMid dateTime={record.created_at} />,
      },
      {
        title: "Actions",
        key: "action",
        render: (text, record) => (
          <Dropdown
            trigger={["click"]}
            overlay={menu(record)}
            placement="bottomRight"
          >
            <Button type="link">
              <MoreOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];

    const headers = [
      { title: "Name", label: "Name", key: "fullname" },
      {
        title: "Status",
        label: "Status",
        key: "status",
        type: "select",
        value: "",
        selectData: [
          {
            id: 1,
            name: "Active",
          },
          { id: 0, name: "Suspend" },
        ],
        onChange: (status) => {
          this.setState({ status });
        },
      },
      { title: "Sex", label: "Gender", key: "gender" },
      { title: "Email", label: "Email", key: "email" },
      { title: "Birthdate", label: "Birthdate", key: "birthdate" },
      { title: "Phone number", label: "Telephone", key: "telephone" },
      {
        title: "Age",
        label: "Age",
        key: "age",
        type: "range",
        onChange: (age) => this.setState({ age }),
      },
      { title: "Points", label: "Points", key: "customers_points" },
      { title: "Created At", label: "Created At", key: "created_at" },
      { title: "Address", label: "Address", key: "address" },
      { title: "Amphure", label: "Amphure", key: "amphure" },
      { title: "District", label: "District", key: "district" },
      {
        title: "Province",
        label: "Province",
        key: "province",
        type: "address",
        selectData: dataProvinces,
        value: "",
        onChange: (province, record) => {
          this.setState({ province: record.key });
        },
      },
      {
        title: "Register date",
        label: "Register date",
        key: "date",
        type: "date",
        onChange: (date, string) =>
          this.setState({
            start_date: moment(string[0], "DD/MM/YYYY").format("YYYY-MM-DD"),
            end_date: moment(string[1], "DD/MM/YYYY").format("YYYY-MM-DD"),
          }),
      },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="Customers"
            extraBtn={[
              <Button
                disabled={loading}
                type="primary"
                onClick={() =>
                  this.props.history.push("/delete-account-customer")
                }
                danger
              >
                Trash view
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "customer",
                breadcrumbName: "Customers",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <AdvancedTableSearch
                columns={columns}
                data={data}
                dataExport={this.state.dataPrint}
                nonExport
                columnsFilter={headers}
                loading={loading}
                columnFilter={headers}
                handleSearch={this.handleSearch}
                onChangeSearch={this.onChangeSearch}
                fileName="customer"
                searchCondition={[
                  "action",
                  "image",
                  "email",
                  "points",
                  "created_at",
                ]}
                filterCondition={[
                  "action",
                  "image",
                  "birthdate",
                  "created_at",
                  "gender",
                  "email",
                  "customers_points",
                  "following",
                  "artists",
                  "address",
                  "amphure",
                  "district",
                ]}
                defaultValueSearch="fullname"
                onChangeTypeSearch={(value) => {
                  this.setState({
                    typeSearch:
                      value === "fullname"
                        ? 1
                        : value === "telephone"
                        ? 3
                        : value === "status"
                        ? 2
                        : value === "gender"
                        ? 4
                        : 5,
                  });
                }}
                rowKey={(record) => record.id}
                total={total}
                onTableChange={this.handlePagination}
                onChangeDate={this.handleRegisterDate}
                provinces={dataProvinces}
                valProvinces={this.state.province}
                provinceId={this.state.province}
                onChangeProvince={this.handleChangeProvince}
                clearFilter={() =>
                  this.setState({
                    name: "",
                    age: [20, 50],
                    email: "",
                    province: "",
                    phone: "",
                    register_date: "",
                    start_date: "",
                    end_date: "",
                    keywords: "",
                    dataProvinces: [],
                    typeFilter: 0,
                    status: "",
                  })
                }
              />
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage);
