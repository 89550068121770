import { httpClient } from "../components/utils/axios";

export const AlbumTable = (start, length, search) =>
  httpClient
    .get(
      `albums/datatable?start=${start || 0}&length=${length || 20}&search=${
        search || ""
      }`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const AlbumSongTable = (start, length, album_id) =>
  httpClient
    .get(
      `albums/songs/datatable?start=${start || 0}&length=${
        length || 10
      }&album_id=${album_id || 0}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const AlbumUpdate = (id, formData) =>
  httpClient
    .put(`albums?id=${id}`, formData)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const CreateAlbum = (formData) =>
  httpClient
    .post(`albums`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const CreateSongOfAlbum = (data) =>
  httpClient
    .post(`albums/songs`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const DeleteAlbum = (id) =>
  httpClient
    .delete(`albums?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const DeleteSongOfAlbum = (id, album_id) =>
  httpClient
    .delete(`albums/songs?id=${id}&album_id=${album_id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const AlbumGetSongs = (id) =>
  httpClient
    .get(`albums/songs/list?search=&id=${id || 0}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const GetAlbum = (id) =>
  httpClient
    .get(`albums?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
