const initialState = {
  users: null,
};
// funcntion name = (state = [], action) {}
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_LOGIN_SUCCESS":
      return action.users;
    default:
      return state;
  }
};

export default userReducer;
