import React, { Component } from "react";
import {
  Card,
  Col,
  Form,
  Row,
  Spin,
  Button,
  Input,
  DatePicker,
  Upload,
  Radio,
} from "antd";
import * as Swal from "sweetalert2";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import QuillStyle from "../../commons/EditorQuill/QuillStyle";
import { httpClient } from "../../utils/axios";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { Layout } from "../../containers";
import { eventsApi } from "../../../apis";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", (e) => {
    const img = new Image();
    img.src = e.target.result;
    img.onload = () => {
      callback(reader.result);
    };
  });
  Swal.close();
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  Swal.fire({
    title: "ระบบกำลังทำงาน",
    allowEscapeKey: false,
    allowOutsideClick: false,
    onOpen: () => {
      Swal.showLoading();
    },
  });
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `You can only upload JPG/PNG file!`,
      timer: 1500,
    });
  }
  const isLt10M = file.size / 1024 / 1024 < 1;
  if (!isLt10M) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `Image must smaller than 1MB!`,
      timer: 1500,
    });
  }
  return isJpgOrPng && isLt10M;
}

const { RangePicker } = DatePicker;

class EditCheckInEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
      loading: false,
      radioStatusValue: 0,
      check_token: false,
      // dataEditor: "",
    };
    this.reactQuillRef = null;
  }

  componentDidMount() {}

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const id = this.props.match.params.checkInEventId;
    this.setState({ loading: true });
    const setData = (doc) => {
      const start = doc.events_details && doc.events_details.start_date;
      const end = doc.events_details && doc.events_details.end_date;

      const startDate = moment(start, "DD/MM/YYYY").format("YYYY-MM-DD");
      const endDate = moment(end, "DD/MM/YYYY").format("YYYY-MM-DD");
      this.setState({
        pageTitle: doc.name,
        textAreaInputActivity: doc.events_details && doc.events_details.detail,
        addressInputActivity: doc.events_details && doc.events_details.place,
        pointsInputActivity: doc.points,
        imageUrl: doc.image_m,
        imageFile: null,
        startDate,
        endDate,
        loading: false,
        radioStatusValue: doc.status,
      });
    };
    eventsApi
      .eventCheckById(id)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
          timer: 1500,
        }).then(() => {
          this.setState({
            loading: false,
          });
        });
      });
  };

  handleCancel = () => {
    this.props.history.push("/check-in-event");
  };

  handleChangePhoto = async (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      await getBase64(info.file.originFileObj, (imageUrl) => {
        this.setState({
          imageUrl,
          imageFile: info.file.originFileObj,
          loading: false,
        });
      });
    }
  };

  handleChangeInput = (changeObject) => {
    this.setState(changeObject);
  };

  onChange = (value) => {
    this.setState({
      startDate: value[0],
      endDate: value[1],
    });
  };

  onFinish = (values) => {
    const id = this.props.match.params.checkInEventId;
    const that = this.props;
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("detail", values.content);
    formData.append("place", values.place);
    formData.append("points", +values.points);
    formData.append(
      "status",
      values.status ? values.status : this.state.radioStatusValue,
    );
    formData.append("start_date", moment(this.state.startDate).unix());
    formData.append("end_date", moment(this.state.endDate).unix());
    formData.append("image", this.state.imageFile);

    Swal.fire({
      title: "ต้องการแก้ไขอีเว้นท์ใหม่ใช่หรือไม่",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "ระบบกำลังทำงาน",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        eventsApi
          .eventCheckInUpdateById(id, formData)
          .then((resp) => {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: `${resp.message}`,
              timer: 1500,
            }).then(() => {
              that.history.push(`/check-in-event/${id}`);
              Swal.close();
            });
          })
          .catch((error) => {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
              timer: 1500,
            });
          });
      }
    });
  };

  disabledDate = (current) =>
    // Can not select days before today and today
    current && current < moment().add(-1, "days").endOf("day");

  onChangeText = (text) => {
    this.setState({ textAreaInputActivity: text });
  };

  uploadFiles = (uploadFileObj) => {
    const formData = new FormData();
    const that = this;
    formData.append("image", uploadFileObj);
    httpClient
      .post("/news/content/image", formData)
      .then((response) => {
        if (response.data) {
          const range = that.reactQuillRef.getEditorSelection();
          setTimeout(() => {
            that.reactQuillRef
              .getEditor()
              .insertEmbed(range.index, "image", response.data.url);
          }, 2000);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      this.uploadFiles(file, this.reactQuillRef);
    };
  };

  render() {
    moment.locale("th");

    const { loading, radioStatusValue, imageUrl, check_token } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        {check_token === true && (
          <Layout
            title="Edit check-in"
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "check-in-event",
                breadcrumbName: "Check in event",
              },
              {
                path: "edit",
                breadcrumbName: "Edit check-in",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              {loading === false && (
                <Form layout="vertical" onFinish={this.onFinish}>
                  <Row gutter={[16, 16]}>
                    <Col md={16}>
                      <Card>
                        <Form.Item
                          label="Title"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล title!",
                            },
                          ]}
                          initialValue={this.state.pageTitle}
                        >
                          <Input
                            onChange={(e) =>
                              this.handleChangeInput({
                                topicInputActivity: e.target.value,
                              })
                            }
                          />
                        </Form.Item>
                        <QuillStyle>
                          <Form.Item
                            initialValue={this.state.textAreaInputActivity}
                            name="content"
                            label="Detail"
                          >
                            <ReactQuill
                              ref={(el) => {
                                this.reactQuillRef = el;
                              }}
                              theme="snow"
                              onChange={this.onChangeText}
                              modules={{
                                toolbar: {
                                  container: [
                                    [{ header: [3, 4, false] }],
                                    ["bold", "italic", "underline"],
                                    [
                                      {
                                        color: [
                                          "#000000",
                                          "#e60000",
                                          "#ff9900",
                                          "#ffff00",
                                          "#008a00",
                                          "#0066cc",
                                          "#9933ff",
                                          "#ffffff",
                                          "#facccc",
                                          "#ffebcc",
                                          "#ffffcc",
                                          "#cce8cc",
                                          "#cce0f5",
                                          "#ebd6ff",
                                          "#bbbbbb",
                                          "#f06666",
                                          "#ffc266",
                                          "#ffff66",
                                          "#66b966",
                                          "#66a3e0",
                                          "#c285ff",
                                          "#888888",
                                          "#a10000",
                                          "#b26b00",
                                          "#b2b200",
                                          "#006100",
                                          "#0047b2",
                                          "#6b24b2",
                                          "#444444",
                                          "#5c0000",
                                          "#663d00",
                                          "#666600",
                                          "#003700",
                                          "#002966",
                                          "#3d1466",
                                        ],
                                      },
                                    ],
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    ["link", "image", "video"],
                                    ["clean"],
                                  ],
                                  handlers: {
                                    image: this.imageHandler,
                                  },
                                },
                                clipboard: {
                                  matchVisual: false,
                                },
                              }}
                            />
                          </Form.Item>
                        </QuillStyle>
                      </Card>
                    </Col>
                    <Col md={8}>
                      <Card>
                        <Form.Item className="my-3">
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                          <Button
                            className="mx-4"
                            onClick={() => this.props.history.goBack()}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                        <Form.Item
                          label="Display day"
                          name="public"
                          rules={[
                            {
                              required: true,
                              message: "กรุณาเลือก วันในการเริ่มและสิ้นสุด!",
                            },
                          ]}
                          initialValue={[
                            moment(this.state.startDate, "YYYY-MM-DD"),
                            moment(this.state.endDate, "YYYY-MM-DD"),
                          ]}
                        >
                          {/*  */}
                          <RangePicker
                            style={{ width: "100%" }}
                            onChange={this.onChange}
                            format="DD/MM/YYYY"
                            disabledDate={this.disabledDate}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Location"
                          name="place"
                          initialValue={this.state.addressInputActivity}
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล สถานที่จัดงาน!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) =>
                              this.handleChangeInput({
                                addressInputActivity: e.target.value,
                              })
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          label="Point ที่ได้"
                          name="points"
                          initialValue={this.state.pointsInputActivity}
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล คะแนนที่ได้เพิ่ม!",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            suffix="Points"
                            onChange={(e) =>
                              this.handleChangeInput({
                                textAreaInputActivity: e.target.value,
                              })
                            }
                            onKeyDown={(evt) =>
                              evt.key === "e" ||
                              evt.key === "E" ||
                              evt.key === "."
                                ? evt.preventDefault()
                                : {}
                            }
                            min={1}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Feature image"
                          name="image"
                          extra={
                            <ul>
                              <li>Max file size 1MB.</li>
                              <li>Image with .jpg, .jpeg and .png</li>
                              <li>Image size 1920*1080</li>
                            </ul>
                          }
                        >
                          <Upload
                            accept=".jpg, .jpeg, .png"
                            name="upload-img"
                            listType="picture-card"
                            className="form__action avatar-uploader w-100"
                            showUploadList={false}
                            multiple={false}
                            action={`${process.env.REACT_APP_FRONT_API}test`}
                            beforeUpload={beforeUpload}
                            onChange={this.handleChangePhoto}
                          >
                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                className="img-fluid"
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </Form.Item>
                        <Form.Item
                          name="status"
                          label="status"
                          initialValue={radioStatusValue === 1 ? 1 : 0}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              this.setState({
                                radioStatusValue: e.target.value,
                              })
                            }
                            value={radioStatusValue}
                          >
                            <Radio value={0}>Suspend</Radio>
                            <Radio value={1}>Active</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              )}
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

export default EditCheckInEvent;
