import React, { Component } from "react";
import { Row, Col, Button, Image, Modal, Descriptions, Spin, Tag } from "antd";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { songApi } from "../../../apis";
import { Layout } from "../../containers";
import { userAction } from "../../../store/action";

const { confirm } = Modal;

class SongsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "",
      nameSong: "",
      nameSongMin: "",
      genres: {},
      artists: "",
      musicFile: "",
      musicUrl: "",
      videoFile: "",
      videoUrl: "",
      loading: false,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await this.setState({ loading: true });
    songApi
      .GetSongById(this.props.match.params.songId)
      .then((resp) => {
        this.setState({
          nameSong: resp.data.name,
          nameSongMin: `${resp.data.name} ( ${
            resp.data.duration_minutes > 9
              ? resp.data.duration_minutes
              : `0${resp.data.duration_minutes}`
          }:${
            resp.data.duration_seconds > 9
              ? resp.data.duration_seconds
              : `0${resp.data.duration_seconds}`
          } ) นาที`,
          imageUrl: resp.data.image,
          genres: resp.data.genres,
          artists: resp.data.artists,
          release_years: resp.data.release_years,
          musicFile: resp.data.media_music && resp.data.media_music.name,
          musicUrl: resp.data.media_music && resp.data.media_music.url,
          videoFile: resp.data.media_video && resp.data.media_video.name,
          videoUrl: resp.data.media_video && resp.data.media_video.url,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleClick = () =>
    this.props.history.push(`/songs/${this.props.match.params.songId}/edit`);

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[13].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    moment.locale("th");
    const {
      nameSong,
      nameSongMin,
      imageUrl,
      videoFile,
      musicUrl,
      videoUrl,
      artists,
      genres,
      musicFile,
      loading,
      release_years,
      check_token,
    } = this.state;
    const id = this.props.match.params.songId;
    return (
      <>
        {check_token === true && (
          <Layout
            title={nameSong}
            extraBtn={[
              <Button
                style={{ display: isEdit[0] === 1 ? "" : "none" }}
                type="primary"
                onClick={this.handleClick}
              >
                Edit
              </Button>,
              <Button
                style={{ display: isDelete[0] === 1 ? "" : "none" }}
                type="primary"
                onClick={() => {
                  confirm({
                    title: "ต้องการลบข้อมูลใช่หรือไม่?",
                    centered: true,
                    onOk: () => this.handleOk(),
                  });
                }}
                danger
              >
                Delete
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "songs",
                breadcrumbName: "Songs",
              },
              {
                path: `${id}`,
                breadcrumbName: "Song detail",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <Row gutter={[32, 32]}>
                <Col md={6} className="text-center">
                  <Image width="100%" src={imageUrl} />
                  <Tag color="#009c93">{release_years}</Tag>
                </Col>
                <Col md={18}>
                  <Descriptions title={nameSongMin} column={1}>
                    <Descriptions.Item
                      label="Artists"
                      labelStyle={{ fontWeight: "bold" }}
                    >
                      {artists.string && artists.string}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Genres"
                      labelStyle={{ fontWeight: "bold" }}
                    >
                      {genres.string && genres.string}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Music file"
                      labelStyle={{ fontWeight: "bold" }}
                    >
                      <a href={musicUrl} target="_blank" rel="noreferrer">
                        {musicFile}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Video file"
                      labelStyle={{ fontWeight: "bold" }}
                    >
                      <a href={videoUrl} target="_blank" rel="noreferrer">
                        {videoFile}
                      </a>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SongsView);
