// eslint-disable-next-line max-classes-per-file
import React, { Component } from "react";
import { Modal, Spin, Avatar, Table, Button } from "antd";
import * as Swal from "sweetalert2";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import { Link } from "react-router-dom";
import ModalDelete from "../../commons/ModalDelete";
import SelectImage from "../../commons/SelectImage";
import { TagStatus } from "../../commons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Layout } from "../../containers";
import { userAction } from "../../../store/action";
import { bannerApi } from "../../../apis";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
let dragingIndex = -1;

class BodyRow extends Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += " drop-over-downward";
      }
      if (restProps.index < dragingIndex) {
        className += " drop-over-upward";
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />,
      ),
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex).then();

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      visibleDelete: false,
      data: [],
      opt: [],
      defaultOpt: [],
      voucherId: 0,
      deleteName: "",
      deleteImg: "",
      value: "0",
      selectId: null,
      search: "",
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    this.setState({ loading: true });
    const bannerTable = bannerApi.bannerTable().then((resp) => {
      const { data } = resp;
      for (let index = 0; index < data.length; index += 1) {
        data[index].index = index + 1;
      }
      this.setState({
        data,
        loading: false,
      });
    });
    const getBannerEventList = bannerApi.getBannerEventList().then((resp) => {
      if (resp.data.length > 0) {
        const dataLog = resp.data.filter((el) => el.status !== 0);
        this.setState({
          opt: dataLog,
          defaultOpt: "",
          loading: false,
        });
      }
    });
    await Promise.all([bannerTable, getBannerEventList]);
    await this.setState({ loading: false });
  };

  onSearch = (val) => {
    this.setState({
      search: val,
    });
  };

  // eslint-disable-next-line react/sort-comp
  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  moveRow = async (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];
    const hoverRow = data[hoverIndex];

    const list = [];
    const dataDragRow = { id: dragRow.id, index: hoverIndex + 1 };
    const dataHoverRow = { id: hoverRow.id, index: dragIndex + 1 };
    list.push(dataDragRow);
    list.push(dataHoverRow);
    const res = list;
    await this.setState(
      update(this.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      }),
    );
    await this.setState({ loading: true });
    await bannerApi.updateBanner(res).then(() => {
      this.componentDidMount();
    });
  };

  setModalVisibleDelete = () => {
    this.setState((prevState) => ({
      ...prevState,
      visibleDelete: !prevState.visibleDelete,
      selectId: null,
    }));
  };

  onDelete = async () => {
    const { voucherId } = this.state;
    await this.setState({ loading: true });
    await this.setState({
      visibleDelete: false,
    });
    await bannerApi.deleteBanner(voucherId).then((resp) => {
      Swal.fire({
        icon: "success",
        title: `${resp.message}`,
        timer: 1500,
      });
    });
    await this.componentDidMount();
    await this.setState({
      loading: false,
    });
  };

  setModalVisible = () => {
    this.setState((prevState) => ({
      ...prevState,
      visible: !prevState.visible,
    }));
  };

  handleChange = (value) => {
    this.setState({ voucherId: value });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = async () => {
    this.setState({ loading: true });
    const { voucherId } = this.state;
    await this.setState({
      visible: false,
      selectId: voucherId,
    });
    await bannerApi.createBanner(voucherId).then((resp) => {
      if (resp.status === 400) {
        Swal.fire({
          icon: "error",
          title: `${resp.data.message}`,
          timer: 1500,
        }).then(() => {
          Swal.close();
          this.setState({
            loading: false,
            selectId: null,
          });
        });
      } else {
        Swal.fire({
          icon: "success",
          title: `${resp.message}`,
          timer: 1500,
        }).then(() => {
          this.setState({
            selectId: null,
            loading: false,
          });
        });
      }
    });
    await this.componentDidMount();
  };

  render() {
    const { loading, check_token } = this.state;

    const columns = [
      {
        title: "Index",
        dataIndex: "index",
        key: "index",
      },
      {
        title: "Image",
        dataIndex: "img",
        key: "img",
        render: (text, record) => (
          <Link to={`/spicy-rewards/${record.event && record.event.id}`}>
            <Avatar
              shape="square"
              className="rounded"
              size={100}
              src={record.event && record.event.image_s}
            />
          </Link>
        ),
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        render: (text, record) => (
          <Link
            className="text-muted"
            to={`/spicy-rewards/${record.event && record.event.id}`}
          >
            {record.event && record.event.name}
          </Link>
        ),
      },
      {
        title: "Points",
        dataIndex: "points",
        key: "points",
        render: (text, record) => (
          <p className="text-muted mb-0">
            {record.event && record.event.points}
          </p>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => (
          <TagStatus StatusValue={record.event.status} />
        ),
      },
      {
        title: "Actions",
        key: "action",
        align: "center",
        render: (record) => (
          <DeleteOutlined
            style={{ color: "#01A69D" }}
            onClick={() => {
              this.setState({
                voucherId: record.id,
                deleteName: record.event && record.event.name,
                deleteImg: record.event && record.event.image_s,
              });
              this.setModalVisibleDelete(record.index);
            }}
          />
        ),
      },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="Spicy club banner"
            extraBtn={[
              <Button type="primary" onClick={this.setModalVisible}>
                Add Banner
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "audition",
                breadcrumbName: "Audition",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <DndProvider backend={HTML5Backend}>
                <Table
                  rowKey={(record) => record.id}
                  columns={columns}
                  dataSource={this.state.data}
                  components={this.components}
                  onRow={(record, index) => ({
                    index,
                    moveRow: this.moveRow,
                  })}
                  pagination={false}
                />
              </DndProvider>
            </Spin>
            <Modal
              className="banner-modal rounded"
              centered
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.setModalVisible}
            >
              <SelectImage
                id={this.state.selectId}
                placeholder="Select voucher"
                className="select-voucher"
                opt={this.state.opt}
                onSearch={this.onSearch}
                onChange={this.handleChange}
              />
            </Modal>
            <ModalDelete
              idShowImg
              isCheck
              visibleModal={this.state.visibleDelete}
              cancelModal={this.setModalVisibleDelete}
              titleModal="ต้องการลบ Banner นี้ออกจากระบบใช่หรือไม่"
              imgSrc={this.state.deleteImg}
              nameText={this.state.deleteName}
              cancelClick={this.setModalVisibleDelete}
              confirmClick={this.onDelete}
            />
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.users ? state.users.role : 0,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
