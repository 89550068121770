import React, { Component } from "react";
import { Button, Card, Form, Spin, Tabs } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { userAction } from "../../../store/action";
import { Layout } from "../../containers";
import ReactQuill from "react-quill";
import QuillStyle from "../../commons/EditorQuill/QuillStyle";
import { httpClient } from "../../utils/axios";
import { contactUsApi } from "../../../apis";

const { TabPane } = Tabs;

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check_token: false,
      loading: false,
      contactUsData: null,
      contactArtist: undefined,
      contactPresenterAndWriting: undefined,
      contactCopyright: undefined,
      contactPublicRelations: undefined,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  onChangeText = (text, type) => {
    if (type === "contactArtist") {
      this.setState({ contactArtist: text });
    }
    if (type === "contactPresenterAndWriting") {
      this.setState({ contactPresenterAndWriting: text });
    }
    if (type === "contactCopyright") {
      this.setState({ contactCopyright: text });
    }
    if (type === "contactPublicRelations") {
      this.setState({ contactPublicRelations: text });
    }
  };

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await this.setState({ loading: true });
    const data = await contactUsApi
      .getContactUs()
      .then((resp) => {
        const data = resp;
        return data;
      })
      .catch((error) => {
        throw error;
      });
    await this.setState({ loading: false });
    await this.setState({
      contactUsData: data,
      contactArtist: data.data[0].detail,
      contactPresenterAndWriting: data.data[1].detail,
      contactCopyright: data.data[2].detail,
      contactPublicRelations: data.data[3].detail,
    });
  };

  uploadFiles = (uploadFileObj) => {
    const formData = new FormData();
    const that = this;
    formData.append("image", uploadFileObj);
    httpClient
      .post("/news/content/image", formData)
      .then((response) => {
        if (response.data) {
          const range = that.reactQuillRef.getEditorSelection();
          setTimeout(() => {
            that.reactQuillRef
              .getEditor()
              .insertEmbed(range.index, "image", response.data.url);
          }, 2000);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      this.uploadFiles(file, this.reactQuillRef);
    };
  };

  updateData = async (updateData) => {
    Swal.fire({
      title: "ต้องการแก้ไขข้อมูลใช่หรือไม่",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "ระบบกำลังทำงาน",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        contactUsApi
          .updateContactUs(updateData)
          .then((resp) => {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: `${resp.message}`,
              timer: 1500,
            }).then(() => {
              Swal.close();
            });
          })
          .catch((error) => {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
              timer: 1500,
            });
          });
      }
    });
  };

  onSubmit = (type) => {
    if (type === "contactArtist") {
      const updateData = {
        id: this.state.contactUsData.data[0].id,
        title: this.state.contactUsData.data[0].title,
        detail: this.state.contactArtist,
      };
      this.updateData(updateData);
    }
    if (type === "contactPresenterAndWriting") {
      const updateData = {
        id: this.state.contactUsData.data[1].id,
        title: this.state.contactUsData.data[1].title,
        detail: this.state.contactPresenterAndWriting,
      };
      this.updateData(updateData);
    }
    if (type === "contactCopyright") {
      const updateData = {
        id: this.state.contactUsData.data[2].id,
        title: this.state.contactUsData.data[2].title,
        detail: this.state.contactCopyright,
      };
      this.updateData(updateData);
    }
    if (type === "contactPublicRelations") {
      const updateData = {
        id: this.state.contactUsData.data[3].id,
        title: this.state.contactUsData.data[3].title,
        detail: this.state.contactPublicRelations,
      };
      this.updateData(updateData);
    }
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isView = [];
    const isEdit = [];
    menus &&
      menus[30].actions.forEach((doc) => {
        if (doc.type === 1 && doc.status === 1) {
          isView.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        }
      });
    const { check_token, loading } = this.state;

    if (
      this.state.contactArtist !== undefined &&
      this.state.contactPresenterAndWriting !== undefined &&
      this.state.contactCopyright !== undefined &&
      this.state.contactPublicRelations !== undefined
    ) {
      return (
        <>
          {check_token === true && (
            <Layout
              title="Contact Us"
              breadcrumbNav={[
                {
                  path: "dashboard",
                  breadcrumbName: "Dashboard",
                },
                {
                  path: "contact-us",
                  breadcrumbName: "Contact Us",
                },
              ]}
            >
              <Spin spinning={loading} tip="Loading...">
                {loading === false && isView[0] === 1 && (
                  <Form layout="vertical">
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="ติดต่องานโชว์ศิลปิน" key="1">
                        <Card>
                          <QuillStyle>
                            <Form.Item
                              name="contactArtistData"
                              initialValue={this.state.contactArtist}
                            >
                              <ReactQuill
                                ref={(el) => {
                                  this.reactQuillRef = el;
                                }}
                                theme="snow"
                                onChange={(text) =>
                                  this.onChangeText(text, "contactArtist")
                                }
                                modules={{
                                  toolbar: {
                                    container: [
                                      ["bold", "italic", "underline"],
                                      [{ list: "ordered" }, { list: "bullet" }],
                                      ["link"],
                                      ["clean"],
                                    ],
                                    handlers: {
                                      image: this.imageHandler,
                                    },
                                  },
                                  clipboard: {
                                    matchVisual: false,
                                  },
                                }}
                              />
                            </Form.Item>
                          </QuillStyle>
                          {isEdit[0] === 1 && (
                            <Button
                              className="my-4"
                              onClick={() => this.onSubmit("contactArtist")}
                              type="primary"
                            >
                              บันทึกการแก้ไข
                            </Button>
                          )}
                        </Card>
                      </TabPane>
                      <TabPane
                        tab="ติดต่องานพรีเซ็นเตอร์สินค้า/แต่งเพลง"
                        key="2"
                      >
                        <Card>
                          <QuillStyle>
                            <Form.Item
                              name="contactPresenterAndWriting"
                              initialValue={
                                this.state.contactPresenterAndWriting
                              }
                            >
                              <ReactQuill
                                ref={(el) => {
                                  this.reactQuillRef = el;
                                }}
                                theme="snow"
                                onChange={(text) =>
                                  this.onChangeText(
                                    text,
                                    "contactPresenterAndWriting",
                                  )
                                }
                                modules={{
                                  toolbar: {
                                    container: [
                                      ["bold", "italic", "underline"],
                                      [{ list: "ordered" }, { list: "bullet" }],
                                      ["link"],
                                      ["clean"],
                                    ],
                                    handlers: {
                                      image: this.imageHandler,
                                    },
                                  },
                                  clipboard: {
                                    matchVisual: false,
                                  },
                                }}
                              />
                            </Form.Item>
                          </QuillStyle>
                          {isEdit[0] === 1 && (
                            <Button
                              className="my-4"
                              onClick={() =>
                                this.onSubmit("contactPresenterAndWriting")
                              }
                              type="primary"
                            >
                              บันทึกการแก้ไข
                            </Button>
                          )}
                        </Card>
                      </TabPane>
                      <TabPane tab="ติดต่อลิขสิทธิ์เพลง" key="3">
                        <Card>
                          <QuillStyle>
                            <Form.Item
                              name="contactCopyright"
                              initialValue={this.state.contactCopyright}
                            >
                              <ReactQuill
                                ref={(el) => {
                                  this.reactQuillRef = el;
                                }}
                                theme="snow"
                                onChange={(text) =>
                                  this.onChangeText(text, "contactCopyright")
                                }
                                modules={{
                                  toolbar: {
                                    container: [
                                      ["bold", "italic", "underline"],
                                      [{ list: "ordered" }, { list: "bullet" }],
                                      ["link"],
                                      ["clean"],
                                    ],
                                    handlers: {
                                      image: this.imageHandler,
                                    },
                                  },
                                  clipboard: {
                                    matchVisual: false,
                                  },
                                }}
                              />
                            </Form.Item>
                          </QuillStyle>
                          {isEdit[0] === 1 && (
                            <Button
                              className="my-4"
                              onClick={() => this.onSubmit("contactCopyright")}
                              type="primary"
                            >
                              บันทึกการแก้ไข
                            </Button>
                          )}
                        </Card>
                      </TabPane>
                      <TabPane tab="ติดต่อฝ่ายประชาสัมพันธ์" key="4">
                        <Card>
                          <QuillStyle>
                            <Form.Item
                              name="contactPublicRelations"
                              initialValue={this.state.contactPublicRelations}
                            >
                              <ReactQuill
                                ref={(el) => {
                                  this.reactQuillRef = el;
                                }}
                                theme="snow"
                                onChange={(text) =>
                                  this.onChangeText(
                                    text,
                                    "contactPublicRelations",
                                  )
                                }
                                modules={{
                                  toolbar: {
                                    container: [
                                      ["bold", "italic", "underline"],
                                      [{ list: "ordered" }, { list: "bullet" }],
                                      ["link"],
                                      ["clean"],
                                    ],
                                    handlers: {
                                      image: this.imageHandler,
                                    },
                                  },
                                  clipboard: {
                                    matchVisual: false,
                                  },
                                }}
                              />
                            </Form.Item>
                          </QuillStyle>
                          {isEdit[0] === 1 && (
                            <Button
                              className="my-4"
                              onClick={() =>
                                this.onSubmit("contactPublicRelations")
                              }
                              type="primary"
                            >
                              บันทึกการแก้ไข
                            </Button>
                          )}
                        </Card>
                      </TabPane>
                    </Tabs>
                  </Form>
                )}
              </Spin>
            </Layout>
          )}
        </>
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
