import React, { Component } from "react";
import { Form, Input, Button } from "antd";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import jwtDecode from "jwt-decode";
import moment from "moment";
import "moment/locale/th";

import { LayoutLogin } from "../../containers";
import { userApi } from "../../../apis";
import firebase from "../../utils/firebase";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  getUserProfile = () => {
    const goToDashboard = () => {
      this.props.history.push("/dashboard");
    };
    const setData = () => {
      this.setState({
        loading: false,
      });
      goToDashboard();
    };
    userApi
      .getMyProfile()
      .then((resp) => {
        Swal.fire({
          icon: "success",
          // title: `${error.response.data.message}`,
          title: `Logged In!`,
          message: "ระบบกำลังนำคุณไปยังหน้าแดชบอร์ด",
          timer: 1500,
          showConfirmButton: false,
        });
        const access_token = jwtDecode(resp.access_token);
        const refresh_token = jwtDecode(resp.refresh_token);
        const exp_access_token = moment
          .unix(access_token.exp)
          .subtract(13, "minutes");
        localStorage.setItem("exp_access_token", exp_access_token.unix());
        localStorage.setItem("exp_refresh_token", refresh_token.exp);
        localStorage.setItem("auth", resp.access_token);
        localStorage.setItem("refreshToken", resp.refresh_token);
        setData(resp);
      })
      .catch((error) => {
        throw error;
      });
  };

  render() {
    const { loading } = this.state;
    const onFinish = (values) => {
      this.setState({ loading: true });
      const getProfile = () => {
        this.getUserProfile();
      };
      firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password)
        .then((response) => {
          // console.log(response.user);
          if (response.user) {
            response.user.getIdToken(true).then((idToken) => {
              localStorage.setItem("auth", idToken);
              getProfile();
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            // title: `${error.response.data.message}`,
            title: `${error.message}`,
            timer: 1500,
            showConfirmButton: false,
          });
          this.setState({ loading: false });
        });
    };

    return (
      <LayoutLogin
        title={
          <div className="form-title">
            <h1>Spicydisc backoffice</h1>
          </div>
        }
      >
        <Form
          layout="vertical"
          className="form-login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input your Email!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item className="d-flex btn-wrap">
            <Button
              className="mr-auto"
              type="primary"
              size="large"
              loading={loading}
              htmlType="submit"
            >
              Log in
            </Button>
            <Link to="/foget-password" className="d-block">
              Forget password ?
            </Link>
          </Form.Item>
        </Form>
      </LayoutLogin>
    );
  }
}

export default LoginPage;
