import React, { Fragment } from "react";
import { PageHeader } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const PageTitleStyle = styled(PageHeader)`
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 25px;
  .ant-page-header-heading-left {
    display: inline-block;
    overflow: initial;
    .ant-page-header-heading-title {
      font-size: 2.5rem;
    }
  }
`;

const PageTitle = ({ title, extraBtn, breadcrumbNav }) => {
  const itemRender = (route, params, routes) => {
    // console.log('Link to', paths.join('/'))
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span key={route.path}>{route.breadcrumbName}</span>
    ) : (
      <Link to={`/${route.path}`} key={route.path}>
        {route.breadcrumbName}
      </Link>
    );
  };

  const getAction = (actions) => {
    const actionList = actions.map((action, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={`btn-${index}`}>{action}</Fragment>
    ));
    return actionList;
  };

  return (
    <PageTitleStyle
      title={title}
      extra={extraBtn ? getAction(extraBtn) : ""}
      breadcrumb={{ routes: breadcrumbNav, itemRender }}
    />
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  extraBtn: PropTypes.array, // PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  breadcrumbNav: PropTypes.array,
};

export default PageTitle;
