import { httpClient } from "../components/utils/axios";

export const GenreTable = (start, length) =>
  httpClient
    .get(`genres/datatable?start=${start || 0}&length=${length || 20}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const CreateGenre = (data) =>
  httpClient
    .post(`genres`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const DeleteGenre = (id) =>
  httpClient
    .delete(`genres?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const ListGenre = () =>
  httpClient
    .get(`genres/list`)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const MediaTable = (start, length, search, type) =>
  httpClient
    .get(
      `medias/datatable?start=${start || 0}&length=${length || 20}&type=${
        type || 0
      }&search=${search || ""}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const CreateMedia = (data) =>
  httpClient
    .post(`medias`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const DeleteMedia = (id) =>
  httpClient
    .delete(`medias?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const EditMedia = (id, data) =>
  httpClient
    .put(`medias?id=${id}`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const ListMedia = (id) =>
  httpClient
    .get(`medias/list?type=${id}`)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const SongTable = (start, length, search, type) =>
  httpClient
    .get(
      `songs/datatable?start=${start || 0}&length=${length || 20}&type=${
        type || 0
      }&search=${search || ""}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const CreateSong = (data) =>
  httpClient
    .post(`songs`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const DeleteSong = (id) =>
  httpClient
    .delete(`songs?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const EditSong = (id, data) =>
  httpClient
    .put(`songs?id=${id}`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const ListSong = () =>
  httpClient
    .get(`songs/list`)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const WhatHotsListSong = () =>
  httpClient
    .get(`what_hots/songs/list`)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const NewReleasesListSong = () =>
  httpClient
    .get(`new_releases/songs/list`)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const GetSongById = (id) =>
  httpClient
    .get(`songs?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const WhatHotsTable = (start, length) =>
  httpClient
    .get(`what_hots/datatable?start=${start || 0}&length=${length || 20}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const CreateWhatHots = (data) =>
  httpClient
    .post(`what_hots`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const DeleteWhatHots = (id) =>
  httpClient
    .delete(`what_hots?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const EditWhatHots = (data) =>
  httpClient
    .put(`what_hots`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const NewReleasesTable = (start, length) =>
  httpClient
    .get(`new_releases/datatable?start=${start || 0}&length=${length || 20}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const CreateNewReleases = (data) =>
  httpClient
    .post(`new_releases`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const DeleteNewReleases = (id) =>
  httpClient
    .delete(`new_releases?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const EditNewReleases = (data) =>
  httpClient
    .put(`new_releases`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
