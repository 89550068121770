import React from "react";
import { Col, Row, Card } from "antd";
import styled from "styled-components";
import { AreaChart, Area } from "recharts";
import PropTypes from "prop-types";

const Col5 = styled(Col)`
  /* flex: 0 0 100%;
  max-width: 100%;
  overflow: hidden;
  @media (min-width: 768px) {
    margin-bottom: 10px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 992px) {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  @media (min-width: 1280px) {
    flex: 0 0 20%;
    max-width: 20%;
  } */

  .ant-card-body {
    padding: 0;
  }

  .text-color-yellow {
    color: #ffcb52;
  }

  .text-color-blue {
    color: #52e9f8;
  }

  .text-color-purple {
    color: #844bc5;
  }

  .text-color-green {
    color: #33cc1a;
  }

  .text-color-orange {
    color: #ff6a1c;
  }
  .ant-card {
    overflow: hidden;
  }
`;

const P = styled.p`
  font-size: 40px;
  font-weight: bold;
  margin: 0;
`;

const Card5Col = ({
  dataAllUsers,
  dataGraphAllUsers,
  newUser,
  dataUsersPerDay,
  dataGraphUsersPerDay,
  dataActivityUser,
  dataGraphActivityUser,
  dataNewUser,
  dataGraphNewUser,
}) => (
  <Row align="top" className="w-100">
    <Col5
      className="px-2"
      xs={{ span: 12 }}
      sm={{ span: 12 }}
      md={{ span: 8 }}
      lg={{ span: 6 }}
    >
      <Card>
        <div className="text-center mt-3 mb-1">
          <P className="text-color-yellow">{dataAllUsers}</P>
          <p className="m-0">ผู้ใช้งานทั้งหมด</p>
        </div>
        <AreaChart
          data={dataGraphAllUsers}
          width={378}
          height={65}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <Area type="monotone" dataKey="uv" stroke="#FFF84A" fill="#FFB309" />
        </AreaChart>
      </Card>
    </Col5>
    <Col5
      className="px-2"
      xs={{ span: 12 }}
      sm={{ span: 12 }}
      md={{ span: 8 }}
      lg={{ span: 6 }}
    >
      <Card>
        <div className="text-center mt-3 mb-1">
          <P className="text-color-purple">
            {dataUsersPerDay}
            <span>Per day</span>
          </P>
          <p className="m-0">จำนวนผู้ใช้งานต่อวัน</p>
        </div>
        <AreaChart
          data={dataGraphUsersPerDay}
          width={378}
          height={65}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <Area type="monotone" dataKey="amt" stroke="#D44B43" fill="#5F4AFF" />
        </AreaChart>
      </Card>
    </Col5>
    <Col5
      className="px-2"
      xs={{ span: 12 }}
      sm={{ span: 12 }}
      md={{ span: 8 }}
      lg={{ span: 6 }}
    >
      <Card>
        <div className="text-center mt-3 mb-1">
          <P className="text-color-green">{dataActivityUser}</P>
          <p className="m-0">ผู้ใช้ที่เข้าร่วมกิจกรรม</p>
        </div>
        <AreaChart
          data={dataGraphActivityUser}
          width={378}
          height={65}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <Area type="monotone" dataKey="uv" stroke="#F7FF29" fill="#00BE16" />
        </AreaChart>
      </Card>
    </Col5>
    <Col5
      className="px-2"
      xs={{ span: 12 }}
      sm={{ span: 12 }}
      md={{ span: 8 }}
      lg={{ span: 6 }}
    >
      <Card>
        <div className="text-center mt-3 mb-1">
          <P className="text-color-orange">{dataNewUser}</P>
          <p className="m-0">ผู้ใช้งานใหม่ {newUser} คน</p>
        </div>
        <AreaChart
          data={dataGraphNewUser}
          width={378}
          height={65}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <Area type="monotone" dataKey="uv" stroke="#FFE34A" fill="#FF3809" />
        </AreaChart>
      </Card>
    </Col5>
  </Row>
);

Card5Col.propTypes = {
  dataAllUsers: PropTypes.string.isRequired,
  dataGraphAllUsers: PropTypes.array.isRequired,
  dataUsersPerDay: PropTypes.string.isRequired,
  dataGraphUsersPerDay: PropTypes.array.isRequired,
  dataActivityUser: PropTypes.string.isRequired,
  dataGraphActivityUser: PropTypes.array.isRequired,
  dataNewUser: PropTypes.string.isRequired,
  dataGraphNewUser: PropTypes.array.isRequired,
};
Card5Col.defaultProps = {
  className: "",
};

export default Card5Col;
