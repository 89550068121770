import React from "react";
import { Modal } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";

export const DivModalDelete = styled.div`
  position: relative;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding-right: 60px;
  padding-left: 60px;

  .border {
    border: 1px solid #dbdcdd;
  }

  .width-border-box {
    width: 100%;
  }
`;

const ModalDelete = ({
  visibleModal,
  titleModal,
  nameText,
  confirmClick,
}) => (
    <Modal
      title={titleModal}
      visible={visibleModal}
      onOk={confirmClick}
      // onCancel={cancelClick}
    >
      <p>{nameText}</p>
    </Modal>
  );
ModalDelete.propTypes = {
  visibleModal: PropTypes.bool.isRequired,
  titleModal: PropTypes.string,
  nameText: PropTypes.string,
  confirmClick: PropTypes.func,
};
ModalDelete.defaultProps = {
  className: "",
};
export default ModalDelete;
