import styled from "styled-components";
import { Layout } from "antd";
const { Header } = Layout;

export const HeaderStyle = styled(Header)`
  background: #01a69d;
  color: #fff;
  padding: 0 24px;
  a {
    color: #fff;
  }
  .profile-menu {
    float: right;
    .profile-menu_name {
      text-transform: capitalize;
      display: inline-block;
      margin-left: 10px;
    }
  }
  .ant-dropdown-trigger {
    cursor: pointer;
  }
`;
