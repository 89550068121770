import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Avatar } from "antd";

export const AvatarStyle = styled.div`
  font-size: 16px;
  .artist {
    /* .ant-avatar {
      width: 100% !important;
      height: auto !important;
    } */
    .name {
      font-size: 14px;
      word-break: break-all;
    }
  }
  .profile {
    .ant-avatar {
      @media (max-width: 768px) {
        width: 100% !important;
        height: auto !important;
      }
    }
    .name {
      font-size: 14px;
      word-break: break-all;
    }
  }
`;

const AvatarProfile = ({ className, imgSrc, imgSize, artistName }) => (
    <AvatarStyle className={className}>
      {artistName ? (
        <div className="artist">
          <Avatar
            size={imgSize}
            src={imgSrc ? imgSrc : "/assets/images/dafault/default-avatar.jpg"}
          />
          <span className="name text-mutd mt-2 d-block">{artistName}</span>
        </div>
      ) : (
        <div className="profile">
          <Avatar
            size={imgSize}
            src={imgSrc ? imgSrc : "/assets/images/dafault/default-avatar.jpg"}
          />
        </div>
      )}
    </AvatarStyle>
  );

AvatarProfile.propTypes = {
  className: PropTypes.string,
  imgSize: PropTypes.number.isRequired,
  imgSrc: PropTypes.string,
  artistName: PropTypes.string,
};
AvatarProfile.defaultProps = {
  className: "",
};
export default AvatarProfile;
