import React, { Component } from "react";
import { Dropdown, Menu, Spin, Button, Modal } from "antd";
import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { Layout } from "../../containers";
import { AdvancedTableSearch } from "../../views";
import { userAction } from "../../../store/action";
import { songApi } from "../../../apis";

const { confirm } = Modal;

class SongPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      total: 0,
      ListGenre: [],
      typeSearch: 1,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const { start, length } = this.state;

    await this.setState({ loading: true });
    await songApi
      .SongTable(start, length)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    await songApi
      .ListGenre()
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          ListGenre: resp.data,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    await this.setState({ loading: false });
  };

  handleSearch = (typeFilter) => {
    this.setState({ loading: true });
    const { length, search, typeSearch } = this.state;
    const roleId = [];
    const text = roleId.length > 0 ? roleId[0] : search;
    songApi
      .SongTable(0, length, text, typeSearch)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          loading: false,
          typeFilter,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handlePaggination = (start, length) => {
    const { search, typeSearch } = this.state;
    this.setState({ loading: true });
    const roleId = [];
    const text = roleId.length > 0 ? roleId[0] : search;
    songApi
      .SongTable(start, length, text, typeSearch)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
          start,
          length,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleDelete = (id) => {
    songApi
      .DeleteSong(id)
      .then(() => {
        this.componentDidMount();
      })
      .catch((error) => {
        throw error;
      });
  };

  onChangeSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[13].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, data, total, check_token } = this.state;

    const menu = (record) => (
      <Menu className="datable-actions-menu">
        <Menu.Item>
          <Link
            to={{
              pathname: `/songs/${record.id}/view`,
            }}
          >
            View
          </Link>
        </Menu.Item>
        {isEdit[0] === 1 && (
          <Menu.Item>
            <Link
              to={{
                pathname: `/songs/${record.id}/edit`,
              }}
            >
              Edit
            </Link>
          </Menu.Item>
        )}
        {isDelete[0] === 1 && (
          <Menu.Item>
            <Link
              to="/songs"
              onClick={() => {
                confirm({
                  title: "ต้องการลบข้อมูลเพลงใช่หรือไม่?",
                  centered: true,
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => {
                    this.handleDelete(record.id);
                  },
                });
              }}
            >
              Delete
            </Link>
          </Menu.Item>
        )}
      </Menu>
    );
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        type: "input",
      },
      {
        title: "Artists",
        dataIndex: "artists",
        key: "artist",
        type: "input",
        value: "all",
      },
      {
        title: "Genres",
        dataIndex: "genres",
        key: "genres",
        type: "input",
        value: "all",
        selectData: [],
      },
      {
        title: "Entry date",
        dataIndex: "created_at",
        key: "date",
        type: "date",
        value: "",
        selectData: [],
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Dropdown
            trigger={["click"]}
            overlay={menu(record)}
            placement="bottomRight"
          >
            <Button type="link">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="Songs"
            extraBtn={[
              <Button
                style={{ display: isCreate[0] === 1 ? "" : "none" }}
                type="primary"
                onClick={() => this.props.history.push("/songs/create")}
              >
                + Add song
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "songs",
                breadcrumbName: "Songs",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <AdvancedTableSearch
                nonExport
                noFillter
                fileName="songs"
                columns={columns}
                data={data}
                handleSearch={this.handleSearch}
                onChangeSearch={this.onChangeSearch}
                defaultValueSearch="name"
                onChangeTypeSearch={(value) => {
                  this.setState({
                    typeSearch:
                      value === "name" ? 1 : value === "artist" ? 2 : 3,
                  });
                }}
                loading={loading}
                searchCondition={["date", "action"]}
                rowKey={(record) => record.id}
                total={total}
                onTableChange={this.handlePaggination}
              />
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SongPage);
