import React, { Component } from "react";
import {
  Dropdown,
  Menu,
  Spin,
  Button,
  Modal,
  Avatar,
  Calendar,
  Badge,
} from "antd";
import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { eventsRenewApi } from "../../../apis";
import { AdvancedTableSearch } from "../../views";
import { Layout } from "../../containers";
import { TagStatus } from "../../commons";
import { userAction } from "../../../store/action";

const { confirm } = Modal;

const CalendarStyle = styled(Calendar)`
  .events {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .notes-month {
    font-size: 28px;
    text-align: center;
  }
  .notes-month section {
    font-size: 28px;
  }
`;

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class EventDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      isCalendarView: false,
      data: [],
      dataPrint: [],
      start: 0,
      length: 20,
      search: "",
      total: 0,
      typeFilter: 0,
      typeSearch: 1,
      name: "",
      location: "",
      status: "",
      date: [],
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await this.setState({ loading: true });
    const { start, length, search } = this.state;
    const EventTable = eventsRenewApi
      .EventTable(start, length, search)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
        }));
      });
    const EventTableReport = eventsRenewApi.EventTableReport().then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        dataPrint: resp.data,
      }));
    });
    const EventCalendar = eventsRenewApi.EventCalendar().then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        date: resp.data,
      }));
    });
    await Promise.all([EventTable, EventTableReport, EventCalendar]);
    await this.setState({ loading: false });
  };

  handleChange = (m, y) => {
    eventsRenewApi.EventCalendar(m, y).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        date: resp.data,
      }));
    });
  };

  handleSearch = (typeFilter) => {
    this.setState({ loading: true });
    const {
      length,
      search,
      typeSearch,
      name,
      location,
      startDate,
      endDate,
      status,
    } = this.state;
    const roleId = [];
    if (typeSearch === 3) {
      if (search === "active" || search === "Active") {
        roleId.push(1);
      } else {
        roleId.push("0");
      }
    }
    const text = roleId.length > 0 ? roleId[0] : search;
    const type =
      typeFilter === 0
        ? typeFilter
        : `1&name=${name}&location=${location}&${
            startDate ? `start_date=${startDate}` : ""
          }&status=${status}&&${endDate ? `end_date=${endDate}` : ""}`;
    eventsRenewApi
      .EventTable(0, length, text, typeSearch, type)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          loading: false,
          typeFilter,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    eventsRenewApi.EventTableReport(text, typeSearch, type).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        dataPrint: resp.data,
        loading: false,
      }));
    });
  };

  handlePaggination = (start, length) => {
    this.setState({ loading: true });
    const {
      search,
      typeSearch,
      name,
      location,
      startDate,
      endDate,
      status,
      typeFilter,
    } = this.state;
    const roleId = [];
    if (typeSearch === 3) {
      if (search === "active" || search === "Active") {
        roleId.push(1);
      } else {
        roleId.push(0);
      }
    }
    const text = roleId.length > 0 ? roleId[0] : search;
    const type =
      typeFilter === 0
        ? typeFilter
        : `1&name=${name}&location=${location}&${
            startDate ? `start_date=${startDate}` : ""
          }&status=${status}&&${endDate ? `end_date=${endDate}` : ""}`;
    eventsRenewApi
      .EventTable(start, length, text, typeSearch, type)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
          start,
          length,
          search,
          loading: false,
        })).catch((error) => {
          this.setState({ loading: false });
          throw error;
        });
      });
  };

  getListData = (value) => {
    const listData = [];
    for (let i = 0; i < this.state.date.length; i += 1) {
      if (moment(value).format("DD/MM/YYYY") === this.state.date[i].date) {
        listData.push({
          id: this.state.date[i].id,
          type: this.state.date[i].type,
          content: this.state.date[i].content,
        });
      }
    }
    return listData || [];
  };

  dateCellRender = (value) => {
    const listData = this.getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge
              onClick={() => console.log(item.id)}
              status={item.type}
              text={item.content}
            />
          </li>
        ))}
      </ul>
    );
  };

  onChangeSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleOk = async (id) => {
    await this.setState({ loading: true });
    await this.setState({
      visible: false,
    });
    await eventsRenewApi.DeleteEvent(id).then((resp) => {
      Swal.fire({
        icon: "success",
        title: `${resp.message}`,
        timer: 1500,
      }).then(() => {
        this.setState({
          loading: false,
        });
        this.componentDidMount();
      });
    });
  };

  handlePromote = async (id) => {
    await this.setState({ loading: true });
    await this.setState({
      visible: false,
    });
    await eventsRenewApi
      .EventSticky(id)
      .then((resp) => {
        Swal.fire({
          icon: "success",
          title: `${resp.message}`,
          timer: 1500,
        }).then(() => {
          this.setState({
            loading: false,
          });
          this.componentDidMount();
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        throw error;
      });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[8].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, data, total, isCalendarView, check_token } = this.state;

    const menu = (record) => (
      <Menu className="datable-actions-menu">
        <Menu.Item>
          <Link
            to={{
              pathname: `/events/${record.id}/view`,
            }}
          >
            View
          </Link>
        </Menu.Item>
        {isEdit[0] === 1 && (
          <Menu.Item>
            <Link
              to="/events"
              onClick={() => {
                confirm({
                  title: "ต้องการโปรโมท Event ใช่หรือไม่?",
                  centered: true,
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => {
                    this.handlePromote(record.id);
                  },
                });
              }}
            >
              Promote
            </Link>
          </Menu.Item>
        )}
        {isEdit[0] === 1 && (
          <Menu.Item>
            <Link
              to={{
                pathname: `/events/${record.id}/edit`,
              }}
            >
              Edit
            </Link>
          </Menu.Item>
        )}
        {isDelete[0] === 1 && (
          <Menu.Item>
            <Link
              to="/events"
              onClick={() => {
                confirm({
                  title: "ต้องการลบข้อมูลข่าวใช่หรือไม่?",
                  centered: true,
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => {
                    this.handleOk(record.id);
                  },
                });
              }}
            >
              Delete
            </Link>
          </Menu.Item>
        )}
      </Menu>
    );

    const columns = [
      {
        title: "Image",
        dataIndex: "image",
        key: "image",
        render: (text) => (
          <div>
            <Avatar size="128" shape="square" src={text} />
          </div>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        id: "name",
        onChange: (e) => this.setState({ name: e.target.value }),
        type: "input",
        value: "",
        selectData: [],
      },
      {
        title: "Event date",
        dataIndex: "end_date",
        key: "date",
        type: "date",
        value: "",
        selectData: [],
        onChange: (e, string) =>
          this.setState({
            startDate: moment(string[0], "DD/MM/YYYY").format("YYYY-MM-DD"),
            endDate: moment(string[1], "DD/MM/YYYY").format("YYYY-MM-DD"),
          }),
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
        id: "location",
        type: "input",
        value: "",
        selectData: [],
        onChange: (e) => this.setState({ location: e.target.value }),
      },
      {
        title: "Ticket Price",
        dataIndex: "price",
        id: "ticket_price",
        align: "right",
        render: (text) => <span>{formatNumber(+text)}</span>,
      },
      {
        title: "Type",
        dataIndex: "type",
        id: "type",
        render: (text) => (
          <span>{text === 1 ? "Event&Concert" : "Showbiz"}</span>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        type: "select",
        value: "all",
        selectData: [
          {
            id: 1,
            name: "Active",
          },
          { id: 0, name: "Suspend" },
        ],
        onChange: (status) => this.setState({ status }),
        render: (text) => <TagStatus StatusValue={text} />,
      },
      {
        title: "Action",
        key: "action",
        width: "10%",
        align: "center",
        render: (text, record) => (
          <Dropdown
            trigger={["click"]}
            overlay={menu(record)}
            placement="bottomRight"
          >
            <Button type="link">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="Events"
            extraBtn={[
              <Button
                style={{ display: isCreate[0] === 1 ? "" : "none" }}
                type="primary"
                onClick={() => this.props.history.push("/events/create")}
              >
                + Add event
              </Button>,
              <Button
                onClick={() =>
                  this.setState({ isCalendarView: !isCalendarView })
                }
              >
                {isCalendarView === false ? "Calendar view" : "Table view"}
              </Button>,
              <Button
                style={{ display: isEdit[0] === 1 ? "" : "none" }}
                onClick={() => this.props.history.push("/events/promote")}
              >
                Promote
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "events",
                breadcrumbName: "Events",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              {isCalendarView === false ? (
                <AdvancedTableSearch
                  nonExport
                  fileName="events"
                  columns={columns}
                  data={data}
                  handleSearch={this.handleSearch}
                  onChangeSearch={this.onChangeSearch}
                  defaultValueSearch="name"
                  onChangeTypeSearch={(value) => {
                    this.setState({
                      typeSearch:
                        value === "name" ? 1 : value === "location" ? 2 : 3,
                    });
                  }}
                  clearFilter={() =>
                    this.setState({
                      name: "",
                      startDate: "",
                      endDate: "",
                      location: "",
                      status: "",
                    })
                  }
                  loading={loading}
                  searchCondition={[
                    "ticket_price",
                    "date",
                    "action",
                    "image",
                    "type",
                  ]}
                  columnsFilter={columns}
                  filterCondition={["ticket_price", "action", "image", "type"]}
                  rowKey={(record) => record.id}
                  total={total}
                  onTableChange={this.handlePaggination}
                />
              ) : (
                <div className="pb-4">
                  <CalendarStyle
                    dateCellRender={this.dateCellRender}
                    onChange={(value) =>
                      this.handleChange(
                        moment(value).format("MM"),
                        moment(value).format("YY"),
                      )
                    }
                  />
                </div>
              )}
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventDataTable);
