import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  Component,
} from "react";
import { Table, Input, Form, Spin } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { Layout } from "../../containers";
import { userApi } from "../../../apis";
import { userAction } from "../../../store/action";
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          color: "#009c93",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={toggleEdit}
        aria-hidden="true"
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class Modules extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "End Point",
        dataIndex: "module_slug",
      },
      {
        title: "Name",
        dataIndex: "name",
        editable: true,
      },
      {
        title: "Description",
        dataIndex: "description",
        editable: true,
      },
    ];
    this.state = {
      dataSource: [],
      loading: false,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await this.setState({ loading: true });
    await userApi
      .modulesGet()
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          dataSource: resp.data,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleSave = async (row) => {
    const data = [];
    const newData = [...this.state.dataSource];
    newData.forEach((doc) => {
      if (doc.id === row.id) {
        if (row.name !== doc.name) {
          data.push({
            name: row.name,
            type: 1,
            description: doc.description,
          });
        }
        if (row.description !== doc.description) {
          data.push({
            name: doc.name,
            type: 2,
            description: row.description,
          });
        }
      }
    });
    userApi.modulesUpdate(row.id, data[0]).then(() => {
      this.componentDidMount();
    });
  };

  render() {
    const { dataSource, loading, check_token } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <>
        {check_token === true && (
          <Layout
            title="Modules management"
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "modules",
                breadcrumbName: "Modules management",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              {loading === false && (
                <Table
                  components={components}
                  rowClassName={() => "editable-row"}
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{ defaultPageSize: 20 }}
                />
              )}
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modules);
