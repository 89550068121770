import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Alert } from "antd";

export const AlertMessagesStyle = styled.div`
  ul {
    margin-bottom: 0;
  }
  .success-message {
    .ant-alert-message {
      color: green;
    }
  }
  .info-message {
    .ant-alert-message {
      color: #3498db;
    }
  }
  .warning-message {
    .ant-alert-message {
      color: orange;
    }
  }
  .error-message {
    .ant-alert-message {
      color: red;
    }
  }
`;

const AlertMessages = ({ className, children, type, icon }) => (
    <AlertMessagesStyle className={className}>
      <Alert
        className={
          type === "success"
            ? "success-message"
            : type === "info"
            ? "info-message"
            : type === "warning"
            ? "warning-message"
            : "error-message"
        }
        message={children}
        type={type}
        showIcon={icon}
      />
    </AlertMessagesStyle>
  );

AlertMessages.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  icon: PropTypes.bool,
};
AlertMessages.defaultProps = {
  className: "",
  icon: true,
};
export default AlertMessages;
