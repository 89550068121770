import React, { Component } from "react";
import { LayoutDashboard } from "../../commons/Layout";
import { Spin } from "antd";
import { CustomerApi } from "../../../apis";
import PageTable from "../../commons/PageTable";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";

class CustomerMiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      customerName: "",
      dataPrint: [],
      data: [],
      loading: false,
      start: 0,
      length: 20,
      search: "",
      total: 0,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const id = this.props.match.params.customerId;
    const { start, length } = this.state;

    await this.setState({ loading: true });

    await CustomerApi.getCustomerById(id).then((response) => {
      this.setState({ customerName: response.data && response.data.fullname });
    });

    await CustomerApi.customerIdMiles(start, length, id).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        data: resp.data,
        total: resp.recordsTotal,
        loading: false,
      }));
    });

    await CustomerApi.customerIdMilesReport(id).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        dataPrint: resp.data,
        loading: false,
      }));
    });
  };

  handlePaggination = (start, length, search, page, size) => {
    const id = this.props.match.params.campaignId;
    this.setState({ loadingReport: true });
    this.setState((prevState) => ({
      ...prevState,
      start,
      length,
      page: page ? page : 1,
      size: size ? size : 20,
    }));
    CustomerApi.customerIdMiles(start, length, id).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        data: resp.data,
        total: resp.recordsTotal,
        loading: false,
      }));
    });
  };

  render() {
    const columns = [
      {
        title: "Point",
        dataIndex: "points",
        key: "points",
      },
      {
        title: "Date",
        dataIndex: "created_at",
        key: "created_at",
      },
    ];

    const headers = [
      { label: "Customer Name", key: "customer_name" },
      { label: "Points", key: "points" },
      { label: "Created At", key: "created_at" },
    ];

    const {
      loading,
      customerName,
      data,
      dataPrint,
      page,
      size,
      total,
      check_token,
    } = this.state;

    return (
      <>
        {check_token === true && (
          <LayoutDashboard
            hasButton
            className="comtain-fluid"
            title={`${customerName}`}
            editName={
              customerName && customerName.length > 20
                ? `${customerName.substring(0, 20)}...`
                : customerName
            }
            caption="Mile"
            buttonName="Export Report"
            fileName={`${customerName}-mile`}
            btnFirstExport
            dataFirstExport={dataPrint}
            headersFirstExport={headers}
          >
            <Spin spinning={loading} tip="Loading...">
              <PageTable
                rowKey={(record) => record.id}
                columns={columns}
                data={data}
                onChange={this.handlePaggination}
                total={total}
                page={page}
                size={size}
              />
            </Spin>
          </LayoutDashboard>
        )}
      </>
    );
  }
}

export default CustomerMiles;
