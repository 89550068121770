import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Spin,
  Input,
} from "antd";
import * as Swal from "sweetalert2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { userAction } from "../../../store/action";
import { Layout } from "../../containers";
import { addressApi, CustomerApi } from "../../../apis";
import AvatarProfile from "../../commons/AvatarProfile";

const { Option } = Select;

class ContactUs extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      check_token: false,
      loading: false,
      provinceData: null,
      districtData: null,
      subDistrictData: null,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  convertBirthDateFormat = (birthDate) => {
    if (birthDate[2] === "/" && birthDate[5] === "/") {
      const day = birthDate.split("/")[0];
      const month = birthDate.split("/")[1];
      const year = birthDate.split("/")[2];
      const newFormat = `${year}-${month}-${day}`;

      return newFormat;
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const id = this.props.match.params.customerId;
    await this.setState({ loading: true /* , loadingArtist: true */ });
    const setData = (doc) => {
      this.setState({
        address: doc.address,
        birthdate: this.convertBirthDateFormat(doc.birthdate),
        district: doc.amphure && doc.amphure.name_th,
        districtId: doc.amphure && doc.amphure.id,
        email: doc.email,
        fullname: doc.fullname,
        gender: doc.gender,
        image: doc.image,
        province: doc.province && doc.province.name_th,
        provinceId: doc.province && doc.province.id,
        subdistrict: doc.district && doc.district.name_th,
        subdistrictId: doc.district && doc.district.id,
        telephone: doc.telephone,
        zipCode: doc.district && doc.district.zip_code,
      });
      this.setupProvinceArray();
      doc.province && this.setupDistrictArray(doc.province.id);
      doc.amphure && this.setupSubDistrictArray(doc.amphure.id);
    };
    await CustomerApi.getCustomerById(id)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    await this.setState({ loading: false });
  };

  setupProvinceArray = async () => {
    const data = await addressApi.getProvinces();
    await this.setState({ provinceData: data });
  };

  setupDistrictArray = async (id) => {
    const data = await addressApi.getDistricts(id);
    await this.setState({ districtData: data });
  };

  setupSubDistrictArray = async (id) => {
    const data = await addressApi.getSubDistricts(id);
    await this.setState({ subDistrictData: data });
  };

  handleChangeInput = (changeObject) => {
    this.setState(changeObject);
  };

  handleProvinceChange = async (id) => {
    await this.setupDistrictArray(id);
    this.formRef.current.setFieldsValue({ district: null, subdistrict: null });
    this.setState({
      provinceId: id,
      district: null,
      districtId: null,
      subdistrict: null,
      subdistrictId: null,
    });
  };

  handleDistrictChange = async (id) => {
    await this.setupSubDistrictArray(id);
    this.formRef.current.setFieldsValue({ subdistrict: null });
    this.setState({ districtId: id, subdistrict: null, subdistrictId: null });
  };

  handleSubDistrictChange = (id) => {
    this.setState({ subdistrictId: id });
  };

  onFinish = async () => {
    const updateData = {
      id: parseInt(this.props.match.params.customerId, 10),
      address: this.state.address,
      birthdate: this.state.birthdate,
      amphure_id: this.state.districtId,
      email: this.state.email,
      name: this.state.fullname,
      gender: this.state.gender,
      province_id: this.state.provinceId,
      district_id: this.state.subdistrictId,
      telephone: this.state.telephone,
      status: 1,
    };
    Swal.fire({
      title: "ต้องการแก้ไขข้อมูล Customer ใช่หรือไม่",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "ระบบกำลังทำงาน",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        CustomerApi.customerEdit(updateData)
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "success",
              timer: 1500,
            }).then(() => this.props.history.goBack());
          })
          .catch((error) => {
            throw error;
          });
      }
    });
    await CustomerApi.customerEdit(updateData).then(() => {});
  };

  render() {
    moment.locale("th");
    const { check_token, loading } = this.state;
    return (
      <>
        {check_token === true && (
          <Layout
            title="Edit Customer"
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "customer",
                breadcrumbName: "Customers",
              },
              {
                path: "",
                breadcrumbName: "Edit",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              {loading === false && (
                <Form
                  initialValues={{
                    address: this.state.address,
                    district: this.state.district,
                    email: this.state.email,
                    fullname: this.state.fullname,
                    gender: this.state.gender,
                    province: this.state.province,
                    subdistrict: this.state.subdistrict,
                    telephone: this.state.telephone,
                    zipCode: this.state.zipCode,
                    birthdate: moment(this.state.birthdate, "YYYY-MM-DD"),
                  }}
                  layout="vertical"
                  onFinish={this.onFinish}
                  ref={this.formRef}
                >
                  <Card>
                    <Row>
                      <Col span={10} />
                      <Col span={4}>
                        <AvatarProfile
                          className="mb-4"
                          imgSize={128}
                          imgSrc={this.state.image}
                        />
                      </Col>
                      <Col span={10} />
                    </Row>

                    <Row>
                      <Col span={1} />
                      <Col span={9} className="h-100">
                        <Form.Item label="Email" name="email">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={2} />
                      <Col span={9} className="h-100">
                        <Form.Item
                          label="Telephone"
                          name="telephone"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) =>
                              this.handleChangeInput({
                                telephone: e.target.value,
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1} />
                    </Row>

                    <Row>
                      <Col span={1} />
                      <Col span={9} className="h-100">
                        <Form.Item
                          label="Full-name"
                          name="fullname"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) =>
                              this.handleChangeInput({
                                fullname: e.target.value,
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2} />
                      <Col span={9} className="h-100">
                        <Form.Item
                          label="Birthdate"
                          name="birthdate"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล!",
                            },
                          ]}
                        >
                          <DatePicker
                            defaultValue={moment(
                              this.state.birthdate,
                              "YYYY-MM-DD",
                            )}
                            format="YYYY-MM-DD"
                            onChange={(date, dateStrings) =>
                              this.handleChangeInput({
                                birthdate: dateStrings,
                              })
                            }
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1} />
                    </Row>

                    <Row>
                      <Col span={1} />
                      <Col span={9}>
                        <Form.Item
                          label="Gender"
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล!",
                            },
                          ]}
                        >
                          <Select placeholder="เลือกสถานะผู้ใช้งาน">
                            <Option value={1}>ชาย</Option>
                            <Option value={2}>หญิง</Option>
                            <Option value={3}>อื่นๆ</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2} />
                      <Col span={9}>
                        <Form.Item label="Address" name="address">
                          <Input
                            onChange={(e) =>
                              this.handleChangeInput({
                                address: e.target.value,
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1} />
                    </Row>

                    <Row>
                      <Col span={1} />
                      <Col span={9}>
                        <Form.Item
                          label="Province"
                          name="province"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล!",
                            },
                          ]}
                        >
                          {this.state.provinceData !== null ? (
                            <Select
                              onChange={(id) => this.handleProvinceChange(id)}
                              placeholder="เลือกจังหวัด"
                            >
                              {this.state.provinceData.map((data) => (
                                <Option key={data.id} value={data.id}>
                                  {data.name_th}
                                </Option>
                              ))}
                            </Select>
                          ) : (
                            <Select placeholder="เลือกจังหวัด" />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={2} />
                      <Col span={9}>
                        <Form.Item
                          label="District"
                          name="district"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล!",
                            },
                          ]}
                        >
                          {this.state.districtData !== null ? (
                            <Select
                              onChange={(id) => this.handleDistrictChange(id)}
                              placeholder="เลือกเขต / อำเภอ"
                            >
                              {this.state.districtData.map((data) => (
                                <Option key={data.id} value={data.id}>
                                  {data.name_th}
                                </Option>
                              ))}
                            </Select>
                          ) : (
                            <Select placeholder="เลือกเขต / อำเภอ" />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={1} />
                    </Row>

                    <Row>
                      <Col span={1} />
                      <Col span={9}>
                        <Form.Item
                          label="Sub District"
                          name="subdistrict"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล!",
                            },
                          ]}
                        >
                          {this.state.subDistrictData !== null ? (
                            <Select
                              onChange={(id) =>
                                this.handleSubDistrictChange(id)
                              }
                              placeholder="เลือกแขวง / ตำบล"
                            >
                              {this.state.subDistrictData.map((data) => (
                                <Option key={data.id} value={data.id}>
                                  {data.name_th}
                                </Option>
                              ))}
                            </Select>
                          ) : (
                            <Select placeholder="เลือกแขวง / ตำบล" />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={2} />
                      <Col span={9} />
                      <Col span={1} />
                    </Row>

                    <Row>
                      <Col span={1} />
                      <Col span={3}>
                        <Button
                          className="my-4"
                          htmlType="submit"
                          onClick={null}
                          type="primary"
                        >
                          บันทึกการแก้ไข
                        </Button>
                      </Col>
                      <Col span={20} />
                    </Row>
                  </Card>
                </Form>
              )}
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
