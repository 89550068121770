import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Button, Select, Space } from "antd";
import {
  FilterOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import moment from "moment";

const { Option, OptGroup } = Select;

const TableSearchTop = (props) => {
  const {
    onChange,
    onChangeTypeSearch,
    title,
    onSearch,
    columns,
    onClickSearch,
    onToggleFilter,
    defaultValueSearch,
    searchCondition,
    noFillter,
    dataExport,
    headers,
    fileName,
    noDropdownSearch,
  } = props;
  return (
    <div className="filter-search mb-4">
      <Row gutter={[16, 16]} justify={title !== "" ? "space-between" : "end"}>
        {title !== "" && (
          <Col>
            <div className="ant-descriptions-title">{title}</div>
          </Col>
        )}
        <Col>
          <Row gutter={[16, 16]}>
            {noDropdownSearch === false ? (
              <Col>
                <Space align="center">
                  <SearchOutlined className="px-2 pb-2" />
                  <Select
                    style={{ minWidth: 135 }}
                    showSearch
                    placeholder="Select a column"
                    optionFilterProp="children"
                    onChange={onChangeTypeSearch}
                    defaultValue={defaultValueSearch}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <OptGroup label="search">
                      {columns.map((col) => (
                        <Fragment key={col.title}>
                          {!searchCondition.includes(col.key) && col.type && (
                            <Option value={col.key}>{col.title}</Option>
                          )}
                        </Fragment>
                      ))}
                    </OptGroup>
                  </Select>
                </Space>
              </Col>
            ) : (
              <Col>
                <SearchOutlined />
              </Col>
            )}
            <Col>
              <Input placeholder="" onChange={onChange} />
            </Col>
            <Col>
              <Button type="primary" onClick={() => onClickSearch(0)}>
                Search
              </Button>
            </Col>

            {noFillter === false && (
              <Col>
                <Button
                  className="d-flex align-items-center"
                  icon={<FilterOutlined />}
                  onClick={onToggleFilter}
                >
                  Filter
                </Button>
              </Col>
            )}
            {dataExport && (
              <Col>
                <CSVLink
                  data={dataExport}
                  filename={`${fileName}-${moment().format(
                    "DD MM YYYY",
                  )}.xls`}
                  headers={headers}
                  target="_blank"
                >
                  <Button type="primary" icon={<UploadOutlined />}>
                    Export to Excel
                  </Button>
                </CSVLink>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

TableSearchTop.propTypes = {
  onSearch: PropTypes.func,
  columns: PropTypes.array,
  onClickSearch: PropTypes.func,
  onToggleFilter: PropTypes.func,
  noDropdownSearch: PropTypes.bool,
  defaultValueSearch: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
};

export default TableSearchTop;
