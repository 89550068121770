import axios from "axios";
import join from "url-join";
import Swal from "sweetalert2";
// import { refreshToken } from "./refreshToken";
import jwtDecode from "jwt-decode";
import moment from "moment";
import "moment/locale/th";
const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

axios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("auth");
  if (!isAbsoluteURLRegex.test(config.url)) {
    config.url = join(`${process.env.REACT_APP_BASE_URL}`, config.url);
  }
  config.headers.Authorization = `Bearer ${token}`;
  config.retry = false;
  // config.timeout = 20000; // 10 Second
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response) {
      if (error.code === "ECONNABORTED") {
        Swal.fire({
          title: "หมดเวลาการเชื่อมต่อ",
          text: "กรุณาลองใหม่อีกครั้ง",
          type: "error",
          timer: 3000,
        });
      } else if (error.response.status === 401 && !error.config.retry) {
        error.config.retry = true;
        const refreshToken = localStorage.getItem("refreshToken");
        const userData = localStorage.getItem("userData");
        return axios
          .post(`${process.env.REACT_APP_BASE_URL}/auth/refresh_token`, {
            token_refresh: refreshToken,
          })
          .then(async (response) => {
            // await localStorage.clear();
            error.config.headers.Authorization = `Bearer ${response.data.access_token}`;
            await localStorage.setItem("auth", response.data.access_token);
            await localStorage.setItem(
              "refreshToken",
              response.data.refreshToken,
            );
            await localStorage.setItem("userData", userData);
            const access_token = await jwtDecode(response.data.access_token);
            const refresh_token = await jwtDecode(response.data.refresh_token);
            const exp_access_token = await moment
              .unix(access_token.exp)
              .subtract(1, "minutes");
            await localStorage.setItem(
              "exp_access_token",
              exp_access_token.unix(),
            );
            await localStorage.setItem("exp_refresh_token", refresh_token.exp);

            error.config.retry = false;
            return axios(error.config);
          })
          .catch(() => {
            localStorage.clear();
            Swal.fire({
              title: "หมดเวลาการเข้าใช้งานของท่าน กรุณรล็อคอินใหม่อีกครั้ง",
              type: "error",
              timer: 3000,
            }).then(() => (window.location.href = "/"));
          });
      } else if (error.response.status === 406) {
        localStorage.clear();
        Swal.fire({
          title: "หมดเวลาการเข้าใช้งานของท่าน กรุณรล็อคอินใหม่อีกครั้ง",
          type: "error",
          timer: 3000,
        }).then(() => (window.location.href = "/"));
      } else if (error.response.status === 500) {
        Swal.fire({
          title: "Server Error",
          type: "error",
          timer: 3000,
        });
      } else if (error.response.status === 403) {
        Swal.fire({
          title: error.response.data.message,
          type: "error",
          timer: 3000,
        }).then(() => (window.location.href = "/"));
      } else {
        Swal.fire({
          title: error.response.data.message,
          type: "error",
          timer: 3000,
        });
      }
    }
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    if (!error.response) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        code: "NOT_CONNECT_NETWORK",
        message: "NETWORK_CONNECTION_MESSAGE",
      });
    }

    return Promise.reject(error);
  },
);

export const httpClient = axios;
