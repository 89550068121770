import { httpClient } from "../components/utils/axios";

export const eventCreateCheckIn = (formData) =>
  httpClient
    .post(`events/check_in_event/create`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventCheckInTable = (start, length, search) =>
  httpClient
    .post(`events/check_in_event/datatable`, {
      start,
      length,
      search,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventCheckInTableReport = () => httpClient
    .post(`events/check_in_event/all`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const deleteEventCheckIn = (id) => httpClient
    .delete(`events/check_in_event/delete/${id}`, {
      id,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventCheckInUpdateById = (id, formData) => httpClient
    .put(`events/check_in_event/update/${id}`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventCheckById = (id) => httpClient
    .get(`events/check_in_event/${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventCheckQrcode = (id) => httpClient
    .post(`events/check_in_event/qrcode/datatable`, {
      id,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventCheckInStatusUpdate = (id, status) => httpClient
    .put(`events/check_in_event/status_update/${id}`, { status })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventCheckInCustomer = (start, length, search, id) => httpClient
    .post(`events/check_in_event/report/datatable`, {
      id,
      start,
      search,
      length,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventCheckInPrintCustomer = (id) => httpClient
    .post(`events/check_in_event/report/all`, { id })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventCheckInLineOffical = (id) => httpClient
    .post(`line_msg/send_broadcast_event`, { id })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventCreateOnlineActivitty = (formData) => httpClient
    .post(`events/online_activity/create`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => error.response);

export const eventOnlineActivitty = (start, length, search) => httpClient
    .post(`events/online_activity/datatable`, {
      start,
      length,
      search,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventOnlineActivittyReport = () => httpClient
    .post(`events/online_activity/all`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventOnlineActivittyById = (id) => httpClient
    .get(`events/online_activity/${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventOnlineActivittyUpdateById = (id, formData) => httpClient
    .put(`events/online_activity/update/${id}`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

// eslint-disable-next-line arrow-body-style
export const deleteOnlineActivity = (id) => {
  return httpClient
    .delete(`events/online_activity/delete/${id}`, {
      id,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const eventOnlineActivityStatusUpdate = (id, status) => httpClient
    .put(`events/online_activity/status_update/${id}`, { status })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventOnlineActivityCustomer = (start, length, search, id) => httpClient
    .post(`events/online_activity/report/datatable`, {
      id,
      start,
      search,
      length,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventOnlineActivityPrintCustomer = (id) => httpClient
    .post(`events/online_activity/report/all`, { id })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventOnlineActivityQrCode = (id) => httpClient
    .post(`events/online_activity/qrcode/datatable`, {
      id,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsOnlineActivityRandomCode = () => httpClient
    .get(`events/online_activity/code/random`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsVoucherTable = (start, length, search) => httpClient
    .post(`events/voucher/datatable`, {
      start,
      length,
      search,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsVoucherTableReport = () => httpClient
    .post(`events/voucher/all`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const deleteEventsVoucher = (id) => httpClient
    .delete(`events/voucher/delete/${id}`, {
      id,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsVoucherViewId = (id) => httpClient
    .get(`events/voucher/${id}`, { id })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsCreateVoucher = (formData) => httpClient
    .post(`events/voucher/create`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsUpdateVoucher = (id, formData) => httpClient
    .put(`events/voucher/update/${id}`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsUpdateVoucherStatus = (id, status) => httpClient
    .put(`events/voucher/status_update/${id}`, {
      status,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsVoucherQrCode = (start, length, id) => httpClient
    .post(`events/voucher/qrcode/datatable`, {
      start,
      length,
      id,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsVoucherTestCode = (type_code, code) => httpClient
    .post(`events/voucher/code/test`, {
      type_code,
      code,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsVoucherRandomCode = () => httpClient
    .get(`events/voucher/code/random`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsVoucherQrCodeAll = (id) => httpClient
    .post(`events/voucher/qrcode/all`, {
      id,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsVoucheCustomer = (start, length, search, id) => httpClient
    .post(`events/voucher/report/datatable`, {
      id,
      start,
      search,
      length,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsVouchePrintCustomer = (id) => httpClient
    .post(`events/voucher/report/all`, { id })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsVoucherSend = (id, customers) => httpClient
    .post(`events/voucher/send`, { id, customers })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsManualPointsProvinces = () => httpClient
    .get(`filter/list/provinces`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsManualPointsAmphures = (province_id) => httpClient
    .post(`filter/list/amphures`, { province_id })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsManualPointsArtists = () => httpClient
    .get(`filter/list/artists`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsManualPointsFilter = (
  keywords,
  gender,
  day,
  month,
  province_id,
  amphures_id,
  artist_id,
  age_from,
  age_to,
  register_date,
) => httpClient
    .post(`filter/list/customers`, {
      keywords,
      gender,
      day,
      month,
      province_id,
      amphures_id,
      artist_id,
      age_from,
      age_to,
      register_date,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsManualPointsCreate = (formData) => httpClient
    .post(`events/manual_points/create`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsManualPointsTable = (start, length, search) => httpClient
    .post(`events/manual_points/datatable`, {
      start,
      length,
      search,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsManualPointsReport = (start, length, search, id) => httpClient
    .post(`events/manual_points/view/datatable`, {
      id,
      start,
      length,
      search,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventManualPointsViewId = (id) => httpClient
    .get(`/events/manual_points/${id}`, {
      id,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsManualPointsTableReport = () => httpClient
    .post(`events/manual_points/all`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsManualPointsViewReport = (id) => httpClient
    .post(`events/manual_points/view/all`, {
      id,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsManualMilesCreate = (formData) => httpClient
    .post(`manual_points_miles/add`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsManualMilesTable = (start, length, search) => httpClient
    .post(`manual_points_miles/datatable`, {
      start,
      length,
      search,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const eventsManualMilesTableReport = () => httpClient
    .post(`manual_points_miles/all`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
