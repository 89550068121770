import React from "react";
import PropTypes from "prop-types";
import { Form, Button, Select } from "antd";

const DropdownDelete = (props) => {
  const { onFinish, onChangeSelect } = props;
  const [form] = Form.useForm();
  const { Option } = Select;

  return (
    <div className="d-flex mb-4">
      <Form
        form={form}
        initialValues={{ trash: "" }}
        name="horizontal_trash"
        layout="inline"
        onFinish={onFinish}
      >
        <Form.Item name="trash">
          <Select style={{ width: 200 }} onChange={onChangeSelect}>
            <Option value="">Bulk actions</Option>
            <Option value="restore">Restore</Option>
            <Option value="delete">Delete permanently</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

DropdownDelete.propTypes = {
  onFinish: PropTypes.func,
  onChangeSelect: PropTypes.func,
};

export default DropdownDelete;
