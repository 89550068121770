// eslint-disable-next-line max-classes-per-file
import React, { Component } from "react";
import { Table, Modal, Spin, Button, Select, Form, Row, Col } from "antd";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { arrayMove } from "react-sortable-hoc";
import Swal from "sweetalert2";
import { Layout } from "../../containers";
import { userAction } from "../../../store/action";
import { songApi } from "../../../apis";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";

let dragingIndex = -1;
const { Option } = Select;
const { confirm } = Modal;

class BodyRow extends Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += " drop-over-downward";
      }
      if (restProps.index < dragingIndex) {
        className += " drop-over-upward";
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />,
      ),
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex).then();

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);

class SongWhatHotDataTable extends Component {
  constructor(props) {
    super(props);
    this.components = {
      body: {
        row: DragableBodyRow,
      },
    };
    this.state = {
      loading: false,
      visible: false,
      data: [],
      ListSong: [],
      songAddId: "",
      total: 0,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const { start, length } = this.state;

    await this.setState({ loading: true });
    const WhatHotsTable = songApi
      .WhatHotsTable(start, length)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    const ListSong = songApi
      .WhatHotsListSong()
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          ListSong: resp.data,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    await Promise.all([WhatHotsTable, ListSong]);
    await this.setState({ loading: false });
  };

  moveRow = async (oldIndex, newIndex) => {
    await this.setState({
      data: arrayMove(this.state.data, oldIndex, newIndex),
    });
    const list = [];
    for (let i = 0; i < this.state.data.length; i += 1) {
      list.push({
        id: this.state.data[i].id,
        index: i + 1,
      });
    }
    songApi.EditWhatHots({ obj: list }).catch((error) => {
      throw error;
    });
  };

  onAdd = () => {
    songApi.CreateWhatHots({ song_id: +this.state.songAddId }).then((resp) => {
      Swal.fire({
        icon: "success",
        title: `${resp.message}`,
        timer: 1500,
      }).then(() => {
        this.setState({
          loading: false,
          visible: false,
          data: [],
          ListSong: [],
          songAddId: "",
          total: 0,
        });
        this.componentDidMount();
      });
    });
  };

  onDelete = (id) => {
    songApi.DeleteWhatHots(id).then((resp) => {
      Swal.fire({
        icon: "success",
        title: `${resp.message}`,
        timer: 1500,
      }).then(() => {
        this.componentDidMount();
      });
    });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[13].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, ListSong, total, check_token } = this.state;

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text) => <span>{text}</span>,
      },
      {
        title: "Artists",
        dataIndex: "artists",
        key: "artists",
        render: (text) => <span>{text}</span>,
      },
      {
        title: "Genres",
        dataIndex: "genres",
        key: "genres",
        render: (text) => <span>{text}</span>,
      },
      {
        title: "Index",
        dataIndex: "index",
        key: "index",
        align: "right",
        render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
        title: "Actions",
        key: "action",
        render: (text, record) => (
          <Link
            style={{ display: isDelete[0] === 1 ? "" : "none" }}
            to="/what-hot"
            onClick={() => {
              confirm({
                title: "ต้องการลบข้อมูล what's hot this weekend ใช่หรือไม่?",
                centered: true,
                icon: <ExclamationCircleOutlined />,
                onOk: () => {
                  this.onDelete(record.id);
                },
              });
            }}
          >
            Delete
          </Link>
        ),
      },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="What's hot this weekend"
            extraBtn={[
              <Button
                style={{ display: isCreate[0] === 1 ? "" : "none" }}
                disabled={loading}
                type="primary"
                onClick={() => this.setState({ visible: true })}
              >
                + Add song
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "songs",
                breadcrumbName: "Songs",
              },
              {
                path: "what-hot",
                breadcrumbName: "What's hot",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <DndProvider backend={HTML5Backend}>
                <Table
                  className="mt-4"
                  rowKey={(record) => record.id}
                  columns={columns}
                  dataSource={this.state.data}
                  components={this.components}
                  onRow={(record, index) => ({
                    index,
                    moveRow: this.moveRow,
                  })}
                  pagination={{
                    showSizeChanger: total <= 20 ? false : true,
                    showQuickJumper: true,
                    total,
                    showTotal: (total) => `รายการทั้งหมด ${total} รายการ`,
                    pageSizeOptions: ["10", "20", "30"],
                    onShowSizeChange: (page, length) => {
                      this.handlePaggination(0 * length, length, "", 1, length);
                    },
                  }}
                />
              </DndProvider>
            </Spin>
            <Modal
              title="Add song"
              visible={this.state.visible}
              onOk={() => this.onAdd()}
              onCancel={() => this.setState({ visible: false })}
              footer={null}
              centered
            >
              <Form layout="vertical">
                <Form.Item initialValue={this.state.songAddId} label="Song">
                  <Select
                    showSearch
                    className="mt-2"
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    onChange={(e) => this.setState({ songAddId: e })}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {ListSong.map((doc) => (
                      <Option key={doc.id} value={doc.id}>
                        {doc.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item className="my-3">
                  <Row>
                    <Col span={24}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => this.onAdd()}
                      >
                        Submit
                      </Button>
                      <Button
                        className="mx-4"
                        onClick={() => this.setState({ visible: false })}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Modal>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SongWhatHotDataTable);
