// import join from "url-join";
// import Swal from "sweetalert2";
// const isAbsoluteURLRegex = /^(?:\w+:)\/\//;
import axios from "axios";
import jwtDecode from "jwt-decode";
import moment from "moment";
import "moment/locale/th";

export const refreshToken = async () =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const userData = localStorage.getItem("userData");
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/refresh_token`, {
        token_refresh: refreshToken,
      })
      .then(async (response) => {
        await localStorage.setItem("auth", response.data.access_token);
        await localStorage.setItem("refreshToken", response.data.refresh_token);
        await localStorage.setItem("userData", userData);
        const access_token = await jwtDecode(response.data.access_token);
        const refresh_token = await jwtDecode(response.data.refresh_token);
        const exp_access_token = await moment
          .unix(access_token.exp)
          .subtract(13, "minutes");
        await localStorage.setItem("exp_access_token", exp_access_token.unix());
        await localStorage.setItem("exp_refresh_token", refresh_token.exp);

        // name !== true && window.location.reload();
        return resolve(true);
      })
      .catch(() => {
        localStorage.clear();
        return resolve(false);
      });
  });
