import React, { Component } from "react";
import { Avatar, Spin, Button, Tag } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";

import { Layout } from "../../containers";
import { DateTimeLong, DateTimeMid } from "../../commons";
import { AdvancedTableSearch } from "../../views";
import { userAction } from "../../../store/action";
import { eventsApi } from "../../../apis";

class ManualMiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataManualMiles: [],
      start: 0,
      length: 20,
      search: "",
      total: 0,
      dataReport: [],
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const { start, length, search } = this.state;

    this.setState({ loading: true });
    await eventsApi
      .eventsManualMilesTable(start, length, search)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          dataManualMiles: resp.data,
          total: resp.recordsTotal,
        }));
      });
    await eventsApi.eventsManualMilesTableReport().then((resp) => {
      const data = [];
      resp.data.forEach((element) => {
        data.push({
          created_at: element.created_at,
          address: element.customer.address,
          amphure: element.customer.amphure && element.customer.amphure.name_th,
          birthdate: element.customer.birthdate,
          district:
            element.customer.district && element.customer.district.name_th,
          zip_code:
            element.customer.district && element.customer.district.zip_code,
          email: element.customer.email,
          fullname: element.customer.fullname,
          gender: element.customer.gender,
          province:
            element.customer.province && element.customer.province.name_th,
          telephone: element.customer.telephone,
          points: element.points,
          id: element.id,
        });
        this.setState((prevState) => ({
          ...prevState,
          dataReport: data,
        }));
      });
    });
    await this.setState({
      loading: false,
    });
  };

  handlePaggination = (start, length, search) => {
    this.setState({ loading: true });
    this.setState((prevState) => ({
      ...prevState,
      start,
      length,
      search,
    }));
    eventsApi.eventsManualMilesTable(start, length, search).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        dataManualMiles: resp.data,
        total: resp.recordsTotal,
        loading: false,
      }));
    });
  };

  handleSearch = (value) => {
    this.setState({ loading: true });
    const { length } = this.state;
    eventsApi.eventsManualMilesTable(0, length, value).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        dataManualMiles: resp.data,
        loading: false,
      }));
    });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[20].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const columns = [
      {
        title: "Miles",
        dataIndex: "points",
        key: "points",
        align: "right",
        render: (text, record) => (
          <Tag color="green" key={record.points}>
            + {record.points}
          </Tag>
        ),
      },
      {
        title: "Image",
        dataIndex: "image",
        key: "image",
        render: (text, record) => (
          <Avatar
            size={64}
            shape="circle"
            src={record.customer && record.customer.image}
          />
        ),
      },
      {
        title: "Name",
        dataIndex: "fullname",
        key: "fullname",
        render: (text, record) => (
          <span>{record.customer && record.customer.fullname}</span>
        ),
      },
      {
        title: "Birthday",
        dataIndex: "birthdate",
        key: "birthdate",
        render: (text, record) => (
          <DateTimeMid
            dateTime={
              record.customer && moment(record.customer.birthdate, "DD/MM/YYYY")
            }
          />
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (text, record) => (
          <span>{record.customer && record.customer.email}</span>
        ),
      },
      {
        title: "Entry date",
        dataIndex: "created_at",
        key: "created_at",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.created_at - b.created_at,
        render: (text, record) => (
          <DateTimeLong
            dateTime={moment(record.created_at, "DD/MM/YYYY HH:mm:ss")}
          />
        ),
      },
    ];

    const { loading, dataManualMiles, total, check_token } = this.state;

    return (
      <>
        {check_token === true && (
          <Layout
            title="Miles"
            extraBtn={
              isCreate[0] === 1
                ? [
                    <Button
                      disabled={loading}
                      type="primary"
                      onClick={() =>
                        this.props.history.push("/manual-miles/create")
                      }
                    >
                      + Add point
                    </Button>,
                  ]
                : []
            }
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
                separator: ">",
              },
              {
                path: "manual-miles",
                breadcrumbName: "Miles",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <AdvancedTableSearch
                columns={columns}
                data={dataManualMiles}
                handleSearch={(value) => this.handleSearch(value)}
                onChangeSearch={(e) => this.handleSearch(e.target.value)}
                nonExport
                noDropdownSearch
                noFillter
                loading={loading}
                searchCondition={[
                  "miles",
                  "image",
                  "points",
                  "birthdate",
                  "created_at",
                ]}
                rowKey={(record) => record.id}
                total={total}
                onTableChange={this.handlePaggination}
              />
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ManualMiles);
