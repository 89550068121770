import React from "react";
import { Col, Select } from "antd";

const CardFilterCity = (props) => (
    <Col className={props.className}>
      {props.nameTitle && <p className="text-left my-1">{props.nameTitle}</p>}
      {props.id === null ? (
        <Select
          showSearch
          onChange={props.onChange}
          value={props.value}
          // onFocus={onFocus}
          // onBlur={onBlur}
          // onSearch={onSearch}
          defaultValue={props.defaultValue}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {props.children}
        </Select>
      ) : (
        <Select
          showSearch
          value={props.id}
          onChange={props.onChange}
          defaultValue={props.defaultValue}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {props.children}
        </Select>
      )}
    </Col>
  );
export default CardFilterCity;
