import React, { Component } from "react";
import { Dropdown, Menu, Spin, Button, Modal, Avatar } from "antd";
import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { Layout } from "../../containers";
import { AdvancedTableSearch } from "../../views";
import { userAction } from "../../../store/action";
import { albumApi } from "../../../apis";

const { confirm } = Modal;

class AlbumsDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      total: 0,
      ListGenre: [],
      typeSearch: 1,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const { start, length } = this.state;
    await this.setState({ loading: true });
    await albumApi
      .AlbumTable(start, length)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    await this.setState({ loading: false });
  };

  handleSearch = (typeFilter) => {
    this.setState({ loading: true });
    const { length, search } = this.state;
    albumApi
      .AlbumTable(0, length, search)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          loading: false,
          typeFilter,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handlePaggination = (start, length) => {
    const { search } = this.state;
    albumApi
      .AlbumTable(start, length, search)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
          start,
          length,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleDelete = (id) => {
    albumApi
      .DeleteAlbum(id)
      .then(() => {
        this.componentDidMount();
      })
      .catch((error) => {
        throw error;
      });
  };

  onChangeSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[29].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, data, total, check_token } = this.state;

    const menu = (record) => (
      <Menu className="datable-actions-menu">
        {isEdit[0] === 1 && (
          <Menu.Item key="edit">
            <Link to={`/albums/${record.id}/edit`}>Edit</Link>
          </Menu.Item>
        )}
        {isDelete[0] === 1 && (
          <Menu.Item key="delete">
            <Link
              to="/albums"
              onClick={() => {
                confirm({
                  title: "ต้องการลบข้อมูลอัลบั้มใช่หรือไม่?",
                  centered: true,
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => {
                    this.handleDelete(record.id);
                  },
                });
              }}
            >
              Delete
            </Link>
          </Menu.Item>
        )}
      </Menu>
    );
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        id: "name",
        type: "input",
        value: "",
        selectData: [],
        onChange: (e) => this.setState({ name: e.target.value }),
        render: (text, record) => (
          <div>
            <Avatar className="rounded" size={64} src={record.image} />
            <span className="ml-3">{text}</span>
          </div>
        ),
      },
      {
        title: "Release year",
        dataIndex: "release_years",
        key: "date",
        type: "date",
        value: "",
        align: "right",
        selectData: [],
      },
      {
        title: "Action",
        key: "action",
        align: "right",
        render: (text, record) => (
          <Dropdown
            trigger={["click"]}
            overlay={menu(record)}
            placement="bottomRight"
          >
            <Button type="link">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="Albums"
            extraBtn={
              isCreate[0] === 1
                ? [
                    <Button
                      type="primary"
                      onClick={() => this.props.history.push("/albums/create")}
                    >
                      + Add albums
                    </Button>,
                  ]
                : []
            }
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "albums",
                breadcrumbName: "Albums",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <AdvancedTableSearch
                nonExport
                noFillter
                columns={columns}
                data={data}
                handleSearch={this.handleSearch}
                onChangeSearch={this.onChangeSearch}
                defaultValueSearch="name"
                loading={loading}
                searchCondition={["date", "action"]}
                rowKey={(record) => record.id}
                total={total}
                onTableChange={this.handlePaggination}
              />
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlbumsDataTable);
