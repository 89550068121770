import React, { Component } from "react";
import { Col, Form, Row, Card, Spin, DatePicker, Button } from "antd";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import PageTable from "../../commons/PageTable";
import { httpClient } from "../../utils/axios";
import { Layout } from "../../containers";
import { Card5ColReport } from "../../views";

export const CardSearchNameUserStyle = styled.div`
  width: 100%;

  .ant-select {
    width: 100%;
    display: block;
  }

  .col-5 {
    flex: 0 0 100%;
    max-width: 100%;
    @media (min-width: 768px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (min-width: 992px) {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
    @media (min-width: 1280px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
`;

class RegistrationReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingSearch: false,
      start: moment().add(-30, "days").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
      length: 20,
      starts: 0,
      data: [],
      total: 0,
      graph: [],
      top5_province: [],
      exportData: [],
      check_token: false,
    };
    // this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await this.setState({ loading: true });
    const { start, end, starts, length } = this.state;
    const register_report = httpClient
      .post(`reports/register_report/datatable`, {
        start_date: start,
        end_date: end,
        start: starts,
        length,
      })
      .then((res) => {
        this.setState({
          data: res.data.data,
          total: res.data.recordsTotal,
        });
      })
      .catch((error) => {
        throw error;
      });
    const register_report_graph = httpClient
      .post(`reports/register_report/graph`, {
        start_date: start,
        end_date: end,
      })
      .then((res) => {
        this.setState({
          graph: res.data,
          top5_province: res.data.top5_province,
        });
      })
      .catch((error) => {
        throw error;
      });
    await Promise.all([register_report, register_report_graph]);
    await this.setState({
      loading: false,
    });
  };

  handlePaggination = async (starts, length) => {
    await this.setState({ loading: true });
    const { start, end } = this.state;
    const register_report = httpClient
      .post(`reports/register_report/datatable`, {
        start_date: start,
        end_date: end,
        start: starts,
        length,
      })
      .then((res) => {
        this.setState({
          data: res.data.data,
          total: res.data.recordsTotal,
        });
      })
      .catch((error) => {
        throw error;
      });
    await Promise.all([register_report]);
    await this.setState({
      loading: false,
    });
  };

  onChangeStart = (value) => {
    const date = value.format("YYYY-MM-DD");
    this.setState({
      start: date,
    });
  };

  onChangeEnd = (value) => {
    const date = value.format("YYYY-MM-DD");
    this.setState({
      end: date,
    });
  };

  onConfirmSearch = async () => {
    const { start, end, length } = this.state;

    const startDate = moment(start).valueOf();
    const endDate = moment(end).valueOf();

    if (startDate > endDate) {
      Swal.fire({
        icon: "error",
        title: `เวลาสิ้นสุดต้องมากกว่าเวลาเริ่มต้นโปรดตรวจสอบและลองอีกครั้ง`,
      });
      return;
    }
    await this.setState({ loading: true, loadingSearch: true });

    const register_report = httpClient
      .post(`reports/register_report/datatable`, {
        start_date: start,
        end_date: end,
        start: 0,
        length,
      })
      .then((res) => {
        this.setState({
          data: res.data.data,
          total: res.data.recordsTotal,
          starts: 0,
        });
      })
      .catch((error) => {
        throw error;
      });
    const register_report_graph = httpClient
      .post(`reports/register_report/graph`, {
        start_date: start,
        end_date: end,
      })
      .then((res) => {
        this.setState({
          graph: res.data,
          top5_province: res.data.top5_province,
        });
      })
      .catch((error) => {
        throw error;
      });
    await Promise.all([register_report, register_report_graph]);
    await this.setState({
      loading: false,
      loadingSearch: false,
    });
  };

  ButtonGreyClick = async () => {
    const that = this;
    await this.setState({ loading: true });
    const { start, end } = this.state;
    await httpClient
      .post(`reports/register_report/export`, {
        start_date: start,
        end_date: end,
      })
      .then((res) => {
        const data = [];
        const result = res.data.data;
        result.forEach((doc) => {
          data.push({
            provinces: doc.provinces,
            male: doc.male,
            female: doc.female,
            other: doc.other,
            total: doc.total,
          });
          that.setState({
            exportData: data,
          });
        });
      })
      .catch((error) => {
        throw error;
      });
    await this.setState({ loading: false });
    await document.getElementById("btn-export").click();
  };

  render() {
    const {
      loading,
      total,
      data,
      graph,
      top5_province,
      exportData,
      start,
      end,
      check_token,
    } = this.state;

    const columns = [
      {
        title: "จังหวัด",
        dataIndex: "provinces",
        key: "provinces",
        width: "100",
        render: (text, record) => (
          <div className="d-block">
            <span style={{ fontSize: 14 }} className="d-block text-muted">
              {record.provinces}
            </span>
          </div>
        ),
      },
      {
        title: "ชาย",
        dataIndex: "male",
        key: "male",
        align: "center",

        render: (text, record) => (
          <div className="d-block">
            <span style={{ fontSize: 14 }} className="d-block text-muted">
              {record.male}
            </span>
          </div>
        ),
      },
      {
        title: "หญิง",
        dataIndex: "female",
        key: "female",
        align: "center",
        render: (text, record) => (
          <div className="d-block">
            <span style={{ fontSize: 14 }} className="d-block text-muted">
              {record.female}
            </span>
          </div>
        ),
      },
      {
        title: "ไม่ระบุเพศ",
        dataIndex: "other",
        key: "other",
        align: "center",
        render: (text, record) => (
          <div className="d-block">
            <span style={{ fontSize: 14 }} className="d-block text-muted">
              {record.other}
            </span>
          </div>
        ),
      },
      {
        title: "รวม",
        dataIndex: "total",
        key: "total",
        align: "center",
        render: (text, record) => (
          <div className="d-block">
            <span style={{ fontSize: 14 }} className="d-block text-muted">
              {record.total}
            </span>
          </div>
        ),
      },
    ];
    const data2 = [
      { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
      { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
      { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
      { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
      { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
      { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
      { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
    ];

    const headers = [
      { label: "Provinces", key: "provinces" },
      { label: "Male", key: "male" },
      { label: "Female", key: "female" },
      { label: "Other", key: "other" },
      { label: "Total", key: "total" },
    ];

    const onFinishFailed = (err) => {
      console.log(err);
    };

    return (
      <>
        {check_token === true && (
          <Layout
            title="Registration Report"
            extraBtn={[]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "register-report",
                breadcrumbName: "Registration Report",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <Form layout="vertical" onFinishFailed={onFinishFailed}>
                <Row className="mb-4" gutter={[16, 16]}>
                  <Col>
                    <p className="text-left mb-1">Start Date</p>
                    <DatePicker
                      //   style={{ width: "calc(100% - 32px)" }}
                      onChange={this.onChangeStart}
                      size="large"
                      defaultValue={moment().add(-30, "days")}
                      format="DD/MM/YYYY"
                      allowclear={false}
                    />
                  </Col>
                  <Col>
                    <p className="text-left mb-1">End Date</p>
                    <DatePicker
                      //   style={{ width: "calc(100% - 32px)" }}
                      onChange={this.onChangeEnd}
                      size="large"
                      defaultValue={moment()}
                      format="DD/MM/YYYY"
                      allowClear={false}
                    />
                  </Col>
                  <Col className="mt-4">
                    <Button
                      type="primary"
                      size="large"
                      onClick={this.onConfirmSearch}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
                <Spin spinning={loading}>
                  <Card5ColReport
                    totalRegister={graph.total_register || 0}
                    dataGraphTotalRegister={data2}
                    totalMale={graph && graph.total_male}
                    dataGraphTotalMale={data2}
                    totalFemale={graph && graph.total_female}
                    dataGraphTotalFemale={data2}
                    totalOther={graph && graph.total_other}
                    dataGraphTotalOther={data2}
                    provinceGraph={top5_province}
                  />
                </Spin>
                <Card>
                  <Row className="m-0" gutter={[16, 16]}>
                    <Col span={18}>
                      <h3 className="mb-0">จำนวนคนสมัคร</h3>
                    </Col>
                    <Col span={6} className="text-right">
                      <Button size="large" onClick={this.ButtonGreyClick}>
                        Export to csv
                      </Button>
                      <CSVLink
                        id="btn-export"
                        className={`${`ant-btn ant-btn-lg`}`}
                        style={{
                          display: "none",
                          background: "#eaeaea",
                          color: "#403737",
                          border: "2px solid #eaeaea",
                        }}
                        data={exportData}
                        filename={`${"RegistrationReport"}-${start}to${end}.csv`}
                        headers={headers}
                        target="_blank"
                      >
                        Export to csv
                      </CSVLink>
                    </Col>
                  </Row>

                  <Form.Item className="my-3">
                    <div className="mt-3">
                      {this.state.loadingSearch === false && (
                        <PageTable
                          rowKey={(record) => record.province}
                          columns={columns}
                          data={data}
                          total={total}
                          onChange={this.handlePaggination}
                        />
                      )}
                    </div>
                  </Form.Item>
                </Card>
              </Form>
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

export default RegistrationReport;
