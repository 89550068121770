import { httpClient } from "../components/utils/axios";

export const AuditionTable = (start, length, search, type) =>
  httpClient
    .get(
      `auditions/datatable?start=${start || 0}&length=${length || 20}&search=${
        search || ""
      }&type=${type || 1}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const AuditionPromoteTable = (start, length) =>
  httpClient
    .get(
      `auditions/promote/datatable?start=${start || 0}&length=${length || 20}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const indexAudition = (data) =>
  httpClient
    .put(`auditions/promote/index`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const updateStickyAudition = (id) =>
  httpClient
    .put(`auditions/promote?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getAudition = (id) =>
  httpClient
    .get(`auditions?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
