import React, { Component } from "react";
import {
  Card,
  Col,
  Form,
  Row,
  Spin,
  Button,
  Input,
  DatePicker,
  Upload,
  Radio,
  Select,
  Modal,
} from "antd";
import * as Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { FaMapMarkerAlt } from "react-icons/fa";
import { SketchPicker } from "react-color";
import ImgCrop from "antd-img-crop";
import styled from "styled-components";
import ReactQuill from "react-quill";
import QuillStyle from "../../commons/EditorQuill/QuillStyle";
import { httpClient } from "../../utils/axios";
import { eventsRenewApi } from "../../../apis";
import { Layout } from "../../containers";

const { Option } = Select;

const UploadStyle = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
  }
`;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    console.log(file);
    onSuccess("ok");
  }, 0);
};

function getBase64Cover(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", (e) => {
    const img = new Image();
    img.src = e.target.result;
    img.onload = () => {
      callback(reader.result);
    };
  });
  Swal.close();
  reader.readAsDataURL(img);
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

async function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `You can only upload JPG/PNG file!`,
      timer: 1500,
    });
  }
  const isLt5M = file.size / 1024 / 1024 < 1;
  if (!isLt5M) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `Image must smaller than 1MB!`,
      timer: 1500,
    });
  }
  return isJpgOrPng && isLt5M;
}
const { RangePicker } = DatePicker;

class EventEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      visible: false,
      radioStatusValue: 0,
      lat: "0",
      lng: "0",
      artistData: [],
      background: "#fff",
      fontColor: "#000",
      fileList: [],
      loadingMap: false,
      editorData: "",
      check_token: false,
    };
    this.reactQuillRef = null;
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const EventGetArtist = eventsRenewApi
      .EventGetArtist()
      .then((resp) => {
        this.setState({
          artistData: resp.data,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        throw error;
      });
    const getEvent = eventsRenewApi
      .getEvent(this.props.match.params.eventId)
      .then((resp) => {
        console.log(resp);
        const artist = [];
        const artistData = resp.data.artists.array;
        for (let i = 0; i < artistData.length; i += 1) {
          artist.push(+artistData[i].artist_id);
        }
        const image = [];
        resp.data.image.forEach((doc) => {
          image.push({
            uid: doc.id,
            name: doc.id,
            status: "done",
            url: doc.image,
          });
        });
        this.setState({
          lat: resp.data.lat,
          lng: resp.data.lng,
          name: resp.data.name,
          editorData: resp.data.description,
          short_description: resp.data.short_description,
          startDate: moment(+resp.data.start_timestamp * 1000).format(
            "DD/MM/YYYY HH:mm",
          ),
          endDate: moment(+resp.data.end_timestamp * 1000).format(
            "DD/MM/YYYY HH:mm",
          ),
          location: resp.data.location,
          price: resp.data.price,
          url: resp.data.url,
          status: resp.data.status,
          imageUrl: resp.data.cover,
          background: resp.data.color,
          fontColor:
            resp.data.font_color !== null ? resp.data.font_color : "#ffffff",
          type: resp.data.type,
          artist,
          fileList: image,
          oldImage: image,
          where_to_buy: resp.data.where_to_buy,
        });
      });
    await Promise.all([EventGetArtist, getEvent]);
    await this.setState({ loading: false });
  };

  loadMap = async () => {
    const { lat, lng, zoom } = this.state;
    await this.setState({ loadingMap: true });
    const map = new window.google.maps.Map(document.getElementById("map-2"), {
      center: {
        lat: +lat,
        lng: +lng,
      },
      zoom: zoom || 13,
      mapTypeControl: false,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        mapTypeIds: ["roadmap", "terrain"],
      },
    });
    const marker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(+lat, +lng),
      map,
      draggable: true,
    });
    const infowindow = new window.google.maps.InfoWindow();
    const infowindowContent = document.getElementById("infowindow-content-2");
    infowindow.setContent(infowindowContent);

    marker.addListener("click", () => {});
    const setPlace = (lat, lng, zoom) => {
      this.setState({ lat, lng, zoom, loadingMap: false });
    };
    marker.addListener("dragend", (ev) => {
      const location = ev.latLng;
      const lat = location.lat();
      const lng = location.lng();
      map.setCenter(location);
      setPlace(lat, lng, marker.map.zoom);
    });
    const inputNode = document.getElementById("pacinput");
    this.autoComplete = new window.google.maps.places.Autocomplete(inputNode);
    this.autoComplete.bindTo("bounds", map);
    this.autoComplete.addListener("place_changed", () => {
      infowindow.close();
      const place = this.autoComplete.getPlace();
      const { location } = place.geometry;
      const lat = place.geometry.location.lat();
      const long = place.geometry.location.lng();
      //   this.setState({
      //     text: place.name,
      //   });
      this.setState({ lat, lng: long, loadingMap: false });
      this.loadMap();
      // bring the selected place in view on the map
      map.fitBounds(place.geometry.viewport);
      map.setCenter(location);
      marker.setPlace({
        placeId: place.place_id,
        location,
      });
      marker.setVisible(true);
    });
    await this.setState({ loadingMap: false });
  };

  onChangeText = (text) => {
    this.setState({ editorData: text });
  };

  handleBackgroundColor = (color) => {
    this.setState({ background: color.hex });
  };

  handleFontColor = (color) => {
    this.setState({ fontColor: color.hex });
  };

  handleCancel = () => {
    this.setState({ visible: false, previewVisible: false });
  };

  handleChangePhoto = async (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      await getBase64Cover(info.file.originFileObj, (imageUrl) => {
        this.setState({
          imageUrl,
          imageFile: info.file.originFileObj,
          loading: false,
        });
      });
    }
  };

  handleChangeInput = (changeObject) => {
    this.setState(changeObject);
  };

  disabledDate = (current) =>
    current && current < moment().add(-1, "days").endOf("day");

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ file, fileList }) => {
    const { oldImage } = this.state;
    if (file.status) {
      if (file.status === "removed") {
        for (let i = 0; i < oldImage.length; i += 1) {
          if (oldImage[i].uid === file.uid) {
            eventsRenewApi
              .DeleteEventImage(
                oldImage[i].uid,
                +this.props.match.params.eventId,
              )
              .then(() => {
                this.setState({ fileList });
              });
          }
        }
        this.setState({ fileList });
      } else {
        this.setState({ fileList });
        if (file.status === "done") {
          const formData = new FormData();
          formData.append("event_custom_id", +this.props.match.params.eventId);
          formData.append("image", file.originFileObj);
          eventsRenewApi.CreateEventImage(formData).then(async (res) => {
            const imageData = this.state.fileList;
            // eslint-disable-next-line no-restricted-syntax
            for (const [i, img] of imageData.entries()) {
              // eslint-disable-next-line eqeqeq
              if (img.uid == file.originFileObj.uid) {
                imageData.splice(i, 1);
              }
            }
            await this.setState({
              fileList: imageData.concat([
                {
                  uid: res.id,
                  name: res.id,
                  status: "done",
                  url: await getBase64(file.originFileObj),
                },
              ]),
              oldImage: imageData.concat([
                {
                  uid: res.id,
                  name: res.id,
                  status: "done",
                  url: res.image,
                },
              ]),
            });
          });
        }
      }
    }
  };

  onChangeDate = (date, string) => {
    console.log(date, string);
    this.setState({
      startDate: string[0],
      endDate: string[1],
    });
  };

  onFinish = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("color", this.state.background);
    formData.append("font_color", this.state.fontColor);
    formData.append("description", values.content);
    formData.append("short_description", values.shotDescription);
    formData.append("url", values.youtube);
    formData.append("price", +values.price);
    formData.append("location", values.place);
    this.state.imageFile && formData.append("cover", this.state.imageFile);
    formData.append("lat", this.state.lat);
    formData.append("lng", this.state.lng);
    formData.append("where_to_buy", values.buyUrl);
    formData.append(
      "start_date",
      moment(this.state.startDate, "DD/MM/YYYY HH:mm").format(
        "YYYY-MM-DD HH:mm",
      ),
    );
    formData.append(
      "end_date",
      moment(this.state.endDate, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm"),
    );
    formData.append("type", values.type);
    formData.append("status", values.status);
    for (let i = 0; i < values.artist.length; i += 1) {
      formData.append("artists[]", values.artist[i]);
    }
    Swal.fire({
      title: "ต้องการแก้ไขข้อมูล Event ใช่หรือไม่",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "ระบบกำลังทำงาน",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        eventsRenewApi
          .EventUpdate(this.props.match.params.eventId, formData)
          .then((resp) => {
            Swal.fire({
              icon: "success",
              title: `${resp.message}`,
              timer: 1500,
            }).then(() => this.props.history.push("/events"));
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
          });
      }
    });
  };

  uploadFiles = (uploadFileObj) => {
    const formData = new FormData();
    const that = this;
    formData.append("image", uploadFileObj);
    httpClient
      .post("/news/content/image", formData)
      .then((response) => {
        if (response.data) {
          const range = that.reactQuillRef.getEditorSelection();
          setTimeout(() => {
            that.reactQuillRef
              .getEditor()
              .insertEmbed(range.index, "image", response.data.url);
          }, 2000);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      this.uploadFiles(file, this.reactQuillRef);
    };
  };

  render() {
    moment.locale("th");
    const {
      loading,
      radioStatusValue,
      imageUrl,
      artistData,
      fileList,
      previewVisible,
      previewImage,
      loadingMap,
      check_token,
    } = this.state;

    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        {check_token === true && (
          <Layout
            title="Edit event"
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "events",
                breadcrumbName: "Events",
              },
              {
                path: "edit",
                breadcrumbName: "Edit event",
              },
            ]}
            extraBtn={[]}
          >
            <Spin spinning={loading} tip="Loading...">
              {loading === false && (
                <Form
                  layout="vertical"
                  onFinish={this.onFinish}
                  initialValues={{
                    description: "",
                  }}
                >
                  <Row gutter={[16, 16]}>
                    <Col md={16} className="h-100">
                      <Card>
                        <Form.Item
                          label="Name"
                          name="name"
                          initialValue={this.state.name}
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล Name!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) =>
                              this.handleChangeInput({
                                name: e.target.value,
                              })
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          label="Short description"
                          name="shotDescription"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล description!",
                            },
                          ]}
                          initialValue={this.state.short_description}
                        >
                          <Input
                            onChange={(e) =>
                              this.handleChangeInput({
                                shotDescription: e.target.value,
                              })
                            }
                          />
                        </Form.Item>
                        <p>Feature image</p>
                        <Form.Item
                          style={{ marginTop: +10, marginBottom: +10 }}
                          name={["image"]}
                          extra={
                            <ul>
                              <li>Max file size 1MB.</li>
                              <li>Image with .jpg, .jpeg and .png</li>
                              <li>ขนาดรูปควรเป็น 450*600px</li>
                              <li>อัพโหลดได้ 1-5 รูป</li>
                            </ul>
                          }
                        >
                          <Row
                            gutter={[16, 16]}
                            type="flex"
                            align="middle"
                            className="form__upload"
                          >
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 14, offset: 0 }}
                            >
                              <ImgCrop aspect={3 / 4}>
                                <UploadStyle
                                  customRequest={dummyRequest}
                                  listType="picture-card"
                                  fileList={fileList}
                                  beforeUpload={beforeUpload}
                                  onPreview={this.handlePreview}
                                  onChange={this.handleChange}
                                >
                                  {fileList.length >= 5 ? null : uploadButton}
                                </UploadStyle>
                              </ImgCrop>
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item
                          label="Artists"
                          name="artist"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล!",
                            },
                          ]}
                          initialValue={this.state.artist}
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {artistData.map((doc) => (
                              <Option key={doc.id} value={doc.id}>
                                {doc.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: false,
                              message: "กรุณากรอกข้อมูล!",
                            },
                          ]}
                          label="Youtube url (Optional)"
                          name="youtube"
                          initialValue={this.state.url}
                        >
                          <Input
                            onChange={(e) =>
                              this.handleChangeInput({
                                youtube: e.target.value,
                              })
                            }
                          />
                        </Form.Item>
                        <QuillStyle>
                          <Form.Item
                            initialValue={this.state.editorData}
                            name="content"
                            label="Description"
                          >
                            <ReactQuill
                              ref={(el) => {
                                this.reactQuillRef = el;
                              }}
                              theme="snow"
                              onChange={this.onChangeText}
                              modules={{
                                toolbar: {
                                  container: [
                                    [{ header: [3, 4, false] }],
                                    ["bold", "italic", "underline"],
                                    [
                                      {
                                        color: [
                                          "#000000",
                                          "#e60000",
                                          "#ff9900",
                                          "#ffff00",
                                          "#008a00",
                                          "#0066cc",
                                          "#9933ff",
                                          "#ffffff",
                                          "#facccc",
                                          "#ffebcc",
                                          "#ffffcc",
                                          "#cce8cc",
                                          "#cce0f5",
                                          "#ebd6ff",
                                          "#bbbbbb",
                                          "#f06666",
                                          "#ffc266",
                                          "#ffff66",
                                          "#66b966",
                                          "#66a3e0",
                                          "#c285ff",
                                          "#888888",
                                          "#a10000",
                                          "#b26b00",
                                          "#b2b200",
                                          "#006100",
                                          "#0047b2",
                                          "#6b24b2",
                                          "#444444",
                                          "#5c0000",
                                          "#663d00",
                                          "#666600",
                                          "#003700",
                                          "#002966",
                                          "#3d1466",
                                        ],
                                      },
                                    ],
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    ["link", "image", "video"],
                                    ["clean"],
                                  ],
                                  handlers: {
                                    image: this.imageHandler,
                                  },
                                },
                                clipboard: {
                                  matchVisual: false,
                                },
                              }}
                            />
                          </Form.Item>
                        </QuillStyle>
                      </Card>
                      <Modal
                        title="Google map"
                        visible={this.state.visible}
                        onOk={this.handleCancel}
                        onCancel={this.handleCancel}
                        footer={null}
                        centered
                      >
                        <Input
                          className="mb-4"
                          id="pacinput"
                          placeholder="search your location."
                        />
                        <div id="map-2" style={{ height: 300 }} />
                        <div id="infowindow-content-2" />
                        <Form.Item className="my-3">
                          <Button
                            type="primary"
                            onClick={() => this.setState({ visible: false })}
                          >
                            Save
                          </Button>
                          <Button
                            className="mx-4"
                            onClick={() => this.setState({ visible: false })}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                      </Modal>
                    </Col>
                    <Col md={8}>
                      <Card>
                        <Form.Item className="my-3">
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                          <Button
                            className="mx-4"
                            onClick={() => this.props.history.goBack()}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                        <Form.Item
                          label="Event date"
                          name="date"
                          rules={[
                            {
                              required: true,
                              message: "กรุณาเลือก วันในการเริ่มและสิ้นสุด!",
                            },
                          ]}
                          initialValue={[
                            moment(this.state.startDate, "DD/MM/YYYY HH:mm"),
                            moment(this.state.endDate, "DD/MM/YYYY HH:mm"),
                          ]}
                        >
                          <RangePicker
                            style={{ width: "100%" }}
                            showTime
                            onChange={this.onChangeDate}
                            format="DD/MM/YYYY HH:mm"
                            disabledDate={this.disabledDate}
                          />
                        </Form.Item>
                        <Form.Item
                          name="type"
                          label="Type"
                          initialValue={this.state.type}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group>
                            <Radio value={1}>Event & Concert</Radio>
                            <Radio value={2}>Showbiz</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          initialValue={this.state.where_to_buy}
                          label="Buy URL (Optional)"
                          name="buyUrl"
                        >
                          <Input
                            onChange={(e) =>
                              this.handleChangeInput({
                                buyUrl: e.target.value,
                              })
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          label="Ticket Price"
                          name="price"
                          help="ใส่ 0 คือฟรี"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล ราคา ticket!",
                            },
                          ]}
                          initialValue={this.state.price}
                        >
                          <Input
                            type="number"
                            onChange={(e) =>
                              this.handleChangeInput({
                                price: e.target.value,
                              })
                            }
                            onKeyDown={(evt) =>
                              evt.key === "e" ||
                              evt.key === "E" ||
                              evt.key === "."
                                ? evt.preventDefault()
                                : {}
                            }
                            min={0}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Cover image"
                          name="image"
                          extra={
                            <ul>
                              <li>Max file size 1MB.</li>
                              <li>Image with .jpg, .jpeg and .png</li>
                              <li>ขนาดรูปควรเป็น 1900*385px</li>
                            </ul>
                          }
                        >
                          <ImgCrop aspect={16 / 9}>
                            <Upload
                              accept=".jpg, .jpeg, .png"
                              name="upload-img"
                              listType="picture-card"
                              className="form__action avatar-uploader w-100"
                              showUploadList={false}
                              multiple={false}
                              customRequest={dummyRequest}
                              beforeUpload={beforeUpload}
                              onChange={this.handleChangePhoto}
                            >
                              {imageUrl ? (
                                <img
                                  src={imageUrl}
                                  alt="avatar"
                                  className="img-fluid"
                                />
                              ) : (
                                uploadButton
                              )}
                            </Upload>
                          </ImgCrop>
                        </Form.Item>
                        <span className="ant-form-item">Background Color</span>
                        <SketchPicker
                          className="my-3"
                          color={this.state.background}
                          onChangeComplete={this.handleBackgroundColor}
                        />
                        <span className="ant-form-item">Font Color</span>
                        <SketchPicker
                          className="mt-3"
                          color={this.state.fontColor}
                          onChangeComplete={this.handleFontColor}
                        />
                        <Form.Item
                          className="mt-3"
                          label="Location"
                          name="place"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล สถานที่จัดงาน!",
                            },
                          ]}
                          initialValue={this.state.location}
                        >
                          <Input
                            onChange={(e) =>
                              this.handleChangeInput({
                                addressInputActivity: e.target.value,
                              })
                            }
                          />
                        </Form.Item>
                        {loadingMap === false && (
                          <Form.Item label="Google map" name="map">
                            <Input
                              onClick={async () => {
                                this.setState({ visible: true });
                                setTimeout(() => {
                                  this.loadMap();
                                }, 1500);
                              }}
                              defaultValue={`${this.state.lat}, ${this.state.lng}`}
                              suffix={<FaMapMarkerAlt />}
                            />
                          </Form.Item>
                        )}
                        <Form.Item
                          initialValue={this.state.status}
                          name="status"
                          label="Status"
                          rules={[{ required: true }]}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              this.setState({
                                radioStatusValue: e.target.value,
                              })
                            }
                            value={radioStatusValue}
                          >
                            <Radio value={1}>Active</Radio>
                            <Radio value={0}>Suspend</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Card>
                    </Col>
                  </Row>

                  <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleCancel}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Form>
              )}
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

export default EventEdit;
