import styled from "styled-components";

const MenuRoleStyle = styled.div`
  .ant-menu-title-content {
    display: flex;
    justify-content: space-between;
  }
  ul.ant-menu {
    li.ant-menu-item {
      display: flex;
      .menu-role_title {
        flex-grow: 1;
      }
      span.ant-menu-title-content {
        display: flex;
        width: 100%;
      }
    }
  }
`;

export default MenuRoleStyle;
