import React from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import { Body } from "./components/commons";
import {
  Dashboard,
  Banner,
  LoginPage,
  NotFoundPage,
  ContactUs,
  CustomerEdit,
  CustomerPage,
  CustomerView,
  PointsRewards,
  SpicyRewards,
  OnlineActivity,
  ArtistsPage,
  ArtistsSticky,
  CheckInEvent,
  ManualAddPage,
  CreateManualAdd,
  CreateSpicyRewards,
  CreateCheckInEvent,
  CreateArtists,
  ViewSpicyRewards,
  ViewOnlineActivity,
  EditCheckInEvent,
  ViewCheckInEvent,
  EditArtists,
  ViewArtists,
  UserPage,
  EditSpicyRewards,
  CreateUser,
  EditUser,
  CampaignCreate,
  EditOnlineActivity,
  SendCoupon,
  ViewManualAdd,
  CustomerTrash,
  PasswordForgetPage,
  ChangePassword,
  UserLog,
  CustomerMiles,
  Miles,
  MilesCreate,
  UserTransactions,
  RegistrationReport,
  NewsDataTable,
  NewsStickyDataTable,
  NewsCreate,
  MediaDataTable,
  SongDataTable,
  SongCreate,
  SongsEdit,
  SongView,
  PortfolioList,
  SongGenresDataTable,
  SongNewReleseDataTable,
  SongWhatHotDataTable,
  Journey,
  AuditionDataTable,
  AuditionPromote,
  AuditionView,
  BannerHome,
  BannerMusic,
  EventDataTable,
  EventCreate,
  EventView,
  Modules,
  RolePesmission,
  AlbumsCreate,
  AlbumsDataTable,
  AlbumsEdit,
  NewsEdit,
  EventsPromote,
  EventEdit,
} from "./components/pages";

export const Routes = {
  dashboard: "/dashboard",

  banner: "/banner",
  bannerMusic: "/banner-music",

  login: "/",

  customer: "/customer",
  deleteAccount: "/delete-account-customer",

  CustomerEdit: "/customer/:customerId/edit",
  CustomerView: "/customer/:customerId",
  pointsRewards: "/customer/:customerId/points-rewards",
  profileMiles: "/customer/:customerId/miles",

  spicyRewards: "/voucher",
  createSpicyRewards: "/voucher/create",
  viewSpicyRewards: "/voucher/:voucherId",
  editSpicyRewards: "/voucher/:voucherId/edit",

  sendCoupon: "/voucher/:voucherId/send-coupon",

  manualAdd: "/manual-add",
  createManualAdd: "/manual-add/create",
  viewManualAdd: "/manual-add/:manualAddId",

  onlineActivity: "/campaign",
  viewOnlineActivity: "/campaign/:campaignId",
  createOnlineActivity: "/campaign/create",
  editOnlineActivity: "/campaign/:campaignId/edit",

  checkInEvent: "/check-in-event",
  viewCheckInEvent: "/check-in-event/:checkInEventId",
  createCheckInEvent: "/check-in-event/create",
  editCheckInEvent: "/check-in-event/:checkInEventId/edit",

  artists: "/artists",
  artistsSticky: "/artists/sticky",
  artistsDetail: "/artists/:artistsId",
  createArtists: "/artists/create",
  editArtists: "/artists/:artistsId/edit",

  albums: "/albums",
  createAlbums: "/albums/create",
  editAlbums: "/albums/:albumsId/edit",

  userPage: "/user",
  createUser: "/user/create",
  editUser: "/user/:userId",
  resetPass: "/foget-password",
  changePass: "/change-password",

  manualMiles: "/manual-miles",
  createManualMiles: "/manual-miles/create",

  userlog: "/userlog",

  userTransactions: "/user-transactions",
  registerReport: "/register-report",

  newDataTable: "/news",
  newsSticky: "/news/sticky",
  newCreate: "/news/create",
  newEdit: "/news/:newsId/edit",

  mediaDataTable: "/media",

  songCreate: "/songs/create",
  songDataTable: "/songs",
  songView: "/songs/:songId",
  songEdit: "/songs/:songId/edit",

  songGenresDataTable: "/genre",
  songNewReleseDataTable: "/new-release",
  songWhatHotDataTable: "/what-hot",

  portfolioList: "/portfolio",

  contactUs: "/contact-us",

  auditionDataTable: "/audition",
  auditionPromote: "/audition/promote",
  auditionView: "/audition/:auditionId/view",

  journey: "/journey",

  eventDataTable: "/events",
  eventPromote: "/events/promote",
  eventCreate: "/events/create",
  eventEdit: "/events/:eventId/edit",
  eventView: "/events/:eventId/view",

  bannerHome: "/banner-home",
  modules: "/modules",

  rolePesmission: "/role",
};

class App extends React.Component {
  render() {
    const LoginRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          !localStorage.getItem("refreshToken") ? (
            <Component {...props} />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />
    );
    const SecuredRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          localStorage.getItem("refreshToken") ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
    return (
      <Body>
        <Router>
          <Switch>
            <Route path={Routes.resetPass} component={PasswordForgetPage} />
            <SecuredRoute path={Routes.changePass} component={ChangePassword} />
            <SecuredRoute path={Routes.dashboard} component={Dashboard} />
            <LoginRoute exact path={Routes.login} component={LoginPage} />
            <SecuredRoute path={Routes.banner} component={Banner} />

            <SecuredRoute
              exact
              path={Routes.customer}
              component={CustomerPage}
            />
            <SecuredRoute
              path={Routes.deleteAccount}
              component={CustomerTrash}
            />
            <SecuredRoute
              path={Routes.pointsRewards}
              component={PointsRewards}
            />
            <SecuredRoute exact path={Routes.contactUs} component={ContactUs} />
            <SecuredRoute
              exact
              path={Routes.CustomerEdit}
              component={CustomerEdit}
            />
            <SecuredRoute
              exact
              path={Routes.CustomerView}
              component={CustomerView}
            />
            <SecuredRoute
              path={Routes.profileMiles}
              component={CustomerMiles}
            />
            <SecuredRoute
              exact
              path={Routes.spicyRewards}
              component={SpicyRewards}
            />
            <SecuredRoute
              path={Routes.createSpicyRewards}
              component={CreateSpicyRewards}
            />
            <SecuredRoute
              path={Routes.editSpicyRewards}
              component={EditSpicyRewards}
            />
            <SecuredRoute
              exact
              path={Routes.sendCoupon}
              component={SendCoupon}
            />
            <SecuredRoute
              exact
              path={Routes.viewSpicyRewards}
              component={ViewSpicyRewards}
            />
            <SecuredRoute
              exact
              path={Routes.viewSpicyRewards}
              component={ViewSpicyRewards}
            />
            <SecuredRoute
              exact
              path={Routes.manualAdd}
              component={ManualAddPage}
            />
            <SecuredRoute
              path={Routes.createManualAdd}
              component={CreateManualAdd}
            />
            <SecuredRoute
              exact
              path={Routes.viewManualAdd}
              component={ViewManualAdd}
            />

            <SecuredRoute
              exact
              path={Routes.onlineActivity}
              component={OnlineActivity}
            />
            <SecuredRoute
              path={Routes.createOnlineActivity}
              component={CampaignCreate}
            />
            <SecuredRoute
              path={Routes.editOnlineActivity}
              component={EditOnlineActivity}
            />
            <SecuredRoute
              exact
              path={Routes.viewOnlineActivity}
              component={ViewOnlineActivity}
            />
            <SecuredRoute
              exact
              path={Routes.checkInEvent}
              component={CheckInEvent}
            />
            <SecuredRoute
              path={Routes.createCheckInEvent}
              component={CreateCheckInEvent}
            />
            <SecuredRoute
              path={Routes.editCheckInEvent}
              component={EditCheckInEvent}
            />
            <SecuredRoute
              exact
              path={Routes.viewCheckInEvent}
              component={ViewCheckInEvent}
            />

            <SecuredRoute exact path={Routes.artists} component={ArtistsPage} />
            <SecuredRoute
              exact
              path={Routes.artistsSticky}
              component={ArtistsSticky}
            />
            <SecuredRoute
              path={Routes.createArtists}
              component={CreateArtists}
            />
            <SecuredRoute path={Routes.editArtists} component={EditArtists} />
            <SecuredRoute
              exact
              path={Routes.artistsDetail}
              component={ViewArtists}
            />

            <SecuredRoute exact path={Routes.userPage} component={UserPage} />
            <SecuredRoute path={Routes.createUser} component={CreateUser} />
            <SecuredRoute path={Routes.editUser} component={EditUser} />

            <SecuredRoute exact path={Routes.manualMiles} component={Miles} />
            <SecuredRoute
              path={Routes.createManualMiles}
              component={MilesCreate}
            />

            <SecuredRoute exact path={Routes.userlog} component={UserLog} />

            <SecuredRoute
              path={Routes.userTransactions}
              component={UserTransactions}
            />
            <SecuredRoute
              path={Routes.registerReport}
              component={RegistrationReport}
            />

            <SecuredRoute
              exact
              path={Routes.newDataTable}
              component={NewsDataTable}
            />
            <SecuredRoute
              path={Routes.newsSticky}
              component={NewsStickyDataTable}
            />
            <SecuredRoute path={Routes.newCreate} component={NewsCreate} />
            <SecuredRoute path={Routes.newEdit} component={NewsEdit} />

            <SecuredRoute
              exact
              path={Routes.mediaDataTable}
              component={MediaDataTable}
            />

            <SecuredRoute
              exact
              path={Routes.songDataTable}
              component={SongDataTable}
            />
            <SecuredRoute path={Routes.songCreate} component={SongCreate} />
            <SecuredRoute path={Routes.songEdit} component={SongsEdit} />
            <SecuredRoute path={Routes.songView} component={SongView} />
            <SecuredRoute
              exact
              path={Routes.songGenresDataTable}
              component={SongGenresDataTable}
            />
            <SecuredRoute
              exact
              path={Routes.songNewReleseDataTable}
              component={SongNewReleseDataTable}
            />
            <SecuredRoute
              exact
              path={Routes.songWhatHotDataTable}
              component={SongWhatHotDataTable}
            />
            <SecuredRoute
              exact
              path={Routes.portfolioList}
              component={PortfolioList}
            />
            <SecuredRoute
              exact
              path={Routes.eventDataTable}
              component={EventDataTable}
            />
            <SecuredRoute path={Routes.eventCreate} component={EventCreate} />
            <SecuredRoute path={Routes.eventEdit} component={EventEdit} />
            <SecuredRoute
              path={Routes.eventPromote}
              component={EventsPromote}
            />
            <SecuredRoute
              exact
              path={Routes.albums}
              component={AlbumsDataTable}
            />
            <SecuredRoute path={Routes.createAlbums} component={AlbumsCreate} />
            <SecuredRoute path={Routes.editAlbums} component={AlbumsEdit} />
            <SecuredRoute
              exact
              path={Routes.bannerMusic}
              component={BannerMusic}
            />
            <SecuredRoute
              exact
              path={Routes.auditionDataTable}
              component={AuditionDataTable}
            />
            <SecuredRoute path={Routes.auditionView} component={AuditionView} />
            <SecuredRoute
              path={Routes.auditionPromote}
              component={AuditionPromote}
            />

            <SecuredRoute
              exact
              path={Routes.bannerHome}
              component={BannerHome}
            />
            <SecuredRoute exact path={Routes.modules} component={Modules} />

            <SecuredRoute exact path={Routes.journey} component={Journey} />
            <SecuredRoute exact path={Routes.eventView} component={EventView} />

            <SecuredRoute
              exact
              path={Routes.rolePesmission}
              component={RolePesmission}
            />

            <Route exact path="*" component={NotFoundPage} />
          </Switch>
        </Router>
      </Body>
    );
  }
}

export default App;
