import React, { Component } from "react";
import { Spin, Button, Switch } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { Layout } from "../../containers";
import { AdvancedTableSearch } from "../../views";
import { userAction } from "../../../store/action";
import { auditionApi } from "../../../apis";

class AuditionDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      check_token: false,
      data: [],
    };
  }

  componentDidMount() {
    this.checkToken();
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await this.setState({ loading: true });
    const { start, length, search } = this.state;
    await auditionApi.AuditionTable(start, length, search).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        data: resp.data,
        total: resp.recordsTotal,
        loading: false,
      }));
    });
  };

  handleSearch = (typeFilter) => {
    this.setState({ loading: true });
    const { length, search, typeSearch } = this.state;
    auditionApi
      .AuditionTable(0, length, search, typeSearch)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          loading: false,
          typeFilter,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handlePaggination = (start, length) => {
    this.setState({ loading: true });
    const { search, typeSearch } = this.state;
    auditionApi
      .AuditionTable(start, length, search, typeSearch)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
          start,
          length,
          search,
          loading: false,
        })).catch((error) => {
          this.setState({ loading: false });
          throw error;
        });
      });
  };

  onChangeSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  onSticky = (e, id) => {
    auditionApi.updateStickyAudition(id).then(() => {
      Swal.fire({
        icon: "success",
        title: `Success`,
        timer: 1500,
      }).then(() => {
        this.setState({
          loading: false,
        });
        this.componentDidMount();
      });
    });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[10].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, data, total, check_token } = this.state;
    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        id: "title",
        type: "input",
        value: "",
      },
      {
        title: "Youtube url",
        dataIndex: "url",
        key: "url",
        type: "input",
        value: "all",
        render: (text) => (
          <span>{text.length < 30 ? text : `${text.slice(0, 30)}...`}</span>
        ),
      },
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        type: "input",
        value: "all",
      },
      {
        title: "Send date",
        dataIndex: "date",
        key: "date",
        type: "date",
        value: "",
        selectData: [],
        onChange: () => console.log("ok"),
        render: (text, record) => <span>{record.send_date}</span>,
      },
      {
        title: "Promote",
        dataIndex: "promote",
        key: "promote",
        type: "select",
        value: "all",
        render: (text, record) =>
          record.promote === 1 ? (
            <Switch
              disabled={isEdit[0] === 1 ? false : true}
              onChange={(e) => this.onSticky(e, record.id)}
              defaultChecked
            />
          ) : (
            <Switch
              disabled={isEdit[0] === 1 ? false : true}
              onChange={(e) => this.onSticky(e, record.id)}
            />
          ),
      },
      {
        title: "Actions",
        key: "action",
        render: (record) => (
          <Link to={`/audition/${record.id}/view`}>View</Link>
        ),
      },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="Audition"
            extraBtn={
              isEdit[0] === 1
                ? [
                    <Button
                      type="primary"
                      onClick={() =>
                        this.props.history.push("/audition/promote")
                      }
                    >
                      Promote
                    </Button>,
                  ]
                : []
            }
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "audition",
                breadcrumbName: "Audition",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <AdvancedTableSearch
                nonExport
                noFillter
                fileName="audition"
                columns={columns}
                data={data}
                handleSearch={(value) => this.handleSearch(value)}
                onChangeSearch={(e) =>
                  this.setState({ search: e.target.value })
                }
                loading={loading}
                searchCondition={["date", "action", "url", "promote"]}
                onChangeTypeSearch={(value) => {
                  this.setState({
                    typeSearch: value === "title" ? 1 : 2,
                  });
                }}
                rowKey={(record) => record.id}
                total={total}
                onTableChange={this.handlePaggination}
              />
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditionDataTable);
