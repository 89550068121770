// eslint-disable-next-line max-classes-per-file
import React, { Component } from "react";
import { Spin, Table, Switch } from "antd";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { arrayMove } from "react-sortable-hoc";
import Swal from "sweetalert2";
import { Layout } from "../../containers";
import { userAction } from "../../../store/action";
import { auditionApi } from "../../../apis";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";

let dragingIndex = -1;

class BodyRow extends Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += " drop-over-downward";
      }
      if (restProps.index < dragingIndex) {
        className += " drop-over-upward";
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />,
      ),
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex).then();

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);

class AuditionDataTable extends Component {
  constructor(props) {
    super(props);
    this.components = {
      body: {
        row: DragableBodyRow,
      },
    };
    this.state = {
      loading: false,
      check_token: false,
      visible: false,
      data: [],
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await this.setState({ loading: true });
    const { start, length, search } = this.state;
    await auditionApi
      .AuditionPromoteTable(start, length, search)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
          loading: false,
        }));
      });
  };

  handlePaggination = (start, length) => {
    this.setState({ loading: true });
    auditionApi.AuditionPromoteTable(start, length).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        data: resp.data,
        total: resp.recordsTotal,
        start,
        length,
        loading: false,
      })).catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    });
  };

  onSticky = (e, id) => {
    auditionApi.updateStickyAudition(id).then(() => {
      Swal.fire({
        icon: "success",
        title: `Success`,
        timer: 1500,
      }).then(() => {
        this.setState({
          loading: false,
        });
        this.componentDidMount();
      });
    });
  };

  moveRow = async (oldIndex, newIndex) => {
    await this.setState({
      data: arrayMove(this.state.data, oldIndex, newIndex),
    });
    const list = [];
    for (let i = 0; i < this.state.data.length; i += 1) {
      list.push({
        id: this.state.data[i].id,
        index: i + 1,
      });
    }
    auditionApi.indexAudition({ obj: list }).catch((error) => {
      throw error;
    });
  };

  render() {
    const { loading, check_token } = this.state;

    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        id: "title",
      },
      {
        title: "Youtube url",
        dataIndex: "url",
        key: "url",
        render: (text) => (
          <span>{text.length < 30 ? text : `${text.slice(0, 30)}...`}</span>
        ),
      },
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        align: "right",
      },
      {
        title: "Promote",
        dataIndex: "promote",
        key: "promote",
        align: "conter",
        render: (text, record) => (
          <Switch
            onChange={(e) => this.onSticky(e, record.id)}
            defaultChecked
          />
        ),
      },
      {
        title: "Index",
        dataIndex: "index",
        key: "index",
        align: "right",
        render: (text, record, index) => <span>{index + 1}</span>,
      },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="Promote"
            extraBtn={[]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "audition",
                breadcrumbName: "Audition",
              },
              {
                path: "promote",
                breadcrumbName: "Promote",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <DndProvider backend={HTML5Backend}>
                <Table
                  className="mt-4"
                  rowKey={(record) => record.id}
                  columns={columns}
                  dataSource={this.state.data}
                  components={this.components}
                  onRow={(record, index) => ({
                    index,
                    moveRow: this.moveRow,
                  })}
                  pagination={{
                    defaultPageSize: 20,
                    showQuickJumper: true,
                    total: this.state.total,
                    defaultCurrent: 1,
                    onChange: (page, length) => {
                      this.handlePaggination(
                        (page - 1) * length,
                        length,
                        "",
                        page,
                        length,
                      );
                    },
                    showTotal: (total) => `รายการทั้งหมด ${total} รายการ`,
                    pageSizeOptions: ["10", "20", "30"],
                    onShowSizeChange: (page, length) => {
                      this.handlePaggination(0 * length, length, "", 1, length);
                    },
                  }}
                />
              </DndProvider>
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.users ? state.users.role : 0,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AuditionDataTable);
