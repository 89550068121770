import React from "react";
import { DynamicBarChart } from "react-dynamic-charts";
// import {LineChart} from "@opd/g2plot-react";
import styled from "styled-components";
import PropTypes from "prop-types";

export const CardGraphStyle = styled.div`
  .live-chart {
    padding: 5px 20px;
  }

  .live-chart .chart {
    margin: auto;
  }

  .live-chart h1 {
    padding: 0;
  }
  .live-chart .bar-wrapper {
    padding-left: 70px;
  }

  .live-chart .bar-wrapper label {
    width: auto;
  }

  .live-chart .bar-wrapper .bar {
    max-height: 40px;
    @media (min-width: 768px) {
      max-height: 25px;
    }
    @media (min-width: 992px) {
      max-height: 30px;
    }
    @media (min-width: 1280px) {
      max-height: 35px;
    }
  }
`;

const CardGraph = ({ data }) => (
    <CardGraphStyle>
      <DynamicBarChart data={data} />
      {/* <LineChart {...config} /> */}
    </CardGraphStyle>
  );

CardGraph.propTypes = {
  data: PropTypes.array.isRequired,
  // config: PropTypes.object.isRequired,
};
CardGraph.defaultProps = {
  className: "",
};

export default CardGraph;
