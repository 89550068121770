import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Avatar,
  Image,
  Modal,
  Tabs,
  PageHeader,
  Descriptions,
} from "antd";
import { PrinterOutlined, UploadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Layout } from "../../containers";
import { DateTimeMid, TagStatus, PageTable, PrintQrCode } from "../../commons";
import { eventsApi } from "../../../apis";
import { userAction } from "../../../store/action";

const { TabPane } = Tabs;
const { confirm } = Modal;

class ViewCheckInEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleQr: false,
      visibleImgQR: false,
      visiblePdfQrCode: false,
      visiblePdfCode: false,
      visiblePdfQr: false,
      valueRadio: 0,
      start: 0,
      length: 20,
      search: "",
      total: 0,
      totalCustomer: 0,
      codeQr: "",
      loadingsOfficial: [],
      dataGenerateCode: [],
      dataCustomer: [],
      dataPrintCustomer: [],
      modalQrCodeName: "",
      modalImgQrCode: "",
      imageUrl: null,
      nameEvent: "",
      descEvent: "",
      pointsEvent: "",
      typeEvent: "",
      statusEvent: false,
      amountEvent: "",
      placeEvent: "",
      amount_used: "",
      start_date: "",
      end_date: "",
      loadingDetails: false,
      loadingGenerateCode: false,
      loadingReport: false,
      visibleQR: false,
      check_token: false,
    };
    this.componentRef = React.createRef();
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    this.setState({
      loadingDetails: true,
      loadingGenerateCode: true,
      loadingReport: true,
    });
    const id = this.props.match.params.checkInEventId;
    const setData = (doc) => {
      console.log(doc);
      const statusA = doc.status === 0 ? false : true;
      this.setState({
        id: doc.id,
        // events_details
        amountEvent: doc.events_details && doc.events_details.amount,
        amount_used: doc.events_details && doc.events_details.amount_used,
        modalImgQrCode: doc.events_details && doc.events_details.code,
        descEvent: doc.events_details && doc.events_details.detail,
        end_date: doc.events_details && doc.events_details.end_date,
        placeEvent: doc.events_details && doc.events_details.place,
        start_date: doc.events_details && doc.events_details.start_date,
        // end obj events_details
        imageUrl: doc.image_m,
        modalQrCodeName: doc.name,
        nameEvent: doc.name,
        pointsEvent: doc.points,
        statusEvent: statusA,
        typeEvent: doc.type,
        loadingDetails: false,
      });
    };
    const eventCheckById = eventsApi.eventCheckById(id).then((response) => {
      setData(response.data);
    });
    await Promise.all([eventCheckById]);
    this.getReport(id);
    // await this.setState({ loading: false });
  };

  showModalImgQR = () => {
    this.setState((prevState) => ({
      ...prevState,
      visibleQR: !prevState.visibleQR,
    }));
  };

  handleOk = async () => {
    const id = this.props.match.params.checkInEventId;
    await this.setState({
      visible: false,
    });

    await eventsApi.deleteEventCheckIn(id).then((resp) => {
      Swal.fire({
        icon: "success",
        title: `${resp.message}`,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        this.props.history.push("/check-in-event");
      });
    });
  };

  getReport = async (id) => {
    this.setState({
      loadingDetails: true,
      loadingGenerateCode: true,
      loadingReport: true,
    });
    //   let id = this.props.location.state.id;
    const { start, search, length } = this.state;
    const eventCheckInCustomer = eventsApi
      .eventCheckInCustomer(start, length, search, id)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          dataCustomer: resp.data,
          totalCustomer: resp.recordsTotal,
          loadingReport: false,
        }));
      });
    const eventCheckInPrintCustomer = eventsApi
      .eventCheckInPrintCustomer(id)
      .then((resp) => {
        const data = [];
        resp.data.forEach((element) => {
          data.push({
            created_at: element.created_at,
            address: element.customer.address,
            birthdate: element.customer.birthdate,
            email: element.customer.email,
            fullname: element.customer.fullname,
            gender: element.customer.gender,
            telephone: element.customer.telephone,
            amphure:
              element.customer.amphure && element.customer.amphure.name_th,
            district:
              element.customer.district && element.customer.district.name_th,
            zip_code:
              element.customer.district && element.customer.district.zip_code,
            province:
              element.customer.province && element.customer.province.name_th,
            code: element.events_qrcode.code,
          });
          this.setState((prevState) => ({
            ...prevState,
            dataPrintCustomer: data,
            loadingReport: false,
          }));
        });
      });
    await Promise.all([eventCheckInCustomer, eventCheckInPrintCustomer]);
  };

  showModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      visible: !prevState.visible,
    }));
  };

  enterLoading = (index) => {
    const id = this.props.match.params.checkInEventId;
    eventsApi.eventCheckInLineOffical(id).then((resp) => {
      Swal.fire({
        icon: "success",
        title: resp.message,
        text: "แจ้งเตือนไปยัง official account เรียบร้อย",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        this.setState(({ loadingsOfficial }) => {
          const newLoadings = [...loadingsOfficial];
          newLoadings[index] = true;
          return {
            loadingsOfficial: newLoadings,
          };
        });
      });
    });
    setTimeout(() => {
      this.setState(({ loadingsOfficial }) => {
        const newLoadings = [...loadingsOfficial];
        newLoadings[index] = false;
        return {
          loadingsOfficial: newLoadings,
        };
      });
    }, 10000);
  };

  handleClick = () => {
    const id = this.props.match.params.checkInEventId;
    this.props.history.push(`/check-in-event/${id}/edit`);
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.props.history.push("/check-in-event");
    this.setState({
      visible: false,
    });
  };

  handleCancelQr = () => {
    this.setState({
      visibleQr: false,
    });
  };

  handleCancelImgQR = () => {
    this.setState((prevState) => ({
      ...prevState,
      visibleImgQR: !prevState.visibleImgQR,
    }));
  };

  onChange = (e) => {
    this.setState({
      valueRadio: e.target.value,
    });
  };

  onChangeSwitch = (checked) => {
    this.setState({
      statusEvent: checked,
    });
    const id = this.props.match.params.checkInEventId;
    const statusEvent = checked === false ? 0 : 1;

    eventsApi
      .eventCheckInStatusUpdate(id, statusEvent)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "success",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  handlePaggination = (start, length, search) => {
    this.setState({ loadingReport: true });
    this.setState((prevState) => ({
      ...prevState,
      start,
      length,
      search,
    }));
    // const { id } = this.props.location.state;
    const id = this.props.match.params.checkInEventId;
    eventsApi.eventCheckInCustomer(start, length, search, id).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        dataCustomer: resp.data,
        totalCustomer: resp.recordsTotal,
        loadingReport: false,
      }));
    });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[18].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    moment.locale("th");
    const {
      modalImgQrCode,
      statusEvent,
      descEvent,
      imageUrl,
      nameEvent,
      pointsEvent,
      start_date,
      end_date,
      placeEvent,
      dataCustomer,
      page,
      size,
      dataPrintCustomer,
      check_token,
    } = this.state;
    console.log(this.state);
    console.log(`start_date: ${start_date}`);
    console.log(`end_date: ${end_date}`);
    const columnsCheckInEvent = [
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
        render: (text, record) => (
          <span>{record.events_qrcode && record.events_qrcode.code}</span>
        ),
      },
      {
        title: "Redeem date",
        dataIndex: "created_at",
        key: "created_at",
        render: (text, record) => <span>{record.created_at}</span>,
      },
      {
        title: "Customer",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Fragment key={record}>
            <Avatar size={48} src={record.customer && record.customer.image} />
            <span className="ml-3">
              {record.customer && record.customer.fullname}
            </span>
          </Fragment>
        ),
      },
    ];

    const headers = [
      { label: "Name", key: "fullname" },
      { label: "Gender", key: "gender" },
      { label: "Email", key: "email" },
      { label: "Birthdate", key: "birthdate" },
      { label: "Age", key: "age" },
      { label: "Telephone", key: "telephone" },
      { label: "Address", key: "address" },
      { label: "Sub District", key: "district" },
      { label: "District", key: "amphure" },
      { label: "Province", key: "province" },
      { label: "Zip Code", key: "zip_code" },
      { label: "Date / Time", key: "created_at" },
      { label: "Code", key: "code" },
    ];

    const id = this.props.match.params.checkInEventId;
    return (
      <>
        {check_token === true && (
          <Layout
            title="Check-in detail"
            extraBtn={[
              <Button
                style={{ display: isEdit[0] === 1 ? "" : "none" }}
                type="primary"
                onClick={this.handleClick}
              >
                Edit
              </Button>,
              <Button
                style={{ display: isDelete[0] === 1 ? "" : "none" }}
                type="primary"
                onClick={() => {
                  confirm({
                    title: "ต้องการลบข้อมูลใช่หรือไม่?",
                    centered: true,
                    onOk: () => this.handleOk(),
                  });
                }}
                danger
              >
                Delete
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "check-in-event",
                breadcrumbName: "Check in",
              },
              {
                path: `${id}`,
                breadcrumbName: "Check in detail",
              },
            ]}
          >
            <Row gutter={[16, 16]}>
              <Col md={6} className="text-center">
                <Image width="100%" src={imageUrl} />
                <TagStatus
                  className="mt-4"
                  StatusValue={statusEvent === false ? 0 : 1}
                  onClick={this.onChangeSwitch}
                />
              </Col>
              <Col md={18}>
                <Descriptions title={nameEvent} column={1}>
                  <Descriptions.Item
                    label="Display date"
                    labelStyle={{ fontWeight: "bold" }}
                  >
                    <>
                      {start_date === end_date ? (
                        <>
                          <DateTimeMid
                            dateTime={moment(start_date, "DD/MM/YYYY")}
                          />
                        </>
                      ) : (
                        <>
                          <DateTimeMid
                            dateTime={moment(start_date, "DD/MM/YYYY")}
                          />
                          <span className="mx-2">-</span>
                          <DateTimeMid
                            dateTime={moment(end_date, "DD/MM/YYYY")}
                          />
                        </>
                      )}
                    </>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Location"
                    labelStyle={{ fontWeight: "bold" }}
                  >
                    {placeEvent}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Point ที่ได้"
                    labelStyle={{ fontWeight: "bold" }}
                  >
                    + {pointsEvent} Points
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Code"
                    labelStyle={{ fontWeight: "bold" }}
                  >
                    {modalImgQrCode}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Tabs
              className="customer-tabs"
              defaultActiveKey="1"
              onChange={this.callback}
            >
              <TabPane tab="Description" key="desc">
                <Card>
                  {descEvent && (
                    <div dangerouslySetInnerHTML={{ __html: descEvent }} />
                  )}
                </Card>
              </TabPane>
              <TabPane tab="Report" key="report">
                <PageHeader
                  className="title-heading-extra-icon"
                  ghost={false}
                  title={`จำนวน Check-in ${dataCustomer.length} คน`}
                  extra={[
                    <ReactToPrint
                      key="2"
                      trigger={() => (
                        <Button type="primary" icon={<PrinterOutlined />}>
                          Print QRcode
                        </Button>
                      )}
                      content={() => this.Ref}
                    />,
                    <CSVLink
                      data={dataPrintCustomer}
                      filename={`${nameEvent}-event(points)-report-${moment().format(
                        "DD MM YYYY",
                      )}.xls`}
                      headers={headers}
                      target="_blank"
                    >
                      <Button type="primary" icon={<UploadOutlined />}>
                        Export to Excel
                      </Button>
                    </CSVLink>,
                  ]}
                />
                <div
                  id="qr-code"
                  className="w-100 vh-100 d-none text-center mx-auto"
                >
                  <PrintQrCode
                    ref={(el) => (this.Ref = el)}
                    code={`${process.env.REACT_APP_LIF}${modalImgQrCode}`}
                    title={nameEvent}
                  />
                </div>
                <PageTable
                  rowKey={(record) => record.created_at}
                  columns={columnsCheckInEvent}
                  data={dataCustomer}
                  total={this.state.totalCustomer}
                  onChange={this.handlePaggination}
                  page={page}
                  size={size}
                />
              </TabPane>
            </Tabs>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCheckInEvent);
