import React from "react";
import { Avatar, Card, Col, List, Row, Spin } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";

const CardActivityStyle = styled(Card)`
  margin-bottom: 3rem;
  @media (min-width: 768px) {
    margin-bottom: 1rem;
  }
  @media (min-width: 1280px) {
    margin-bottom: 2rem;
  }

  .ant-card-head {
    border: 0;
    .ant-card-head-title,
    .ant-card-extra {
      padding-top: 25px;
      font-weight: bold;
    }
  }
  .ant-card-body {
    padding: 0 10px 30px 30px;
    //padding: 0 30px 30px;
    //
    //@media (min-width: 768px) {
    //  padding: 0 25px 35px;
    //}
    //@media (min-width: 992px) {
    //  padding: 0 30px 35px;
    //}
  }
  .empty {
    img {
      height: auto !important;
    }
  }

  .demo-infinite-container {
    //border: 1px solid #e8e8e8;
    //border-radius: 4px;
    overflow: auto;
    //padding: 8px 24px;
    padding-right: 10px;
    height: 209px;
  }

  .demo-loading-container {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
  }

  .demo-infinite-container-voucher {
    //border: 1px solid #e8e8e8;
    //border-radius: 4px;
    overflow: auto;
    //padding: 8px 24px;
    padding-right: 10px;
    height: 300px;
  }

  .img-dafault-empty-center {
    position: relative;
    height: 100%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const CardDataStyle = styled(Card)`
  margin-top: 10px;

  .ant-card-body {
    padding: 5px;

    @media (min-width: 768px) {
      padding: 0;
    }
    @media (min-width: 992px) {
      padding: 5px;
    }
  }
`;
const CardActivity = ({
  title,
  dataActivity,
  linkTo,
  loadMore,
  loading,
  hasMore,
  className,
// eslint-disable-next-line arrow-body-style
}) => {
  return (
    <CardActivityStyle title={title} className="border-0">
      <div className={className}>
        {dataActivity.length > 0 ? (
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={loadMore}
            hasMore={!loading && hasMore}
            useWindow={false}
          >
            <List
              dataSource={dataActivity}
              renderItem={(item) => (
                <CardDataStyle key={item.id}>
                  <Row gutter={[8, 8]} align="middle" className="m-0">
                    <Col span={8}>
                      <Link className="text-muted" to={`${linkTo}/${item.id}`}>
                        <Avatar size={64} src={item.image} shape="square" />
                      </Link>
                    </Col>
                    <Col span={16}>
                      <Link className="text-muted" to={`${linkTo}/${item.id}`}>
                        {item.label}
                      </Link>
                    </Col>
                  </Row>
                </CardDataStyle>
              )}
            >
              {loading && hasMore && (
                <div className="demo-loading-container">
                  <Spin />
                </div>
              )}
            </List>
          </InfiniteScroll>
        ) : (
          <div className="empty img-dafault-empty-center">
            <Avatar
              className="text-center"
              shape="square"
              src="/assets/images/dafault/empty.png"
              size={150}
            />
            <p className="mb-0">ยังไม่มีข้อมูล</p>
          </div>
        )}
      </div>
    </CardActivityStyle>
  );
};

CardActivity.propTypes = {
  title: PropTypes.string.isRequired,
  dataActivity: PropTypes.array.isRequired,
  linkTo: PropTypes.string,
  loadMore: PropTypes.func,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
};
CardActivity.defaultProps = {
  className: "",
};
export default CardActivity;
