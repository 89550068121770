import React from "react";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import DualListBox from "react-dual-listbox";
import PropTypes from "prop-types";
import styled from "styled-components";

export const ListBoxStyle = styled.div`
  margin-top: 2.5em;

  .react-dual-listbox {
    height: 500px;
  }
`;

const ListBox = ({ options, custonText, selected, onChange }) => (
  <ListBoxStyle>
    <DualListBox
      options={options}
      selected={selected}
      onChange={onChange}
      canFilter
      lang={custonText}
      showHeaderLabels
      icons={{
        moveLeft: <LeftOutlined />,
        moveAllLeft: <DoubleLeftOutlined />,
        moveRight: <RightOutlined />,
        moveAllRight: <DoubleRightOutlined />,
      }}
    />
  </ListBoxStyle>
);
ListBox.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.array,
  onChange: PropTypes.func,
  custonText: PropTypes.object,
};
ListBox.defaultProps = {
  className: "",
};
export default ListBox;
