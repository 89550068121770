import React, { Component } from "react";
import { Col, Form, Row, Spin, Button, Input, Card, Select, Radio } from "antd";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { Layout } from "../../containers";
import { userApi } from "../../../apis";

const { Option } = Select;

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleUser: [],
      pageTitle: "",
      nameUser: "",
      userEmail: "",
      loading: false,
      check_token: false,
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  handleChangeInput(changeObject) {
    this.setState(changeObject);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const id = this.props.match.params.userId;
    await this.setState({
      loading: true,
    });
    const setData = (doc) => {
      this.setState({
        pageTitle: doc.name,
        nameUser: doc.name,
        userEmail: doc.email,
        role: doc.role_id,
        status: doc.status,
        uuid: doc.uuid,
        loading: false,
      });
    };
    userApi
      .RoleList()
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          roleUser: resp.data,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    userApi
      .getUser(id)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleChangeRoleUser = (value) => {
    this.setState({
      role: value,
    });
  };

  handleCancel = () => {
    this.props.history.push("/user");
  };

  render() {
    const { loading, roleUser, pageTitle, check_token } = this.state;
    const onFinish = (values) => {
      const data = {
        name: values.name,
        email: values.email,
        password: values.password,
        status: values.status,
        role_id: values.role,
      };

      Swal.fire({
        title: "ต้องการแก้ไขผู้ใช้ระบบใช่หรือไม่",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#0c7973",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: "ระบบกำลังทำงาน",
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              Swal.showLoading();
            },
          });
          userApi
            .userUpdate(this.props.match.params.userId, data)
            .then((resp) => {
              Swal.fire({
                icon: "success",
                title: `${resp.message}`,
                timer: 1500,
              });
              this.props.history.push("/user");
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: `${error.response.data.message}`,
              });
            });
        }
      });
    };

    return (
      <>
        {check_token === true && (
          <Layout
            hasButton={false}
            title="Edit User"
            breadcrumb={[
              {
                id: "0",
                to: "/dashboard",
                title: "Dashboard",
              },
              {
                id: "1",
                to: "/user",
                title: "User",
              },
              {
                id: "2",
                to: `#`,
                title:
                  pageTitle && pageTitle.length > 20
                    ? `${pageTitle.substring(0, 20)}...`
                    : pageTitle,
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              {loading === false && (
                <Card>
                  <Row>
                    <Col xxl={8} xl={12} xs={24}>
                      <Form onFinish={onFinish} layout="vertical">
                        <Form.Item
                          initialValue={this.state.uuid}
                          name={["uuid"]}
                          label="UUID"
                        >
                          <Input disabled />
                        </Form.Item>
                        <Form.Item
                          name={["name"]}
                          label="Name"
                          initialValue={this.state.nameUser}
                          rules={[
                            { required: true, message: "Name is required!" },
                            {
                              pattern: new RegExp("^[a-zA-Zก-ฮะ-์\\s]*$"),
                              message: "Wrong format!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={["email"]}
                          label="Email"
                          initialValue={this.state.userEmail}
                          rules={[
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              required: true,
                              message: "Please input your E-mail!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={["role"]}
                          label="Role"
                          initialValue={this.state.role}
                          rules={[
                            {
                              required: true,
                              message: "Please select user role!",
                            },
                          ]}
                        >
                          {roleUser.length > 0 && (
                            <Select
                              className="col-12 col-md-12"
                              optionFilterProp="children"
                              onChange={this.handleChangeRoleUser}
                            >
                              {roleUser.map((doc) => (
                                <Option key={doc.id} value={doc.id}>
                                  {doc.name}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                        <Form.Item
                          name={["status"]}
                          label="Status"
                          initialValue={this.state.status}
                          rules={[
                            {
                              required: true,
                              message: "Please select user status!",
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value={1}>Active</Radio>
                            <Radio value={0}>Suspend</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item className="my-3">
                          <Row>
                            <Col span={24}>
                              <Button type="primary" htmlType="submit">
                                Save
                              </Button>
                              <Button
                                className="mx-4"
                                onClick={this.handleCancel}
                              >
                                Cancel
                              </Button>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </Card>
              )}
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

export default EditUser;
