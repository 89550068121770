import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";

import { LoginStyle } from "./style";

const LayoutLogin = (props) => {
  const { title, children } = props;
  return (
    <LoginStyle className="page">
      <div className="login-wrapper">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 48 }} align="middle">
          <Col className="order-2 order-md-1" xs={24} sm={24} md={16}>
            {title}
            {children}
          </Col>
          <Col className="order-1 order-md-2 mb-3" xs={24} sm={24} md={8}>
            <img
              className="img-thumbnail border-0 p-0"
              src="/assets/images/Logo@2x.png"
              alt="SPICYDISC"
            />
          </Col>
        </Row>
      </div>
    </LoginStyle>
  );
};

LayoutLogin.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
};
LayoutLogin.defaultProps = {
  className: "",
  loading: false,
};

export default LayoutLogin;
