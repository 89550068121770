/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { Col, DatePicker, Row, Spin, Card, Avatar, message } from "antd";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { LineChartProps, LineChart } from "@opd/g2plot-react";
import { Layout } from "../../containers";
import { Card5Col, CardActivity, CardAudience } from "../../views";
// import { dashbaordApi, userApi } from "../../../apis";
import { dashbaordApi } from "../../../apis";
import CardGraph, { CardGraphStyle } from "../../views/CardGraph";
const ColGeaph = styled(Col)`
  flex: 0 0 50%;
  max-width: 50%;
  @media (min-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (min-width: 1280px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .empty {
    img {
      height: auto !important;
    }
  }
`;

const ColActivity = styled(Col)`
  flex: 0 0 28%;
  max-width: 28%;
  @media (min-width: 768px) {
    flex: 0 0 55%;
    max-width: 55%;
  }
  @media (min-width: 1280px) {
    flex: 0 0 28%;
    max-width: 28%;
  }
`;

const ColAudience = styled(Col)`
  flex: 0 0 22%;
  max-width: 22%;
  @media (min-width: 768px) {
    flex: 0 0 45%;
    max-width: 45%;
  }
  @media (min-width: 1280px) {
    flex: 0 0 22%;
    max-width: 22%;
  }
`;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check_token: false,
      loadingUsers: false,
      loadingBarGraph: false,
      loadingLineGraph: false,
      loadingActivity: false,
      loadingAudience: false,
      loadingVoucher: false,
      listAudience: [],
      listVoucher: [],
      listActivity: [],
      listAge: [],
      listBar: null,
      listline: [],
      endDate: moment(),
      avg_customer: 0,
      count_customers_news: 0,
      total_customers: 0,
      new_user: 0,
      count_customers_active_event: 0,
      hasMoreActivity: true,
      loadMoreActivity: false,
      loadMoreVoucher: false,
      start: 0,
      length: 5,
      loadingAge: false,

      getDashboardBannner: async (endDate) => {
        await this.setState({ loadingUsers: true });
        await dashbaordApi
          .getDashActivity({ end_date: endDate })
          .then((result) => {
            const { avg_customer } = result.data;
            const { count_customers_active_event } = result.data;
            const { count_customers_news } = result.data;
            const new_user = result.data.count_customers_total_new;
            const { total_customers } = result.data;
            this.setState({
              avg_customer,
              count_customers_active_event,
              count_customers_news,
              total_customers,
              new_user,
            });
          })
          .catch((error) => {
            throw error;
          });
        await this.setState({ loadingUsers: false });
      },
      getDashBoardAudience: async (endDate) => {
        await this.setState({ loadingAudience: true });
        await dashbaordApi
          .getDashBoardAudience({ end_date: endDate })
          .then((result) => {
            const { data } = result;
            const createObject = [];
            for (let i = 0; i < data.length; i += 1) {
              if (i < 10) {
                const obj = {
                  province: data[i].name_th,
                  province_id: data[i].province_id,
                  content: data[i].total,
                  // + "%",
                };
                createObject.push(obj);
              }
            }
            this.setState({ listAudience: createObject });
          })
          .catch((error) => {
            throw error;
          });
        await this.setState({ loadingAudience: false });
      },
      getDashBoardVoucher: async (endDate) => {
        await this.setState({ loadingVoucher: true });
        await dashbaordApi
          .getDashboardVoucher({ end_date: endDate })
          .then((result) => {
            const { data } = result;
            const createObject = [];
            data.forEach((v) => {
              const obj = {
                id: v.id,
                image: v.image_s,
                label: v.name,
                total: v.total,
              };
              createObject.push(obj);
            });
            this.setState({ listVoucher: createObject });
          })
          .catch((error) => {
            throw error;
          });
        await this.setState({ loadingVoucher: false });
      },
      getDashBoardCheckInEvent: async (endDate) => {
        await this.setState({ loadingActivity: true });
        const { start, length } = this.state;
        await dashbaordApi
          .getDashboardCheckInEvent(start, length, endDate)
          .then((result) => {
            const { data } = result;
            const createObject = [];
            data.forEach((v) => {
              const obj = { id: v.id, image: v.image_s, label: v.name };
              createObject.push(obj);
            });
            this.setState({
              listActivity: createObject,
              length: this.state.length + result.data.length,
            });
          })
          .catch((error) => {
            throw error;
          });
        await this.setState({ loadingActivity: false });
      },
      getDashBoardBarGraph: async (endDate) => {
        await this.setState({ loadingBarGraph: true });
        await dashbaordApi
          .getDashboardBarGraph({ end_date: endDate })
          .then((result) => {
            const { data } = result;
            this.setState({ listBar: data });
          })
          .catch((error) => {
            throw error;
          });
        await this.setState({ loadingBarGraph: false });
      },
      getDashBoardLineGraph: async (endDate) => {
        await this.setState({ loadingLineGraph: true });
        dashbaordApi
          .getDashboardLineGraph({ end_date: endDate })
          .then((result) => {
            const { data } = result;
            this.setState({ listline: data, loadingLineGraph: false });
          })
          .catch((error) => {
            throw error;
          });
      },
      getDashBoardAge: async (endDate) => {
        await this.setState({ loadingAge: true });
        await dashbaordApi
          .getDashBoardAge({ end_date: endDate })
          .then((result) => {
            const { data } = result;
            const createObject = [];
            for (let i = 0; i < data.length; i += 1) {
              if (i < 10) {
                const obj = {
                  province: data[i].age_group,
                  content: data[i].age_count,
                };
                createObject.push(obj);
              }
            }
            this.setState({ listAge: createObject });
          })
          .catch((error) => {
            throw error;
          });
        await this.setState({ loadingAge: false });
      },
    };
  }

  componentDidMount() {
    this.checkToken();
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = () => {
    this.setState({
      check_token: true,
    });
    this.state.getDashboardBannner(moment().unix());
    this.state.getDashBoardAudience(moment().unix());
    this.state.getDashBoardVoucher(moment().unix());
    this.state.getDashBoardCheckInEvent(moment().unix());
    this.state.getDashBoardBarGraph(moment().unix());
    this.state.getDashBoardLineGraph(moment().unix());
    this.state.getDashBoardVoucher(moment().unix());
    this.state.getDashBoardAge(moment().unix());
  };

  handdleChageDate = (value) => {
    if (value) {
      this.setState({ endDate: value });
      const endDate = value.unix();
      if (value.unix() > moment().unix()) {
        this.state.getDashboardBannner(moment().unix());
        this.state.getDashBoardAudience(moment().unix());
        this.state.getDashBoardCheckInEvent(moment().unix());
        this.state.getDashBoardBarGraph(moment().unix());
        this.state.getDashBoardLineGraph(moment().unix());
        this.state.getDashBoardVoucher(moment().unix());
      } else {
        this.state.getDashboardBannner(endDate);
        this.state.getDashBoardAudience(endDate);
        this.state.getDashBoardCheckInEvent(endDate);
        this.state.getDashBoardBarGraph(endDate);
        this.state.getDashBoardLineGraph(endDate);
        this.state.getDashBoardVoucher(endDate);
      }
    } else {
      this.state.getDashboardBannner(moment().unix());
      this.state.getDashBoardAudience(moment().unix());
      this.state.getDashBoardCheckInEvent(moment().unix());
      this.state.getDashBoardBarGraph(moment().unix());
      this.state.getDashBoardLineGraph(moment().unix());
      this.state.getDashBoardVoucher(moment().unix());
    }
  };

  handleInfiniteOnLoadVoucher = () => {
    this.setState({
      loadMoreVoucher: false,
    });
  };

  handleInfiniteOnLoadActivity = () => {
    const { loadMoreActivity, length, listActivity } = this.state;
    this.setState({
      loadMoreActivity: true,
    });
    if (loadMoreActivity !== true) {
      dashbaordApi
        .getDashboardCheckInEvent(0, length, moment().unix())
        .then((result) => {
          if (listActivity.length === result.recordsTotal) {
            message.warning("ไม่มีกิจกรรมที่กำลังดำเนินการ");
            this.setState({
              loadMoreActivity: false,
              hasMoreActivity: false,
            });
            return;
          }
          if (result.recordsTotal > this.state.length) {
            const { data } = result;
            const createObject = [];
            data.forEach((v) => {
              const obj = { id: v.id, image: v.image_s, label: v.name };
              createObject.push(obj);
            });
            this.setState({
              listActivity: createObject,
              loadMoreActivity: false,
              start: this.state.length + result.data.length,
            });
          } else {
            const { data } = result;
            const createObject = [];
            data.forEach((v) => {
              const obj = { id: v.id, image: v.image_s, label: v.name };
              createObject.push(obj);
            });
            this.setState({
              loadMoreActivity: false,
              listActivity: createObject,
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  render() {
    const {
      avg_customer,
      count_customers_active_event,
      count_customers_news,
      total_customers,
      new_user,
      listAudience,
      listVoucher,
      listActivity,
      listBar,
      loadingUsers,
      check_token,
      loadingBarGraph,
      loadingLineGraph,
      loadingActivity,
      loadingVoucher,
      loadingAudience,
      hasMoreActivity,
      hasMoreVoucher,
      loadMoreActivity,
      loadMoreVoucher,
      loadingAge,
      listAge,
    } = this.state;
    const data = [
      {
        values: [
          {
            id: 1,
            label: "ผู้ชาย",
            value: 0,
            color: ["#FF7F00", "#F7B604"],
          },
          {
            id: 2,
            label: "ผู้หญิง",
            value: 0,
            color: ["#00C3F2", "#04DFA0"],
          },
          {
            id: 3,
            label: "ไม่ระบุ",
            value: 0,
            color: ["#876aa4", "#b579a6"],
          },
        ],
      },
    ];

    const data2 = [
      { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
      { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
      { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
      { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
      { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
      { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
      { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
    ];

    const getBarGraph = ({
      data,
      dataapi,
      // listline = []
    }) => {
      const newData = data[0];
      if (dataapi) {
        for (let index = 0; index < newData.values.length; index += 1) {
          if (newData.values[index].id === dataapi[index].gender) {
            newData.values[index].value = dataapi[index].total;
          }
        }
        return <CardGraph data={data} />;
      }
      // return <CardGraph data={data} config={config1} />
    };

    const getLineGraph = ({ listline = [] }) => {
      // console.log('listline', listline)
      if (listline.length !== 0) {
        const config1: LineChartProps = {
          title: {
            visible: true,
            text: "People activity",
          },
          description: {
            visible: false,
          },
          padding: "auto",
          forceFit: true,
          data: listline,
          xField: "date",
          yField: "value",
          yAxis: {
            label: {
              // ค่าถูกจัดรูปแบบเป็นหลักพัน
              // formatter: (v) =>
              //   `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
          },
          legend: {
            position: "right-top",
          },
          seriesField: "type",
          color: ["#FF7F00", "#00C3F2", "#876aa4"],
          responsive: true,
        };
        return (
          <CardGraphStyle>
            <LineChart {...config1} />
          </CardGraphStyle>
        );
      }
      // return <CardGraph data={data} config={config1} />
    };

    const disabledDate = (current) =>
      current && current > moment().endOf("day");

    // const dataAge = [
    //     {province: "00-00", content: "40%"},
    //     {province: "00-00", content: "15%"},
    //     {province: "00-00", content: "15%"},
    //     {province: "00-00", content: "10%"},
    //     {province: "00-00", content: "8%"},
    //     {province: "00-00", content: "8%"},
    // ];

    return (
      <>
        {check_token === true && (
          <Layout
            hasButton={false}
            title="Dashboard"
            className="comtain-fluid"
            caption="รวมกิจกรรม"
          >
            <div className="px-2 text-right mb-2">
              <span>
                แสดงข้อมูลย้อนหลัง 7 วัน
                <DatePicker
                  className="ml-3"
                  format="DD/MM/YYYY"
                  disabledDate={disabledDate}
                  defaultValue={moment(this.state.endDate, "DD/MM/YYYY")}
                  showToday={false}
                  onChange={this.handdleChageDate}
                  allowClear={false}
                />
              </span>
            </div>
            <Spin spinning={loadingUsers}>
              <Card5Col
                dataAllUsers={total_customers.toString()}
                dataGraphAllUsers={data2}
                dataUsersPerDay={avg_customer.toString()}
                dataGraphUsersPerDay={data2}
                dataActivityUser={count_customers_active_event.toString()}
                dataGraphActivityUser={data2}
                dataNewUser={count_customers_news.toString()}
                newUser={new_user}
                dataGraphNewUser={data2}
              />
            </Spin>
            <Row gutter={[16, 16]} className="m-0 mt-4">
              <ColGeaph className="px-2">
                {loadingBarGraph === true ? (
                  <Spin spinning={loadingBarGraph} tip="Loading...">
                    <Card className="mb-3" style={{ height: 260 }} />
                  </Spin>
                ) : (
                  <Card className="mb-3">
                    {getBarGraph({
                      data,
                      dataapi: listBar,
                      listline: [],
                    })}
                  </Card>
                )}
                {loadingLineGraph === true ? (
                  <Spin spinning={loadingLineGraph} tip="Loading...">
                    <Card className="mb-3" style={{ height: 600 }} />
                  </Spin>
                ) : (
                  <Card>
                    {this.state.listline.length > 0 ? (
                      getLineGraph({ listline: this.state.listline })
                    ) : (
                      <div className="text-center empty">
                        <Avatar
                          shape="square"
                          src="/assets/images/dafault/empty.png"
                          size={150}
                        />
                        <p className="mb-0">ยังไม่มีข้อมูล</p>
                      </div>
                    )}
                  </Card>
                )}
              </ColGeaph>
              <ColActivity className="px-2">
                {loadingActivity === true ? (
                  <Spin spinning={loadingActivity} tip="Loading...">
                    <Card style={{ height: 288 }} className="mb-4" />
                  </Spin>
                ) : (
                  <CardActivity
                    className="demo-infinite-container"
                    title="กิจกรรมที่กำลังดำเนินการ"
                    linkTo="/check-in-event"
                    dataActivity={listActivity.length === 0 ? [] : listActivity}
                    hasMore={hasMoreActivity}
                    loading={loadMoreActivity}
                    loadMore={this.handleInfiniteOnLoadActivity}
                  />
                )}
                {loadingVoucher === true ? (
                  <Spin spinning={loadingVoucher} tip="Loading...">
                    <Card style={{ height: 288 }} />
                  </Spin>
                ) : (
                  <CardActivity
                    className="demo-infinite-container-voucher"
                    title="Voucher ที่ใช้ยอดการใช้งานสูงสุด"
                    linkTo="/voucher"
                    dataActivity={
                      listVoucher.length === 0 ? listVoucher : listVoucher
                    }
                    hasMore={hasMoreVoucher}
                    loading={loadMoreVoucher}
                    loadMore={this.handleInfiniteOnLoadVoucher}
                  />
                )}
              </ColActivity>
              <ColAudience className="px-2">
                {loadingAge === true ? (
                  <Spin spinning={loadingAge} tip="Loading...">
                    <Card
                      title="Audience"
                      style={{ height: 288 }}
                      className="mb-4"
                    />
                  </Spin>
                ) : (
                  <CardAudience title="ช่วงอายุ" dataAudience={listAge} />
                )}
                {loadingAudience === true ? (
                  <Spin spinning={loadingAudience} tip="Loading...">
                    <Card title="Audience" style={{ height: 288 }} />
                  </Spin>
                ) : (
                  <CardAudience
                    title="Audience"
                    dataAudience={listAudience.length === 0 ? [] : listAudience}
                  />
                )}
              </ColAudience>
            </Row>
          </Layout>
        )}
      </>
    );
  }
}

export default Dashboard;
