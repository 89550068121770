import React from "react";
import PropTypes from "prop-types";
import { Tag } from "antd";

const TagStatus = ({ className, StatusValue, onClick }) => {
  let TagRender;
  if (StatusValue === 1) {
    TagRender = (
      <Tag className={className} onClick={onClick} color="blue">
        ACTIVE
      </Tag>
    );
  } else if (StatusValue === 0) {
    TagRender = (
      <Tag className={className} onClick={onClick} color="red">
        SUSPEND
      </Tag>
    );
  } else {
    TagRender = <Tag>No value</Tag>;
  }
  return <>{TagRender}</>;
};

TagStatus.propTypes = {
  className: PropTypes.string,
  StatusValue: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};
TagStatus.defaultProps = {
  className: "",
};

export default TagStatus;
