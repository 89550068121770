import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Button, Row, Col, Upload } from "antd";
import PictureOutlined from "@ant-design/icons/lib/icons/PictureOutlined";
import styled from "styled-components";

export const CardUploadImageStyle = styled(Card)`
  border: none;
  .ant-card-head {
    font-size: 18px;
    border-bottom: none !important;
    .ant-card-head-title {
      padding: 16px 0 0 0 !important;
    }
  }
  .form__title {
    .ic_font_title {
      font-size: 20px;
    }
    .ic_font {
      font-size: 20px;
    }
  }
  .form__upload {
    background: #f3f3f4;
    padding: 15px;
  }
  .form__thumbnail {
    text-align: center;
    width: 339px;
    height: 214px;
    max-width: 339px;
    margin: 0 auto;
    border: 2px solid #d9d9d9;
    border-radius: 3px;
    img {
      max-width: 100%;
      max-height: 100%;
      background: #cccccc;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
  }
  .form__action {
    .ant-upload {
      @media (max-width: 768px) {
        text-align: center;
        display: block;
      }
      .ant-btn {
        border: 1px solid #d44b43;
        background-color: transparent;
        color: #d44b43;
      }
    }
  }
`;

const CardUploadImage = ({
  title,
  className,
  imgSrc,
  imgTitle,
  onChange,
  beforeUpload,
  disable,
  errImg,
  newImage,
  extra,
}) => (
  <CardUploadImageStyle
    title={title}
    className={`${className} ${disable === true ? "disable" : ""}`}
  >
    <Form.Item
      style={{ marginTop: -10 }}
      name={["image"]}
      rules={[{ required: newImage, message: errImg }]}
    >
      <Row
        gutter={[16, 16]}
        type="flex"
        align="middle"
        className="form__upload"
      >
        <Col
          className="form__thumbnail"
          sm={24}
          md={24}
          lg={{ span: 10, offset: 0 }}
        >
          <img src={imgSrc} alt={imgTitle} className="thumbnail rounded" />
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 14, offset: 0 }}
        >
          <div className="form__title">
            <span className="ic_font_title">
              <PictureOutlined className="ic_font" />
              Image
            </span>
            {extra ? <p className="text-danger mt-2">{extra}</p> : ""}
          </div>
          <Upload
            className="form__action"
            name="upload-img"
            action={`${process.env.REACT_APP_FRONT_API}test`}
            listType="text"
            multiple={false}
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={onChange}
          >
            <Button type="default" size="large">
              Upload
            </Button>
          </Upload>
        </Col>
      </Row>
    </Form.Item>
  </CardUploadImageStyle>
);

CardUploadImage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  imgSrc: PropTypes.string,
  imgTitle: PropTypes.string,
  newImage: PropTypes.bool.isRequired,
  extra: PropTypes.string,
  // multiple: PropTypes.bool,
  // showUploadList: PropTypes.bool,
  errImg: PropTypes.string,
  beforeUpload: PropTypes.func,
  onChange: PropTypes.func,
};
CardUploadImage.defaultProps = {
  className: "",
};
export default CardUploadImage;
