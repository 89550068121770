import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";

const PageTable = ({
  rowKey,
  columns,
  data,
  rowSelection,
  total,
  onChange,
  page,
  size,
}) => (
  <Table
    rowKey={rowKey}
    columns={columns}
    dataSource={data}
    rowSelection={rowSelection}
    pagination={{
      defaultPageSize: size ? size : 20,
      showSizeChanger: true,
      showQuickJumper: true,
      total,
      defaultCurrent: page ? page : 1,
      onChange: (page, length) => {
        onChange((page - 1) * length, length, "", page, length);
      },
      showTotal: (total) => `รายการทั้งหมด ${total} รายการ`,
      pageSizeOptions: ["10", "20", "30"],
      onShowSizeChange: (page, length) => {
        onChange(0 * length, length, "", 1, length);
      },
    }}
  />
);

PageTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  rowSelection: PropTypes.object,
  onChange: PropTypes.func,
  rowKey: PropTypes.func,
  total: PropTypes.number,
  page: PropTypes.number,
  size: PropTypes.number,
};
PageTable.defaultProps = {
  className: "",
};
export default PageTable;
