import { httpClient } from "../components/utils/axios";

export const bannerTable = (start, length, search) =>
  httpClient
    .post(`banner/datatable`, {
      start,
      length,
      search,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const getBannerEventList = () =>
  httpClient
    .get(`banner/list/events`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const createBanner = (id) =>
  httpClient
    .post(`banner/create`, {
      id,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch(
      (error) =>
        // throw error;
        error.response,
    );

export const deleteBanner = (id) =>
  httpClient
    .delete(`banner/delete/${id}`, {
      id,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const updateBanner = (data) =>
  httpClient
    .post(`banner/update`, { banners: data })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const bannerHomeTable = (start, length) =>
  httpClient
    .get(`banners_homes/datatable?start=${start || 0}&length=${length || 50}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const indexBannerHome = (data) =>
  httpClient
    .put(`banners_homes/index`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const createBannerHome = (formData) =>
  httpClient
    .post(`banners_homes`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch(
      (error) =>
        // throw error;
        error.response,
    );
export const updateBannerHome = (formData, id) =>
  httpClient
    .put(`banners_homes?id=${id}`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch(
      (error) =>
        // throw error;
        error.response,
    );

export const deleteBannerHome = (id) =>
  httpClient
    .delete(`banners_homes?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getBannerHome = (id) =>
  httpClient
    .get(`banners_homes?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const bannerMusicTable = (start, length) =>
  httpClient
    .get(`banners_musics/datatable?start=${start || 0}&length=${length || 50}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const indexBannerMusic = (data) =>
  httpClient
    .put(`banners_musics/index`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const createBannerMusic = (formData) =>
  httpClient
    .post(`banners_musics`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch(
      (error) =>
        // throw error;
        error.response,
    );
export const updateBannerMusic = (formData, id) =>
  httpClient
    .put(`banners_musics?id=${id}`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch(
      (error) =>
        // throw error;
        error.response,
    );

export const deleteBannerMusic = (id) =>
  httpClient
    .delete(`banners_musics?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getBannerMusic = (id) =>
  httpClient
    .get(`banners_musics?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
