import { Layout } from "antd";
import Navigation from "../Nav";
import PropTypes from "prop-types";
import React from "react";

const { Sider } = Layout;

const Sidebar = ({ navi, userId, profileId, roleIds, menus }) => (
  <Sider
    theme="light"
    trigger={null}
    onBreakpoint={(broken) => {
      console.log(broken);
    }}
    width={250}
  >
    <Navigation
      navi={navi}
      userId={userId}
      profileId={profileId}
      roleIds={roleIds}
      menus={menus}
    />
  </Sider>
);
Sidebar.propTypes = {
  navi: PropTypes.array.isRequired,
};

export default Sidebar;
