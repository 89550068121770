import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const DateTimeLong = (props) => {
  const { dateTime, className } = props;
  moment.locale("en");
  return (
    <span className={className}>
      {moment(dateTime).format("DD MMMM YYYY - HH:mm")}
    </span>
  );
};

DateTimeLong.propTypes = {
  className: PropTypes.string,
  dateTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
DateTimeLong.defaultProps = {
  className: "",
};

export default DateTimeLong;
