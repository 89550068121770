import React, { Component } from "react";
import { Col, Row, Spin, Button, Tabs, Card, Upload, Modal } from "antd";
import styled from "styled-components";
import Swal from "sweetalert2";
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Markup } from "interweave";

import { Layout } from "../../containers";
import AlertMessages from "../../commons/AlertMessages";
import { ArtistsApi } from "../../../apis";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
// import ReactExport from "react-export-excel";
import AvatarProfile from "../../commons/AvatarProfile";
import TagStatus from "../../commons/TagStatus";
import { userAction } from "../../../store/action";

import ArtistAlbums from "../../views/ArtistAlbums";

const { TabPane } = Tabs;

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

async function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `You can only upload JPG/PNG file!`,
      timer: 1500,
    });
  }
  const isLt5M = file.size / 1024 / 1024 < 0.512;
  if (!isLt5M) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `Image must smaller than 512KB!`,
      timer: 1500,
    });
  }
  return isJpgOrPng && isLt5M;
}
export const DivArtists = styled.div`
  padding: 2.5em;
  font-size: 18px;
  font-weight: 500;
  background-color: white;

  img {
    width: 100%;
  }

  .text-size {
    font-size: 14px;
  }

  .total-follower-color {
    margin: 0;
    color: #01a69d;
    font-size: 22px;
  }
`;

const allowList = [
  "b",
  "br",
  "i",
  "iframe",
  "img",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "p",
  "span",
  "strong",
  "u",
];

class ViewArtists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: "",
      follower: 0,
      songs: 0,
      albums: 0,
      image: [],
      name: "",
      loading: true,
      check_token: false,
      fileList: [],
      oldImage: [],
      followerData: [],
      previewVisible: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const id = this.props.match.params.artistsId;
    // const { start, search, length } = this.state;
    await this.setState({ loading: true });
    const getArtist = ArtistsApi.getArtist(id)
      .then((resp) => {
        this.setState({
          name: resp.data.name,
          facebook: resp.data.facebook,
          twitter: resp.data.twitter,
          instagram: resp.data.instagram,
          youtube: resp.data.youtube,
          status: resp.data.status,
          detail: resp.data.detail,
          follower: resp.data.customers_follows,
          albums: resp.data.albums,
          songs: resp.data.songs,
          image: resp.data.artists_images,
          cover: resp.data.cover,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    const getGallary = ArtistsApi.getGallary(id)
      .then((res) => {
        const image = [];
        //   resp.data.artists_images
        res.data.forEach((doc) => {
          image.push({
            uid: doc.id,
            name: doc.id,
            status: "done",
            url: doc.image,
          });
        });
        this.setState({
          fileList: image,
          oldImage: image,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    const getFollowerList = ArtistsApi.getFollowerList(id)
      .then((resp) => {
        this.setState({
          followerData: resp.data,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    const getAlbumList = ArtistsApi.getAlbumList(id)
      .then((resp) => {
        this.setState({
          albumData: resp.data,
          albumTotal: resp.recordsTotal,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    await Promise.all([getArtist, getFollowerList, getGallary, getAlbumList]);
    await this.setState({ loading: false });
  };

  showModal = () => {
    const id = this.props.match.params.artistsId;
    Swal.fire({
      title: "ต้องการลบข้อมูลศิลปินใช่หรือไม่",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({
          loading: true,
        });
        ArtistsApi.DeleteArtist(id).then((resp) => {
          Swal.fire({
            icon: "success",
            title: `${resp.message}`,
            timer: 1500,
          }).then(() => this.props.history.push("/artists"));
        });
      }
    });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ file, fileList }) => {
    const { oldImage } = this.state;
    if (file.status) {
      if (file.status === "removed") {
        for (let i = 0; i < oldImage.length; i += 1) {
          if (oldImage[i].uid === file.uid) {
            ArtistsApi.DeleteArtistgallary(
              oldImage[i].uid,
              this.props.match.params.artistsId,
            ).then(() => {
              this.setState({ fileList });
            });
          }
        }
        this.setState({ fileList });
      } else {
        this.setState({ fileList });
        if (file.status === "done") {
          const formData = new FormData();
          formData.append("artist_id", +this.props.match.params.artistsId);
          formData.append("image", file.originFileObj);
          ArtistsApi.CreateArtistgallary(formData).then(async (res) => {
            const imageData = this.state.fileList;
            // eslint-disable-next-line no-restricted-syntax
            for (const [i, img] of imageData.entries()) {
              // eslint-disable-next-line eqeqeq
              if (img.uid == file.originFileObj.uid) {
                imageData.splice(i, 1);
              }
            }
            this.setState({
              fileList: imageData.concat([
                {
                  uid: res.id,
                  name: res.id,
                  status: "done",
                  url: await getBase64(file.originFileObj),
                },
              ]),
              oldImage: imageData.concat([
                {
                  uid: res.id,
                  name: res.id,
                  status: "done",
                  url: res.image,
                },
              ]),
            });
          });
        }
      }
    }
  };

  handleClick = () =>
    this.props.history.push(
      `/artists/${this.props.match.params.artistsId}/edit`,
    );

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[9].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const {
      name,
      image,
      detail,
      loading,
      followerData,
      fileList,
      albumData,
      albumTotal,
      check_token,
    } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        {check_token === true && (
          <Layout
            title="Artist Profile"
            extraBtn={[
              <Button
                style={{ display: isEdit[0] === 1 ? "" : "none" }}
                type="primary"
                onClick={this.handleClick}
              >
                Edit
              </Button>,
              <Button
                style={{ display: isDelete[0] === 1 ? "" : "none" }}
                type="danger"
                onClick={this.showModal}
              >
                Delete
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "artists",
                breadcrumbName: "Artists",
              },
              {
                path: "profile",
                breadcrumbName: "Artist Profile",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <Card>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <p>Cover image</p>
                    <Row gutter={16}>
                      <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                        <span className="avatar_img">
                          <img src={this.state.cover} alt={this.state.cover} />
                        </span>
                      </Col>
                    </Row>
                    <p className="mt-3">Feature image</p>
                    <Row gutter={16}>
                      {image.map((doc, index) => (
                        <Col
                          key={doc.id}
                          xs={{ span: 24 }}
                          md={{ span: 8 }}
                          lg={{ span: 8 }}
                        >
                          <span className="avatar_img">
                            <img
                              className={index > 2 ? "mt-3" : ""}
                              src={doc.image}
                              alt={doc.image}
                            />
                          </span>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col span={12}>
                    <h5>
                      {name} <TagStatus StatusValue={this.state.status} />
                    </h5>
                    <Markup allowList={allowList} content={detail} />
                    <Row justify="mt-4" align="bottom">
                      <Col span={3}>
                        <p className="total-follower-color ml-3 ">
                          {formatNumber(this.state.follower)}
                        </p>
                        <span className="text-size text-muted">Follower</span>
                      </Col>
                      <Col span={3}>
                        <p className="total-follower-color ml-3">
                          {formatNumber(this.state.albums)}
                        </p>
                        <span className="text-size text-muted">Albums</span>
                      </Col>
                      <Col span={3}>
                        <p className="total-follower-color ml-3">
                          {formatNumber(this.state.songs)}
                        </p>
                        <span className="text-size text-muted">Songs</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Spin>

            <Spin spinning={loading} tip="Loading...">
              <Tabs
                className="customer-tabs"
                defaultActiveKey="info"
                onChange={this.callback}
              >
                <TabPane tab="Basic information" key="info">
                  <Card>
                    <ul className="list-unstyled fs-sm mb-0">
                      <li className="d-flex mb-2">
                        <FaFacebook className="mr-2" />
                        <strong>Facebook:</strong>{" "}
                        <span className="ml-2">{this.state.facebook}</span>
                      </li>
                      <li className="d-flex mb-2">
                        <FaInstagram className="mr-2" />
                        <strong>Instagram:</strong>{" "}
                        <span className="ml-2">{this.state.instagram}</span>
                      </li>
                      <li className="d-flex mb-2">
                        <FaYoutube className="mr-2" />
                        <strong>Youtube:</strong>{" "}
                        <span className="ml-2">{this.state.youtube}</span>
                      </li>
                      <li className="d-flex">
                        <FaTwitter className="mr-2" />
                        <strong>Twitter:</strong>{" "}
                        <span className="ml-2">{this.state.twitter}</span>
                      </li>
                    </ul>
                  </Card>
                </TabPane>
                <TabPane tab="Gallery" key="gallery">
                  <Card>
                    <AlertMessages type="warning" icon={false}>
                      <ul>
                        <li>Max file size 512kb.</li>
                        <li>Only 1:1 ratio</li>
                      </ul>
                    </AlertMessages>
                    <Row
                      gutter={[16, 16]}
                      type="flex"
                      align="middle"
                      className="form__upload"
                    >
                      <Col span={8}>
                        <ImgCrop aspect={1 / 1}>
                          <Upload
                            action={`${process.env.REACT_APP_FRONT_API}test`}
                            listType="picture-card"
                            fileList={fileList}
                            beforeUpload={beforeUpload}
                            onPreview={this.handlePreview}
                            onChange={this.handleChange}
                          >
                            {fileList.length >= 8 ? null : uploadButton}
                          </Upload>
                        </ImgCrop>
                      </Col>
                    </Row>
                  </Card>
                </TabPane>
                <TabPane tab="Follower" key="follower">
                  <Spin spinning={loading}>
                    <Card>
                      <Row align="top">
                        {followerData.length > 0 ? (
                          <Row align="top" className="w-100">
                            {followerData.map((item) => (
                              <Col md={4} key={item.id}>
                                <AvatarProfile
                                  className="text-center mb-3"
                                  imgSize={90}
                                  imgSrc={item.image}
                                  artistName={item.fullname}
                                />
                              </Col>
                            ))}
                          </Row>
                        ) : (
                          "ไม่มี follower"
                        )}
                      </Row>
                      {/* {followerData.length > 0 && loadmore !== false && (
                    <div className="text-center">
                      <Button
                        type="primary"
                        size="large"
                        onClick={this.loadMore}
                      >
                        Load more
                      </Button>
                    </div>
                  )} */}
                    </Card>
                  </Spin>
                </TabPane>
                <TabPane tab="Albums" key="albums">
                  <Card>
                    {loading === false && (
                      <ArtistAlbums
                        albumData={albumData}
                        total={albumTotal}
                        artistsId={this.props.match.params.artistsId}
                      />
                    )}
                  </Card>
                </TabPane>
              </Tabs>
              <Modal
                visible={this.state.previewVisible}
                footer={null}
                onCancel={() => this.setState({ previewVisible: false })}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={this.state.previewImage}
                />
              </Modal>
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewArtists);
