import styled from "styled-components";

export const AdvancedTableSearchStyle = styled.div`
  .advanced-table-search {
  }
  .advanced-table-search-form {
    background-color: #fbfbfb;
    border: 1px solid #d9d9d9;
  }
`;
