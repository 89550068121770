import React, { Component } from "react";
import { Col, Form, Row, Card, Spin, Select, DatePicker, Button } from "antd";
import styled from "styled-components";
import { eventsApi } from "../../../apis";
import PageTable from "../../commons/PageTable";
import { httpClient } from "../../utils/axios";
import { CSVLink } from "react-csv";
import { Layout } from "../../containers";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
const { RangePicker } = DatePicker;
const { Option } = Select;

export const CardSearchNameUserStyle = styled.div`
  width: 100%;

  .ant-select {
    width: 100%;
    display: block;
  }

  .col-5 {
    flex: 0 0 100%;
    max-width: 100%;
    @media (min-width: 768px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (min-width: 992px) {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
    @media (min-width: 1280px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
`;

export const ArticleMaualAdd = styled.article`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

class UserTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataProvinces: [],
      dataAmphures: [],
      dataArtist: [],
      gender: "",
      day: "",
      month: "",
      province_id: "",
      amphures_id: null,
      artist_id: "",
      type: [1, 2, 3, 4],
      data: [],
      total: 0,
      start: "",
      end: "",
      exportData: [],
      loadingSearch: false,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await this.setState({ loading: true });
    const eventsManualPointsProvinces = eventsApi
      .eventsManualPointsProvinces()
      .then((resp) => {
        resp.data.forEach((doc) => {
          doc.customers_points_logs = doc.customers_points_logs
            ? doc.customers_points_logs
            : { point: 0 };
        });
        this.setState({
          dataProvinces: resp.data,
        });
      });
    const eventsManualPointsArtists = eventsApi
      .eventsManualPointsArtists()
      .then((resp) => {
        resp.data.forEach((doc) => {
          doc.customers_points_logs = doc.customers_points_logs
            ? doc.customers_points_logs
            : { point: 0 };
        });
        this.setState({
          dataArtist: resp.data,
        });
      });
    const transactions_report = httpClient
      .post(`reports/transactions_report/datatable`)
      .then((res) => {
        res.data.data.forEach((doc) => {
          doc.customers_points_logs = doc.customers_points_logs
            ? doc.customers_points_logs
            : { point: 0 };
        });
        this.setState({
          data: res.data.data,
          total: res.data.totalFiltered,
        });
      })
      .catch((error) => {
        throw error;
      });
    await Promise.all([
      eventsManualPointsProvinces,
      eventsManualPointsArtists,
      transactions_report,
    ]);
    await this.setState({
      loading: false,
    });
  };

  handlePaggination = async (starts, length) => {
    await this.setState({ loading: true });
    const {
      gender,
      day,
      month,
      province_id,
      amphures_id,
      artist_id,
      start,
      end,
      type,
    } = this.state;
    const transactions_report = httpClient
      .post(`reports/transactions_report/datatable`, {
        gender,
        day,
        month,
        province_id,
        amphures_id,
        artist_id,
        start_date: start,
        end_date: end,
        event_type: type,
        start: starts,
        length,
      })
      .then((res) => {
        res.data.data.forEach((doc) => {
          doc.customers_points_logs = doc.customers_points_logs
            ? doc.customers_points_logs
            : { point: 0 };
        });
        this.setState({
          data: res.data.data,
          total: res.data.totalFiltered,
        });
      })
      .catch((error) => {
        throw error;
      });
    await Promise.all([transactions_report]);
    await this.setState({
      loading: false,
    });
  };

  handleChangeInput = (changeObject) => {
    this.setState(changeObject);
  };

  handleChangeGender = (e) => {
    this.setState({
      gender: e,
    });
  };

  handleChangeDay = (e) => {
    this.setState({
      day: e,
    });
  };

  handleChangeMonth = (e) => {
    this.setState({
      month: e,
    });
  };

  handleChangeProvince = async (e) => {
    await this.setState({
      province_id: e,
      amphures_id: null,
      loading: true,
    });
    const provinceId = e === "" ? 0 : e;
    await eventsApi.eventsManualPointsAmphures(provinceId).then((resp) => {
      this.setState({
        dataAmphures: resp.data,
        loading: false,
      });
    });
  };

  handleChangeCounty = (e) => {
    this.setState({
      amphures_id: e,
    });
  };

  handleChangeArtists = (e) => {
    this.setState({
      artist_id: e,
    });
  };

  onConfirmSearch = async () => {
    await this.setState({ loading: true, loadingSearch: true });
    const {
      gender,
      day,
      month,
      province_id,
      amphures_id,
      artist_id,
      start,
      end,
      type,
    } = this.state;
    const transactions_report = httpClient
      .post(`reports/transactions_report/datatable`, {
        gender,
        day,
        month,
        province_id,
        amphures_id,
        artist_id,
        start_date: start,
        end_date: end,
        event_type: type,
      })
      .then((res) => {
        res.data.data.forEach((doc) => {
          doc.customers_points_logs = doc.customers_points_logs
            ? doc.customers_points_logs
            : { point: 0 };
        });
        this.setState({
          data: res.data.data,
          total: res.data.totalFiltered,
        });
      })
      .catch((error) => {
        throw error;
      });
    await Promise.all([transactions_report]);
    await this.setState({
      loading: false,
      loadingSearch: false,
    });
  };

  // handleChangeAge = (value) => {
  //   this.setState({
  //     age_to: value[1],
  //   });
  // };

  // onAfterChange = (value) => {
  //   this.setState({
  //     age_from: value[0],
  //   });
  // };

  ButtonGreyClick = async () => {
    const that = this;
    await this.setState({ loading: true });
    const {
      gender,
      day,
      month,
      province_id,
      amphures_id,
      artist_id,
      start,
      end,
      type,
    } = this.state;
    await httpClient
      .post(`reports/transactions_report/export`, {
        gender,
        day,
        month,
        province_id,
        amphures_id,
        artist_id,
        start_date: start,
        end_date: end,
        event_type: type,
      })
      .then((res) => {
        const data = [];
        const result = res.data.data;
        result.forEach((doc) => {
          data.push({
            created_at: moment(doc.created_at).format("DD/MM/YYYY"),
            customer: doc.customer.fullname,
            address: doc.customer.address,
            province: doc.customer.province.name_th,
            amphure: doc.customer.amphure.name_th,
            district: doc.customer.district.name_th,
            zip_code: doc.customer.district.zip_code,
            birthdate: doc.customer.birthdate,
            gender: doc.customer.gender,
            telephone: doc.customer.telephone,
            activity: doc.event.name,
            type:
              doc.event.type === 1
                ? "rewards"
                : doc.event.type === 2
                ? "campaign"
                : doc.event.type === 3
                ? "checkin"
                : "manual points",
            point: doc.customers_points_logs
              ? doc.customers_points_logs.points
              : { points: 0 },
          });
          that.setState({
            exportData: data,
          });
        });
      })
      .catch((error) => {
        throw error;
      });
    await this.setState({ loading: false });
    await document.getElementById("btn-export").click();
  };

  render() {
    const {
      loading,
      total,
      dataProvinces,
      data,
      dataAmphures,
      dataArtist,
      exportData,
      check_token,
    } = this.state;

    const listBirthSex = [
      { key: "", label: "ทุกเพศ" },
      { key: "1", label: "ชาย" },
      { key: "2", label: "หญิง" },
      { key: "3", label: "ไม่ระบุ" },
    ];
    const listEventType = [
      { key: 1, label: "Rewards" },
      { key: 2, label: "Campaign" },
      { key: 3, label: "Checkin" },
      { key: 4, label: "Manual points" },
    ];

    const listBirthDay = [
      { key: "", label: "ทุกวันเกิด" },
      { key: "0", label: "วันจันทร์" },
      { key: "1", label: "วันอังคาร" },
      { key: "2", label: "วันพุธ" },
      { key: "3", label: "วันพฤหัสบดี" },
      { key: "4", label: "วันศุกร์" },
      { key: "5", label: "วันเสาร์" },
      { key: "6", label: "วันอาทิตย์" },
    ];

    const listBirthMonth = [
      { key: "", label: "ทุกเดือนเกิด" },
      { key: "1", label: "มกราคม" },
      { key: "2", label: "กุมภาพันธ์" },
      { key: "3", label: "มีนาคม" },
      { key: "4", label: "เมษายน" },
      { key: "5", label: "พฤษภาคม" },
      { key: "6", label: "มิถุนายน" },
      { key: "7", label: "กรกฎาคม" },
      { key: "8", label: "สิงหาคม" },
      { key: "9", label: "กันยายน" },
      { key: "10", label: "ตุลาคม" },
      { key: "11", label: "พฤศจิกายน" },
      { key: "12", label: "ธันวาคม" },
    ];
    const columns = [
      {
        title: "Date",
        dataIndex: "created_at",
        key: "created_at",
        sorter: (a, b) =>
          moment(a.created_at).unix() - moment(b.created_at).unix(),
        render: (text) => (
          <div className="d-block">
            <span style={{ fontSize: 14 }} className="d-block text-muted">
              {text && moment(text).format("DD/MM/YYYY")}
            </span>
          </div>
        ),
      },
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        render: (text, record) => (
          <div className="d-block">
            <span style={{ fontSize: 14 }} className="d-block text-muted">
              {record.customer && record.customer.fullname}
            </span>
          </div>
        ),
      },
      {
        title: "Activity",
        dataIndex: "Activity",
        key: "Activity",
        align: "center",
        render: (text, record) => (
          <div className="d-block">
            <span style={{ fontSize: 14 }} className="d-block text-muted">
              {record.event && record.event.name}
            </span>
          </div>
        ),
      },
      {
        title: "Activity Type",
        dataIndex: "type",
        key: "type",
        render: (text, record) => (
          <div className="d-block">
            <span style={{ fontSize: 14 }} className="d-block text-muted">
              {record.event.type === 1
                ? "rewards"
                : record.event.type === 2
                ? "campaign"
                : record.event.type === 3
                ? "checkin"
                : "manual points"}
            </span>
          </div>
        ),
      },
      {
        title: "Point",
        dataIndex: "Point",
        key: "Point",
        render: (text, record) => (
          <div className="d-block">
            <span
              style={{
                color:
                  record.customers_points_logs.type_points === 0
                    ? "red"
                    : "green",
                fontSize: 14,
              }}
              className="d-block "
            >
              {record.customers_points_logs.points}
            </span>
          </div>
        ),
      },
    ];

    const headers = [
      { label: "Date", key: "created_at" },
      { label: "Customer", key: "customer" },
      { label: "Address", key: "address" },
      { label: "District", key: "district" },
      { label: "Amphure", key: "amphure" },
      { label: "Province", key: "province" },
      { label: "Zip_code", key: "zip_code" },
      { label: "Birthdate", key: "birthdate" },
      { label: "Gender", key: "gender" },
      { label: "Telephone", key: "telephone" },
      { label: "Activity", key: "activity" },
      { label: "Activity Type", key: "type" },
      { label: "Point", key: "point" },
    ];

    const onFinishFailed = (err) => {
      console.log(err);
    };

    return (
      <>
        {check_token === true && (
          <Layout
            title="Customer Transactions"
            extraBtn={[]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "audition",
                breadcrumbName: "Audition",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <Form layout="vertical" onFinishFailed={onFinishFailed}>
                <Card>
                  <div>
                    <p className="lead text-muted">
                      เลือกผู้ใช้งานที่ต้องการให้คะแนน
                    </p>
                  </div>

                  <CardSearchNameUserStyle>
                    <Row gutter={[8, 8]} align="bottom">
                      <Col className="px-2" span={8}>
                        <p className="text-left my-1">Activity type</p>
                        <Select
                          mode="multiple"
                          onChange={(e) => this.setState({ type: e })}
                          size="large"
                          placeholder="Please select"
                          showSearch
                          // value={this.state.type}
                        >
                          {listEventType.map((item) => (
                            <Option key={item.key} value={item.key}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>

                      <Col className="px-2" span={8}>
                        <p className="text-left my-1">เพศ</p>
                        <Select
                          onChange={(e) => this.setState({ gender: e })}
                          size="large"
                          placeholder="เลือกเพศ"
                          showSearch
                        >
                          {listBirthSex.map((item) => (
                            <Option key={item.key} value={item.key}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>

                      <Col className="px-2" span={8}>
                        <p className="text-left my-1">เดือนเกิด</p>
                        <Select
                          onChange={this.handleChangeMonth}
                          size="large"
                          placeholder="เลือกเดือนเกิด"
                          showSearch
                          value={this.state.month}
                        >
                          {listBirthMonth.map((item) => (
                            <Option key={item.key} value={item.key}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>

                      <Col className="px-2" span={8}>
                        <p className="text-left my-1">Date</p>
                        <RangePicker
                          onChange={(value, dateString) =>
                            this.setState({
                              start: dateString[0],
                              end: dateString[1],
                            })
                          }
                          size="large"
                          format="DD/MM/YYYY"
                          style={{ width: "100%" }}
                        />
                      </Col>

                      <Col className="px-2" span={8}>
                        <p className="text-left my-1">วันเกิด</p>
                        <Select
                          onChange={this.handleChangeDay}
                          size="large"
                          placeholder="เลือกวันเกิด"
                          showSearch
                          value={this.state.day}
                        >
                          {listBirthDay.map((item) => (
                            <Option key={item.key} value={item.key}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>

                      <Col className="px-2" span={8}>
                        <p className="text-left my-1">คิลปิน</p>
                        <Select
                          onChange={this.handleChangeArtists}
                          size="large"
                          placeholder="เลือกคิลปิน"
                          showSearch
                        >
                          <Option key="" value="">
                            ทุกศิลปิน
                          </Option>
                          {dataArtist.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>

                      <Col className="px-2" span={8}>
                        <p className="text-left my-1">จังหวัด</p>
                        <Select
                          onChange={this.handleChangeProvince}
                          size="large"
                          placeholder="เลือกจังหวัด"
                          showSearch
                        >
                          <Option key="" value="">
                            ทุกจังหวัด
                          </Option>
                          {dataProvinces.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name_th}
                            </Option>
                          ))}
                        </Select>
                      </Col>

                      <Col className="px-2" span={8}>
                        <p className="text-left my-1">เขต</p>
                        <Select
                          onChange={this.handleChangeCounty}
                          size="large"
                          placeholder="เลือกเขต"
                          showSearch
                        >
                          <Option key="" value="">
                            ทุกเขต
                          </Option>
                          {dataAmphures.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name_th}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </CardSearchNameUserStyle>

                  <Form.Item className="my-3">
                    <Row justify="end" className="m-0" gutter={[16, 16]}>
                      <Col className="width-185">
                        <Button
                          size="large"
                          onClick={this.ButtonGreyClick}
                          block
                        >
                          Export to csv
                        </Button>
                        <CSVLink
                          id="btn-export"
                          className={`${`ant-btn ant-btn-lg`}`}
                          style={{
                            display: "none",
                            background: "#eaeaea",
                            color: "#403737",
                            border: "2px solid #eaeaea",
                          }}
                          data={exportData}
                          filename={`${"UserTransactions"}-${moment().format(
                            "DD MM YYYY",
                          )}.csv`}
                          headers={headers}
                          target="_blank"
                        >
                          Export to csv
                        </CSVLink>
                      </Col>
                      <Col className="width-185">
                        <Button
                          type="primary"
                          size="large"
                          onClick={this.onConfirmSearch}
                          block
                        >
                          Search
                        </Button>
                      </Col>
                    </Row>
                    <div className="mt-3">
                      {this.state.loadingSearch === false && (
                        <PageTable
                          rowKey={(record) => record.customer}
                          columns={columns}
                          data={data}
                          total={total}
                          onChange={this.handlePaggination}
                        />
                      )}
                    </div>
                  </Form.Item>
                </Card>
              </Form>
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

export default UserTransactions;
