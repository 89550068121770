import { httpClient } from "../components/utils/axios";

export const CustomerTable = (start, length, search, type, type_filter) =>
  httpClient
    .get(
      `customers/datatable?start=${start || 0}&length=${
        length || 20
      }&type_filter=${type_filter || 0}&search=${search || ""}&type=${
        type || 0
      }`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const CustomerTableReport = (search, type, type_filter) =>
  httpClient
    .get(
      `customers/export?type_filter=${type_filter || 0}&search=${
        search || ""
      }&type=${type || 0}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const DeleteAccountTable = (start, length) =>
  httpClient
    .get(
      `customers/trashs/datatable?start=${start || 0}&length=${length || 20}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const DeleteCustomer = (id) =>
  httpClient
    .delete(`customers?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const DeleteAccount = (data, type) => {
  httpClient
    .put(`customers/trashs`, {
      type,
      id: data.id,
    })
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
};

export const getCustomerById = (id) =>
  httpClient
    .get(`customers?id=${id}`)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const customerIdFollow = (id) =>
  httpClient
    .get(`customers/following/list?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const customerIdHistory = (start, length, id, search) =>
  httpClient
    .get(
      `customers/points_rewards/datatable?id=${id}&start=${start || 0}&length=${
        length || 20
      }&search=${search}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const customerIdPointReport = (id) =>
  httpClient
    .get(`customers/points_rewards/export?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const customerIdMiles = (start, length, id) =>
  httpClient
    .get(
      `customers/points_miles/datatable?id=${id}&start=${start || 0}&length=${
        length || 20
      }`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const customerIdMilesReport = (id) =>
  httpClient
    .get(`customers/points_miles/export?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const customerEdit = (updateData) =>
  httpClient
    .put(`customers`, updateData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
