import React, { Component } from "react";
import styled from "styled-components";
import firebase from "../../utils/firebase";
import {
  Avatar,
  // Badge,
  Breadcrumb,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  // List,
} from "antd";
import {
  ShopOutlined,
  // MailOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Link, NavLink } from "react-router-dom";
import PageTitle from "../PageTitle";
// import { userApi } from "../../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userAction } from "../../../store/action";

const { SubMenu } = Menu;
const { Header, Content, Sider, Footer } = Layout;

const HeaderDashboard = styled(Header)`
  padding-left: 1em;
  padding-right: 1em;
  color: #fff;
  background: #01a69d;
  .site-title {
    margin-bottom: 0;
    color: #ffffff !important;
    line-height: 2;
  }
  .ant-avatar.ant-avatar-icon {
    height: auto !important;
    width: auto !important;
    > .anticon {
      font-size: 24px;
      vertical-align: middle !important;
    }
  }
`;

const ContentDashboard = styled(Content)`
  height: auto;
  padding: 30px;
`;

const UserMenu = styled(Menu)`
  .ant-dropdown-menu-item > .anticon {
    font-size: 18px;
  }
`;

const UserContanter = styled.div`
  height: 100%;
  &.text-rignt {
    text-align: right;
  }
  span {
    display: block;
    line-height: 1.5;
    &:first-child {
      font-weight: bold;
    }
  }
`;

class LayoutDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
    this.logout = this.logout.bind(this);
  }

  async componentDidMount() {
    this.onLoadUser();
  }

  onLoadUser = async () => {
    this.props.userAction.userProfile();
  };

  logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.clear();
        window.location.href = "/";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const path = window.location.pathname;
    const host = path.split("/");
    const marp = host.slice(1, host.length);

    const couponOption = marp.map((name, index) => {
      const routeTo = `${marp.slice(0, index + 1).join("/")}`;
      marp[1] = this.props.editName;

      const routesId = host.slice(2, host.length - 1);
      const isSec = `${marp.slice(0, host.length - 2).join("/")}`;
      const routesIdLink = `${host.slice(0, host.length - 2).join("/")}`;
      const isLast = index === marp.length - 1;
      return (
        <Breadcrumb.Item key={name}>
          {marp.length === 2 ? (
            <Link
              disabled={isLast}
              className="text-capitalize text-muted pl-2 text-lowercase"
              to={`${
                routeTo === "/" ? `/${routeTo}/${marp[0]}` : `/${routeTo}`
              }`}
            >
              <span>{name && name.split("-").join(" ")}</span>
            </Link>
          ) : (
            <Link
              disabled={isLast}
              className="text-capitalize text-muted pl-2 text-lowercase"
              to={
                routeTo !== isSec
                  ? `${routesIdLink}`
                  : `${routesIdLink}/${routesId}`
              }
            >
              <span>{name && name.split("-").join(" ")}</span>
            </Link>
          )}
        </Breadcrumb.Item>
      );
    });

    const menu = (
      <UserMenu>
        <Menu.Item icon={<SettingOutlined />}>
          <Link
            to={{
              pathname: `/change-password`,
              query: { email: this.state.email },
            }}
          >
            Change password
          </Link>
        </Menu.Item>
        <Menu.Item onClick={this.logout} icon={<LogoutOutlined />}>
          <span>Log out</span>
        </Menu.Item>
      </UserMenu>
    );

    const {
      // role,
      email,
      name,
      image,
    } = this.props;
    return (
      <React.Fragment key="frag">
        <Layout>
          <HeaderDashboard>
            <Row justify="space-between" align="center">
              <Col>
                <Link to="/dashboard">
                  <Row justify="space-around">
                    <Col>
                      <img
                        src="/assets/images/Logo@2x.png"
                        alt="logo"
                        width={55}
                      />
                    </Col>
                    <Col>
                      <h2 className="site-title">SPICYDISC</h2>
                    </Col>
                  </Row>
                </Link>
              </Col>
              <Col>
                <Row gutter={[16, 0]} justify="end">
                  <Col>
                    <Dropdown trigger={["click"]} overlay={menu}>
                      <Row
                        gutter={[8, 0]}
                        align="middle"
                        justify="center"
                        className="user-menu"
                      >
                        <Col>
                          <UserContanter className="text-rignt">
                            <span>{name}</span>
                            <span>{email}</span>
                          </UserContanter>
                        </Col>
                        <Col>
                          <Avatar shape="square" src={image} size={48} />
                        </Col>
                      </Row>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
          </HeaderDashboard>
          <Content>
            <Breadcrumb className="py-2 pl-4 bg-gray-light" separator=">">
              <Breadcrumb.Item>
                <Link className="p-2" to="/dashboard">
                  <img
                    src="/assets/icon/ic_view_quilt_24px@2x.png"
                    alt="logo"
                  />
                </Link>
              </Breadcrumb.Item>
              {couponOption}
            </Breadcrumb>
            <Layout>
              <Sider trigger={null} style={{ minHeight: "84vh" }}>
                <Menu
                  className="border-0 h-100"
                  mode="inline"
                  defaultSelectedKeys={["/dashboard"]}
                  selectedKeys={[this.props.location]}
                >
                  <Menu.Item key="/dashboard">
                    <NavLink to="/dashboard">
                      <ShopOutlined />
                      <span>Dashboard</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/banner">
                    <NavLink to="/banner">
                      <ShopOutlined />
                      <span>Banner</span>
                    </NavLink>
                  </Menu.Item>

                  <SubMenu key="points" icon={<ShopOutlined />} title="Points">
                    <Menu.Item key="/voucher">
                      <NavLink to="/voucher">
                        <ShopOutlined />
                        <span>Voucher</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="/campaign">
                      <NavLink to="/campaign">
                        <ShopOutlined />
                        <span>Campaign</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="/check-in-event">
                      <NavLink to="/check-in-event">
                        <ShopOutlined />
                        <span>Check in event</span>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="adjust"
                    icon={<ShopOutlined />}
                    title="Adjust Point"
                  >
                    <Menu.Item key="/manual-add">
                      <NavLink to="/manual-add">
                        <ShopOutlined />
                        <span>Manual Points</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="/manual-miles">
                      <NavLink to="/manual-miles">
                        <ShopOutlined />
                        <span>Manual Miles</span>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="/artists">
                    <NavLink to="/artists">
                      <ShopOutlined />
                      <span>Artists</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/albums">
                    <NavLink to="/albums">
                      <ShopOutlined />
                      <span>Albums</span>
                    </NavLink>
                  </Menu.Item>
                  <SubMenu
                    key="/customer"
                    icon={<ShopOutlined />}
                    title="Customer"
                  >
                    <Menu.Item key="/customer">
                      <NavLink to="/customer">
                        <ShopOutlined />
                        <span>Customer</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="/delete-account-customer">
                      <NavLink to="/delete-account-customer">
                        <ShopOutlined />
                        <span>Delete Account</span>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu key="report" icon={<ShopOutlined />} title="Report">
                    <Menu.Item key="/user-transactions">
                      <NavLink to="/user-transactions">
                        <ShopOutlined />
                        <span>Customer Transactions</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="/register-report">
                      <NavLink to="/register-report">
                        <ShopOutlined />
                        <span>Registration Report</span>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu key="system" icon={<ShopOutlined />} title="System">
                    <Menu.Item key="/user">
                      <NavLink to="/user">
                        <ShopOutlined />
                        <span>User</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="/userlog">
                      <NavLink to="/userlog">
                        <ShopOutlined />
                        <span>User Log</span>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>
                </Menu>
              </Sider>
              <ContentDashboard>
                <article className={this.props.className}>
                  <PageTitle
                    hasButton={this.props.hasButton}
                    className="title"
                    title={this.props.title}
                    firstBtn={this.props.firstBtn}
                    secondBtn={this.props.secondBtn}
                  />
                  {this.props.children}
                </article>
              </ContentDashboard>
            </Layout>
          </Content>
        </Layout>
        <Footer style={{ minHeight: "53px", backgroundColor: "black" }} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.users ? state.users.role : 0,
  name: state.users ? state.users.name : "",
  email: state.users ? state.users.email : "",
  image: state.users ? state.users.image : "",
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

// export default LayoutDashboard;
export default connect(mapStateToProps, mapDispatchToProps)(LayoutDashboard);
