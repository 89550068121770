const initialState = {
  menu: null,
};

const isMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case "IS_MENU":
      // eslint-disable-next-line no-case-declarations
      const menus = action.payload;
      return {
        ...state,
        menu: menus,
      };
    default:
      return state;
  }
};

export default isMenuReducer;
