import React, { Component } from "react";
import {
  Dropdown,
  Menu,
  Spin,
  Button,
  Modal,
  Input,
  Row,
  Col,
  Form,
  Radio,
} from "antd";
import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { Layout } from "../../containers";
import { AdvancedTableSearch } from "../../views";
import { userAction } from "../../../store/action";
import { songApi } from "../../../apis";

const { confirm } = Modal;

class MediaPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      total: 0,
      visible: false,
      editModal: false,
      typeSearch: 1,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const { start, length } = this.state;

    await this.setState({ loading: true });
    await songApi
      .MediaTable(start, length)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    await this.setState({ loading: false });
  };

  handleSearch = (typeFilter) => {
    this.setState({ loading: true });
    const { length, search, typeSearch } = this.state;
    const roleId = [];
    if (typeSearch === 2) {
      if (search === "music" || search === "Music") {
        roleId.push(1);
      } else {
        roleId.push(0);
      }
    }
    const text = roleId.length > 0 ? roleId[0] : search;
    songApi
      .MediaTable(0, length, text, typeSearch)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          loading: false,
          typeFilter,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handlePaggination = (start, length) => {
    const { search, typeSearch } = this.state;
    this.setState({ loading: true });
    const roleId = [];
    if (typeSearch === 2) {
      if (search === "music" || search === "Music") {
        roleId.push(1);
      } else {
        roleId.push(0);
      }
    }
    const text = roleId.length > 0 ? roleId[0] : search;
    songApi
      .MediaTable(start, length, text, typeSearch)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
          start,
          length,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleDelete = (id) => {
    songApi
      .DeleteMedia(id)
      .then(() => {
        this.componentDidMount();
      })
      .catch((error) => {
        throw error;
      });
  };

  onFinishEdit = (values) => {
    Swal.fire({
      title: "ระบบกำลังทำงาน",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    songApi
      .EditMedia(this.state.id, {
        name: values.name,
        url: values.url,
        type: values.type,
        source: values.source,
      })
      .then((resp) => {
        Swal.fire({
          icon: "success",
          title: `${resp.message}`,
          timer: 1500,
        }).then(() => {
          this.setState({
            editModal: false,
            loading: false,
            data: [],
            total: 0,
            visible: false,
            typeSearch: 1,
          });
          this.componentDidMount();
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  onFinish = (values) => {
    Swal.fire({
      title: "ระบบกำลังทำงาน",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    songApi
      .CreateMedia({
        name: values.name,
        url: values.url,
        type: values.type,
        source: values.source,
      })
      .then((resp) => {
        Swal.fire({
          icon: "success",
          title: `${resp.message}`,
          timer: 1500,
        }).then(() => {
          this.setState({
            editModal: false,
            loading: false,
            data: [],
            total: 0,
            visible: false,
            typeSearch: 1,
          });
          this.componentDidMount();
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  onChangeSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[11].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, data, total, check_token } = this.state;

    const menu = (record) => (
      <Menu className="datable-actions-menu">
        {isEdit[0] === 1 && (
          <Menu.Item
            onClick={async () => {
              await this.setState({
                name: record.name,
                url: record.url,
                type: record.type,
                id: record.id,
              });
              await this.setState({
                editModal: true,
              });
            }}
          >
            Edit
          </Menu.Item>
        )}
        {isDelete[0] === 1 && (
          <Menu.Item>
            <Link
              to="/media"
              onClick={() => {
                confirm({
                  title: "ต้องการลบข้อมูลข่าวใช่หรือไม่?",
                  centered: true,
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => {
                    this.handleDelete(record.id);
                  },
                });
              }}
            >
              Delete
            </Link>
          </Menu.Item>
        )}
      </Menu>
    );

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        id: "name",
        key: "name",
        type: "input",
        value: "",
        selectData: [],
        onChange: () => console.log("ok"),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        type: "select",
        value: "all",
        selectData: [
          {
            id: 1,
            name: "Music",
          },
          { id: 0, name: "Video" },
        ],
        onChange: (urlType) => this.setState({ urlType }),
        render: (text) => <span>{text === 1 ? "Music" : "Video"}</span>,
      },
      {
        title: "Source",
        dataIndex: "url",
        key: "url",
        type: "input",
        value: "",
        selectData: [],
        render: (text) => (
          <span>{text.length < 50 ? text : `${text.slice(0, 50)}...`}</span>
        ),
      },
      {
        title: "Entry date",
        dataIndex: "created_at",
        key: "created_at",
        type: "date",
        value: "",
        selectData: [],
      },
      {
        title: "Action",
        key: "action",
        width: "10%",
        align: "center",
        render: (text, record) => (
          <Dropdown
            trigger={["click"]}
            overlay={menu(record)}
            placement="bottomRight"
          >
            <Button type="link">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="Media"
            extraBtn={
              isCreate[0] === 1
                ? [
                    <Button
                      type="primary"
                      onClick={() =>
                        this.setState({
                          visible: true,
                        })
                      }
                    >
                      + Add media
                    </Button>,
                  ]
                : []
            }
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "media",
                breadcrumbName: "Media",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <AdvancedTableSearch
                noFillter
                fileName="news"
                columns={columns}
                data={data}
                handleSearch={this.handleSearch}
                onChangeSearch={this.onChangeSearch}
                defaultValueSearch="name"
                onChangeTypeSearch={(value) => {
                  this.setState({
                    typeSearch: value === "name" ? 1 : 2,
                  });
                }}
                loading={loading}
                searchCondition={["created_at", "url", "action"]}
                columnsFilter={columns}
                rowKey={(record) => record.id}
                total={total}
                onTableChange={this.handlePaggination}
              />
              <Modal
                title="Add media"
                visible={this.state.visible}
                onCancel={() => this.setState({ visible: false })}
                footer={null}
                centered
              >
                <Form onFinish={this.onFinish} layout="vertical">
                  <Form.Item
                    name={["name"]}
                    label="Name"
                    rules={[{ required: true, message: "Name is required!" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={["type"]}
                    label="Media Type"
                    initialValue={0}
                  >
                    <Radio.Group>
                      <Radio value={1}>Music</Radio>
                      <Radio value={2}>Video</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name={["source"]}
                    label="Media Source"
                    initialValue={1}
                  >
                    <Radio.Group>
                      <Radio value={1}>External url</Radio>
                      <Radio disabled value={2}>
                        upload
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name={["url"]}
                    label="Url"
                    rules={[{ required: true, message: "Url is required!" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item className="my-3">
                    <Row>
                      <Col span={24}>
                        <Button type="primary" htmlType="submit">
                          Add
                        </Button>
                        <Button
                          className="mx-4"
                          onClick={() => this.setState({ visible: false })}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </Modal>
              {this.state.editModal === true && (
                <Modal
                  title="Edit media"
                  visible={this.state.editModal}
                  onCancel={() => this.setState({ editModal: false })}
                  footer={null}
                  centered
                >
                  <Form onFinish={this.onFinishEdit} layout="vertical">
                    <Form.Item
                      name={["name"]}
                      label="Name"
                      initialValue={this.state.name}
                      rules={[{ required: true, message: "Name is required!" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={["type"]}
                      initialValue={this.state.type}
                      label="Media Type"
                    >
                      <Radio.Group>
                        <Radio value={1}>Music</Radio>
                        <Radio value={2}>Video</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      name={["source"]}
                      label="Media Source"
                      initialValue={1}
                    >
                      <Radio.Group>
                        <Radio value={1}>External url</Radio>
                        <Radio disabled value={2}>
                          upload
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      name={["url"]}
                      label="Url"
                      initialValue={this.state.url}
                      rules={[{ required: true, message: "Url is required!" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item className="my-3">
                      <Row>
                        <Col span={24}>
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                          <Button
                            className="mx-4"
                            onClick={() => this.setState({ editModal: false })}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form>
                </Modal>
              )}
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaPage);
