import React, { Component } from "react";
import { Col, Row, Spin, Button, Card, Tabs } from "antd";
import styled from "styled-components";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactPlayer from "react-player";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import AvatarProfile from "../../commons/AvatarProfile";
import { eventsRenewApi } from "../../../apis";
import TagStatus from "../../commons/TagStatus";
import MapView from "../../commons/MapView";
import { userAction } from "../../../store/action";
import { Layout } from "../../containers";
import {
  FaCalendar,
  FaClock,
  FaLocationArrow,
  FaDollarSign,
} from "react-icons/fa";

const { TabPane } = Tabs;

export const DivArtists = styled.div`
  padding: 2.5em;
  font-size: 18px;
  font-weight: 500;
  background-color: white;

  img {
    width: 100%;
  }

  .text-size {
    font-size: 14px;
  }

  .total-follower-color {
    margin: 0;
    color: #01a69d;
    font-size: 22px;
  }
`;

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class ViewEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      artistData: [],
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await eventsRenewApi
      .getEvent(this.props.match.params.eventId)
      .then((resp) => {
        this.setState({
          lat: resp.data.lat,
          lng: resp.data.lng,
          data: {
            id: this.props.match.params.eventId,
            event_name: resp.data.name,
            description: resp.data.description,
            short_description: resp.data.short_description,
            artist: resp.data.artists.array,
            event_data: {
              date_start: resp.data.start_date,
              date_end: resp.data.end_date,
              time_start: resp.data.start_time,
              time_end: resp.data.end_time,
              location_name: resp.data.location,
              ticket_price: formatNumber(resp.data.price),
              url: resp.data.url,
            },
            status: resp.data.status,
            image: resp.data.cover,
          },
          loadmore: false,
          loading: false,
        });
      });
  };

  handleOk = async () => {
    await eventsRenewApi
      .DeleteEvent(this.props.match.params.eventId)
      .then((resp) => {
        Swal.fire({
          icon: "success",
          title: `${resp.message}`,
          timer: 1500,
        }).then(() => {
          this.props.history.push("/events");
        });
      });
  };

  showModal = () => {
    Swal.fire({
      title: "ต้องการลบข้อมูลอีเวนท์ใช่หรือไม่",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.handleOk();
      }
    });
  };

  handleClick = () => {};

  render() {
    const menus = this.props.menu;
    const { loading, check_token } = this.state;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[8].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { data, artistData, loadmore, lat, lng } = this.state;
    console.log(artistData);
    return (
      <>
        {check_token === true && (
          <Layout
            title="Event Detail"
            extraBtn={[
              <Button
                onClick={() =>
                  this.props.history.push(
                    `/events/${this.props.match.params.eventId}/edit`,
                  )
                }
                style={{ display: isEdit[0] === 1 ? "" : "none" }}
                type="primary"
              >
                Edit
              </Button>,
              <Button
                style={{ display: isDelete[0] === 1 ? "" : "none" }}
                type="danger"
                onClick={this.showModal}
              >
                Delete
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "events",
                breadcrumbName: "Events",
              },
              {
                path: "eventDetail",
                breadcrumbName: "Event Detail",
              },
            ]}
          >
            {loading === false && (
              <div>
                <Spin spinning={loadmore} tip="Loading...">
                  <Card>
                    <Row gutter={[16, 16]}>
                      <Col span={5}>
                        <Row>
                          <Col md={20} className="text-center">
                            <span className="avatar_img">
                              <img
                                src={data.image}
                                alt={data.image}
                                className="mb-4"
                              />
                            </span>
                            <TagStatus StatusValue={1} />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={18}>
                        <h5>{data.event_name}</h5>
                        <p className="text-muted">{data.short_description}</p>
                        <ul className="list-unstyled fs-sm mb-0">
                          <li className="d-flex mb-2">
                            <FaCalendar className="mr-2" />
                            <span className="ml-2">
                              {data.event_data.date_start} -{" "}
                              {data.event_data.date_end}
                            </span>
                          </li>
                          <li className="d-flex mb-2">
                            <FaClock className="mr-2" />
                            <span className="ml-2">
                              {data.event_data.time_start} -{" "}
                              {data.event_data.time_end}
                            </span>
                          </li>
                          <li className="d-flex mb-2">
                            <FaLocationArrow className="mr-2" />
                            <span className="ml-2">
                              {data.event_data.location_name}
                            </span>
                          </li>
                          <li className="d-flex">
                            <FaDollarSign className="mr-2" />
                            <span className="ml-2">
                              {data.event_data.ticket_price} บาท
                            </span>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Card>
                </Spin>

                <Spin spinning={loadmore} tip="Loading...">
                  <Tabs
                    className="customer-tabs"
                    defaultActiveKey="info"
                    onChange={this.callback}
                  >
                    <TabPane tab="Description" key="description">
                      <Card>
                        <p className="text-muted">
                          {data.description && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data.description,
                              }}
                            />
                          )}
                        </p>
                      </Card>
                    </TabPane>
                    <TabPane tab="Google map" key="map">
                      <Card>
                        <MapView
                          isMarkerShown
                          lat={+lat}
                          lng={+lng}
                          zoom={15}
                        />
                      </Card>
                    </TabPane>
                    <TabPane tab="Artists" key="artists">
                      <Spin spinning={loadmore}>
                        <Card>
                          <Row align="top">
                            {data.artist.length > 0 ? (
                              <Row align="top" className="w-100">
                                {data.artist.map((item) => (
                                  <Col md={4} key={item.id}>
                                    <AvatarProfile
                                      className="text-center mb-3"
                                      imgSize={90}
                                      imgSrc={item.image}
                                      artistName={item.name}
                                    />
                                  </Col>
                                ))}
                              </Row>
                            ) : (
                              "ไม่มี ศิลปิน"
                            )}
                          </Row>
                          {data.length > 0 && loadmore !== false && (
                            <div className="text-center">
                              <Button
                                type="primary"
                                size="large"
                                onClick={this.loadMore}
                              >
                                Load more
                              </Button>
                            </div>
                          )}
                        </Card>
                      </Spin>
                    </TabPane>
                    <TabPane tab="Youtube" key="youtube">
                      <Card>
                        <ReactPlayer
                          width="853px"
                          height="480px"
                          url={data.event_data.url}
                        />
                      </Card>
                    </TabPane>
                  </Tabs>
                </Spin>
              </div>
            )}
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewEvent);
