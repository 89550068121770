import React, { Component } from "react";
import { LayoutDashboard } from "../../commons";
import { Avatar, Tag, Spin } from "antd";
import PageTable from "../../commons/PageTable";
import { CustomerApi } from "../../../apis";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";

class PointsRewards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageTitle: "",
      start: 0,
      length: 20,
      search: "",
      total: 0,
      data: [],
      dataPrint: [],
      customerName: "",
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    this.setState({ loading: true });
    const { start, length } = this.state;
    const id = this.props.match.params.customerId;
    await CustomerApi.getCustomerById(id).then((response) => {
      this.setState({ customerName: response.data && response.data.fullname });
    });
    await CustomerApi.customerIdHistory(start, length, id).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        data: resp.data,
        total: resp.recordsTotal,
        loading: false,
      }));
    });
    await CustomerApi.customerIdPointReport(id).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        dataPrint: resp.data,
        loading: false,
      }));
    });
  };

  handlePaggination = (start, length, search, page, size) => {
    const id = this.props.match.params.customerId;
    this.setState({ loadingReport: true });
    this.setState((prevState) => ({
      ...prevState,
      start,
      length,
      page: page ? page : 1,
      size: size ? size : 20,
    }));
    CustomerApi.customerIdHistory(start, length, +id).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        data: resp.data,
        total: resp.recordsTotal,
        loading: false,
      }));
    });
  };

  render() {
    const {
      data,
      loading,
      customerName,
      dataPrint,
      page,
      size,
      total,
      check_token,
    } = this.state;
    const columns = [
      {
        title: "Pic.",
        dataIndex: "image",
        key: "image",
        align: "center",
        render: (text, record) => (
          <Avatar
            shape="square"
            className="rounded"
            size={64}
            src={record.image}
          />
        ),
      },
      {
        title: "Detail",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Date",
        dataIndex: "created_at",
        key: "created_at",
        render: (text, record) => (
          <span style={{ fontSize: 14 }} className="text-muted">
            {record.created_at}
          </span>
        ),
      },
      {
        title: "Type",
        dataIndex: "points",
        key: "points",
        render: (text, record) => (
          <Tag
            color={
              record.type_points === 0
                ? "volcano"
                : record.type_points === 1
                ? "green"
                : ""
            }
            key={record.points}
          >
            {record.type_points === 0 ? "-" : "+"} {record.points}
          </Tag>
        ),
      },
    ];

    const headers = [
      { label: "Customer Name", key: "customer_name" },
      { label: "Rewards Name", key: "rewards_detail" },
      { label: "Points", key: "points" },
      { label: "Created At", key: "created_at" },
    ];

    return (
      <>
        {check_token === true && (
          <LayoutDashboard
            hasButton
            className="comtain-fluid"
            title={customerName}
            editName={customerName}
            caption="Points / Rewards"
            buttonName="Export Report"
            btnFirstExport
            dataFirstExport={dataPrint}
            headersFirstExport={headers}
            fileName={`${customerName}-points`}
          >
            <Spin spinning={loading} tip="Loading...">
              <PageTable
                rowKey={(record) => record.created_at}
                columns={columns}
                data={data}
                onChange={this.handlePaggination}
                total={total}
                page={page}
                size={size}
              />
            </Spin>
          </LayoutDashboard>
        )}
      </>
    );
  }
}

export default PointsRewards;
