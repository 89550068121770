import Menu from "./menu";
import userReducer from "./user";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  /* use */
  users: userReducer,
  menus: Menu,
  /* use */
});

export default rootReducer;
