import React, { Component } from "react";
import { Layout, Menu } from "antd";
import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";

import Header from "../Header";
import Sidebar from "../Sidebar";
import navigation from "../_nav";
import { httpClient } from "../../utils/axios";

// import { ContentDashboard } from "./style";
import * as ACTION from "../../../store/action";
import PageTitle from "../../commons/PageTitle";
import firebase from "../../utils/firebase";

const { Content } = Layout;
class LayoutDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: true,
      menuLoading: true,
      menus: [],
    };
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.onLoadUser();
  }

  onLoadUser = async () => {
    const that = this;
    httpClient
      .get("/users/me")
      .then((response) => {
        if (response !== undefined) {
          that.setState({
            email: response.data.data.email,
            name: response.data.data.name,
            image: response.data.data.image,
            loading: false,
          });
          localStorage.setItem("userData", response.data.data.name);
          httpClient
            .get("/menus/list")
            .then((response) => {
              if (response !== undefined) {
                // console.log("/menus/list");
                // refreshToken(true);
                const array = new Array(30);
                const menu = response.data.data;
                for (let i = 0; i < menu.length; i += 1) {
                  array[menu[i].module_id] = menu[i];
                }
                this.setState({
                  menus: array,
                  menuLoading: false,
                });
                this.props.isMenu(array);
              }
            })
            .catch((error) => {
              throw error;
            });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.onLoadUser();
        }
        throw error;
      });
  };

  logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.clear();
        window.location.href = "/";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const menu = (
      <Menu className="datable-actions-menu">
        <Menu.Item icon={<SettingOutlined />}>
          <Link
            to={{
              pathname: `/change-password`,
              query: { email: this.state.email },
            }}
          >
            Change password
          </Link>
        </Menu.Item>
        <Menu.Item onClick={this.logout} icon={<LogoutOutlined />}>
          <span>Log out</span>
        </Menu.Item>
      </Menu>
    );
    const { loading, email, name, image, menuLoading, menus } = this.state;
    return (
      <>
        <Header menu={menu} name={name} email={email} image={image} />
        <Layout>
          {menuLoading === false && (
            <>
              <Sidebar
                defaultSelectedKeys={["/dashboard"]}
                location={[this.props.location]}
                navi={navigation}
                menus={menus}
              />
              <Layout style={{ padding: "24px", marginBottom: "24px" }}>
                <PageTitle
                  title={this.props.title}
                  extraBtn={this.props.extraBtn}
                  breadcrumbNav={this.props.breadcrumbNav}
                />
                {loading === false && <Content>{this.props.children}</Content>}
              </Layout>
            </>
          )}
        </Layout>
      </>
    );
  }
}
function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    isMenu: (menus) => dispatch(ACTION.menuAction.isMenu(menus)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LayoutDashboard);
