import React, { Component } from "react";
import { Avatar, Spin, Descriptions } from "antd";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { Layout } from "../../containers";
import { AdvancedTableSearch } from "../../views";
import { DateTimeLong } from "../../commons";
import { eventsApi } from "../../../apis";

class ViewManualAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataManualPoints: [],
      dataManualPointsView: [],
      dataReport: [],
      start: 0,
      length: 20,
      search: "",
      total: 0,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const id = this.props.match.params.manualAddId;
    const { start, length, search } = this.state;

    this.setState({ loading: true });
    const eventManualPointsViewId = eventsApi
      .eventManualPointsViewId(id)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          dataManualPoints: resp.data,
        }));
      });
    const eventsManualPointsReport = eventsApi
      .eventsManualPointsReport(start, length, search, id)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          dataManualPointsView: resp.data,
        }));
      });
    await Promise.all([eventManualPointsViewId, eventsManualPointsReport]);
    await eventsApi.eventsManualPointsViewReport(id).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        dataReport: resp.data,
      }));
    });
    await this.setState({
      loading: false,
    });
  };

  handlePaggination = (start, length, search) => {
    this.setState({ loading: true });
    this.setState((prevState) => ({
      ...prevState,
      start,
      length,
      search,
    }));
    const id = this.props.match.params.manualAddId;

    eventsApi
      .eventsManualPointsReport(start, length, search, id)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          dataManualPointsView: resp.data,
          loading: false,
        }));
      });
  };

  handleSearch = (value) => {
    this.setState({ loading: true });
    const { length } = this.state;
    const id = this.props.match.params.manualAddId;

    eventsApi.eventsManualPointsReport(0, length, value, id).then((resp) => {
      this.setState((prevState) => ({
        ...prevState,
        dataManualPointsView: resp.data,
        loading: false,
      }));
    });
  };

  render() {
    const columns = [
      {
        title: "Image",
        dataIndex: "image",
        key: "image",
        render: (text, record) => (
          <Avatar
            size={64}
            shape="circle"
            src={record.customer && record.customer.image}
          />
        ),
      },
      {
        title: "Name",
        dataIndex: "fullname",
        key: "fullname",
        render: (text, record) => (
          <span>{record.customer && record.customer.fullname}</span>
        ),
      },
    ];

    const {
      dataManualPoints,
      loading,
      dataManualPointsView,
      total,
      dataReport,
      check_token,
    } = this.state;

    const headers = [
      { label: "Name", key: "customer.fullname" },
      { label: "Gender", key: "customer.gender" },
      { label: "Email", key: "customer.email" },
      { label: "Age", key: "customer.age" },
      { label: "Birthdate", key: "customer.birthdate" },
      { label: "Telephone", key: "customer.telephone" },
      { label: "Points", key: "customer.customers_points.points" },
      { label: "Address", key: "customer.address" },
      { label: "Sub District", key: "customer.district.name_th" },
      { label: "District", key: "customer.amphure.name_th" },
      { label: "Province", key: "customer.province.name_th" },
      { label: "Zip Code", key: "customer.district.zip_code" },
      { label: "Reason", key: "event.events_details.detail" },
      { label: "Reason Points", key: "customers_points_logs.points" },
      { label: "Created At", key: "created_at" },
    ];
    const id = this.props.match.params.manualAddId;
    return (
      <>
        {check_token === true && (
          <Layout
            title="Adjust points detail"
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "manual-add",
                breadcrumbName: "Adjust Points",
              },
              {
                path: `${id}`,
                breadcrumbName: "Adjust points detail",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <Descriptions title={dataManualPoints.name} column={1}>
                <Descriptions.Item
                  label="detail"
                  labelStyle={{ display: "none" }}
                >
                  {dataManualPoints.events_details &&
                    dataManualPoints.events_details.detail}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Point"
                  labelStyle={{ fontWeight: "bold" }}
                >
                  {dataManualPoints.points}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Entry date"
                  labelStyle={{ fontWeight: "bold" }}
                >
                  <DateTimeLong dateTime="12/11/2020 18:02:29" />
                </Descriptions.Item>
              </Descriptions>

              <AdvancedTableSearch
                className="mt-4"
                title="Customer List"
                dataExport={dataReport}
                fileName="manual-add-points"
                headers={headers}
                noFillter
                columns={columns}
                data={dataManualPointsView}
                handleSearch={(value) => this.handleSearch(value)}
                onChangeSearch={(e) =>
                  e.target.value === "" && this.componentDidMount()
                }
                loading={loading}
                searchCondition={["image", "fullname", "action"]}
                noDropdownSearch
                rowKey={() => Math.random() * 100}
                total={total}
                onTableChange={this.handlePaggination}
              />
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

export default ViewManualAdd;
