import React, { Component } from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { Modal, Spin, Button, Upload, Typography, Form, Row, Col } from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { IoMdCloseCircle } from "react-icons/io";
import * as Swal from "sweetalert2";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { userAction } from "../../../store/action";
import { Layout } from "../../containers";
import { cmsApi } from "../../../apis";

const { confirm } = Modal;
const { Text } = Typography;
const UploadStyle = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
  }
`;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    console.log(file);
    onSuccess("ok");
  }, 0);
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", (e) => {
    const img = new Image();
    img.src = e.target.result;
    img.onload = () => {
      callback(reader.result);
    };
  });
  Swal.close();
  reader.readAsDataURL(img);
}
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  const isLt512k = file.size / 1024 / 1024 < 0.512;
  if (!isLt512k) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `Image must smaller than 512KB!`,
      timer: 1500,
    });
  }
  return isJpgOrPng && isLt512k;
};
class PortfolioList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      loading: true,
      visible: false,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    cmsApi
      .PortfoliosTable()
      .then((resp) => {
        this.setState({
          items: resp.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleChangePhoto = async (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      await getBase64(info.file.originFileObj, (imageUrl) => {
        this.setState({
          imageUrl,
          loading: false,
          imageFile: info.file.originFileObj,
        });
      });
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      imageUrl: null,
    });
  };

  onFinish = () => {
    const formData = new FormData();
    formData.append("image", this.state.imageFile);
    Swal.fire({
      title: "ต้องการสร้างข้อมูล Portfolio ใช่หรือไม่",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "ระบบกำลังทำงาน",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        cmsApi
          .createPortfolios(formData)
          .then((resp) => {
            Swal.fire({
              icon: "success",
              title: `${resp.message}`,
              timer: 1500,
            }).then(() => {
              this.setState({
                visible: false,
                imageUrl: null,
                imageFile: null,
              });
              this.componentDidMount();
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
          });
      }
    });
  };

  onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      await this.setState({
        items: arrayMove(this.state.items, oldIndex, newIndex),
      });
      const list = [];
      for (let i = 0; i < this.state.items.length; i += 1) {
        list.push({
          id: this.state.items[i].id,
          index: i + 1,
        });
      }
      cmsApi.indexPortfolios({ obj: list }).catch((error) => {
        throw error;
      });
    }
  };

  removeImage = (id) => {
    confirm({
      title: "ต้องการลบข้อมูลใช่หรือไม่?",
      centered: true,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        Swal.fire({
          title: "ระบบกำลังทำงาน",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        cmsApi
          .deletePortfolios(id)
          .then((resp) => {
            Swal.fire({
              icon: "success",
              title: `${resp.message}`,
              timer: 1500,
            }).then(() => {
              this.componentDidMount();
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
          });
      },
    });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[7].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, imageUrl, check_token } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    const SortableItem = SortableElement(({ item }) => (
      <div className="sortable-hoc-item">
        <div className="sortable-close">
          <Button
            style={{ background: "transparent", border: "transparent" }}
            onClick={() => this.removeImage(item.id)}
          >
            <IoMdCloseCircle
              style={{
                fontSize: "xx-large",
                color: "#FE0000",
                background: "white",
                borderRadius: "50%",
              }}
            />
          </Button>
        </div>
        <div className="sortable-hoc-inner-item">
          <img src={item.image} alt="item" />
        </div>
      </div>
    ));

    const SortableList = SortableContainer(({ items }) => (
      <div className="sortable-hoc-container">
        {items.map((item, index) => (
          <SortableItem key={`${item.id}`} index={index} item={item} />
        ))}
      </div>
    ));

    return (
      <>
        {check_token === true && (
          <Layout
            title="Portfolio"
            extraBtn={
              isCreate[0] === 1
                ? [
                    <Button
                      disabled={loading}
                      type="primary"
                      onClick={() => this.setState({ visible: true })}
                    >
                      + Add portfolio
                    </Button>,
                  ]
                : []
            }
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "portfolio",
                breadcrumbName: "Portfolio",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              {loading === false && (
                <SortableList
                  items={this.state.items}
                  onSortEnd={this.onSortEnd}
                  axis="xy"
                  helperClass="SortableHelper"
                />
              )}
            </Spin>
            <Modal
              title="Add portfolio"
              visible={this.state.visible}
              onCancel={this.handleCancel}
              footer={null}
              centered
            >
              <Text>Cover</Text>
              <Form.Item
                className="mt-4"
                extra={
                  <ul>
                    <li>Max file size 512Kb.</li>
                    <li>Image with .jpg, .jpeg and .png</li>
                    <li>ขนาดรูปแนะนำ 500*500px</li>
                  </ul>
                }
              >
                <ImgCrop aspect={1 / 1}>
                  <UploadStyle
                    accept=".jpg, .jpeg, .png"
                    name="avartar"
                    customRequest={dummyRequest}
                    listType="picture-card"
                    multiple={false}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={this.handleChangePhoto}
                    onPreview={this.onPreview}
                  >
                    {imageUrl ? (
                      <img width="100%" src={imageUrl} alt="avatar" />
                    ) : (
                      uploadButton
                    )}
                  </UploadStyle>
                </ImgCrop>
              </Form.Item>
              <Form.Item className="my-3">
                <Row>
                  <Col span={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => this.onFinish()}
                    >
                      Submit
                    </Button>
                    <Button className="mx-4" onClick={this.handleCancel}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Modal>
          </Layout>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioList);
