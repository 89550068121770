import React, { Component } from "react";
import { Table } from "antd";

class PrintTable extends Component {
  render() {
    return (
      <Table
        className={this.props.className}
        rowKey={this.props.rowKey}
        columns={this.props.columns}
        dataSource={this.props.data}
        pagination={false}
      />
    );
  }
}

export default PrintTable;
