import React, { Component } from "react";
import { Col, Form, Row, Spin, Button, Input, Card, Select, Radio } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { Layout } from "../../containers";
import { userApi } from "../../../apis";

const { Option } = Select;

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      roleUser: [],
      check_token: false,
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  handleChangeInput(changeObject) {
    this.setState(changeObject);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    userApi
      .RoleList()
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          roleUser: resp.data,
          loading: false,
          role: resp.data[0].id,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleChangeRoleUser = (value) => {
    this.setState({
      role: value,
    });
  };

  handleCancel = () => {
    this.props.history.push("/user");
  };

  render() {
    const { loading, roleUser, role, check_token } = this.state;

    const onFinish = (values) => {
      const data = {
        name: values.name,
        email: values.email,
        password: values.password,
        status: values.status,
        role_id: role,
      };
      Swal.fire({
        title: "ต้องการสร้างผู้ใช้ระบบใช่หรือไม่",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#0c7973",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: "ระบบกำลังทำงาน",
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              Swal.showLoading();
            },
          });
          userApi
            .createUser(data)
            .then((resp) => {
              Swal.fire({
                icon: "success",
                title: `${resp.message}`,
                timer: 1500,
              });
              this.props.history.push("/user");
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: `${error.response.data.message}`,
              });
            });
        }
      });
    };

    return (
      <>
        {check_token === true && (
          <Layout
            hasButton={false}
            title="Create user"
            editName="Create user"
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "user",
                breadcrumbName: "User",
              },
              {
                path: "create",
                breadcrumbName: "Create user",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <Card>
                <Row>
                  <Col xxl={8} xl={12} xs={24}>
                    <Form onFinish={onFinish} layout="vertical">
                      <Form.Item
                        name={["name"]}
                        label="Name"
                        rules={[
                          { required: true, message: "Name is required!" },
                          {
                            pattern: new RegExp("^[a-zA-Zก-ฮะ-์\\s]*$"),
                            message: "Wrong format!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={["email"]}
                        label="Email"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Please input your E-mail!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={["password"]}
                        label="Password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                        ]}
                      >
                        <Input.Password
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                      {loading === false && (
                        <Form.Item
                          name={["role"]}
                          label="Role"
                          initialValue={role}
                          rules={[
                            {
                              required: true,
                              message: "Please select user role!",
                            },
                          ]}
                        >
                          <Select
                            className="col-12 col-md-12"
                            optionFilterProp="children"
                            onChange={this.handleChangeRoleUser}
                          >
                            {roleUser.map((doc) => (
                              <Option key={doc.id} value={doc.id}>
                                {doc.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                      <Form.Item
                        name={["status"]}
                        label="Status"
                        initialValue={1}
                        rules={[
                          {
                            required: true,
                            message: "Please select user status!",
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value={1}>Active</Radio>
                          <Radio value={0}>Suspend</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item className="my-3">
                        <Row>
                          <Col span={24}>
                            <Button type="primary" htmlType="submit">
                              Add
                            </Button>
                            <Button
                              className="mx-4"
                              onClick={this.handleCancel}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

export default CreateUser;
