import React, { Component } from "react";
import {
  Col,
  Row,
  Spin,
  Button,
  Card,
  Upload,
  Form,
  Input,
  DatePicker,
  Table,
  Menu,
  Modal,
  Dropdown,
  Select,
} from "antd";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import * as Swal from "sweetalert2";
import {
  PlusOutlined,
  LoadingOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { albumApi } from "../../../apis";
import { Layout } from "../../containers";
const { Option } = Select;
const { TextArea } = Input;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    console.log(file);
    onSuccess("ok");
  }, 0);
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", (e) => {
    const img = new Image();
    img.src = e.target.result;
    img.onload = () => {
      callback(reader.result);
    };
  });
  Swal.close();
  reader.readAsDataURL(img);
}

const UploadStyle = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
  }
`;

class AlbumsCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataSource: [],
      ListSong: [],
      faild: false,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const ListSong = albumApi
      .AlbumGetSongs()
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          ListSong: resp.data,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    await Promise.all([ListSong]);
    await this.setState({ loading: false });
  };

  beforeUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", (event) => {
      // eslint-disable-next-line no-underscore-dangle
      const _loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        const { width, height } = image;
        if (width !== height) {
          this.setState({
            faild: true,
          });
          Swal.fire({
            icon: "error",
            text: `The media condition maximum width 500px sets the size of the image`,
            timer: 1500,
          });
        } else {
          this.setState({
            faild: false,
          });
        }
      });
    });
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt512k = file.size / 1024 / 1024 < 0.512;
    if (!isLt512k) {
      Swal.close();
      Swal.fire({
        icon: "error",
        text: `Image must smaller than 512KB!`,
        timer: 1500,
      });
    }
    return isJpgOrPng && isLt512k;
  };

  handleChangePhoto = async (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      await getBase64(info.file.originFileObj, (imageUrl) => {
        this.state.faild === false &&
          this.setState({
            imageUrl,
            imageFile: info.file.originFileObj,
            loading: false,
          });
      });
    }
  };

  onAdd = () => {
    const { ListSong, songAddId } = this.state;
    const data = [];
    for (let i = 0; i < ListSong.length; i += 1) {
      if (ListSong[i].id === songAddId) {
        data.push(ListSong[i]);
      }
    }
    // console.log(dataSource.concat(data[0]));
    this.setState({
      dataSource: this.state.dataSource.concat(data[0]),
      visible: false,
      songAddId: null,
    });
  };

  onRemove = (id) => {
    const { dataSource } = this.state;
    const data = [];
    for (let i = 0; i < dataSource.length; i += 1) {
      if (dataSource[i].id !== id) {
        data.push(dataSource[i]);
      }
    }
    // console.log(dataSource.concat(data[0]));
    this.setState({
      dataSource: data,
    });
  };

  render() {
    const { loading, imageUrl, dataSource, ListSong, check_token } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    const menu = (record) => (
      <Menu className="datable-actions-menu">
        <Menu.Item>
          <Link to="/albums/create" onClick={() => this.onRemove(record.id)}>
            Delete
          </Link>
        </Menu.Item>
      </Menu>
    );
    const onFinish = (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("release_years", moment(values.year).format("YYYY"));
      for (let i = 0; i < this.state.dataSource.length; i += 1) {
        formData.append("songs[]", this.state.dataSource[i].id);
      }
      formData.append("image", this.state.imageFile);
      Swal.fire({
        title: "ต้องการสร้างข้อมูลอัลบั้มใช่หรือไม่",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#0c7973",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: "ระบบกำลังทำงาน",
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              Swal.showLoading();
            },
          });
          albumApi
            .CreateAlbum(formData)
            .then((resp) => {
              Swal.fire({
                icon: "success",
                title: `${resp.message}`,
                timer: 1500,
              }).then(() => this.props.history.push("/albums"));
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: `${error.response.data.message}`,
              });
            });
        }
      });
    };
    const columns = [
      {
        title: "ชื่อเพลง",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "ศิลปิน",
        dataIndex: "artist",
        key: "artist",
        render: (text, record) => <span>{record.artists.string}</span>,
      },
      {
        title: "ความยาว/นาที",
        dataIndex: "length",
        key: "length",
        align: "right",
        render: (text, record) => (
          <span>
            {+record.duration_minutes > 9
              ? record.duration_minutes
              : `0${record.duration_minutes}`}{" "}
            :{" "}
            {+record.duration_seconds > 9
              ? record.duration_seconds
              : `0${record.duration_seconds}`}
          </span>
        ),
      },
      {
        title: "Action",
        key: "action",
        align: "right",
        render: (text, record) => (
          <Dropdown
            trigger={["click"]}
            overlay={menu(record)}
            placement="bottomRight"
          >
            <Button type="link">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];
    return (
      <>
        {check_token === true && (
          <Layout
            title="Add Albums"
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "albums",
                breadcrumbName: "Albums",
              },
              {
                path: "create",
                breadcrumbName: "Add Albums",
              },
            ]}
            extraBtn={[
              <Button
                disabled={loading}
                type="primary"
                onClick={() => this.setState({ visible: true })}
              >
                + Add song
              </Button>,
            ]}
          >
            <Form layout="vertical" size="medium" onFinish={onFinish}>
              <Spin spinning={loading} tip="Loading...">
                <Card>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Row gutter={16}>
                        <Form.Item
                          label="Cover"
                          extra={
                            <ul>
                              <li>Max file size 512Kb.</li>
                              <li>Image with .jpg, .jpeg and .png</li>
                              <li>ขนาดรูปแนะนำ 500*500px</li>
                            </ul>
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please upload image!",
                            },
                          ]}
                        >
                          <UploadStyle
                            accept=".jpg, .jpeg, .png"
                            name="avartar"
                            customRequest={dummyRequest}
                            listType="picture-card"
                            multiple={false}
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                            onChange={this.handleChangePhoto}
                            onPreview={this.onPreview}
                          >
                            {imageUrl ? (
                              <img width="100%" src={imageUrl} alt="avatar" />
                            ) : (
                              uploadButton
                            )}
                          </UploadStyle>
                        </Form.Item>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please input name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please input description!",
                          },
                        ]}
                      >
                        <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                      </Form.Item>
                      <Form.Item
                        label="Release year"
                        name="year"
                        rules={[
                          {
                            required: true,
                            message: "Please input release year!",
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder=""
                          picker="year"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Table
                    rowKey="id"
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ defaultPageSize: 20 }}
                  />
                  <Form.Item className="my-3">
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                    <Button
                      className="mx-4"
                      onClick={() => this.props.history.goBack()}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Card>
              </Spin>
            </Form>
            <Modal
              title="Add song"
              visible={this.state.visible}
              onOk={() => this.onAdd()}
              onCancel={() => this.setState({ visible: false })}
              footer={null}
              centered
            >
              <Select
                showSearch
                className="mt-2"
                style={{ width: "100%" }}
                optionFilterProp="children"
                onChange={(e) => this.setState({ songAddId: e })}
                value={this.state.songAddId}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {ListSong.map((doc) => (
                  <Option key={doc.id} value={doc.id}>
                    {doc.name}
                  </Option>
                ))}
              </Select>
              <Row className="mt-4">
                <Col span={24}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => this.onAdd()}
                  >
                    Submit
                  </Button>
                  <Button
                    className="mx-4"
                    onClick={() => this.setState({ visible: false })}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Modal>
          </Layout>
        )}
      </>
    );
  }
}

export default AlbumsCreate;
