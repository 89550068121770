import React, { Component } from "react";
import {
  Col,
  Form,
  Row,
  Spin,
  Button,
  Card,
  Input,
  Radio,
  Upload,
  Modal,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import AlertMessages from "../../commons/AlertMessages";
import ImgCrop from "antd-img-crop";
import * as Swal from "sweetalert2";
import { ArtistsApi } from "../../../apis";
import { Layout } from "../../containers";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import ReactQuill from "react-quill";
import QuillStyle from "../../commons/EditorQuill/QuillStyle";
import { httpClient } from "../../utils/axios";

const UploadStyle = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
  }
`;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    console.log(file);
    onSuccess("ok");
  }, 0);
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

async function beforeUpload(file) {
  const reader = new FileReader();
  await reader.readAsDataURL(file);
  await reader.addEventListener("load", (event) => {
    // eslint-disable-next-line no-underscore-dangle
    const _loadedImageUrl = event.target.result;
    const image = document.createElement("img");
    image.src = _loadedImageUrl;
    image.addEventListener("load", () => {
      const { width, height } = image;
      if (width !== height) {
        Swal.fire({
          icon: "error",
          text: `The media condition maximum width 500px sets the size of the image`,
          timer: 1500,
        });

        return false;
      }
    });
  });
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `You can only upload JPG/PNG file!`,
      timer: 1500,
    });
  }
  const isLt5M = file.size / 1024 / 1024 < 0.512;
  if (!isLt5M) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `Image must smaller than 512KB!`,
      timer: 1500,
    });
  }
  return isJpgOrPng && isLt5M;
}

class CreateArtists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      check_token: false,
      previewVisible: false,
      previewImage: "",
      fileList: [],
      detail: "",
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.reactQuillRef = null;
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
  };

  handleCancel = () => {
    this.props.history.push("/artists");
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt512k = file.size / 1024 / 1024 < 0.512;
    if (!isLt512k) {
      Swal.close();
      Swal.fire({
        icon: "error",
        text: `Image must smaller than 512KB!`,
        timer: 1500,
      });
    }
    return isJpgOrPng && isLt512k;
  };

  handleChange = ({ file, fileList }) => {
    if (file.status) {
      this.setState({ fileList });
    }
  };

  handleChangeInput = (changeObject) => {
    this.setState(changeObject);
  };

  handleChangePhotoCover = async (info) => {
    const that = this;
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      const filesss = await getBase64(info.file.originFileObj);
      console.log("files=>", filesss);
      that.setState({
        imageUrl: filesss,
        imageFile: info.file.originFileObj,
        loading: false,
      });
    }
  };

  uploadFiles = (uploadFileObj) => {
    const formData = new FormData();
    const that = this;
    formData.append("image", uploadFileObj);
    httpClient
      .post("/news/content/image", formData)
      .then((response) => {
        if (response.data) {
          const range = that.reactQuillRef.getEditorSelection();
          setTimeout(() => {
            that.reactQuillRef
              .getEditor()
              .insertEmbed(range.index, "image", response.data.url);
          }, 2000);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      this.uploadFiles(file, this.reactQuillRef);
    };
  };

  render() {
    const onFinish = (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("detail", values.detail);
      formData.append("facebook", values.facebook);
      formData.append("twitter", values.twitter);
      formData.append("instagram", values.instagram);
      formData.append("youtube", values.youtube);
      formData.append("status", values.status);
      formData.append("cover", this.state.imageFile);
      for (let i = 0; i < this.state.fileList.length; i += 1) {
        formData.append("image", this.state.fileList[i].originFileObj);
      }

      Swal.fire({
        title: "ต้องการสร้างข้อมูลศิลปินใช่หรือไม่",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#0c7973",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: "ระบบกำลังทำงาน",
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              Swal.showLoading();
            },
          });
          ArtistsApi.CreateArtist(formData)
            .then((resp) => {
              Swal.fire({
                icon: "success",
                title: `${resp.message}`,
                timer: 1500,
              }).then(() => this.props.history.push("/artists"));
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: `${error.response.data.message}`,
              });
            });
        }
      });
    };

    const {
      loading,
      fileList,
      previewVisible,
      previewImage,
      imageUrl,
      check_token,
    } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        {check_token === true && (
          <Layout
            title="Add Artists"
            // extraBtn={[{<Button>ถ้ามี</Button>},{<Button>ถ้ามี</Button>}]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "artists",
                breadcrumbName: "Artists",
              },
              {
                path: "create",
                breadcrumbName: "Add Artists",
              },
            ]}
          >
            <Card className="mb-4">
              <Spin spinning={loading} tip="Loading...">
                <Form layout="vertical" size="medium" onFinish={onFinish}>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <p>Cover image</p>
                      <Form.Item style={{ marginTop: +10 }} name={["image"]}>
                        <Row
                          gutter={[16, 16]}
                          type="flex"
                          align="middle"
                          className="form__upload"
                        >
                          <Col
                            xs={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            lg={{ span: 14, offset: 0 }}
                          >
                            <ImgCrop aspect={16 / 9}>
                              <UploadStyle
                                accept=".jpg, .jpeg, .png"
                                name="avartar"
                                customRequest={dummyRequest}
                                listType="picture-card"
                                multiple={false}
                                showUploadList={false}
                                beforeUpload={this.beforeUpload}
                                onChange={this.handleChangePhotoCover}
                              >
                                {imageUrl ? (
                                  <img
                                    width="100%"
                                    src={imageUrl}
                                    alt="avatar"
                                  />
                                ) : (
                                  uploadButton
                                )}
                              </UploadStyle>
                            </ImgCrop>
                          </Col>
                        </Row>
                      </Form.Item>
                      <AlertMessages type="warning" icon={false}>
                        <ul>
                          <li>Max file size 512kb.</li>
                          <li>Image with .jpg, .jpeg and .png</li>
                          <li>ขนาดรูปแนะนำ 1920*1080px</li>
                        </ul>
                      </AlertMessages>
                      <p style={{ marginTop: "16px" }}>Feature image</p>
                      <Form.Item style={{ marginTop: +10 }} name={["image"]}>
                        <Row
                          gutter={[16, 16]}
                          type="flex"
                          align="middle"
                          className="form__upload"
                        >
                          <Col
                            xs={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            lg={{ span: 14, offset: 0 }}
                          >
                            <ImgCrop aspect={1 / 1}>
                              <Upload
                                action={`${process.env.REACT_APP_FRONT_API}test`}
                                listType="picture-card"
                                fileList={fileList}
                                beforeUpload={beforeUpload}
                                onPreview={this.handlePreview}
                                onChange={this.handleChange}
                              >
                                {fileList.length >= 8 ? null : uploadButton}
                              </Upload>
                            </ImgCrop>
                          </Col>
                        </Row>
                      </Form.Item>
                      <AlertMessages type="warning" icon={false}>
                        <ul>
                          <li>Max file size 512kb.</li>
                          <li>Image with .jpg, .jpeg and .png</li>
                          <li>ขนาดรูปแนะนำ 500*500px</li>
                          <li>อัพโหลดได้ 1-5 รูป</li>
                        </ul>
                      </AlertMessages>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please input name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <QuillStyle>
                        <Form.Item
                          label="Detail"
                          name="detail"
                          initialValue={this.state.detail}
                          rules={[
                            {
                              required: true,
                              message: "Please input detail!",
                            },
                          ]}
                        >
                          <ReactQuill
                            ref={(el) => {
                              this.reactQuillRef = el;
                            }}
                            theme="snow"
                            modules={{
                              toolbar: {
                                container: [
                                  ["bold", "italic", "underline"],
                                  [
                                    {
                                      color: [
                                        "#000000",
                                        "#e60000",
                                        "#ff9900",
                                        "#ffff00",
                                        "#008a00",
                                        "#0066cc",
                                        "#9933ff",
                                        "#ffffff",
                                        "#facccc",
                                        "#ffebcc",
                                        "#ffffcc",
                                        "#cce8cc",
                                        "#cce0f5",
                                        "#ebd6ff",
                                        "#bbbbbb",
                                        "#f06666",
                                        "#ffc266",
                                        "#ffff66",
                                        "#66b966",
                                        "#66a3e0",
                                        "#c285ff",
                                        "#888888",
                                        "#a10000",
                                        "#b26b00",
                                        "#b2b200",
                                        "#006100",
                                        "#0047b2",
                                        "#6b24b2",
                                        "#444444",
                                        "#5c0000",
                                        "#663d00",
                                        "#666600",
                                        "#003700",
                                        "#002966",
                                        "#3d1466",
                                      ],
                                    },
                                  ],
                                  [{ list: "ordered" }, { list: "bullet" }],
                                  ["link", "image"],
                                  ["clean"],
                                ],
                                handlers: {
                                  image: this.imageHandler,
                                },
                              },
                              clipboard: {
                                matchVisual: false,
                              },
                            }}
                          />
                        </Form.Item>
                      </QuillStyle>
                      <Form.Item label="Facebook (Optional)" name="facebook">
                        <Input />
                      </Form.Item>
                      <Form.Item label="Instagram (Optional)" name="instagram">
                        <Input />
                      </Form.Item>
                      <Form.Item label="Youtube (Optional)" name="youtube">
                        <Input />
                      </Form.Item>
                      <Form.Item label="Twitter (Optional)" name="twitter">
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="status"
                        label="Status"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Status!",
                          },
                        ]}
                        initialValue="1"
                      >
                        <Radio.Group>
                          <Radio value="1">Active</Radio>
                          <Radio value="0">Suspend</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item className="my-3">
                    <Button type="primary" htmlType="submit">
                      Add
                    </Button>
                    <Button
                      className="mx-4"
                      onClick={() => this.handleCancel()}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
                <Modal
                  visible={previewVisible}
                  footer={null}
                  onCancel={this.handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </Spin>
            </Card>
          </Layout>
        )}
      </>
    );
  }
}

export default CreateArtists;
