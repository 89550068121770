import React, { Component } from "react";
import { Dropdown, Menu, Spin, Button, Modal, Avatar, Table } from "antd";
import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { eventsRenewApi } from "../../../apis";
import { Layout } from "../../containers";
import { TagStatus } from "../../commons";
import { userAction } from "../../../store/action";

const { confirm } = Modal;

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class EventDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      data: [],
      total: 0,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await this.setState({ loading: true });
    const { start, length, search } = this.state;
    await eventsRenewApi
      .EventStickyTable(start, length, search)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
          loading: false,
        }));
      });
  };

  handleOk = async (id) => {
    await this.setState({ loading: true });
    await this.setState({
      visible: false,
    });
    await eventsRenewApi.EventSticky(id).then((resp) => {
      Swal.fire({
        icon: "success",
        title: `${resp.message}`,
        timer: 1500,
      }).then(() => {
        this.setState({
          loading: false,
        });
        this.componentDidMount();
      });
    });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[8].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, data, total, check_token } = this.state;

    const menu = (record) => (
      <Menu className="datable-actions-menu">
        {isEdit[0] === 1 && (
          <Menu.Item>
            <Link
              to="/events/promote"
              onClick={() => {
                confirm({
                  title: "ต้องการยกเลิกการโปรโมทใช่หรือไม่?",
                  centered: true,
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => {
                    this.handleOk(record.id);
                  },
                });
              }}
            >
              Delete
            </Link>
          </Menu.Item>
        )}
      </Menu>
    );

    const columns = [
      {
        title: "Image",
        dataIndex: "image",
        key: "image",
        render: (text) => (
          <div>
            <Avatar size="128" shape="square" src={text} />
          </div>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        id: "name",
        onChange: (e) => this.setState({ name: e.target.value }),
        type: "input",
        value: "",
        selectData: [],
      },
      {
        title: "Event date",
        dataIndex: "end_date",
        key: "date",
        type: "date",
        value: "",
        selectData: [],
        onChange: (e, string) =>
          this.setState({
            startDate: moment(string[0], "DD/MM/YYYY").format("YYYY-MM-DD"),
            endDate: moment(string[1], "DD/MM/YYYY").format("YYYY-MM-DD"),
          }),
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
        id: "location",
        type: "input",
        value: "",
        selectData: [],
        onChange: (e) => this.setState({ location: e.target.value }),
      },
      {
        title: "Ticket Price",
        dataIndex: "price",
        id: "ticket_price",
        align: "right",
        render: (text) => <span>{formatNumber(+text)}</span>,
      },
      {
        title: "Type",
        dataIndex: "type",
        id: "type",
        render: (text) => (
          <span>{text === 1 ? "Event&Concert" : "Showbiz"}</span>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        type: "select",
        value: "all",
        selectData: [
          {
            id: 1,
            name: "Active",
          },
          { id: 0, name: "Suspend" },
        ],
        onChange: (status) => this.setState({ status }),
        render: (text) => <TagStatus StatusValue={text} />,
      },
      {
        title: "Action",
        key: "action",
        width: "10%",
        align: "center",
        render: (text, record) => (
          <Dropdown
            trigger={["click"]}
            overlay={menu(record)}
            placement="bottomRight"
          >
            <Button type="link">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="Events Promote"
            extraBtn={[
              <Button
                style={{ display: isCreate[0] === 1 ? "" : "none" }}
                type="primary"
                onClick={() => this.props.history.push("/events")}
              >
                Event List
              </Button>,
              <Button
                style={{ display: isEdit[0] === 1 ? "" : "none" }}
                onClick={() => this.props.history.push("/events/promote")}
              >
                Promote
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "events",
                breadcrumbName: "Events",
              },
              {
                path: "promote",
                breadcrumbName: "Events Promote",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <Table
                className="mt-4"
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={data}
                components={this.components}
                onRow={(record, index) => ({
                  index,
                  moveRow: this.moveRow,
                })}
                pagination={{
                  showSizeChanger: total <= 20 ? false : true,
                  showQuickJumper: true,
                  total,
                  onChange: (page, length) => {
                    this.handlePaggination(
                      (page - 1) * length,
                      length,
                      "",
                      page,
                      length,
                    );
                  },
                  showTotal: (total) => `รายการทั้งหมด ${total} รายการ`,
                  pageSizeOptions: ["10", "20", "30"],
                  onShowSizeChange: (page, length) => {
                    this.handlePaggination(0 * length, length, "", 1, length);
                  },
                }}
              />
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventDataTable);
