// eslint-disable-next-line max-classes-per-file
import React, { Component } from "react";
import {
  Table,
  Modal,
  Spin,
  Button,
  Input,
  Form,
  Row,
  Col,
  Avatar,
  Menu,
  Dropdown,
  Upload,
  Typography,
} from "antd";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  ExclamationCircleOutlined,
  EllipsisOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { arrayMove } from "react-sortable-hoc";
import * as Swal from "sweetalert2";
import styled from "styled-components";
import ImgCrop from "antd-img-crop";

import { Layout } from "../../containers";
import { userAction } from "../../../store/action";
import { bannerApi } from "../../../apis";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";

let dragingIndex = -1;
const { Text } = Typography;
const { confirm } = Modal;

const UploadStyle = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
  }
`;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    console.log(file);
    onSuccess("ok");
  }, 0);
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", (e) => {
    const img = new Image();
    img.src = e.target.result;
    img.onload = () => {
      callback(reader.result);
    };
  });
  Swal.close();
  reader.readAsDataURL(img);
}
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  const isLt512k = file.size / 1024 / 1024 < 0.512;
  if (!isLt512k) {
    Swal.close();
    Swal.fire({
      icon: "error",
      text: `Image must smaller than 512KB!`,
      timer: 1500,
    });
  }
  return isJpgOrPng && isLt512k;
};

class BodyRow extends Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += " drop-over-downward";
      }
      if (restProps.index < dragingIndex) {
        className += " drop-over-upward";
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />,
      ),
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex).then();

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);

class BannerMusic extends Component {
  constructor(props) {
    super(props);
    this.components = {
      body: {
        row: DragableBodyRow,
      },
    };
    this.myFormRef = React.createRef();
    this.state = {
      loading: false,
      visible: false,
      data: [],
      editVisble: false,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    bannerApi
      .bannerMusicTable()
      .then((resp) => {
        this.setState({
          data: resp.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleChangePhoto = async (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      await getBase64(info.file.originFileObj, (imageUrl) => {
        this.setState({
          imageUrl,
          loading: false,
          imageFile: info.file.originFileObj,
        });
      });
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      editVisble: false,
    });
  };

  onFinish = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("url", values.url);
    formData.append("image", this.state.imageFile);
    this.myFormRef.current.resetFields();
    Swal.fire({
      title: "ต้องการสร้างข้อมูล Banner Music ใช่หรือไม่",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "ระบบกำลังทำงาน",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        bannerApi
          .createBannerMusic(formData)
          .then((resp) => {
            Swal.fire({
              icon: "success",
              title: `${resp.message}`,
              timer: 1500,
            }).then(() => {
              this.setState({
                visible: false,
                imageUrl: null,
                imageFile: null,
              });
              this.componentDidMount();
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
          });
      }
    });
  };

  onEditFinish = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("url", values.url);
    formData.append("image", this.state.imageFile);
    Swal.fire({
      title: "ต้องการแก้ไขข้อมูล Banner Music ใช่หรือไม่",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "ระบบกำลังทำงาน",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        bannerApi
          .updateBannerMusic(formData, this.state.id)
          .then((resp) => {
            Swal.fire({
              icon: "success",
              title: `${resp.message}`,
              timer: 1500,
            }).then(() => {
              this.setState({
                editVisble: false,
                imageUrl: null,
                imageFile: null,
              });
              this.componentDidMount();
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
          });
      }
    });
  };

  moveRow = async (oldIndex, newIndex) => {
    await this.setState({
      data: arrayMove(this.state.data, oldIndex, newIndex),
    });
    const list = [];
    for (let i = 0; i < this.state.data.length; i += 1) {
      list.push({
        id: this.state.data[i].id,
        index: i + 1,
      });
    }
    bannerApi.indexBannerMusic({ obj: list }).catch((error) => {
      throw error;
    });
  };

  onEdit = (id) => {
    bannerApi
      .getBannerMusic(id)
      .then(async (resp) => {
        await this.setState({
          id: resp.data.id,
          name: resp.data.name,
          url: resp.data.url,
          imageUrl: resp.data.image,
        });
        await this.setState({
          editVisble: true,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[3].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    const { loading, imageUrl, check_token } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    const menu = (record) => (
      <Menu className="datable-actions-menu">
        {isEdit[0] === 1 && (
          <Menu.Item>
            <Link to="/banner-music" onClick={() => this.onEdit(record.id)}>
              Edit
            </Link>
          </Menu.Item>
        )}
        {isDelete[0] === 1 && (
          <Menu.Item>
            <Link
              to="/banner-music"
              onClick={() => {
                confirm({
                  title: "ต้องการลบข้อมูลใช่หรือไม่?",
                  centered: true,
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => {
                    Swal.fire({
                      title: "ระบบกำลังทำงาน",
                      allowEscapeKey: false,
                      allowOutsideClick: false,
                      onOpen: () => {
                        Swal.showLoading();
                      },
                    });
                    bannerApi
                      .deleteBannerMusic(record.id)
                      .then((resp) => {
                        Swal.fire({
                          icon: "success",
                          title: `${resp.message}`,
                          timer: 1500,
                        }).then(() => {
                          this.componentDidMount();
                        });
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: `${error.response.data.message}`,
                        });
                      });
                  },
                });
              }}
            >
              Delete
            </Link>
          </Menu.Item>
        )}
      </Menu>
    );
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Image",
        dataIndex: "image",
        key: "image",
        render: (image) => (
          <Avatar shape="square" className="rounded" size={100} src={image} />
        ),
      },
      {
        title: "Url",
        dataIndex: "url",
        key: "url",
      },
      {
        title: "Index",
        dataIndex: "index",
        id: "index",
        align: "right",
        render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
        title: "Actions",
        key: "action",
        width: "10%",
        render: (record) => (
          <Dropdown
            trigger={["click"]}
            overlay={menu(record)}
            placement="bottomRight"
          >
            <Button type="link">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="Banner music"
            extraBtn={
              isCreate[0] === 1
                ? [
                    <Button
                      disabled={loading}
                      type="primary"
                      onClick={async () => {
                        await this.setState({ visible: true });
                        this.myFormRef.current.resetFields();
                      }}
                    >
                      + Add banner
                    </Button>,
                  ]
                : []
            }
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "banner-music",
                breadcrumbName: "Banner music",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <DndProvider backend={HTML5Backend}>
                <Table
                  className="mt-4"
                  rowKey={(record) => record.id}
                  columns={columns}
                  dataSource={this.state.data}
                  components={this.components}
                  onRow={(record, index) => ({
                    index,
                    moveRow: this.moveRow,
                  })}
                  pagination={false}
                />
              </DndProvider>
            </Spin>
            <Modal
              title="Add banner"
              visible={this.state.visible}
              onOk={this.handleCancel}
              onCancel={this.handleCancel}
              footer={null}
              centered
              width={800}
            >
              <Form
                ref={this.myFormRef}
                layout="vertical"
                onFinish={this.onFinish}
              >
                <Row>
                  <Col span={8}>
                    <Text>image</Text>
                    <Form.Item
                      className="mt-4"
                      rules={[
                        { required: true, message: "Please Select image" },
                      ]}
                      extra={
                        <ul>
                          <li>Max file size 512Kb.</li>
                          <li>Image with .jpg, .jpeg and .png</li>
                          <li>ขนาดรูปที่แนะนำ 1280*720px</li>
                        </ul>
                      }
                    >
                      <ImgCrop aspect={16 / 9}>
                        <UploadStyle
                          accept=".jpg, .jpeg, .png"
                          name="avartar"
                          customRequest={dummyRequest}
                          listType="picture-card"
                          multiple={false}
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={this.handleChangePhoto}
                          onPreview={this.onPreview}
                          required
                        >
                          {imageUrl ? (
                            <img width="100%" src={imageUrl} alt="avatar" />
                          ) : (
                            uploadButton
                          )}
                        </UploadStyle>
                      </ImgCrop>
                    </Form.Item>

                    <Form.Item className="my-3">
                      <Row>
                        <Col span={24}>
                          <Button type="primary" htmlType="submit">
                            Add
                          </Button>
                          <Button
                            className="mx-4"
                            onClick={() => this.setState({ visible: false })}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        { required: true, message: "Please input name!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Url"
                      name="url"
                      rules={[{ required: true, message: "Please input url!" }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>
            <Modal
              title="Edit banner"
              visible={this.state.editVisble}
              onOk={this.handleCancel}
              onCancel={this.handleCancel}
              footer={null}
              centered
              width={800}
            >
              <Form layout="vertical" onFinish={this.onEditFinish}>
                <Row>
                  <Col span={8}>
                    <Text>image</Text>
                    <Form.Item
                      className="mt-4"
                      rules={[
                        { required: true, message: "Please Select image" },
                      ]}
                      extra={
                        <ul>
                          <li>Max file size 512Kb.</li>
                          <li>Image with .jpg, .jpeg and .png</li>
                        </ul>
                      }
                    >
                      <ImgCrop aspect={16 / 9}>
                        <UploadStyle
                          accept=".jpg, .jpeg, .png"
                          name="avartar"
                          customRequest={dummyRequest}
                          listType="picture-card"
                          multiple={false}
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={this.handleChangePhoto}
                          onPreview={this.onPreview}
                          required
                        >
                          {imageUrl ? (
                            <img width="100%" src={imageUrl} alt="avatar" />
                          ) : (
                            uploadButton
                          )}
                        </UploadStyle>
                      </ImgCrop>
                    </Form.Item>

                    <Form.Item className="my-3">
                      <Row>
                        <Col span={24}>
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                          <Button
                            className="mx-4"
                            onClick={() => this.setState({ editVisble: false })}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      initialValue={this.state.name}
                      label="Name"
                      name="name"
                      rules={[
                        { required: true, message: "Please input name!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      initialValue={this.state.url}
                      label="Url"
                      name="url"
                      rules={[{ required: true, message: "Please input url!" }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BannerMusic);
