import React from "react";
import { Select, Avatar } from "antd";
import styled from "styled-components";

const { Option } = Select;

export const SelectStyle = styled(Select)`
  padding-top: 20px;
  .ant-select-arrow {
    display: none;
  }
  width: 100%;
  .ant-select-selector {
    height: 40px !important;
    .ant-select-selection-placeholder {
      line-height: 40px !important;
    }
    .ant-select-selection-item {
      display: flex;
      align-self: center;
    }
  }
`;

const SelectImage = ({
  className,
  opt,
  onChange,
  placeholder,
  id,
  onSearch,
}) => (
  <>
    {id === null ? (
      <SelectStyle
        showSearch
        className={className}
        onChange={onChange}
        placeholder={placeholder}
        onSearch={onSearch}
        filterOption={(input, option) =>
          // console.log(option)
          option.children
            .toString()
            .toLowerCase()
            .indexOf(input.toString().toLowerCase()) >= 0
        }
      >
        {opt.map((item) => (
          <Option value={item.id} key={item.id}>
            <Avatar
              className="mr-2"
              shape="square"
              size={32}
              src={item.image_s}
            />{" "}
            {item.name}
          </Option>
        ))}
      </SelectStyle>
    ) : (
      <SelectStyle
        showSearch
        className={className}
        onChange={onChange}
        placeholder={placeholder}
        value={id}
        onSearch={onSearch}
        filterOption={(input, option) =>
          // console.log(option)
          option.children
            .toString()
            .toLowerCase()
            .indexOf(input.toString().toLowerCase()) >= 0
        }
      >
        {opt.map((item) => (
          <Option value={item.id} key={item.id}>
            <Avatar
              className="mr-2"
              shape="square"
              size={32}
              src={item.image_s}
            />{" "}
            {item.name}
          </Option>
        ))}
      </SelectStyle>
    )}
  </>
);

export default SelectImage;
