import { httpClient } from "../components/utils/axios";

export const ArtistTable = (start, length, search, type, type_filter) =>
  httpClient
    .get(
      `artists/datatable?start=${start || 0}&length=${length || 20}&search=${
        search || ""
      }&type=${type || 0}&type_filter=${type_filter}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const ArtistStickyTable = (start, length) =>
  httpClient
    .get(`artists/sticky/datatable?start=${start || 0}&length=${length || 20}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const ArtistTableReport = (search, type, type_filter) =>
  httpClient
    .get(
      `artists/export?search=${search || ""}&type=${
        type || 0
      }&type_filter=${type_filter}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const ArtistUpdate = (id, formData) =>
  httpClient
    .put(`artists?id=${id}`, formData)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const ArtistSticky = (id) =>
  httpClient
    .put(`artists/sticky?id=${id}`)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const CreateArtist = (formData) =>
  httpClient
    .post(`artists`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const DeleteArtist = (id) =>
  httpClient
    .delete(`artists?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const getArtist = (id) =>
  httpClient
    .get(`artists?id=${id}`)
    .then((response) => {
      if (response !== undefined) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const CreateArtistImage = (formData) =>
  httpClient
    .post(`artists/images`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const DeleteArtistImage = (id, artistId) =>
  httpClient
    .delete(`artists/images?id=${id}&artist_id=${artistId}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const EditIndexSticky = (data) =>
  httpClient
    .put(`artists/sticky/index`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const getFollower = (start, length, search, id) =>
  httpClient
    .post(`artists/follower/datatable`, {
      id,
      start,
      length,
      search,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const getFollowerReport = (start, length, search, id) =>
  httpClient
    .post(`artists/follower/all`, {
      id,
      start,
      length,
      search,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const getGallary = (id) =>
  httpClient
    .get(`artists/galleries/list?artist_id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getFollowerList = (id) =>
  httpClient
    .get(`artists/following/list?artist_id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const getAlbumList = (id, start, length, search) =>
  httpClient
    .get(
      `artists/albums/datatable?start=${start || 0}&length=${
        length || 4
      }&artist_id=${id}&search=${search || ""}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getAlbumListSong = (artist_id, id, start, length) =>
  httpClient
    .get(
      `artists/albums/songs/datatable?start=${start || 0}&length=${
        length || 50
      }&artist_id=${artist_id}&album_id=${id}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getSingleList = (id, start, length) =>
  httpClient
    .get(
      `artists/single/songs/datatable?start=${start || 0}&length=${
        length || 50
      }&artist_id=${id}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const CreateArtistgallary = (formData) =>
  httpClient
    .post(`artists/galleries`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const DeleteArtistgallary = (id, artistId) =>
  httpClient
    .delete(`artists/galleries?id=${id}&artist_id=${artistId}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
