import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import PropTypes from "prop-types";

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBgt64vH0Lxz5TEvrNVY48mVLPDsTGLIXY&libraries=places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(({ isMarkerShown, lat, lng, zoom }) => (
  <GoogleMap defaultZoom={zoom} defaultCenter={{ lat, lng }}>
    {isMarkerShown && <Marker position={{ lat, lng }} />}
    {console.log(isMarkerShown)}
  </GoogleMap>
));

<MyMapComponent isMarkerShown />;
MyMapComponent.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  isMarkerShown: PropTypes.bool,
  zoom: PropTypes.number,
};
MyMapComponent.defaultProps = {
  isMarkerShown: false,
  zoom: 8,
};
export default MyMapComponent;
