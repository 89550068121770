import { httpClient } from "../components/utils/axios";

export const JourneyTable = (start, length, search) =>
  httpClient
    .get(
      `journeys/datatable?start=${start || 0}&length=${length || 50}&search=${
        search || ""
      }`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const createJourney = (formData) =>
  httpClient
    .post(`journeys`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const updateJourney = (formData, id) =>
  httpClient
    .put(`journeys?id=${id}`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const deleteJourney = (id) =>
  httpClient
    .delete(`journeys?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getJourney = (id) =>
  httpClient
    .get(`journeys?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const PortfoliosTable = (start, length) =>
  httpClient
    .get(`portfolios/datatable?start=${start || 0}&length=${length || 50}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const indexPortfolios = (data) =>
  httpClient
    .put(`portfolios/index`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const createPortfolios = (formData) =>
  httpClient
    .post(`portfolios`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const deletePortfolios = (id) =>
  httpClient
    .delete(`portfolios?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });

export const NewsTable = (start, length, search, type, type_filter) =>
  httpClient
    .get(
      `news/datatable?start=${start || 0}&length=${length || 20}&search=${
        search || ""
      }&type=${type || 0}&type_filter=${type_filter}`,
    )
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const NewsStickyTable = (start, length) =>
  httpClient
    .get(`news/sticky/datatable?start=${start || 0}&length=${length || 20}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const NewsUserList = () =>
  httpClient
    .get(`news/users/list`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const NewsExportTable = (type_filter) =>
  httpClient
    .get(`news/export?type_filter=${type_filter}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const indexNews = (data) =>
  httpClient
    .put(`news/sticky/index`, data)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const updateStickyNews = (id) =>
  httpClient
    .put(`news/sticky?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const createNews = (formData) =>
  httpClient
    .post(`news`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getNews = (id) =>
  httpClient
    .get(`news?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const updateNews = (formData, id) =>
  httpClient
    .put(`news?id=${id}`, formData)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const deleteNews = (id) =>
  httpClient
    .delete(`news?id=${id}`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const NewsTagList = () =>
  httpClient
    .get(`news/tags/list?name=`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const NewUserList = () =>
  httpClient
    .get(`news/users/list?name=`)
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
