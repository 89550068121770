/* eslint import/no-anonymous-default-export: [2, {'allowArray': true}] */
import React from "react";
import {
  FaThLarge,
  FaBookOpen,
  FaCalendarCheck,
  FaTheaterMasks,
  FaMicrophoneAlt,
  FaMusic,
  FaAward,
  FaSplotch,
  FaWrench,
} from "react-icons/fa";
import { MdPermMedia, MdBurstMode, MdPeople } from "react-icons/md";

// 1: {module_id: 1, module_key: 'dashboard', actions: Array(1)}
// 2: {module_id: 2, module_key: 'spicy-club-banner', actions: Array(4)}
// 3: {module_id: 3, module_key: 'music-banner', actions: Array(4)}
// 4: {module_id: 4, module_key: 'home-banner', actions: Array(4)}
// 5: {module_id: 5, module_key: 'news', actions: Array(3)}
// 6: {module_id: 6, module_key: 'journey', actions: Array(4)}
// 7: {module_id: 7, module_key: 'portfolio', actions: Array(4)}
// 8: {module_id: 8, module_key: 'events', actions: Array(4)}
// 9: {module_id: 9, module_key: 'artists', actions: Array(4)}
// 10: {module_id: 10, module_key: 'audition', actions: Array(2)}
// 11: {module_id: 11, module_key: 'media', actions: Array(4)}
// 12: {module_id: 12, module_key: 'genre', actions: Array(3)}
// 13: {module_id: 13, module_key: 'songs', actions: Array(4)}
// 14: {module_id: 14, module_key: 'what-hot', actions: Array(4)}
// 15: {module_id: 15, module_key: 'new-release', actions: Array(4)}
// 16: {module_id: 16, module_key: 'voucher', actions: Array(4)}
// 17: {module_id: 17, module_key: 'campaign', actions: Array(4)}
// 18: {module_id: 18, module_key: 'check-in-event', actions: Array(4)}
// 19: {module_id: 19, module_key: 'adjust-points', actions: Array(2)}
// 20: {module_id: 20, module_key: 'miles', actions: Array(2)}
// 21: {module_id: 21, module_key: 'customer', actions: Array(3)}
// 22: {module_id: 22, module_key: 'users', actions: Array(4)}
// 23: {module_id: 23, module_key: 'user-logs', actions: Array(1)}
// 24: {module_id: 24, module_key: 'role', actions: Array(4)}
// 25: {module_id: 25, module_key: 'customer-transactions', actions: Array(1)}
// 26: {module_id: 26, module_key: 'register-report', actions: Array(1)}
// 27: {module_id: 27, module_key: 'modules', actions: Array(2)}
// 28: {module_id: 28, module_key: 'test', actions: Array(2)}
// 29: {module_id: 29, module_key: 'albums', actions: Array(4)}
export default [
  {
    id: "1",
    tag: "NavItem",
    name: "Dashboard",
    to: "/dashboard",
    icon: <FaThLarge className="mr-2" />,
  },
  {
    id: ["2", "3", "4"],
    tag: "NavDropdown",
    name: "Banner",
    icon: <MdBurstMode className="mr-2" />,
    children: [
      {
        id: "2",
        tag: "NavItem",
        name: "Spicy club",
        to: "/banner",
      },
      {
        id: "3",
        tag: "NavItem",
        name: "Music",
        to: "/banner-music",
      },
      {
        id: "4",
        tag: "NavItem",
        name: "Home",
        to: "/banner-home",
      },
    ],
  },
  {
    id: ["5", "30", "6"],
    tag: "NavDropdown",
    name: "CMS",
    to: "/cms",
    icon: <FaBookOpen className="mr-2" />,
    children: [
      {
        id: "30",
        tag: "NavItem",
        name: "Contact Us",
        to: "/contact-us",
      },
      {
        id: "5",
        tag: "NavItem",
        name: "News",
        to: "/news",
      },
      {
        id: "6",
        tag: "NavItem",
        name: "Journey",
        to: "/journey",
      },
      {
        id: "7",
        tag: "NavItem",
        name: "Portfolio",
        to: "/portfolio",
      },
    ],
  },
  {
    id: "8",
    tag: "NavItem",
    name: "Events",
    to: "/events",
    icon: <FaCalendarCheck className="mr-2" />,
  },
  {
    id: "9",
    tag: "NavItem",
    name: "Artists",
    to: "/artists",
    icon: <FaTheaterMasks className="mr-2" />,
  },
  {
    id: "29",
    tag: "NavItem",
    name: "Albums",
    to: "/albums",
    icon: <FaMusic className="mr-2" />,
  },
  {
    id: "10",
    tag: "NavItem",
    name: "Audition",
    to: "/audition",
    icon: <FaMicrophoneAlt className="mr-2" />,
  },
  {
    id: "11",
    tag: "NavItem",
    name: "Media",
    to: "/media",
    icon: <MdPermMedia className="mr-2" />,
  },
  {
    id: ["12", "13", "14", "15"],
    tag: "NavDropdown",
    name: "Songs",
    to: "/songs",
    icon: <FaMusic className="mr-2" />,
    children: [
      {
        id: "12",
        tag: "NavItem",
        name: "Genre",
        to: "/genre",
      },
      {
        id: "13",
        tag: "NavItem",
        name: "Songs",
        to: "/songs",
      },
      {
        id: "14",
        tag: "NavItem",
        name: "What's hot",
        to: "/what-hot",
      },
      {
        id: "15",
        tag: "NavItem",
        name: "New release",
        to: "/new-release",
      },
    ],
  },
  {
    id: ["16", "17", "18", "19"],
    tag: "NavDropdown",
    name: "Points",
    to: "/points",
    icon: <FaAward className="mr-2" />,
    children: [
      {
        id: "16",
        tag: "NavItem",
        name: "Voucher",
        to: "/voucher",
      },
      {
        id: "17",
        tag: "NavItem",
        name: "Campaign",
        to: "/campaign",
      },
      {
        id: "18",
        tag: "NavItem",
        name: "Check-in event",
        to: "/check-in-event",
      },
      {
        id: "19",
        tag: "NavItem",
        name: "Adjust points",
        to: "/manual-add",
      },
    ],
  },
  {
    id: "20",
    tag: "NavItem",
    name: "Miles",
    to: "/manual-miles",
    icon: <FaSplotch className="mr-2" />,
  },
  {
    id: "21",
    tag: "NavItem",
    name: "Customers",
    to: "/customer",
    icon: <MdPeople className="mr-2" />,
  },
  {
    id: ["22", "23", "24", "25", "26", "27"],
    tag: "NavDropdown",
    name: "System",
    to: "/system",
    icon: <FaWrench className="mr-2" />,
    children: [
      {
        id: "22",
        tag: "NavItem",
        name: "Users",
        to: "/user",
      },
      {
        id: "23",
        tag: "NavItem",
        name: "User logs",
        to: "/userlog",
      },
      {
        id: "24",
        tag: "NavItem",
        name: "Roles",
        to: "/role",
      },
      {
        id: "26",
        tag: "NavItem",
        name: "Registration Report",
        to: "/register-report",
      },
      {
        id: "25",
        tag: "NavItem",
        name: "Customer Transactions",
        to: "/user-transactions",
      },
      {
        id: "27",
        tag: "NavItem",
        name: "Modules",
        to: "/modules",
      },
    ],
  },
];
