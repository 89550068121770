import React, { Component } from "react";
import { Row, Col, Card, Tabs, Descriptions, Spin } from "antd";
import { Layout } from "../../containers";
import { auditionApi } from "../../../apis";
import ReactPlayer from "react-player";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";

const { TabPane } = Tabs;

class AuditionView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = () => {
    this.setState({
      check_token: true,
    });
    auditionApi
      .getAudition(this.props.match.params.auditionId)
      .then((res) => {
        this.setState({
          customer: res.data.customer,
          description: res.data.description,
          promote: res.data.promote,
          send_date: res.data.send_date,
          title: res.data.title,
          url: res.data.url,
          loading: false,
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  render() {
    const { loading, check_token } = this.state;
    const id = this.props.match.params.auditionId;
    return (
      <>
        {check_token === true && (
          <Layout
            title="Audition detail"
            extraBtn={[]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "audition",
                breadcrumbName: "Audition",
              },
              {
                path: `${id}`,
                breadcrumbName: "Audition detail",
              },
            ]}
          >
            {loading === false && (
              <Spin spinning={loading} tip="Loading...">
                <Row gutter={[16, 16]}>
                  <Col md={8} className="text-center">
                    <ReactPlayer
                      width="auto"
                      height="300px"
                      url={this.state.url}
                    />
                  </Col>
                  <Col md={16}>
                    <Descriptions title={this.state.title} column={1}>
                      <Descriptions.Item
                        label="Customer"
                        labelStyle={{ fontWeight: "bold" }}
                      >
                        {this.state.customer}
                      </Descriptions.Item>

                      <Descriptions.Item
                        label="Send date"
                        labelStyle={{ fontWeight: "bold" }}
                      >
                        <span className="mx-2">{this.state.send_date}</span>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Promote"
                        labelStyle={{ fontWeight: "bold" }}
                      >
                        {this.state.promote === 1 ? "Yes" : "No"}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
                <Tabs
                  className="customer-tabs"
                  defaultActiveKey="Description"
                  onChange={this.callback}
                >
                  <TabPane tab="Description" key="desc">
                    <Card>
                      {this.state.description && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.description,
                          }}
                        />
                      )}
                    </Card>
                  </TabPane>
                </Tabs>
              </Spin>
            )}
          </Layout>
        )}
      </>
    );
  }
}

export default AuditionView;
