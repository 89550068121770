import React from "react";
import { notification } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        },
      },
      value: this.props.value || "",
    };
  }

  componentDidMount = async () => {
    const { toolbar } = this.props;
    await this.setState({
      modules: {
        toolbar: toolbar || [
          ["bold", "italic", "underline", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        },
      },
    });
    await this.setState({
      loading: false,
    });
  };

  handleChange = (value) => this.setState({ value });

  prompt = () => {
    notification.open({
      message: "We got value:",
      description: (
        <span dangerouslySetInnerHTML={{ __html: this.state.value }} />
      ),
    });
  };

  render() {
    return (
      <div className={this.props.className}>
        {this.state.loading === false && (
          <ReactQuill
            theme="snow"
            onChange={this.props.onChange}
            value={this.state.value}
            modules={this.state.modules}
            // bounds={'.app'}
            placeholder={this.props.placeholder}
            // formats={this.formats}
          />
        )}
      </div>
      // responsive and edit
      // <Card title="Rich text editor">
      //   <ReactQuill value={this.state.value} onChange={this.handleChange} />
      //   <Button style={{ marginTop: 16 }} onClick={this.prompt}>Prompt</Button>
      // </Card>
    );
  }
}

// Editor.propTypes = {
//   isRules: PropTypes.bool,
//   data: PropTypes.string,
//   disabled: PropTypes.bool,
//   toolbar: PropTypes.array,
//   onChange: PropTypes.func,
// };
// export default Editor;
