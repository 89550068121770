import { httpClient } from "../components/utils/axios";
import moment from "moment";

export const getDashActivity = ({ end_date = moment.unix() }) => httpClient
    .post(`dashboard_b/customers`, {
      end_date,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getDashBoardAudience = ({ end_date = moment.unix() }) => httpClient
    .post(`dashboard_b/audience`, {
      end_date,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getDashboardVoucher = ({ end_date = moment.unix() }) => httpClient
    .post(`/dashboard_b/voucher`, {
      end_date,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getDashboardCheckInEvent = (start, length, end_date) => httpClient
    .post(`/dashboard_b/check_in_event`, {
      start,
      length,
      end_date,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getDashboardBarGraph = ({ end_date = moment.unix() }) => httpClient
    .post(`/dashboard_b/bar_graph`, {
      end_date,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getDashboardLineGraph = ({ end_date = moment.unix() }) => httpClient
    .post(`/dashboard_b/line_graph`, {
      end_date,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
export const getDashBoardAge = ({ end_date = moment.unix() }) => httpClient
    .post(`dashboard_b/age_range`, {
      end_date,
    })
    .then((res) => {
      if (res !== undefined) {
        return res.data;
      }
    })
    .catch((error) => {
      throw error;
    });
