import styled from 'styled-components';

export const LoginStyle = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  padding: 15px;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  background-color: #e5e5e5;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  justify-content: center;
  .login-wrapper {
    padding: 45px;
    background-color: #ffffff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    @media (max-width: 576px) {
      width: 100%;
      padding: 15px;
    }
    .logo {
      width: 150px;
      margin: 0 auto 30px;
    }
    .form-title {
      text-align: left;
      h1 {
        font-weight: bold;
        font-size: 2.5rem;
      }
      span {
        font-size: 2.5rem;
        color: #333333;
      }
    }
    .form-login {
      margin-top: 20px;
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
      .text-right {
        text-align: right;
      }
      .btn-wrap {
        margin-top: 0px;
        margin-bottom: 0px;
        .ant-form-item-control-input-content {
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;
