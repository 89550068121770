import React, { Component } from "react";
import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Card,
  Spin,
  Select,
  DatePicker,
  Button,
  PageHeader,
  Slider,
} from "antd";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import { Layout } from "../../containers";
import { eventsApi } from "../../../apis";
import { ListBox } from "../../commons";

const { Option } = Select;

class CreateManualAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      selected: [],
      pass: "",
      number: 0,
      dataProvinces: [],
      dataAmphures: [],
      dataArtist: [],
      dataOptions: [],
      gender: "",
      day: "",
      month: "",
      province_id: "",
      amphures_id: null,
      artist_id: "",
      age_rage: [0, 30],
      age_from: 0,
      age_to: 100,
      keywords: "",
      register_date: "",
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    this.setState({ loading: true });
    await eventsApi.eventsManualPointsProvinces().then((resp) => {
      this.setState({
        dataProvinces: resp.data,
      });
    });
    await eventsApi.eventsManualPointsArtists().then((resp) => {
      this.setState({
        dataArtist: resp.data,
      });
    });
    await this.setState({
      loading: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleChangeInput = (changeObject) => {
    this.setState(changeObject);
  };

  handleChangeGender = (e) => {
    this.setState({
      gender: e,
    });
  };

  handleChangeDay = (e) => {
    this.setState({
      day: e,
    });
  };

  handleChangeMonth = (e) => {
    this.setState({
      month: e,
    });
  };

  handleChangeProvince = (e) => {
    this.setState({
      province_id: e,
      amphures_id: null,
    });
    const provinceId = e === "" ? 0 : e;
    eventsApi.eventsManualPointsAmphures(provinceId).then((resp) => {
      this.setState({
        dataAmphures: resp.data,
      });
    });
  };

  handleChangeCounty = (e) => {
    this.setState({
      amphures_id: e,
    });
  };

  handleChangeArtists = (e) => {
    this.setState({
      artist_id: e,
    });
  };

  handleRegisterDate = (e) => {
    let date = "";
    if (!e) {
      date = "";
    } else {
      date = e.format("DD-MM-YYYY");
    }
    this.setState({
      register_date: date,
    });
  };

  handleOk = () => {
    if (this.state.pass !== "") {
      this.setState({ visible: false });
      Swal.fire({
        icon: "success",
        title: "ยืนยันรายการเรียบร้อย",
      }).then(this.props.history.push(`/manual-add`));
    } else {
      Swal.fire({
        icon: "error",
        title: "กรุณกรอกรหัสยืนยัน",
      });
    }
  };

  onSelectUer = (selected) => {
    this.setState({ selected });
  };

  onConfirmSearch = () => {
    this.setState({ loading: true });
    const {
      gender,
      day,
      month,
      province_id,
      amphures_id,
      artist_id,
      age_from,
      age_to,
      keywords,
      register_date,
    } = this.state;

    eventsApi
      .eventsManualPointsFilter(
        keywords,
        gender,
        day,
        month,
        province_id,
        amphures_id,
        artist_id,
        age_from,
        age_to,
        register_date,
      )
      .then((resp) => {
        const options = [];
        resp.data.forEach((s) => {
          options.push({ value: s.id, label: s.fullname });
        });
        this.setState({
          dataOptions: options,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  handleChangeAge = (value) => {
    this.setState({ age_from: value[0], age_to: value[1] });
  };

  render() {
    const {
      loading,
      number,
      dataProvinces,
      selected,
      dataOptions,
      dataAmphures,
      dataArtist,
      check_token,
    } = this.state;

    const listBirthSex = [
      { key: "", label: "ทุกเพศ" },
      { key: "1", label: "ชาย" },
      { key: "2", label: "หญิง" },
      { key: "3", label: "ไม่ระบุ" },
    ];

    const listBirthDay = [
      { key: "", label: "ทุกวัน" },
      { key: "0", label: "วันจันทร์" },
      { key: "1", label: "วันอังคาร" },
      { key: "2", label: "วันพุธ" },
      { key: "3", label: "วันพฤหัสบดี" },
      { key: "4", label: "วันศุกร์" },
      { key: "5", label: "วันเสาร์" },
      { key: "6", label: "วันอาทิตย์" },
    ];

    const listBirthMonth = [
      { key: "", label: "ทุกเกิด" },
      { key: "1", label: "มกราคม" },
      { key: "2", label: "กุมภาพันธ์" },
      { key: "3", label: "มีนาคม" },
      { key: "4", label: "เมษายน" },
      { key: "5", label: "พฤษภาคม" },
      { key: "6", label: "มิถุนายน" },
      { key: "7", label: "กรกฎาคม" },
      { key: "8", label: "สิงหาคม" },
      { key: "9", label: "กันยายน" },
      { key: "10", label: "ตุลาคม" },
      { key: "11", label: "พฤศจิกายน" },
      { key: "12", label: "ธันวาคม" },
    ];

    const onFinish = (values) => {
      this.setState({ loading: true });
      const that = this.props;
      Swal.fire({
        title: "ต้องการเพิ่มคะแนนให้ลูกค้าใช่หรือไม่",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#0c7973",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      })
        .then((result) => {
          if (result.value) {
            eventsApi
              .eventsManualPointsCreate({
                name: values.title,
                detail: values.reason,
                points: +values.points,
                customers: values.select,
              })
              .then((res) => {
                Swal.fire({
                  icon: "success",
                  title: `${res.message}`,
                  timer: 1000,
                });
                this.setState({ loading: false });
                that.history.push("/manual-add");
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: `${error.response.data.message}`,
                  timer: 1000,
                });
                this.setState({ loading: false });
              });
          }
        })
        .then(() => {
          this.setState({ loading: false });
        });
    };

    return (
      <>
        {check_token === true && (
          <Layout
            title="Add points"
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "manual-add",
                breadcrumbName: "Adjust Points",
              },
              {
                path: `add`,
                breadcrumbName: "Add points",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <Form
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                  gender: "",
                  day: "",
                  month: "",
                  province_id: "",
                  amphures_id: "",
                  artist_id: "",
                  age_rage: [10, 30],
                }}
              >
                <Card className="mb-4 create-points">
                  <Row gutter={[16, 16]}>
                    <Col md={12}>
                      <Form.Item
                        label="Target group"
                        name="title"
                        rules={[
                          { required: true, message: "กรุณากรอกข้อมูล title!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Points"
                        name="points"
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอกข้อมูล Increase points!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          value={number}
                          onChange={(e) =>
                            this.handleChangeInput({ number: e.target.value })
                          }
                          onKeyDown={(evt) =>
                            evt.key === "e" ||
                            evt.key === "E" ||
                            evt.key === "." ||
                            evt.key === "-"
                              ? evt.preventDefault()
                              : {}
                          }
                          style={{ width: "100%" }}
                          min={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Note"
                        name={["reason"]}
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอกข้อมูล reason!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <PageHeader
                    ghost={false}
                    className="title-filter-point mb-4"
                    title="Select customer"
                    subTitle="เลือกผู้ใช้งานที่ต้องการให้คะแนน"
                  />
                  <Row gutter={[16, 16]}>
                    <Col md={8}>
                      <Form.Item label="เพศ" name="gender">
                        <Select
                          className="w-100"
                          optionFilterProp="children"
                          onChange={this.handleChangeGender}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          value={this.state.gender}
                        >
                          {listBirthSex.map((item) => (
                            <Option key={item.key} value={item.key}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label="วันเกิด" name="day">
                        <Select
                          className="w-100"
                          optionFilterProp="children"
                          onChange={this.handleChangeDay}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          value={this.state.day}
                        >
                          {listBirthDay.map((item) => (
                            <Option key={item.key} value={item.key}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label="เดือนเกิด" name="month">
                        <Select
                          className="w-100"
                          optionFilterProp="children"
                          onChange={this.handleChangeDay}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          value={this.state.month}
                        >
                          {listBirthMonth.map((item) => (
                            <Option key={item.key} value={item.key}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label="ช่วงอายุ" name="age_rage">
                        <Slider
                          range
                          tooltipVisible
                          value={this.state.age_rage}
                          onChange={this.handleChangeAge}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label="เลือกจังหวัด" name="province_id">
                        <Select
                          className="w-100"
                          showSearch
                          value={this.state.province_id}
                          onChange={this.handleChangeProvince}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="">ทุกจังหวัด</Option>
                          {dataProvinces.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name_th}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label="เลือกเขต" name="amphures_id">
                        <Select
                          className="w-100"
                          showSearch
                          value={this.state.amphures_id}
                          onChange={this.handleChangeCounty}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="">ทุกเขต</Option>
                          {dataAmphures.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name_th}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label="เลือกคิลปิน" name="artist_id">
                        <Select
                          className="w-100"
                          showSearch
                          value={this.state.artist_id}
                          onChange={this.handleChangeArtists}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="">ทุกศิลปิน</Option>
                          {dataArtist.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label="วันที่สมัคร" name="register_date">
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={this.handleRegisterDate}
                          format="DD/MM/YYYY"
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} className="text-right">
                      <Button
                        type="primary"
                        size="large"
                        onClick={this.onConfirmSearch}
                      >
                        ตกลง
                      </Button>
                    </Col>
                  </Row>
                  <Form.Item
                    name={["select"]}
                    rules={[
                      {
                        required: true,
                        message: "กรุณาเลือกผู้ใช้งานที่ต้องการให้คะแนน",
                      },
                    ]}
                  >
                    <ListBox
                      options={dataOptions}
                      selected={selected}
                      custonText={{
                        availableHeader: "Resulte",
                        moveAllLeft: "Move all left",
                        moveAllRight: "Move all right",
                        moveLeft: "Move left",
                        moveRight: "Move right",
                        selectedHeader: "Selected",
                      }}
                      onChange={this.onSelectUer}
                    />
                  </Form.Item>
                  <Form.Item className="my-3">
                    <Button type="primary" htmlType="submit">
                      Add
                    </Button>
                    <Button
                      className="mx-4"
                      onClick={() => this.props.history.goBack()}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Card>
              </Form>
            </Spin>
            <Modal
              title="กรุณากรอกรหัสก่อนทำการให้คะแนน"
              visible={this.state.visible}
              onCancel={this.handleCancel}
              footer={[
                <Button
                  form="myForm"
                  key="submit"
                  htmlType="submit"
                  onClick={this.handleOk}
                >
                  Submit
                </Button>,
              ]}
            >
              <Input
                size="large"
                type="text"
                value={this.state.pass}
                onChange={(e) =>
                  this.handleChangeInput({ pass: e.target.value })
                }
              />
            </Modal>
          </Layout>
        )}
      </>
    );
  }
}

export default CreateManualAdd;
