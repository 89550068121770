import React, { Component } from "react";
import QRCode from "qrcode.react";

class PrintQrCode extends Component {
  render() {
    return (
      <div className="w-100 vh-90 d-flex flex-column align-items-center justify-content-center">
        <div className="w-100 text-center">
          <QRCode
            value={this.props.code} // string
            id="printme"
            bgColor="transparent"
            size={512} // number
            level="L" // string ('L' 'M' 'Q' 'H')
            includeMargin // boolean if true has bg white(default)
            renderAs="svg"
          />
        </div>
        <h2 className="text-center">Event name : {this.props.title}</h2>
      </div>
    );
  }
}

export default PrintQrCode;
