// eslint-disable-next-line max-classes-per-file
import React, { Component } from "react";
import { Modal, Spin, Button, Form, Row, Col, Input, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
import PageTable from "../../commons/PageTable";
import { Layout } from "../../containers";
import { userAction } from "../../../store/action";
import { songApi } from "../../../apis";

const { confirm } = Modal;
const { Text } = Typography;

class SongGenresDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      data: [],
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    const { start, length } = this.state;

    await this.setState({ loading: true });
    await songApi
      .GenreTable(start, length)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
    await this.setState({ loading: false });
  };

  handlePaggination = (start, length) => {
    this.setState({ loading: true });
    songApi
      .GenreTable(start, length)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          data: resp.data,
          total: resp.recordsTotal,
          start,
          length,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };

  handleDelete = (id) => {
    songApi
      .DeleteGenre(id)
      .then(() => {
        this.componentDidMount();
      })
      .catch((error) => {
        throw error;
      });
  };

  onFinish = () => {
    Swal.fire({
      title: "ระบบกำลังทำงาน",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    songApi
      .CreateGenre({
        name: this.state.name,
      })
      .then((resp) => {
        Swal.fire({
          icon: "success",
          title: `${resp.message}`,
          timer: 1500,
        }).then(() => {
          this.setState({ loading: false, visible: false, data: [], name: "" });
          this.componentDidMount();
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  render() {
    const { loading, check_token } = this.state;
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text) => <span className="ml-3">{text}</span>,
      },
      {
        title: "Actions",
        key: "action",
        width: "10%",
        render: (record) => (
          <Link
            to="/genre"
            onClick={() => {
              confirm({
                title: "ต้องการลบข้อมูล Genre ใช่หรือไม่?",
                centered: true,
                icon: <ExclamationCircleOutlined />,
                onOk: () => this.handleDelete(record.id),
              });
            }}
          >
            <Text type="danger">Delete</Text>
          </Link>
        ),
      },
    ];

    return (
      <>
        {check_token === true && (
          <Layout
            title="Genres"
            extraBtn={[
              <Button
                disabled={loading}
                type="primary"
                onClick={() => this.setState({ visible: true })}
              >
                + Add genre
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "genre",
                breadcrumbName: "Genre",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <PageTable
                rowKey={(record) => record.id}
                columns={columns}
                data={this.state.data}
                total={this.state.total}
                onChange={this.handlePaggination}
              />
            </Spin>
            <Modal
              title="Add genre"
              visible={this.state.visible}
              onOk={() => this.setState({ visible: false, name: "" })}
              onCancel={() => this.setState({ visible: false, name: "" })}
              footer={null}
              centered
            >
              <Form layout="vertical">
                <Form.Item label="Name">
                  <Input
                    defaultValue={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <Row>
                    <Col span={24}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={this.onFinish}
                      >
                        Submit
                      </Button>
                      <Button
                        className="mx-4"
                        onClick={() => this.setState({ visible: false })}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Modal>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.users ? state.users.role : 0,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SongGenresDataTable);
