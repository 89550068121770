import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const DateTimeMid = (props) => {
  const { dateTime, className } = props;
  moment.locale('en');
  return (
    <span className={className}>{moment(dateTime).format('DD MMMM YYYY')}</span>
  );
};

DateTimeMid.propTypes = {
  className: PropTypes.string,
  dateTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
DateTimeMid.defaultProps = {
  className: "",
};

export default DateTimeMid;
