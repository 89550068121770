import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Avatar,
  Image,
  Modal,
  Tabs,
  PageHeader,
  Descriptions,
  Spin,
} from "antd";
import { PrinterOutlined, UploadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import QRCode from "qrcode.react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";
// import ReactExport from "react-export-excel";
import ReactToPrint from "react-to-print";
import { Layout } from "../../containers";
import { userAction } from "../../../store/action";
import {
  DateTimeMid,
  TagStatus,
  PageTable,
  PrintQrCode,
  // DateTimeLong,
  PrintTable,
} from "../../commons";
import { eventsApi } from "../../../apis";

const { TabPane } = Tabs;
const { confirm } = Modal;

// const { ExcelFile } = ReactExport;
// const { ExcelSheet } = ReactExport.ExcelFile;
// const { ExcelColumn } = ReactExport.ExcelFile;

class VoucherView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingDetails: false,
      loadingGenerateCode: false,
      loadingAllGenerateCode: false,
      loadingAllReport: false,
      loadingReport: false,
      valueRadio: 0,
      active: "Active",
      inactive: "Inactive",
      imageUrl: "",
      nameEvent: "",
      descEvent: "",
      pointsEvent: "",
      status: false,
      amount: "",
      amount_used: 0,
      used: "",
      start_date: "",
      end_date: "",
      modalImgQrCode: "",
      type_used: "",
      time_used: "",
      start: 0,
      length: 20,
      search: "",
      dataGenerateCode: [],
      totalGenerateCode: 0,
      dataVoucherReport: [],
      totalVoucherReport: 0,
      dataPrintCustomer: [],
      dataPrintQrCode: [],
      type_code: 0,
      loading: true,
      isSendCode: false,
      selected: [],
      dataProvinces: [],
      dataAmphures: [],
      dataArtist: [],
      dataOptions: [],
      gender: "",
      day: "",
      month: "",
      province_id: "",
      amphures_id: "",
      artist_id: "",
      age_rage: [0, 100],
      age_from: 0,
      age_to: 100,
      register_date: "",
      keywords: "",
      PageName: "",
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await this.setState({
      loadingDetails: true,
      loadingGenerateCode: true,
      loadingReport: true,
      loadingAllGenerateCode: true,
      loadingAllReport: true,
    });
    const id = this.props.match.params.campaignId;
    const { start, length, search } = this.state;
    const setData = (doc) => {
      const statusA = doc.status !== 0;
      const typeUsed =
        doc.events_details && doc.events_details.type_used === 1
          ? "ใช้ทันที"
          : "เก็บไว้ใช้";
      this.setState({
        id: doc.id,
        nameEvent: doc.name,
        imageUrl: doc.image_m,
        descEvent: doc.events_details.detail,
        pointsEvent: doc.points,
        status: statusA,
        modalQrCodeName: doc.name,
        modalImgQrCode: doc.events_details && doc.events_details.code,
        amount: doc.events_details && doc.events_details.amount,
        amount_used: doc.events_details && doc.events_details.amount_used,
        start_date: doc.events_details && doc.events_details.start_date,
        end_date: doc.events_details && doc.events_details.end_date,
        type_used: typeUsed,
        time_used: doc.events_details && doc.events_details.time_used,
      });
    };

    const eventOnlineActivittyById = eventsApi
      .eventOnlineActivittyById(id)
      .then((response) => {
        setData(response.data);
        this.setState({
          used: response.used,
          loadingDetails: false,
        });
      });
    const eventOnlineActivityCustomer = eventsApi
      .eventOnlineActivityCustomer(start, length, search, id)
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          dataVoucherReport: response.data,
          totalVoucherReport: response.recordsTotal,
          loadingReport: false,
        }));
      });
    const eventOnlineActivityPrintCustomer = eventsApi
      .eventOnlineActivityPrintCustomer(id)
      .then((response) => {
        const data = [];
        response.data.forEach((element) => {
          data.push({
            created_at: element.created_at,
            address: element.customer.address,
            birthdate: element.customer.birthdate,
            email: element.customer.email,
            fullname: element.customer.fullname,
            gender: element.customer.gender,
            telephone: element.customer.telephone,
            amphure:
              element.customer.amphure && element.customer.amphure.name_th,
            district:
              element.customer.district && element.customer.district.name_th,
            zip_code:
              element.customer.district && element.customer.district.zip_code,
            province:
              element.customer.province && element.customer.province.name_th,
            code: element.events_qrcode.code,
          });
          this.setState((prevState) => ({
            ...prevState,
            dataPrintCustomer: data,
            loadingAllReport: false,
          }));
        });
      });
    const eventOnlineActivityQrCodeAll = eventsApi
      .eventOnlineActivityQrCode(id)
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          dataPrintQrCode: response.data,
          loadingAllGenerateCode: false,
        }));
      });
    const eventOnlineActivityQrCode = eventsApi
      .eventOnlineActivityQrCode(id)
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          dataGenerateCode: response.data,
          type_code: response.data.map((i) => i.type_code),
          totalGenerateCode: response.recordsTotal,
          loadingGenerateCode: false,
        }));
      });
    await Promise.all([
      eventOnlineActivityCustomer,
      eventOnlineActivittyById,
      eventOnlineActivityPrintCustomer,
      eventOnlineActivityQrCodeAll,
      eventOnlineActivityQrCode,
    ]);
    await this.loadSendData();
    await this.setState({ loading: false });
  };

  loadSendData = async () => {
    this.setState({ loading: true });
    const id = this.props.match.params.campaignId;
    const setData = (doc) => {
      this.setState({
        PageName: doc.name,
      });
    };
    await eventsApi.eventOnlineActivittyById(id).then((response) => {
      setData(response.data);
    });
    await this.setState({
      loading: false,
    });
  };

  showModal = (status) => {
    if (status === true) {
      status = this.state.active;
    } else {
      status = this.state.inactive;
    }

    Swal.fire({
      icon: "success",
      title: `${status} รายการเรียบร้อย`,
    });
  };

  handlePaggination = (start, length, search, page, size) => {
    const id = this.props.match.params.campaignId;
    this.setState({ loadingGenerateCode: true });
    this.setState((prevState) => ({
      ...prevState,
      start,
      length,
      page: page ? page : 1,
      size: size ? size : 20,
    }));
    eventsApi.eventOnlineActivityQrCode(start, length, id).then((response) => {
      this.setState((prevState) => ({
        ...prevState,
        dataGenerateCode: response.data,
        totalGenerateCode: response.recordsTotal,
        loadingGenerateCode: false,
      }));
    });
  };

  handlePagginationReport = (start, length, search, page, size) => {
    const id = this.props.match.params.campaignId;
    this.setState({ loadingReport: true });
    this.setState((prevState) => ({
      ...prevState,
      start,
      length,
      search,
      page: page ? page : 1,
      size: size ? size : 20,
    }));
    eventsApi
      .eventOnlineActivityCustomer(start, length, search, id)
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          dataVoucherReport: response.data,
          totalVoucherReport: response.recordsTotal,
          loadingReport: false,
        }));
      });
  };

  showModalQrCode = () => {
    this.setState({
      visibleImgQR: true,
    });
  };

  handleCancelImgQR = () => {
    this.setState({
      visibleImgQR: false,
    });
  };

  handleClick = () => {
    const id = this.props.match.params.campaignId;
    this.props.history.push(`/campaign/${id}/edit`);
    this.setState({
      visible: false,
    });
  };

  handleCancelQr = () => {
    this.setState({
      visibleQr: false,
    });
  };

  onChange = (e) => {
    this.setState({
      valueRadio: e.target.value,
    });
  };

  onChangeSwitch = (checked) => {
    this.setState({
      status: checked,
    });
    const id = this.props.match.params.campaignId;
    const status = checked === false ? 0 : 1;

    eventsApi
      .eventOnlineActivityStatusUpdate(id, status)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: `${response.message}`,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "success",
          title: `${error.response.data.message}`,
        });
      });
  };

  onChange = (selected) => {
    this.setState({ selected });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[17].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    moment.locale("th");
    const {
      modalImgQrCode,
      status,
      descEvent,
      imageUrl,
      nameEvent,
      pointsEvent,
      start_date,
      end_date,
      page,
      size,
      dataPrintCustomer,
      amount,
      dataVoucherReport,
      dataPrintQrCode,
      dataGenerateCode,
      totalGenerateCode,
      loading,
      check_token,
    } = this.state;
    const columnsCheckInEvent = [
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
        render: (text, record) => (
          <span>{record.events_qrcode && record.events_qrcode.code}</span>
        ),
      },
      {
        title: "Redeem date",
        dataIndex: "created_at",
        key: "created_at",
        render: (text, record) => (
          // <DateTimeLong dateTime={record.created_at} />
          <span>{record.created_at}</span>
        ),
      },
      {
        title: "Customer",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Fragment key={record}>
            <Avatar size={48} src={record.customer && record.customer.image} />
            <span className="ml-3">
              {record.customer && record.customer.fullname}
            </span>
          </Fragment>
        ),
      },
    ];

    const headers = [
      { label: "Name", key: "fullname" },
      { label: "Gender", key: "gender" },
      { label: "Email", key: "email" },
      { label: "Birthdate", key: "birthdate" },
      { label: "Age", key: "age" },
      { label: "Telephone", key: "telephone" },
      { label: "Address", key: "address" },
      { label: "Sub District", key: "district" },
      { label: "District", key: "amphure" },
      { label: "Province", key: "province" },
      { label: "Zip Code", key: "zip_code" },
      { label: "Date / Time", key: "created_at" },
      { label: "Code", key: "code" },
    ];

    // const headersExport = headers
    //   ? headers.map((key) => (
    //       <ExcelColumn key={key.key} label={key.label} value={key.key} />
    //     ))
    //   : [];
    const columnsPrintQrCode = [
      {
        title: "Qr Code",
        dataIndex: "qrCode",
        key: "qrCode",
        render: (text, record) => (
          <QRCode
            value={`${process.env.REACT_APP_LIF}${record.code}`} // string
            id="printme2"
            bgColor="transparent"
            size={148} // number
            level="L" // string ('L' 'M' 'Q' 'H')
            includeMargin // boolean if true has bg white(default)
            renderAs="svg"
          />
        ),
      },
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
      },
      {
        title: "จำนวนครั้งในการใข้",
        dataIndex: "used",
        key: "used",
        align: "center",
        render: (text, record) => (
          <span>{`${record.used} / ${record.amount}`}</span>
        ),
      },
    ];
    const columnsGenerateCode = [
      {
        title: "Qr Code",
        dataIndex: "code",
        key: "code",
        width: "30%",
        render: (text, record) => (
          <QRCode
            style={{ marginLeft: -10, marginTop: -10, marginBottom: "-15px" }}
            value={`${process.env.REACT_APP_LIF}${record.code}`} // string
            id="printme2"
            bgColor="transparent"
            size={148} // number
            level="L" // string ('L' 'M' 'Q' 'H')
            includeMargin // boolean if true has bg white(default)
            renderAs="svg"
          />
        ),
      },
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
        render: (text, record) => (
          <span className="text-muted">{record.code}</span>
        ),
      },
      {
        title: "จำนวนครั้งในการใข้",
        dataIndex: "used",
        key: "used",
        align: "center",
        width: "20%",
        render: (text, record) => (
          <span>{`${record.used} / ${record.amount}`}</span>
        ),
      },
    ];

    const id = this.props.match.params.checkInEventId;
    return (
      <>
        {check_token === true && (
          <Layout
            title="Campaign detail"
            extraBtn={[
              <Button
                style={{ display: isEdit[0] === 1 ? "" : "none" }}
                type="primary"
                onClick={this.handleClick}
              >
                Edit
              </Button>,
              <Button
                style={{ display: isDelete[0] === 1 ? "" : "none" }}
                type="primary"
                onClick={() => {
                  confirm({
                    title: "ต้องการลบข้อมูลใช่หรือไม่?",
                    centered: true,
                    onOk: () => this.handleOk(),
                  });
                }}
                danger
              >
                Delete
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "campaign",
                breadcrumbName: "Campaign",
              },
              {
                path: `${id}`,
                breadcrumbName: "Campaign detail",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <Row gutter={[16, 16]}>
                <Col md={6} className="text-center">
                  <Image width="100%" src={imageUrl} />
                  <TagStatus
                    className="mt-4"
                    StatusValue={status === false ? 0 : 1}
                    onClick={this.onChangeSwitch}
                  />
                </Col>
                <Col md={18}>
                  <Descriptions title={nameEvent} column={1}>
                    <Descriptions.Item
                      label="Display date"
                      labelStyle={{ fontWeight: "bold" }}
                    >
                      <DateTimeMid
                        dateTime={moment(start_date, "DD/MM/YYYY")}
                      />
                      <span className="mx-2">-</span>
                      <DateTimeMid dateTime={moment(end_date, "DD/MM/YYYY")} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="จำนวนสิทธิ์"
                      labelStyle={{ fontWeight: "bold" }}
                    >
                      {amount} สิทธิ์
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Point ที่ได้"
                      labelStyle={{ fontWeight: "bold" }}
                    >
                      {pointsEvent} Points
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Code"
                      labelStyle={{ fontWeight: "bold" }}
                    >
                      {modalImgQrCode}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <Tabs
                className="customer-tabs"
                defaultActiveKey="Description"
                onChange={this.callback}
              >
                <TabPane tab="Description" key="desc">
                  <Card>
                    {descEvent && (
                      <div dangerouslySetInnerHTML={{ __html: descEvent }} />
                    )}
                  </Card>
                </TabPane>
                <TabPane tab="Code" key="code">
                  <div>
                    <PageHeader
                      className="title-heading-extra-icon"
                      ghost={false}
                      extra={[
                        <ReactToPrint
                          key="2"
                          trigger={() => (
                            <Button icon={<PrinterOutlined />}>
                              Print QRcode
                            </Button>
                          )}
                          content={() => this.Ref}
                        />,
                      ]}
                    />
                    <PageTable
                      rowKey={(record) => record.created_at}
                      columns={columnsGenerateCode}
                      data={dataGenerateCode}
                      total={totalGenerateCode}
                      onChange={this.handlePaggination}
                      page={page}
                      size={size}
                    />
                    <div className="w-100 d-none text-center mx-auto">
                      <PrintTable
                        className=""
                        ref={(el) => (this.Ref = el)}
                        rowKey={(record) => record.code}
                        columns={columnsPrintQrCode}
                        data={dataPrintQrCode}
                        pagination={false}
                      />
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Report" key="report">
                  <PageHeader
                    className="title-heading-extra-icon"
                    ghost={false}
                    title={`ผู้แลกทั้งหมด ${this.state.totalVoucherReport} คน`}
                    extra={[
                      <CSVLink
                        data={dataPrintCustomer}
                        filename={`${nameEvent}-event(points)-report-${moment().format(
                          "DD MM YYYY",
                        )}.xls`}
                        headers={headers}
                        target="_blank"
                      >
                        <Button type="primary" icon={<UploadOutlined />}>
                          Export to Excel
                        </Button>
                      </CSVLink>,
                    ]}
                  />
                  <div
                    id="qr-code"
                    className="w-100 vh-100 d-none text-center mx-auto"
                  >
                    <PrintQrCode
                      ref={(el) => (this.Ref = el)}
                      code={`${process.env.REACT_APP_LIF}${modalImgQrCode}`}
                      title={nameEvent}
                    />
                  </div>
                  <PageTable
                    rowKey={(record) => record.created_at}
                    columns={columnsCheckInEvent}
                    data={dataVoucherReport}
                    total={this.state.totalVoucherReport}
                    onChange={this.handlePagginationReport}
                    page={page}
                    size={size}
                  />
                  <div className="w-100 d-none text-center mx-auto">
                    <PrintTable
                      className=""
                      ref={(el) => (this.Ref = el)}
                      rowKey={(record) => record.code}
                      columns={columnsPrintQrCode}
                      data={dataPrintQrCode}
                      pagination={false}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoucherView);
