import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Avatar,
  Image,
  Modal,
  Tabs,
  PageHeader,
  Descriptions,
  Select,
  Spin,
  DatePicker,
  Form,
  Slider,
} from "antd";
import { PrinterOutlined, UploadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import QRCode from "qrcode.react";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PrintTable from "../../commons/PrintTable";
import { Layout } from "../../containers";
import {
  DateTimeMid,
  TagStatus,
  PageTable,
  PrintQrCode,
  ListBox,
} from "../../commons";
import { eventsApi } from "../../../apis";
import { userAction } from "../../../store/action";
import moment from "moment";
import "moment/locale/th";
import { refreshToken } from "../../utils/refreshToken";

const { TabPane } = Tabs;
const { confirm } = Modal;
const { Option } = Select;

class VoucherView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingDetails: false,
      loadingGenerateCode: false,
      loadingAllGenerateCode: false,
      loadingAllReport: false,
      loadingReport: false,
      valueRadio: 0,
      active: "Active",
      inactive: "Inactive",
      imageUrl: "",
      nameEvent: "",
      descEvent: "",
      pointsEvent: "",
      status: false,
      amount: "",
      amount_used: 0,
      used: "",
      start_date: "01/01/1997",
      end_date: "01/01/1997",
      modalImgQrCode: "",
      type_used: "",
      time_used: "",
      start: 0,
      length: 20,
      search: "",
      dataGenerateCode: [],
      totalGenerateCode: 0,
      dataVoucherReport: [],
      totalVoucherReport: 0,
      dataPrintCustomer: [],
      dataPrintQrCode: [],
      type_code: 0,
      loading: true,
      isSendCode: false,
      selected: [],
      dataProvinces: [],
      dataAmphures: [],
      dataArtist: [],
      dataOptions: [],
      gender: "",
      day: "",
      month: "",
      province_id: "",
      amphures_id: "",
      artist_id: "",
      age_rage: [0, 100],
      age_from: 0,
      age_to: 100,
      register_date: "",
      keywords: "",
      PageName: "",
      check_token: false,
    };
  }

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  checkToken = async () => {
    const now = moment().unix();
    if (now >= localStorage.getItem("exp_access_token")) {
      const check = await refreshToken();
      if (check) {
        this.timeout = setTimeout(() => {
          this.fetchData();
        }, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    this.setState({
      check_token: true,
    });
    await this.setState({
      loadingDetails: true,
      loadingGenerateCode: true,
      loadingReport: true,
      loadingAllGenerateCode: true,
      loadingAllReport: true,
    });
    const id = this.props.match.params.voucherId;
    const { start, length, search } = this.state;
    const setData = (doc) => {
      const statusA = doc.status !== 0;
      const typeUsed =
        doc.events_details && doc.events_details.type_used === 1
          ? "ใช้ทันที"
          : "เก็บไว้ใช้";
      this.setState({
        id: doc.id,
        nameEvent: doc.name,
        imageUrl: doc.image_m,
        descEvent: doc.events_details.detail,
        pointsEvent: doc.points,
        status: statusA,
        modalQrCodeName: doc.name,
        modalImgQrCode: doc.events_details && doc.events_details.code,
        amount: doc.events_details && doc.events_details.amount,
        amount_used: doc.events_details && doc.events_details.amount_used,
        start_date: doc.events_details && doc.events_details.start_date,
        end_date: doc.events_details && doc.events_details.end_date,
        type_used: typeUsed,
        time_used: doc.events_details && doc.events_details.time_used,
      });
    };
    const eventsVoucherViewId = eventsApi
      .eventsVoucherViewId(id)
      .then((response) => {
        setData(response.data);
        this.setState({
          used: response.used,
          loadingDetails: false,
        });
      });
    const eventsVoucheCustomer = eventsApi
      .eventsVoucheCustomer(start, length, search, id)
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          dataVoucherReport: response.data,
          totalVoucherReport: response.recordsTotal,
          loadingReport: false,
        }));
      });
    const eventsVouchePrintCustomer = eventsApi
      .eventsVouchePrintCustomer(id)
      .then((response) => {
        const data = [];
        response.data.forEach((element) => {
          data.push({
            created_at: element.created_at,
            address: element.customer.address,
            birthdate: element.customer.birthdate,
            email: element.customer.email,
            fullname: element.customer.fullname,
            gender: element.customer.gender,
            telephone: element.customer.telephone,
            amphure:
              element.customer.amphure && element.customer.amphure.name_th,
            district:
              element.customer.district && element.customer.district.name_th,
            zip_code:
              element.customer.district && element.customer.district.zip_code,
            province:
              element.customer.province && element.customer.province.name_th,
            code: element.events_qrcode.code,
          });
          this.setState((prevState) => ({
            ...prevState,
            dataPrintCustomer: data,
            loadingAllReport: false,
          }));
        });
      });
    const eventsVoucherQrCodeAll = eventsApi
      .eventsVoucherQrCodeAll(id)
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          dataPrintQrCode: response.data,
          loadingAllGenerateCode: false,
        }));
      });
    const eventsVoucherQrCode = eventsApi
      .eventsVoucherQrCode(start, length, id)
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          dataGenerateCode: response.data,
          type_code: response.data.map((i) => i.type_code),
          totalGenerateCode: response.recordsTotal,
          loadingGenerateCode: false,
        }));
      });
    await Promise.all([
      eventsVoucheCustomer,
      eventsVoucherViewId,
      eventsVouchePrintCustomer,
      eventsVoucherQrCodeAll,
      eventsVoucherQrCode,
    ]);
    await this.loadSendData();
    await this.setState({ loading: false });
  };

  loadSendData = async () => {
    this.setState({ loading: true });
    const id = this.props.match.params.voucherId;
    const setData = (doc) => {
      this.setState({
        PageName: doc.name,
      });
    };
    await eventsApi.eventsVoucherViewId(id).then((response) => {
      setData(response.data);
    });
    await eventsApi.eventsManualPointsProvinces().then((resp) => {
      this.setState({
        dataProvinces: resp.data,
      });
    });
    await eventsApi.eventsManualPointsArtists().then((resp) => {
      this.setState({
        dataArtist: resp.data,
      });
    });
    await this.setState({
      loading: false,
    });
  };

  showModal = (status) => {
    if (status === true) {
      status = this.state.active;
    } else {
      status = this.state.inactive;
    }

    Swal.fire({
      icon: "success",
      title: `${status} รายการเรียบร้อย`,
    });
  };

  handlePaggination = (start, length, search, page, size) => {
    const id = this.props.match.params.voucherId;
    this.setState({ loadingGenerateCode: true });
    this.setState((prevState) => ({
      ...prevState,
      start,
      length,
      page: page ? page : 1,
      size: size ? size : 20,
    }));
    eventsApi.eventsVoucherQrCode(start, length, id).then((response) => {
      this.setState((prevState) => ({
        ...prevState,
        dataGenerateCode: response.data,
        totalGenerateCode: response.recordsTotal,
        loadingGenerateCode: false,
      }));
    });
  };

  handlePagginationReport = (start, length, search, page, size) => {
    const id = this.props.match.params.voucherId;
    this.setState({ loadingReport: true });
    this.setState((prevState) => ({
      ...prevState,
      start,
      length,
      search,
      page: page ? page : 1,
      size: size ? size : 20,
    }));
    eventsApi
      .eventsVoucheCustomer(start, length, search, id)
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          dataVoucherReport: response.data,
          totalVoucherReport: response.recordsTotal,
          loadingReport: false,
        }));
      });
  };

  showModalQrCode = () => {
    this.setState({
      visibleImgQR: true,
    });
  };

  handleCancelImgQR = () => {
    this.setState({
      visibleImgQR: false,
    });
  };

  handleOk = async () => {
    this.setState({ loading: true });
    await this.setState({
      visible: false,
    });
    await eventsApi.deleteEventsVoucher(this.props.match.params.voucherId).then((resp) => {
      Swal.fire({
        icon: "success",
        title: `${resp.message}`,
        timer: 1500,
      }).then(() => {
        this.setState({
          loading: false,
        });
        this.props.history.push("/voucher");
      });
    });
  };

  handleClick = () => {
    const id = this.props.match.params.voucherId;
    this.props.history.push(`/voucher/${id}/edit`);
    this.setState({
      visible: false,
    });
  };

  handleCancelQr = () => {
    this.setState({
      visibleQr: false,
    });
  };

  onChange = (e) => {
    this.setState({
      valueRadio: e.target.value,
    });
  };

  onChangeSwitch = (checked) => {
    this.setState({
      status: checked,
    });
    const id = this.props.match.params.voucherId;
    const status = checked === false ? 0 : 1;

    eventsApi
      .eventsUpdateVoucherStatus(id, status)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: `${response.message}`,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "success",
          title: `${error.response.data.message}`,
        });
      });
  };

  onChange = (selected) => {
    this.setState({ selected });
  };

  handleChangeGender = (e) => {
    this.setState({
      gender: e,
    });
  };

  handleChangeDay = (e) => {
    this.setState({
      day: e,
    });
  };

  handleRegisterDate = (e) => {
    let date = "";
    if (!e) {
      date = "";
    } else {
      date = e.format("DD-MM-YYYY");
    }
    this.setState({
      register_date: date,
    });
  };

  handleChangeMonth = (e) => {
    this.setState({
      month: e,
    });
  };

  handleChangeProvince = (e) => {
    this.setState({
      province_id: e,
      amphures_id: null,
    });
    const provinceId = e === "" ? 0 : e;
    eventsApi.eventsManualPointsAmphures(provinceId).then((resp) => {
      this.setState({
        dataAmphures: resp.data,
      });
    });
  };

  handleChangeCounty = (e) => {
    this.setState({
      amphures_id: e,
    });
  };

  handleChangeArtists = (e) => {
    this.setState({
      artist_id: e,
    });
  };

  onFinish = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.voucherId;
    Swal.fire({
      title: "ยืนยันการส่งคูปอง",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0c7973",
      confirmButtonText: "ส่งคูปอง",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        eventsApi
          .eventsVoucherSend(id, this.state.select)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: `${res.message}`,
              timer: 1000,
            });
            this.setState({ loading: false });
            this.props.history.goBack();
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
              timer: 1000,
            });
            this.setState({ loading: false });
          });
      }
    });
  };

  onConfirmSearch = () => {
    this.setState({ loading: true });
    const {
      gender,
      day,
      month,
      province_id,
      amphures_id,
      artist_id,
      keywords,
      age_to,
      age_from,
      register_date,
    } = this.state;
    eventsApi
      .eventsManualPointsFilter(
        keywords,
        gender,
        day,
        month,
        province_id,
        amphures_id,
        artist_id,
        age_from,
        age_to,
        register_date,
      )
      .then((resp) => {
        const options = [];
        resp.data.forEach((s) => {
          options.push({ value: s.id, label: s.fullname });
        });
        this.setState({
          dataOptions: options,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  handleChangeAge = (value) => {
    this.setState({ age_from: value[0], age_to: value[1] });
  };

  render() {
    const menus = this.props.menu;
    // type 1 = read, 2 = create, 3 = update, 4 = delete
    const isCreate = [];
    const isEdit = [];
    const isDelete = [];
    menus &&
      menus[16].actions.forEach((doc) => {
        if (doc.type === 2 && doc.status === 1) {
          isCreate.push(1);
        } else if (doc.type === 3 && doc.status === 1) {
          isEdit.push(1);
        } else if (doc.type === 4 && doc.status === 1) {
          isDelete.push(1);
        }
      });
    moment.locale("th");
    const {
      modalImgQrCode,
      statusEvent,
      descEvent,
      imageUrl,
      nameEvent,
      pointsEvent,
      start_date,
      end_date,
      page,
      size,
      dataPrintCustomer,
      amount,
      amount_used,
      dataVoucherReport,
      dataPrintQrCode,
      time_used,
      dataGenerateCode,
      totalGenerateCode,
      dataProvinces,
      selected,
      dataOptions,
      dataAmphures,
      dataArtist,
      loading,
      check_token,
    } = this.state;
    const columnsCheckInEvent = [
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
        render: (text, record) => (
          <span>{record.events_qrcode && record.events_qrcode.code}</span>
        ),
      },
      {
        title: "Redeem date",
        dataIndex: "created_at",
        key: "created_at",
        render: (text, record) => <span>{record.created_at}</span>,
      },
      {
        title: "Customer",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Fragment key={record}>
            <span className="mr-2">
              {record.customer && record.customer.fullname}
            </span>
            <Avatar size={48} src={record.customer && record.customer.image} />
          </Fragment>
        ),
      },
    ];

    const headers = [
      { label: "Name", key: "fullname" },
      { label: "Gender", key: "gender" },
      { label: "Email", key: "email" },
      { label: "Birthdate", key: "birthdate" },
      { label: "Age", key: "age" },
      { label: "Telephone", key: "telephone" },
      { label: "Address", key: "address" },
      { label: "Sub District", key: "district" },
      { label: "District", key: "amphure" },
      { label: "Province", key: "province" },
      { label: "Zip Code", key: "zip_code" },
      { label: "Date / Time", key: "created_at" },
      { label: "Code", key: "code" },
    ];

    const columnsPrintQrCode = [
      {
        title: "Qr Code",
        dataIndex: "qrCode",
        key: "qrCode",
        render: (text, record) => (
          <QRCode
            value={`${process.env.REACT_APP_LIF}${record.code}`} // string
            id="printme2"
            bgColor="transparent"
            size={148} // number
            level="L" // string ('L' 'M' 'Q' 'H')
            includeMargin // boolean if true has bg white(default)
            renderAs="svg"
          />
        ),
      },
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
      },
      {
        title: "จำนวนครั้งในการใข้",
        dataIndex: "used",
        key: "used",
        align: "center",
        render: (text, record) => (
          <span>
            {record.type_code === 2
              ? `${record.used} / ${record.amount}`
              : `${record.used} / ${record.amount_used}`}
          </span>
        ),
      },
    ];
    const columnsGenerateCode = [
      {
        title: "Qr Code",
        dataIndex: "code",
        key: "code",
        width: "30%",
        render: (text, record) => (
          <QRCode
            style={{ marginLeft: -10, marginTop: -10, marginBottom: "-15px" }}
            value={`${process.env.REACT_APP_LIF}${record.code}`} // string
            id="printme2"
            bgColor="transparent"
            size={148} // number
            level="L" // string ('L' 'M' 'Q' 'H')
            includeMargin // boolean if true has bg white(default)
            renderAs="svg"
          />
        ),
      },
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
        render: (text, record) => (
          <span className="text-muted">{record.code}</span>
        ),
      },
      {
        title: "จำนวนครั้งในการใข้",
        dataIndex: "used",
        key: "used",
        align: "center",
        width: "20%",
        render: (text, record) => (
          <span>
            {record.type_code === 2
              ? `${record.used} / ${record.amount}`
              : `${record.used} / ${record.amount_used}`}
          </span>
        ),
      },
    ];

    const listBirthSex = [
      { key: "", label: "ทุกเพศ" },
      { key: "1", label: "ชาย" },
      { key: "2", label: "หญิง" },
      { key: "3", label: "ไม่ระบุ" },
    ];

    const listBirthDay = [
      { key: "", label: "ทุกวันเกิด" },
      { key: "0", label: "วันจันทร์" },
      { key: "1", label: "วันอังคาร" },
      { key: "2", label: "วันพุธ" },
      { key: "3", label: "วันพฤหัสบดี" },
      { key: "4", label: "วันศุกร์" },
      { key: "5", label: "วันเสาร์" },
      { key: "6", label: "วันอาทิตย์" },
    ];

    const listBirthMonth = [
      { key: "", label: "ทุกเดือนเกิด" },
      { key: "1", label: "มกราคม" },
      { key: "2", label: "กุมภาพันธ์" },
      { key: "3", label: "มีนาคม" },
      { key: "4", label: "เมษายน" },
      { key: "5", label: "พฤษภาคม" },
      { key: "6", label: "มิถุนายน" },
      { key: "7", label: "กรกฎาคม" },
      { key: "8", label: "สิงหาคม" },
      { key: "9", label: "กันยายน" },
      { key: "10", label: "ตุลาคม" },
      { key: "11", label: "พฤศจิกายน" },
      { key: "12", label: "ธันวาคม" },
    ];

    const id = this.props.match.params.checkInEventId;
    return (
      <>
        {check_token === true && (
          <Layout
            title="Voucher detail"
            extraBtn={[
              <Button
                style={{ display: isEdit[0] === 1 ? "" : "none" }}
                type="primary"
                onClick={this.handleClick}
              >
                Edit
              </Button>,
              <Button
                style={{ display: isDelete[0] === 1 ? "" : "none" }}
                type="primary"
                onClick={() => {
                  confirm({
                    title: "ต้องการลบข้อมูลใช่หรือไม่?",
                    centered: true,
                    onOk: () => this.handleOk(),
                  });
                }}
                danger
              >
                Delete
              </Button>,
            ]}
            breadcrumbNav={[
              {
                path: "dashboard",
                breadcrumbName: "Dashboard",
              },
              {
                path: "voucher",
                breadcrumbName: "Voucher",
              },
              {
                path: `${id}`,
                breadcrumbName: "Voucher detail",
              },
            ]}
          >
            <Spin spinning={loading} tip="Loading...">
              <Row gutter={[16, 16]}>
                <Col md={6} className="text-center">
                  <Image width="100%" src={imageUrl} />
                  <TagStatus
                    className="mt-4"
                    StatusValue={statusEvent === false ? 0 : 1}
                    onClick={this.onChangeSwitch}
                  />
                </Col>
                <Col md={18}>
                  <Descriptions title={nameEvent} column={1}>
                    <Descriptions.Item
                      label="Display date"
                      labelStyle={{ fontWeight: "bold" }}
                    >
                      <DateTimeMid
                        dateTime={moment(start_date, "DD/MM/YYYY")}
                      />
                      <span className="mx-2">-</span>
                      <DateTimeMid dateTime={moment(end_date, "DD/MM/YYYY")} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="จำนวนโค้ด"
                      labelStyle={{ fontWeight: "bold" }}
                    >
                      {amount} โค้ด
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="สิทธิในการแลก"
                      labelStyle={{ fontWeight: "bold" }}
                    >
                      {amount_used} ครั้ง/คน/โค้ด
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="ระยะเวลาแสดงผล"
                      labelStyle={{ fontWeight: "bold" }}
                    >
                      {time_used} นาที
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Point ที่ได้"
                      labelStyle={{ fontWeight: "bold" }}
                    >
                      {pointsEvent} Points
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <Tabs
                className="customer-tabs"
                defaultActiveKey="Description"
                onChange={this.callback}
              >
                <TabPane tab="Description" key="desc">
                  <Card>
                    {descEvent && (
                      <div dangerouslySetInnerHTML={{ __html: descEvent }} />
                    )}
                  </Card>
                </TabPane>
                <TabPane tab="Code" key="code">
                  {this.state.isSendCode === false ? (
                    <div>
                      <PageHeader
                        className="title-heading-extra-icon"
                        ghost={false}
                        extra={[
                          <Button
                            onClick={() =>
                              this.setState({
                                isSendCode: true,
                              })
                            }
                            type="primary"
                          >
                            ส่งคูปอง
                          </Button>,
                          <ReactToPrint
                            key="2"
                            trigger={() => (
                              <Button icon={<PrinterOutlined />}>
                                Print QRcode
                              </Button>
                            )}
                            content={() => this.Ref}
                          />,
                        ]}
                      />
                      <PageTable
                        rowKey={(record) => record.created_at}
                        columns={columnsGenerateCode}
                        data={dataGenerateCode}
                        total={totalGenerateCode}
                        onChange={this.handlePaggination}
                        page={page}
                        size={size}
                      />
                      <div className="w-100 d-none text-center mx-auto">
                        <PrintTable
                          className=""
                          ref={(el) => (this.Ref = el)}
                          rowKey={(record) => record.code}
                          columns={columnsPrintQrCode}
                          data={dataPrintQrCode}
                          pagination={false}
                        />
                      </div>
                    </div>
                  ) : (
                    <Form layout="vertical">
                      <Card>
                        <Row gutter={[16, 16]} align="bottom">
                          <Col span={24}>
                            <h3>ส่งคูปอง</h3>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              initialValue={this.state.gender}
                              name={["gender"]}
                              label="เพศ"
                            >
                              <Select
                                style={{ width: "100%" }}
                                onChange={this.handleChangeGender}
                              >
                                {listBirthSex.map((item) => (
                                  <Option key={item.key} value={item.key}>
                                    {item.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              initialValue={this.state.day}
                              name={["day"]}
                              label="วันเกิด"
                            >
                              <Select
                                style={{ width: "100%" }}
                                onChange={this.handleChangeDay}
                              >
                                {listBirthDay.map((item) => (
                                  <Option key={item.key} value={item.key}>
                                    {item.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              initialValue={this.state.month}
                              name={["mounth"]}
                              label="เดือนเกิด"
                            >
                              <Select
                                style={{ width: "100%" }}
                                onChange={this.handleChangeDay}
                              >
                                {listBirthMonth.map((item) => (
                                  <Option key={item.key} value={item.key}>
                                    {item.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              initialValue={this.state.age_rage}
                              name={["age_rage"]}
                              label="ช่วงอายุ"
                            >
                              <Slider
                                range
                                step={1}
                                onChange={this.handleChangeAge}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              initialValue={this.state.province_id}
                              name={["province"]}
                              label="จังหวัด"
                            >
                              <Select
                                style={{ width: "100%" }}
                                onChange={this.handleChangeDay}
                              >
                                <Option key="" value="">
                                  ทุกจังหวัด
                                </Option>
                                {dataProvinces.map((item) => (
                                  <Option key={item.id} value={item.id}>
                                    {item.name_th}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              initialValue={this.state.amphures_id}
                              name={["amphures"]}
                              label="เขต"
                            >
                              <Select
                                style={{ width: "100%" }}
                                onChange={this.handleChangeCounty}
                              >
                                <Option key="" value="">
                                  ทุกเขต
                                </Option>
                                {dataAmphures.map((item) => (
                                  <Option key={item.id} value={item.id}>
                                    {item.name_th}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              initialValue={this.state.artist_id}
                              name={["artist"]}
                              label="คิลปิน"
                            >
                              <Select
                                style={{ width: "100%" }}
                                onChange={this.handleChangeArtists}
                              >
                                <Option key="" value="">
                                  ทุกคิลปิน
                                </Option>
                                {dataArtist.map((item) => (
                                  <Option key={item.id} value={item.id}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item name={["date"]} label="วันที่สมัคร">
                              <DatePicker
                                style={{ width: "100%" }}
                                onChange={this.handleRegisterDate}
                                format="DD/MM/YYYY"
                                allowClear
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Button
                              type="primary"
                              style={{ float: "right" }}
                              onClick={this.onConfirmSearch}
                            >
                              Search
                            </Button>
                          </Col>
                        </Row>
                        <Form.Item
                          name={["select"]}
                          rules={[
                            {
                              required: true,
                              message: "กรุณาเลือกผู้ใช้งานที่ต้องการให้คะแนน",
                            },
                          ]}
                        >
                          <ListBox
                            options={dataOptions}
                            selected={selected}
                            onChange={this.onChange}
                          />
                        </Form.Item>
                        <Row>
                          <Col span={24}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={() => this.onFinish()}
                            >
                              Send
                            </Button>
                            <Button
                              className="mx-4"
                              onClick={() =>
                                this.setState({
                                  isSendCode: false,
                                })
                              }
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </Form>
                  )}
                </TabPane>
                <TabPane tab="Report" key="report">
                  <PageHeader
                    className="title-heading-extra-icon"
                    ghost={false}
                    title={`ผู้แลกทั้งหมด ${this.state.totalVoucherReport} คน`}
                    extra={[
                      <CSVLink
                        data={dataPrintCustomer}
                        filename={`${nameEvent}-event(points)-report-${moment().format(
                          "DD MM YYYY",
                        )}.xls`}
                        headers={headers}
                        target="_blank"
                      >
                        <Button type="primary" icon={<UploadOutlined />}>
                          Export to Excel
                        </Button>
                      </CSVLink>,
                    ]}
                  />
                  <div
                    id="qr-code"
                    className="w-100 vh-100 d-none text-center mx-auto"
                  >
                    <PrintQrCode
                      ref={(el) => (this.Ref = el)}
                      code={`${process.env.REACT_APP_LIF}${modalImgQrCode}`}
                      title={nameEvent}
                    />
                  </div>
                  <PageTable
                    rowKey={(record) => record.created_at}
                    columns={columnsCheckInEvent}
                    data={dataVoucherReport}
                    total={this.state.totalVoucherReport}
                    onChange={this.handlePagginationReport}
                    page={page}
                    size={size}
                  />
                  <div className="w-100 d-none text-center mx-auto">
                    <PrintTable
                      className=""
                      ref={(el) => (this.Ref = el)}
                      rowKey={(record) => record.code}
                      columns={columnsPrintQrCode}
                      data={dataPrintQrCode}
                      pagination={false}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </Spin>
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menus.menu,
});

const mapDispatchToProps = (dispatch) => ({
  userAction: bindActionCreators(userAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoucherView);
